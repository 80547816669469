export default function guid() {
  let id = "";
  let i;
  let random;
  for (i = 0; i < 32; i++) {
    random = Math.random() * 16 | 0;
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      id += "-";
    }
    id += (i === 12 ? 4 : i === 16 ? random & 3 | 8 : random).toString(16); // eslint-disable-line no-nested-ternary
  }
  return id;
}