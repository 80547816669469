"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RunQuery = void 0;
const global_1 = require("@memberjunction/global");
/**
 * Class used to run a query and return the results.
 */
class RunQuery {
  async RunQuery(params, contextUser) {
    return RunQuery.Provider.RunQuery(params, contextUser);
  }
  static get Provider() {
    const g = global_1.MJGlobal.Instance.GetGlobalObjectStore();
    if (g) return g[RunQuery._globalProviderKey];else throw new Error('No global object store, so we cant get the static provider');
  }
  static set Provider(value) {
    const g = global_1.MJGlobal.Instance.GetGlobalObjectStore();
    if (g) g[RunQuery._globalProviderKey] = value;else throw new Error('No global object store, so we cant set the static provider');
  }
}
exports.RunQuery = RunQuery;
RunQuery._globalProviderKey = 'MJ_RunQueryProvider';
