<div class="home">
    <div class="section-home" *ngIf="sharedService.LoggedIn">
        <div class="header-banner">
            <h2>Please select from one of the following</h2>
        </div>
        <div class="container-large">
            <div class="card-wrapper">
                <a routerLink="trends">
                    <div class="kendo-card">
                        <img src="../../assets/revenue.svg" alt="revenue" />
                        <h3>Revenue Trends</h3>
                        <p>Explore revenue trends and easily filter them based on a variety of criteria to suit your
                            analysis and insights
                            needs.</p>
                    </div>
                </a>
                <a routerLink="select-role">
                    <div class="kendo-card">
                        <img src="../../assets/role.svg" alt="revenue" />
                        <h3>Salary by Role</h3>
                        <p>
                            Explore roles like CEO, Marketing, IT, etc. Drill down for compensation by role, industry,
                            organization size etc.
                        </p>
                    </div>
                </a>
                <a routerLink="orgs">
                    <div class="kendo-card">
                        <img src="../../assets/orgs.svg" alt="orgs" />
                        <h3>Search by Organization</h3>
                        <p>
                            Search associations by Name, Tax ID, or Domain. View tax summary with compensation data for
                            top
                            employees.
                        </p>
                    </div>
                </a>
                <a routerLink="benchmark">
                    <div class="kendo-card">
                        <img src="../../assets/insights.svg" alt="orgs" />
                        <h3>Benchmarking</h3>
                        <p>
                            Interactively benchmark compensation at your organization using advanced analytics and artificial intelligence.
                        </p>
                    </div>
                </a>
            </div>
        </div>

    </div>


    <!-- <div>
        <app-user-profile></app-user-profile>
    </div> -->
</div>