"use strict";

var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProviderBase = exports.AllMetadataArrays = exports.AllMetadata = void 0;
const baseEntity_1 = require("./baseEntity");
const entityInfo_1 = require("./entityInfo");
const applicationInfo_1 = require("../generic/applicationInfo");
const securityInfo_1 = require("./securityInfo");
const global_1 = require("@memberjunction/global");
const logging_1 = require("./logging");
const queryInfo_1 = require("./queryInfo");
const libraryInfo_1 = require("./libraryInfo");
const explorerNavigationItem_1 = require("./explorerNavigationItem");
/**
 * AllMetadata is used to pass all metadata around in a single object for convenience and type safety.
 */
class AllMetadata {
  constructor() {
    this.CurrentUser = null;
    // Arrays of Metadata below
    this.AllEntities = [];
    this.AllApplications = [];
    this.AllRoles = [];
    this.AllRowLevelSecurityFilters = [];
    this.AllAuditLogTypes = [];
    this.AllAuthorizations = [];
    this.AllQueryCategories = [];
    this.AllQueries = [];
    this.AllQueryFields = [];
    this.AllQueryPermissions = [];
    this.AllEntityDocumentTypes = [];
    this.AllLibraries = [];
    this.AllExplorerNavigationItems = [];
  }
  // Create a new instance of AllMetadata from a simple object
  static FromSimpleObject(data, md) {
    try {
      const newObject = new AllMetadata();
      newObject.CurrentUser = data.CurrentUser ? new securityInfo_1.UserInfo(md, data.CurrentUser) : null;
      // we now have to loop through the AllMetadataArray and use that info to build the metadata object with proper strongly typed object instances
      for (let m of exports.AllMetadataArrays) {
        if (data.hasOwnProperty(m.key)) {
          newObject[m.key] = data[m.key].map(d => new m.class(d, md));
        }
      }
      return newObject;
    } catch (e) {
      (0, logging_1.LogError)(e);
    }
  }
}
exports.AllMetadata = AllMetadata;
/**
 * This is a list of all metadata classes that are used in the AllMetadata class. This is used to automatically determine the class type when deserializing the metadata and otherwise whenever we need to iterate through all of the elements.
 */
exports.AllMetadataArrays = [{
  key: 'AllEntities',
  class: entityInfo_1.EntityInfo
}, {
  key: 'AllApplications',
  class: applicationInfo_1.ApplicationInfo
}, {
  key: 'AllRoles',
  class: securityInfo_1.RoleInfo
}, {
  key: 'AllRowLevelSecurityFilters',
  class: securityInfo_1.RowLevelSecurityFilterInfo
}, {
  key: 'AllAuditLogTypes',
  class: securityInfo_1.AuditLogTypeInfo
}, {
  key: 'AllAuthorizations',
  class: securityInfo_1.AuthorizationInfo
}, {
  key: 'AllQueryCategories',
  class: queryInfo_1.QueryCategoryInfo
}, {
  key: 'AllQueries',
  class: queryInfo_1.QueryInfo
}, {
  key: 'AllQueryFields',
  class: queryInfo_1.QueryFieldInfo
}, {
  key: 'AllQueryPermissions',
  class: queryInfo_1.QueryPermissionInfo
}, {
  key: 'AllEntityDocumentTypes',
  class: entityInfo_1.EntityDocumentTypeInfo
}, {
  key: 'AllLibraries',
  class: libraryInfo_1.LibraryInfo
}, {
  key: 'AllExplorerNavigationItems',
  class: explorerNavigationItem_1.ExplorerNavigationItem
}];
class ProviderBase {
  constructor() {
    this._localMetadata = new AllMetadata();
    this._refresh = false;
    this._cachedVisibleExplorerNavigationItems = null;
  }
  /******** END - ABSTRACT SECTION ****************************************************************** */
  async Config(data) {
    this._ConfigData = data;
    this._localMetadata = new AllMetadata(); // start with fresh metadata
    if (this._refresh || (await this.CheckToSeeIfRefreshNeeded())) {
      // either a hard refresh flag was set within Refresh(), or LocalMetadata is Obsolete
      // first, make sure we reset the flag to false so that if another call to this function happens
      // while we are waiting for the async call to finish, we dont do it again
      this._refresh = false;
      this._cachedVisibleExplorerNavigationItems = null; // reset this so it gets rebuilt next time it is requested
      const start = new Date().getTime();
      const res = await this.GetAllMetadata();
      const end = new Date().getTime();
      (0, logging_1.LogStatus)(`GetAllMetadata() took ${end - start} ms`);
      if (res) {
        this.UpdateLocalMetadata(res);
        this._latestLocalMetadataTimestamps = this._latestRemoteMetadataTimestamps; // update this since we just used server to get all the stuff
        await this.SaveLocalMetadataToStorage();
      }
    }
    return true;
  }
  BuildDatasetFilterFromConfig() {
    // setup the schema filters as needed
    const f = [];
    // make sure that the MJ Core schema is always included if includeSchemas are provided because if the user doesn't include them stuff will break
    const includeSchemaList = this.ConfigData.IncludeSchemas;
    const excludeSchemaList = this.ConfigData.ExcludeSchemas;
    const mjcSchema = this.ConfigData.MJCoreSchemaName;
    // check to see if the MJ Core schema is already in the list, if not add it
    if (includeSchemaList && includeSchemaList.length > 0 && includeSchemaList.indexOf(mjcSchema) === -1) includeSchemaList.push(mjcSchema);
    // check to make sure that if exclude schemas are provided, the list DOES NOT include the MJ Core schema, if it does, remove it
    if (excludeSchemaList && excludeSchemaList.length > 0 && excludeSchemaList.indexOf(mjcSchema) !== -1) {
      const index = excludeSchemaList.indexOf(mjcSchema);
      excludeSchemaList.splice(index, 1);
      (0, logging_1.LogStatus)(`Removed MJ Core schema (${mjcSchema}) from ExcludeSchemas list because it is required for the API to function correctly`);
    }
    let schemaFilter = '';
    if (includeSchemaList && includeSchemaList.length > 0) {
      schemaFilter = 'SchemaName IN (' + includeSchemaList.map(s => `'${s}'`).join(',') + ')';
    }
    if (excludeSchemaList && excludeSchemaList.length > 0) {
      schemaFilter = (schemaFilter.length > 0 ? ' AND ' : '') + 'SchemaName NOT IN (' + excludeSchemaList.map(s => `'${s}'`).join(',') + ')';
    }
    if (schemaFilter.length > 0) {
      f.push({
        ItemCode: 'Entities',
        Filter: schemaFilter
      });
      f.push({
        ItemCode: 'EntityFields',
        Filter: schemaFilter
      });
    }
    return f;
  }
  async GetAllMetadata() {
    try {
      // we are now using datasets instead of the custom metadata to GraphQL to simplify GraphQL's work as it was very slow preivously
      //const start1 = new Date().getTime();
      const f = this.BuildDatasetFilterFromConfig();
      const d = await this.GetDatasetByName(_a._mjMetadataDatasetName, f.length > 0 ? f : null);
      if (d && d.Success) {
        // got the results, let's build our response in the format we need
        const simpleMetadata = {};
        for (let r of d.Results) {
          simpleMetadata[r.Code] = r.Results;
        }
        // Post Process Entities because there's some special handling of the sub-objects
        simpleMetadata.AllEntities = this.PostProcessEntityMetadata(simpleMetadata.Entities, simpleMetadata.EntityFields, simpleMetadata.EntityFieldValues, simpleMetadata.EntityPermissions, simpleMetadata.EntityRelationships, simpleMetadata.EntitySettings);
        // Post Process the Applications, because we want to handle the sub-objects properly.
        simpleMetadata.AllApplications = simpleMetadata.Applications.map(a => {
          a.ApplicationEntities = simpleMetadata.ApplicationEntities.filter(ae => ae.ApplicationID === a.ID);
          a.ApplicationSettings = simpleMetadata.ApplicationSettings.filter(as => as.ApplicationID === a.ID);
          return new applicationInfo_1.ApplicationInfo(a, this);
        });
        // now we need to construct our return type. The way the return type works, which is an instance of AllMetadata, we have to 
        // construst each item so it contains an array of the correct type. This is because the AllMetadata class has an array of each type of metadata
        // rather than just plain JavaScript objects that we have in the allMetadata object.
        // build the base return type
        const returnMetadata = new AllMetadata();
        returnMetadata.CurrentUser = await this.GetCurrentUser(); // set the current user
        // now iterate through the AllMetadataMapping array and construct the return type
        for (let m of exports.AllMetadataArrays) {
          let simpleKey = m.key;
          if (!simpleMetadata.hasOwnProperty(simpleKey)) {
            simpleKey = simpleKey.substring(3); // remove the All prefix
          }
          if (simpleMetadata.hasOwnProperty(simpleKey)) {
            // at this point, only do this particular property if we have a match, it is either prefixed with All or not
            // for example in our strongly typed AllMetadata class we have AllQueryCategories, but in the simple allMetadata object we have QueryCategories
            // so we need to check for both which is what the above is doing.
            // Build the array of the correct type and initialize with the simple object
            returnMetadata[m.key] = simpleMetadata[simpleKey].map(d => new m.class(d, this));
          }
        }
        return returnMetadata;
      } else {
        (0, logging_1.LogError)('GetAllMetadata() - Error getting metadata from server' + (d ? ': ' + d.Status : ''));
      }
    } catch (e) {
      (0, logging_1.LogError)(e);
    }
  }
  PostProcessEntityMetadata(entities, fields, fieldValues, permissions, relationships, settings) {
    const result = [];
    if (fieldValues && fieldValues.length > 0) for (let f of fields) {
      // populate the field values for each field, if we have them
      f.EntityFieldValues = fieldValues.filter(fv => fv.EntityFieldID === f.ID);
    }
    for (let e of entities) {
      e.EntityFields = fields.filter(f => f.EntityID === e.ID).sort((a, b) => a.Sequence - b.Sequence);
      e.EntityPermissions = permissions.filter(p => p.EntityID === e.ID);
      e.EntityRelationships = relationships.filter(r => r.EntityID === e.ID);
      e.EntitySettings = settings.filter(s => s.EntityID === e.ID);
      result.push(new entityInfo_1.EntityInfo(e));
    }
    return result;
  }
  get ConfigData() {
    return this._ConfigData;
  }
  get Entities() {
    return this._localMetadata.AllEntities;
  }
  get Applications() {
    return this._localMetadata.AllApplications;
  }
  get CurrentUser() {
    return this._localMetadata.CurrentUser;
  }
  get Roles() {
    return this._localMetadata.AllRoles;
  }
  get RowLevelSecurityFilters() {
    return this._localMetadata.AllRowLevelSecurityFilters;
  }
  get AuditLogTypes() {
    return this._localMetadata.AllAuditLogTypes;
  }
  get Authorizations() {
    return this._localMetadata.AllAuthorizations;
  }
  get Queries() {
    return this._localMetadata.AllQueries;
  }
  get QueryCategories() {
    return this._localMetadata.AllQueryCategories;
  }
  get QueryFields() {
    return this._localMetadata.AllQueryFields;
  }
  get QueryPermissions() {
    return this._localMetadata.AllQueryPermissions;
  }
  get Libraries() {
    return this._localMetadata.AllLibraries;
  }
  get AllExplorerNavigationItems() {
    return this._localMetadata.AllExplorerNavigationItems;
  }
  get VisibleExplorerNavigationItems() {
    // filter and sort once and cache
    if (!this._cachedVisibleExplorerNavigationItems) this._cachedVisibleExplorerNavigationItems = this._localMetadata.AllExplorerNavigationItems.filter(e => e.IsActive).sort((a, b) => a.Sequence - b.Sequence);
    return this._cachedVisibleExplorerNavigationItems;
  }
  async Refresh() {
    // do nothing here, but set a _refresh flag for next time things are requested
    if (this.AllowRefresh) {
      this._refresh = true;
      return this.Config(this._ConfigData);
    } else return true; // subclass is telling us not to do any refresh ops right now
  }
  async CheckToSeeIfRefreshNeeded() {
    if (this.AllowRefresh) {
      await this.RefreshRemoteMetadataTimestamps(); // get the latest timestamps from the server first
      await this.LoadLocalMetadataFromStorage(); // then, attempt to load before we check to see if it is obsolete
      return this.LocalMetadataObsolete();
    } else
      //subclass is telling us not to do any refresh ops right now
      return false;
  }
  async RefreshIfNeeded() {
    if (await this.CheckToSeeIfRefreshNeeded()) return this.Refresh();else return true;
  }
  async GetEntityObject(entityName, contextUser = null) {
    try {
      const entity = this.Metadata.Entities.find(e => e.Name == entityName);
      if (entity) {
        // Use the MJGlobal Class Factory to do our object instantiation - we do NOT use metadata for this anymore, doesn't work well to have file paths with node dynamically at runtime
        // type reference registration by any module via MJ Global is the way to go as it is reliable across all platforms.
        try {
          const newObject = global_1.MJGlobal.Instance.ClassFactory.CreateInstance(baseEntity_1.BaseEntity, entityName, entity);
          await newObject.Config(contextUser);
          return newObject;
        } catch (e) {
          (0, logging_1.LogError)(e);
          throw new Error(`Entity ${entityName} could not be instantiated via MJGlobal Class Factory.  Make sure you have registered the class reference with MJGlobal.Instance.ClassFactory.Register(). ALSO, make sure you call LoadGeneratedEntities() from the GeneratedEntities project within your project as tree-shaking sometimes removes subclasses and could be causing this error!`);
        }
      } else throw new Error(`Entity ${entityName} not found in metadata`);
    } catch (ex) {
      (0, logging_1.LogError)(ex);
      return null;
    }
  }
  /**
   * Returns a list of entity dependencies, basically metadata that tells you the links to this entity from all other entities.
   * @param entityName
   * @returns
   */
  async GetEntityDependencies(entityName) {
    // using our metadata, find all of the foreign keys that point to this entity
    // go through each entity and find all the fields that have a RelatedEntity = entityName
    try {
      const eName = entityName.trim().toLowerCase();
      const result = [];
      for (let re of this.Entities) {
        const relatedFields = re.Fields.filter(f => f.RelatedEntity?.trim().toLowerCase() === eName);
        // we now have all the fields, so let's create the EntityDependency objects
        relatedFields.map(f => {
          result.push({
            EntityName: entityName,
            RelatedEntityName: re.Name,
            FieldName: f.Name
          });
        });
      }
      return result;
    } catch (e) {
      (0, logging_1.LogError)(e);
      throw e;
    }
  }
  /**
   * Gets a database by name, if required, and caches it in a format available to the client (e.g. IndexedDB, LocalStorage, File, etc). The cache method is Provider specific
   * If itemFilters are provided, the combination of datasetName and the filters are used to determine a match in the cache
   * @param datasetName
   * @param itemFilters
   */
  async GetAndCacheDatasetByName(datasetName, itemFilters) {
    // first see if we have anything in cache at all, no reason to check server dates if we dont
    if (await this.IsDatasetCached(datasetName, itemFilters)) {
      // compare the local version, if exists to the server version dates
      if (await this.IsDatasetCacheUpToDate(datasetName, itemFilters)) {
        // we're up to date, all we need to do is get the local cache and return it
        return this.GetCachedDataset(datasetName, itemFilters);
      } else {
        // we're out of date, so get the dataset from the server
        const dataset = await this.GetDatasetByName(datasetName, itemFilters);
        // cache it
        await this.CacheDataset(datasetName, itemFilters, dataset);
        return dataset;
      }
    } else {
      // get the dataset from the server
      const dataset = await this.GetDatasetByName(datasetName, itemFilters);
      // cache it
      await this.CacheDataset(datasetName, itemFilters, dataset);
      return dataset;
    }
  }
  /**
   * This routine checks to see if the local cache version of a given datasetName/itemFilters combination is up to date with the server or not
   * @param datasetName
   * @param itemFilters
   * @returns
   */
  async IsDatasetCacheUpToDate(datasetName, itemFilters) {
    const ls = this.LocalStorageProvider;
    if (ls) {
      const key = this.GetDatasetCacheKey(datasetName, itemFilters);
      const dateKey = key + '_date';
      const val = await ls.getItem(dateKey);
      if (val) {
        // we have a local cached timestamp, so compare it to the server timestamp
        const status = await this.GetDatasetStatusByName(datasetName, itemFilters);
        if (status) {
          const serverTimestamp = status.LatestUpdateDate.getTime();
          const localTimestamp = new Date(val);
          if (localTimestamp.getTime() >= serverTimestamp) {
            // this situation means our local cache timestamp is >= the server timestamp, so we're most likely up to date
            // in this situation, the last thing we check is for each entity, if the rowcount is the same as the server, if it is, we're good
            // iterate through all of the entities and check the row counts
            const localDataset = await this.GetCachedDataset(datasetName, itemFilters);
            for (const eu of status.EntityUpdateDates) {
              const localEntity = localDataset.Results.find(e => e.EntityID === eu.EntityID);
              if (localEntity && localEntity.Results.length === eu.RowCount) {
                return true;
              } else {
                // we either couldn't find the entity in the local cache or the row count is different, so we're out of date
                // the RowCount being different picks up on DELETED rows. The UpdatedAt check which is handled above would pick up 
                // on any new rows or updated rows. This approach makes sure we detect deleted rows and refresh the cache.
                return false;
              }
            }
          } else {
            // our local cache timestamp is < the server timestamp, so we're out of date
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  /**
   * This routine gets the local cached version of a given datasetName/itemFilters combination, it does NOT check the server status first and does not fall back on the server if there isn't a local cache version of this dataset/itemFilters combination
   * @param datasetName
   * @param itemFilters
   * @returns
   */
  async GetCachedDataset(datasetName, itemFilters) {
    const ls = this.LocalStorageProvider;
    if (ls) {
      const key = this.GetDatasetCacheKey(datasetName, itemFilters);
      const val = await ls.getItem(key);
      if (val) {
        const dataset = JSON.parse(val);
        return dataset;
      }
    }
  }
  /**
   * Stores a dataset in the local cache. If itemFilters are provided, the combination of datasetName and the filters are used to build a key and determine a match in the cache
   * @param datasetName
   * @param itemFilters
   * @param dataset
   */
  async CacheDataset(datasetName, itemFilters, dataset) {
    const ls = this.LocalStorageProvider;
    if (ls) {
      const key = this.GetDatasetCacheKey(datasetName, itemFilters);
      const val = JSON.stringify(dataset);
      await ls.setItem(key, val);
      const dateKey = key + '_date';
      const dateVal = dataset.LatestUpdateDate.toISOString();
      await ls.setItem(dateKey, dateVal);
    }
  }
  /**
   * Determines if a given datasetName/itemFilters combination is cached locally or not
   * @param datasetName
   * @param itemFilters
   * @returns
   */
  async IsDatasetCached(datasetName, itemFilters) {
    const ls = this.LocalStorageProvider;
    if (ls) {
      const key = this.GetDatasetCacheKey(datasetName, itemFilters);
      const val = await ls.getItem(key);
      return val !== null && val !== undefined;
    }
  }
  /**
   * Creates a key for the given datasetName and itemFilters combination
   * @param datasetName
   * @param itemFilters
   * @returns
   */
  GetDatasetCacheKey(datasetName, itemFilters) {
    return _a.localStorageRootKey + '__DATASET__' + datasetName + this.ConvertItemFiltersToUniqueKey(itemFilters);
  }
  ConvertItemFiltersToUniqueKey(itemFilters) {
    if (itemFilters) {
      const key = '{' + itemFilters.map(f => `"${f.ItemCode}":"${f.Filter}"`).join(',') + '}'; // this is a unique key for the item filters
      return key;
    } else return '';
  }
  /**
   * If the specified datasetName is cached, this method will clear the cache. If itemFilters are provided, the combination of datasetName and the filters are used to determine a match in the cache
   * @param datasetName
   * @param itemFilters
   */
  async ClearDatasetCache(datasetName, itemFilters) {
    const ls = this.LocalStorageProvider;
    if (ls) {
      const key = this.GetDatasetCacheKey(datasetName, itemFilters);
      await ls.remove(key);
      const dateKey = key + '_date';
      await ls.remove(dateKey);
    }
  }
  get LatestRemoteMetadata() {
    return this._latestRemoteMetadataTimestamps;
  }
  get LatestLocalMetadata() {
    return this._latestLocalMetadataTimestamps;
  }
  async GetLatestMetadataUpdates() {
    const f = this.BuildDatasetFilterFromConfig();
    const d = await this.GetDatasetStatusByName(_a._mjMetadataDatasetName, f.length > 0 ? f : null);
    if (d && d.Success) {
      const ret = d.EntityUpdateDates.map(e => {
        return {
          ID: e.EntityID,
          Type: e.EntityName,
          UpdatedAt: e.UpdateDate,
          RowCount: e.RowCount
        };
      });
      // combine the entityupdate dates with a single top level entry for the dataset itself
      ret.push({
        ID: "",
        Type: 'All Entity Metadata',
        UpdatedAt: d.LatestUpdateDate,
        RowCount: d.EntityUpdateDates.reduce((a, b) => a + b.RowCount, 0)
      });
      return ret;
    }
  }
  async RefreshRemoteMetadataTimestamps() {
    const mdTimeStamps = await this.GetLatestMetadataUpdates(); // sub-class implements this 
    if (mdTimeStamps) {
      this._latestRemoteMetadataTimestamps = mdTimeStamps;
      return true;
    } else return false;
  }
  LocalMetadataObsolete(type) {
    const mdLocal = this.LatestLocalMetadata;
    const mdRemote = this.LatestRemoteMetadata;
    if (!mdLocal || !mdRemote || !mdLocal.length || !mdRemote.length || mdLocal.length === 0 || mdRemote.length === 0) return true;
    for (let i = 0; i < mdRemote.length; ++i) {
      let bProcess = true;
      if (type && type.length > 0) bProcess = mdRemote[i].Type.toLowerCase().trim() === type.trim().toLowerCase();
      if (bProcess) {
        const l = mdLocal.find(md => md.Type.trim().toLowerCase() === mdRemote[i].Type.trim().toLowerCase());
        if (!l) return true; // no match, obsolete in this case 
        else {
          // we have a match, now test various things
          if (!l.UpdatedAt && !mdRemote[i].UpdatedAt) {
            // both are null, so we're good
            // do nothing, keep on truckin'
            // console.log('TEST: both are null, so we\'re good')
          } else if (l.UpdatedAt && mdRemote[i].UpdatedAt) {
            // both are not null, so we need to compare them
            const localTime = new Date(l.UpdatedAt);
            const remoteTime = new Date(mdRemote[i].UpdatedAt);
            if (localTime.getTime() !== remoteTime.getTime()) {
              return true; // we can short circuit the entire rest of the function 
              // as one obsolete is good enough to obsolete the entire local metadata
            } else {
              // here we have a match for the local and remote timestamps, so we need to check the row counts
              // if the row counts are different, we're obsolete
              if (l.RowCount !== mdRemote[i].RowCount) {
                return true;
              }
            }
          } else return true; // one is null and the other is not, so we're obsolete without even comparing
        }
      }
    }
    // if we get here, we're not obsolete!!
    return false;
  }
  UpdateLocalMetadata(res) {
    this._localMetadata = res;
  }
  async LoadLocalMetadataFromStorage() {
    try {
      const ls = this.LocalStorageProvider;
      if (ls) {
        // execution environment supports local storage, use it
        this._latestLocalMetadataTimestamps = JSON.parse(await ls.getItem(_a.localStorageTimestampsKey));
        const temp = JSON.parse(await ls.getItem(_a.localStorageAllMetadataKey)); // we now have a simple object for all the metadata
        if (temp) {
          // we have local metadata
          const metadata = AllMetadata.FromSimpleObject(temp, this); // create a new object to start this up
          this.UpdateLocalMetadata(metadata);
        }
      }
    } catch (e) {
      // some enviroments don't support local storage
    }
  }
  async SaveLocalMetadataToStorage() {
    try {
      const ls = this.LocalStorageProvider;
      if (ls) {
        // execution environment supports local storage, use it
        await ls.setItem(_a.localStorageTimestampsKey, JSON.stringify(this._latestLocalMetadataTimestamps));
        // now persist the AllMetadata object
        await ls.setItem(_a.localStorageAllMetadataKey, JSON.stringify(this._localMetadata));
      }
    } catch (e) {
      // some enviroments don't support local storage
      (0, logging_1.LogError)(e);
    }
  }
  async RemoveLocalMetadataFromStorage() {
    try {
      const ls = this.LocalStorageProvider;
      for (let i = 0; i < _a.localStorageKeys.length; i++) {
        await ls.remove(_a.localStorageKeys[i]);
      }
    } catch (e) {
      // some enviroments don't support local storage
      (0, logging_1.LogError)(e);
    }
  }
}
exports.ProviderBase = ProviderBase;
_a = ProviderBase;
ProviderBase._mjMetadataDatasetName = 'MJ_Metadata';
ProviderBase.localStorageRootKey = '___MJCore_Metadata';
ProviderBase.localStorageTimestampsKey = _a.localStorageRootKey + '_Timestamps';
ProviderBase.localStorageAllMetadataKey = _a.localStorageRootKey + '_AllMetadata';
ProviderBase.localStorageKeys = [_a.localStorageTimestampsKey, _a.localStorageAllMetadataKey];
