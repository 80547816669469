export const environment = {
    GRAPHQL_URI: 'https://ggmjapi-test.azurewebsites.net/',
    GRAPHQL_WS_URI: 'wss://ggmjapi-test.azurewebsites.net/',
    REDIRECT_URI: 'https://dev.greengopher.ai/',
    CLIENT_ID: '0M5srY0BGEa8RPTWT6NgdBgOFJypqEE9',
    CLIENT_AUTHORITY: 'dev-y44or1it10zq4tvx.us.auth0.com',
    AUTH_TYPE: 'Auth0',
    NODE_ENV: 'production',
    AUTOSAVE_DEBOUNCE_MS: 1200,
    SEARCH_DEBOUNCE_MS: 800,
    MIN_SEARCH_LENGTH: 3,
    production: false,
    APP_TITLE: 'Green Gopher',
  };
  