import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogError, Metadata, RunView } from '@memberjunction/core';
import { SharedService } from '../shared-service';
import { TaxReturnCompensationEntity, TaxReturnEntity } from 'mj_generatedentities';
import { SharedData } from '../shared-data';
import { RowClassArgs } from "@progress/kendo-angular-grid";

interface TaxReturnEntityInterface extends InstanceType<typeof TaxReturnEntity> {
  [key: string]: any;
}

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {
  public orgName: string = "Loading..."
  public taxId: string | null = null;
  public taxReturns: TaxReturnEntityInterface[] = [];
  public latestTaxReturn: TaxReturnEntity | null = null;
  public compensationData: TaxReturnCompensationEntity[] = [];
  public processedCompensationData: ProcessedCompensation[] = [];
  public compensationGridColumns: any[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private sharedService: SharedService, private sharedData: SharedData) {}

  ngOnInit() {
    this.taxId = this.route.snapshot.paramMap.get('taxId');
    if (this.taxId) {
      // Do something with the organizationId
      this.Refresh();
    }
  }

  public loading: boolean = false;
  public async Refresh() {
    this.loading = true;
    this.sharedService.setupComplete$.subscribe(async (isComplete) => {
      if (isComplete) {
        await this.LoadTaxReturns();
        await this.LoadBenchmarkData();
        await this.LoadFinancials();
        await this.LoadTaxReturnCompensation();
        await this.LoadPersonBenchmarks()
        this.loading = false;    
      }
    });
  }

  public benchmarkData: any;
  protected async LoadBenchmarkData() {
    try {
      // Get Metro Area Size
      const postalCode = this.latestTaxReturn?.PostalCode?.slice(0,5); //some tax return data has longer codes than exist in the postal code table
      const rv = new RunView();
      const postal_code_result = await rv.RunView({
        EntityName: 'Postal Codes',
        ExtraFilter: `PostalCode=${postalCode} AND IsPrimaryRecord=1`,
      })
      const countyId = postal_code_result.Results[0].CountyID
      const county_result = await rv.RunView({
        EntityName: 'Counties',
        ExtraFilter: `ID=${countyId}`,
      })
      const metroAreaId = county_result.Results[0].MetroAreaID;
      const metro_result = await rv.RunView({
        EntityName: 'Metro Areas',
        ExtraFilter: `ID=${metroAreaId}`,
      })
      const populationEstimate = metro_result.Results[0].PopulationEstimate;
      const metroAreaSize = this.sharedData.RangeClasses.filter(r => r.Type === "Metro Areas" && r.MinVal && r.MinVal <= populationEstimate && (r.MaxVal ? r.MaxVal : Number.MAX_SAFE_INTEGER) >= populationEstimate)[0].Name;

      // Get Org Size Employees
      const numberEmployees = this.latestTaxReturn?.NumberEmployees ? this.latestTaxReturn.NumberEmployees : 0;
      const orgSizeEmployees = this.sharedData.RangeClasses.filter(r => r.Type === "Employees" && r.MinVal && r.MinVal <= numberEmployees && (r.MaxVal ? r.MaxVal : Number.MAX_SAFE_INTEGER) >= numberEmployees)[0].Name

      // Get Org Size Revenue
      const revenue = this.latestTaxReturn?.TotalRevenue ? this.latestTaxReturn.TotalRevenue : 0;
      const orgSizeRevenue = this.sharedData.RangeClasses.filter(r => r.Type === "Revenue" && r.MinVal && r.MinVal <= revenue && (r.MaxVal ? r.MaxVal : Number.MAX_SAFE_INTEGER) >= revenue)[0].Name

      const industry = this.latestTaxReturn?.Industry ? this.latestTaxReturn.Industry : '';
      
      console.log(this.latestTaxReturn)
      debugger;
      this.benchmarkData = this.sharedData.TaxReturnComp.filter(t => t.MetroAreaSize === metroAreaSize && t.OrgSizeEmployees == orgSizeEmployees && t.OrgSizeRevenue == orgSizeRevenue && t.Industry == industry)
    }
    catch (e) {
      this.sharedService.DisplayNotification('Error loading benchmark data', 'error');
      LogError(e);
    }
  }

  protected async LoadTaxReturns() {
    try {
      const rv = new RunView();
      const result = await rv.RunView({
        EntityName: 'Tax Returns',
        ExtraFilter: `TaxID='${this.taxId}'`,
        OrderBy: 'TaxYear DESC',
      })
      if (result && result.Success && result.Results.length > 0) {
        // we have the data, now we can do something with it
        this.taxReturns = result.Results;
        this.latestTaxReturn = this.taxReturns[0];
        this.orgName = this.latestTaxReturn.Name;
      }
      else 
        throw result.ErrorMessage
    }
    catch (e) {
      this.sharedService.DisplayNotification('Error loading organization data', 'error');
      LogError(e);
    }
  }

  public loadingCompData: boolean = false;
  protected async LoadTaxReturnCompensation() {
    try {
      this.loadingCompData = true;
      
      const rv = new RunView();
      const result = await rv.RunView({
        EntityName: 'Tax Return Compensation',
        ExtraFilter: `TaxReturnID IN (${this.taxReturns.map(tr => tr.ID).join(',')}) AND
                      TotalCompensation > 0 AND AverageHoursPerWeek >= 30`,
        OrderBy: 'TaxReturnID, TotalCompensation DESC',
      });
      if (result && result.Success && result.Results.length > 0) {
        // we have the data, now we can do something with it
        this.compensationData = result.Results;
        this.summarizeCompensationDataByPerson();
      }
      else {
        // no data, clear the grid
        this.compensationData = [];
        this.processedCompensationData = [];
        this.compensationGridColumns = [];
        this.sharedService.DisplayNotification('No compensation data found for this organization', 'info');
      }
      this.loadingCompData = false;
    }
    catch (e) {
      this.loadingCompData = false;
      this.sharedService.DisplayNotification('Error loading organization compensation data', 'error');
      LogError(e);
    }
  }

  public loadingFinancials: boolean = false;
  public summaryFinancials: any[] = [];
  public summaryFinancialsGridColumns: any[] = [];  
  protected async LoadFinancials() {
    // go through all the financials and create a summary table in an array that has rows for each of the key financial metrics and the years as columns
    try {
      // the data is all in the tax returns, so we can just use that
      this.loadingFinancials = true;

      // Fields you want to pivot
      const fieldsToPivot = ['TotalRevenue', 'TotalMemberIncome', 'TotalProgramServiceRevenue', 'OfficerCompensation','OtherSalaries', 'LobbyingFees','AdvertisingExpense','InformationTechnologyExpense', 'TotalAssets', 'TotalLiabilities', 'TotalNetAssets', 'FinancialStrength'];
      const md = new Metadata();
      const trEntity = md.Entities.find(e => e.Name === 'Tax Returns');
      if (!trEntity)
        throw 'Unable to find Tax Returns entity in metadata';

      // Initialize an array to hold the pivoted data
      const pivotedArray: any[] = [];
      const uniqueYears: number[] = [];
      // Loop through each field to pivot
      this.summaryFinancials = [];
      fieldsToPivot.forEach((field) => {
        // Initialize an empty object to hold the pivoted data for this field
        const ef = trEntity.Fields.find(f => f.Name === field);
        const pivotedObject: any = { "Field": field, "Title": ef?.DisplayName ? ef.DisplayName : field };

        // Loop through each taxReturn record to populate the pivotedObject
        this.taxReturns.forEach((taxReturn) => {
          const year = taxReturn.TaxYear;
          if (!uniqueYears.includes(year))
            uniqueYears.push(year);
          const value = taxReturn[field];

          // Add a new property to the object for each TaxYear
          pivotedObject['_' + year] = value;
        });

        // Add the pivoted object for this field to the array
        this.summaryFinancials.push(pivotedObject);
      });

      // now generate the columsn so the grid can display things nicely
      this.summaryFinancialsGridColumns = [];
      this.summaryFinancialsGridColumns.push({ Name: 'Title', Title: 'Item', Width: 100 });
      uniqueYears.sort((a, b) => a - b);
      uniqueYears.forEach((year) => {
        this.summaryFinancialsGridColumns.push({ Name: '_' + year.toString(), Title: year.toString(), Width: 100, Format: '{0:C0}', Class: 'text-right' });
      });
      this.loadingFinancials = false;
    }
    catch (e) {
      this.loadingFinancials = false;
      this.sharedService.DisplayNotification('Error loading organization financial data', 'error');
      LogError(e);
    }
  }

  public loadingBenchmark: boolean = false;
  public personBenchmarkData: any[] = [];
  public benchmarkGridColumns: any[] = [
    { Name: 'Person', Title: 'Person', Width: 125 },
    { Name: 'Title', Title: 'Title', Width: 125 },
    { Name: 'Role', Title: 'Role' , Width: 125},
    { Name: 'Level', Title: 'Level' , Width: 125},
    { Name: 'MaxYear', Title: 'Year' , Width: 125},
    { Name: 'MostRecentCompensation', Title: 'Compensation' , Width: 150, Format: '{0:C0}'},
    { Name: 'AvgCompensation', Title: 'Average' , Width: 125, Format: '{0:C0}'},
    { Name: 'FirstQuartile', Title: '1st Quartile' , Width: 125 , Format: '{0:C0}'},
    { Name: 'SecondQuartile', Title: 'Median' , Width: 125, Format: '{0:C0}'},
    { Name: 'ThirdQuartile', Title: '3rd Quartile' , Width: 125, Format: '{0:C0}'},
  ];  
  protected async LoadPersonBenchmarks() {
    try {
      this.loadingBenchmark = true;
      const processedCompensationData = this.processedCompensationData;
      processedCompensationData.forEach((item) => {
        const newRow: any = {
          Person: item.Person,
          Title: item.Title,
          Level: item.Level,   
          Role: item.Role,     
          MaxYear: item.MaxYear
        };

        const benchmarkData = this.benchmarkData;
        const benchmark = benchmarkData.filter((r: {
          ContactLevel: string;
          ContactRole: string; 
          TaxYear: any; 
        }) => r.TaxYear == item.MaxYear && r.ContactRole == item.Role && r.ContactLevel == item.Level)

        newRow['MostRecentCompensation'] = item.MostRecentCompensation;
        newRow['AvgCompensation'] = benchmark[0]?.AverageComp ? benchmark[0].AverageComp : null;
        newRow['FirstQuartile'] = benchmark[0] ? benchmark[0].FirstQuartileComp : null;
        newRow['SecondQuartile'] = benchmark[0]?.SecondQuartileComp ? benchmark[0].SecondQuartileComp : null;
        newRow['ThirdQuartile']= benchmark[0] ? benchmark[0].ThirdQuartileComp : null;

        this.personBenchmarkData.push(newRow)
      });

      this.loadingBenchmark = false;
    }
    catch (e) {
      this.loadingBenchmark = false;
      this.sharedService.DisplayNotification('Error loading organization benchmark data', 'error');
      LogError(e); 
    }
  }
  public getCellClass(field: string, item: any): string {
    if (field === 'MostRecentCompensation' && item["AvgCompensation"] != null) {
      if (item[field] > item["ThirdQuartile"]){
        return 'high'
      }
      else if (item[field] < item["FirstQuartile"]){
        return 'low'
      }
      else {
        return 'normal'
      }
    }
    return '';
  }

  public getOrgWebsite(latestTaxReturn: TaxReturnEntity) {
    if (latestTaxReturn.Website)
      if (latestTaxReturn.Website.startsWith('http://') || latestTaxReturn.Website.startsWith('https://') )
        return latestTaxReturn.Website;
      else 
        return `http://${latestTaxReturn.Website}`;
    else
      return null;
  }

  public getTotalHCECompByTaxReturn(TaxReturnID: number) {
    // summarize all of the TotalCompensation fields within the compensation array that have the same TaxReturnID
    const totalComp = this.compensationData.filter(cd => cd.TaxReturnID === TaxReturnID).reduce((acc, curr) => acc + (curr.TotalCompensation ?? 0), 0);
    return totalComp;    
  }
  public getTotalHCECountByTaxReturn(TaxReturnID: number) {
    // count the # of people in the latest tax return comp that have > 0 in the TotalCompensation field
    const totalHCECount = this.compensationData.filter(cd => cd.TaxReturnID === TaxReturnID && (cd.TotalCompensation ?? 0) > 0).length;
    return totalHCECount;
  }

  protected getFirstAndLastName(fullName: string) {
    // first check to see if there is a single dash in the name, is so, grab everthing to the left of that
    let tempName = fullName
    const dashIndex = tempName.indexOf('-');
    if (dashIndex > 0)
      tempName = tempName.substring(0, dashIndex - 1);

    // now we've removed the dash if it was there, let's grab the fist and the last full word in the name
    const parts = tempName.split(' ');
    return `${parts[0]} ${parts[parts.length - 1]}`;
  };

  public summarizeCompensationDataByPerson() {
    // first group the data by PersonName
    const distinctYears: number[] = [];
    const groupedData: { [name: string]: TaxReturnCompensationEntity[] } = {};

    this.compensationData.forEach((item) => {
      const simplifiedName = this.getFirstAndLastName(item.PersonName);
      // update the item.PersonName so it matches later
      item.PersonName = simplifiedName;

      if (!groupedData[simplifiedName]) {
        groupedData[simplifiedName] = [];
      }
      groupedData[simplifiedName].push(item);
    });

    // Process into new structure that has a column for each year of comp data
    this.processedCompensationData = [];

    for (const [personName, compensationRecords] of Object.entries(groupedData)) {
      const contactLevel = ''; //compensationRecords[0].ContactLevel
      const contactRole = ''; //compensationRecords[0].ContactRole
      const newRow: any = {
        Person: personName,
        Title: compensationRecords[0].Title, // Assuming the title/level/role doesn't change over the years
        Level: contactLevel,   
        Role: contactRole,     
      };

      // now create a new property in the object for each year of compensation data
      var maxYear = -1;
      compensationRecords.forEach((item) => {
        const year = this.getTaxReturnYear(item['TaxReturnID']);
        if (year > maxYear) {
          maxYear = year;
        }
        if (!distinctYears.includes(year))
          distinctYears.push(year);
        newRow['_' + year] = item.TotalCompensation;

        const benchmark = this.benchmarkData.filter((r: {
          ContactLevel: string;
          ContactRole: string; 
          TaxYear: any; 
        }) => r.TaxYear == year && r.ContactRole == contactRole && r.ContactLevel == contactLevel)
        const avgComp = benchmark[0]?.AverageComp ? benchmark[0].AverageComp : null;
        const medianComp = benchmark[0]?.SecondQuartileComp ? benchmark[0].SecondQuartileComp : null;
        const iqrComp = benchmark[0] ? `${benchmark[0].ThirdQuartileComp} - ${benchmark[0].FirstQuartileComp}` : null;
        newRow['_' + year + 'Average'] = avgComp;
        newRow['_' + year + 'Median'] = medianComp;
        newRow['_' + year + 'IQR'] = iqrComp;

        if (year == maxYear) {
          newRow['MaxYear'] = year
          newRow['Average'] = avgComp;
          newRow['Median'] = medianComp;
          newRow['IQR'] = iqrComp;
          newRow['MostRecentCompensation'] = item.TotalCompensation;
        }

      });
    
      this.processedCompensationData.push(newRow);
    }

    // Sort the processed data by total compensation, highest to lowest
    this.processedCompensationData.sort((a, b) => {
      const totalCompensationA = Object.values(a)
        .filter(value => typeof value === 'number')
        .reduce((acc, curr) => acc + curr, 0);

      const totalCompensationB = Object.values(b)
        .filter(value => typeof value === 'number')
        .reduce((acc, curr) => acc + curr, 0);

      return totalCompensationB - totalCompensationA;
    });    

    // lastly, configure the comp columns array for the grid
    this.compensationGridColumns = [
      { Name: 'Person', Title: 'Person', Width: 125 },
      { Name: 'Role', Title: 'Role' , Width: 125},
      { Name: 'Title', Title: 'Title', Width: 125 },
    ];
    distinctYears.sort((a, b) => a - b); // put the years in order 
    distinctYears.forEach((year) => {
      this.compensationGridColumns.push({ 
        Name: '_' + year.toString(), 
        Title: year.toString(), 
        Width: 100,
        Format: '{0:C0}' });
    });

    // add benchmark columns
    this.compensationGridColumns.push(
      {Name: 'Average', Title: 'Average', Format: '{0:C0}'},
      {Name: 'Median', Title: 'Median', Format: '{0:C0}'},
      {Name: 'IQR', Title: 'IQR', Format: '{0:C0}'},
    )
  }

  public getTaxReturn(TaxReturnID: number): TaxReturnEntity | undefined {
    return this.taxReturns.find(tr => tr.ID === TaxReturnID);
  }
  public getTaxReturnYear(TaxReturnID: number): number {
    const tr = this.getTaxReturn(TaxReturnID);
    if (tr)
      return tr.TaxYear;
    else
      return 0;
  }

  public toInt(value: any): number {
    return parseInt(value);
  }

}

interface ProcessedCompensation {
  Person: string;
  Title: string;
  Level: string; // Assuming this comes from another place
  Role: string;  // Assuming this comes from another place
  MaxYear: number;
  MostRecentCompensation: number;
  YearlyCompensation: { [year: number]: number };
}