import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared-service';
import { SharedData } from '../shared-data';
import { RunView, RunViewResult } from '@memberjunction/core';
import { AuthService } from '@auth0/auth0-angular';
import { ContactRoleEntity } from 'mj_generatedentities';

@Component({
  selector: 'app-salary-drilldown',
  templateUrl: './salary-drilldown.component.html',
  styleUrls: ['./salary-drilldown.component.css']
})
export class SalaryDrilldownComponent {
  public filterParams: any;
  public gridData: any[] = [];
  public latestResult: RunViewResult | null = null;
  public loading: boolean = true;
  public colHeaderStyle = {};
  public roleRecord: ContactRoleEntity | undefined = undefined;

  constructor(public auth: AuthService, private route: ActivatedRoute, private sharedService: SharedService, public sharedData: SharedData) {
    this.sharedService.setupComplete$.subscribe(async (isComplete) => {
      if (isComplete) {
        this.evaluateQueryParams();
      }
    });

    this.colHeaderStyle = this.sharedService.ColHeaderStyle;
  }

  protected evaluateQueryParams() {
    this.route.queryParams.subscribe(params => {
      // make sure we've loaded the data before we try to apply the filters
      this.filterParams = params;
      this.Refresh();
    });    
  }

  public async Refresh() {
    // we need to run a view to get Tax Return Compensation Enriched records back
    this.loading = true;
    const rv = new RunView();
    this.latestResult = await rv.RunView({
      EntityName: 'Tax Return Compensation Enriched',
      ExtraFilter: this.CreateSQLFilterFromParams()
    })
    if (this.latestResult && this.latestResult.Success)
      this.gridData = this.latestResult.Results;

    this.loading = false;
  }

  protected CreateSQLFilterFromParams(): string {
    const p = this.filterParams;
    let sSQL: string = 'TotalCompensation > 0 AND AverageHoursPerWeek > 10';
    if (p.roleId){
      sSQL = this.addClause(sSQL, `ContactRoleID = ${p.roleId}`);
      this.roleRecord = this.sharedData.ContactRoles.find(r => r.ID === +p.roleId);
    } 
    if (p.contactLevel) 
      sSQL = this.addClause(sSQL, `ContactLevel = '${p.contactLevel}'`);
    if (p.taxYearStart)
      sSQL = this.addClause(sSQL, `TaxYear >= ${p.taxYearStart}`);
    if (p.taxYearEnd)
      sSQL = this.addClause(sSQL, `TaxYear <= ${p.taxYearEnd}`);
    if (p.industry)
      sSQL = this.addClause(sSQL, `Industry = '${p.industry}'`);
    if (p.revenue)
      sSQL = this.addClause(sSQL, `OrgSizeRevenue = '${p.revenue}'`);
    if (p.employees)
      sSQL = this.addClause(sSQL, `OrgSizeEmployees = '${p.employees}'`);
    if (p.metroAreaSize)
      sSQL = this.addClause(sSQL, `MetroAreaSize = '${p.metroAreaSize}'`);

    if (p.groupByField && p.drillDownValue) 
        sSQL = this.addClause(sSQL, `${p.groupByField} = '${p.drillDownValue}'`);
  


    return sSQL;
  }

  protected addClause(sql: string, clause: string) : string {
    return sql + (sql.length === 0 ? '' : ' AND ') + clause;    
  }
}


/*
      const qp = {
        metroAreaSize: this.selectedMetroAreaSize?.Name,
        industry: this.selectedIndustry?.Name,
        employees: this.selectedOrgSizeEmployees?.Name,
        revenue: this.selectedOrgSizeRevenue?.Name,
      };
*/