import { getSupportedFeatures } from '../common';

/* eslint-disable arrow-body-style, no-useless-escape */

export const extend = Object.assign;
export const proxy = (method, context) => {
  return method.bind(context);
};
export let convertToHtml = html => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.firstChild;
};
export let appendHtml = (html, element) => {
  const div = document.createElement('div');
  div.innerHTML = html;

  // use childNodes instead of children
  // to cover text nodes as well
  while (div.childNodes.length > 0) {
    element.appendChild(div.childNodes[0]);
  }
};
export let removeChildren = element => {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
};
export let prepend = (element, originElement) => {
  originElement.insertBefore(element, originElement.firstChild);
};
export let wrapInner = (parent, wrapper) => {
  parent.appendChild(wrapper);
  while (parent.firstChild !== wrapper) {
    wrapper.appendChild(parent.firstChild);
  }
};
export let toHyphens = str => {
  const result = str.replace(/([a-z][A-Z])/g, function (g) {
    return g.charAt(0) + '-' + g.charAt(1).toLowerCase();
  });
  return result;
};
export let toPixels = value => {
  let result;
  if (value && String(value).endsWith("px")) {
    result = value;
  } else {
    result = String(value) + "px";
  }
  return result;
};
export let hasNativeScrolling = userAgent => {
  const {
    mobileOS
  } = getSupportedFeatures(userAgent);
  return mobileOS.ios || mobileOS.android;
};
export const setDefaultEvents = (type, events) => {
  const proto = type.prototype;
  if (proto.events) {
    events.forEach(event => {
      if (proto.events.indexOf(event) < 0) {
        proto.events.push(event);
      }
    });
  } else {
    proto.events = events;
  }
};
export const wheelDeltaY = e => {
  const deltaY = e.wheelDeltaY;
  let delta;
  if (e.wheelDelta) {
    // Webkit and IE
    if (deltaY === undefined || deltaY) {
      // IE does not have deltaY, thus always scroll (horizontal scrolling is treated as vertical)
      delta = e.wheelDelta;
    }
  } else if (e.detail && e.axis === e.VERTICAL_AXIS) {
    // Firefox and Opera
    delta = -e.detail * 10;
  }
  return delta;
};
export const renderPos = pos => {
  let result = [];
  if (pos) {
    let parts = toHyphens(pos).split("-");
    for (let i = 0; i < parts.length; i++) {
      result.push("k-pos-" + parts[i]);
    }
  }
  return result.join(" ");
};

/* eslint-enable arrow-body-style, no-useless-escape */