import { drawing as draw } from '@progress/kendo-drawing';
import ShapeElement from "../../core/shape-element";
import { deepExtend, setDefaultOptions } from '../../common';
import { CENTER, CIRCLE, LEFT, RECT, TOP, WHITE } from "../../common/constants";
import MarkerLineArea from "./legend-item-line-area";
import { DEFAULT_MARKER_BORDER_WIDTH, DEFAULT_MARKER_SIZE } from "./legend-item-marker";
class LegendItemLine extends ShapeElement {
  getElement() {
    const group = new draw.Group();
    const element = new draw.MultiPath({
      stroke: {
        color: this.options.border.color,
        opacity: this.options.opacity,
        width: this.options.height,
        dashType: this.options.dashType
      }
    });
    const box = this.paddingBox;
    const centerY = box.center().y;
    element.moveTo(box.x1, centerY).lineTo(box.x2, centerY);
    group.append(element);
    return group;
  }
  createHighlight() {
    this.highlight = [this.createHighlightLine(), this.createHighlightMarker()];
    return this.highlight;
  }
  createHighlightLine() {
    const options = deepExtend({}, {
      border: {
        color: this.options.border.color,
        opacity: this.options.border.opacity,
        dashType: this.options.border.dashType
      }
    }, this.options.highlight);
    const highlightLine = new MarkerLineArea(options);
    highlightLine.reflow(this.parent.paddingBox.clone());
    this.highlightLine = highlightLine.getElement();
    return this.highlightLine;
  }
  createHighlightMarker() {
    const options = deepExtend({}, {
      background: this.options.background
    }, this.options.highlight.markers);
    const highlightMarker = new ShapeElement(options);
    const paddingBox = this.parent.paddingBox;
    const borderWidth = options.border.width;
    const box = this.parent.box.clone();
    box.pad({
      left: borderWidth - paddingBox.x1,
      top: borderWidth - paddingBox.y1
    });
    highlightMarker.reflow(box);
    this.highlightMarker = highlightMarker.getElement();
    return this.highlightMarker;
  }
}
setDefaultOptions(LegendItemLine, {
  border: {
    width: 0
  },
  type: RECT,
  align: LEFT,
  vAlign: CENTER,
  highlight: {
    border: {
      width: DEFAULT_MARKER_BORDER_WIDTH
    },
    markers: {
      type: CIRCLE,
      width: DEFAULT_MARKER_SIZE,
      height: DEFAULT_MARKER_SIZE,
      border: {
        width: DEFAULT_MARKER_BORDER_WIDTH,
        color: WHITE
      },
      align: LEFT,
      vAlign: TOP
    }
  }
});
export default LegendItemLine;