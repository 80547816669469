"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TypeTablesCache = void 0;
const core_1 = require("@memberjunction/core");
/**
 * Simple cache for commonly used type tables across the system that are outside of what we have in the Metadata object
 */
class TypeTablesCache extends core_1.BaseEngine {
  constructor() {
    super(...arguments);
    this._EntityRelationshipDisplayComponents = [];
  }
  async Config(forceRefresh, contextUser) {
    const c = [{
      Type: 'entity',
      EntityName: 'Entity Relationship Display Components',
      PropertyName: '_EntityRelationshipDisplayComponents'
    }];
    await this.Load(c, forceRefresh, contextUser);
  }
  static get Instance() {
    return super.getInstance();
  }
  get EntityRelationshipDisplayComponents() {
    return this._EntityRelationshipDisplayComponents;
  }
}
exports.TypeTablesCache = TypeTablesCache;
