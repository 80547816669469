<div *ngIf="!loaded">
  <div class="gg-loader">
    <img src="../../assets/loader.gif" />
  </div>
</div>
<div *ngIf="loaded">
  <div class="breadcrumbWrapper">
    <div class="container-large">
      <div class="breadcrumb">
        <a routerLink="/"><kendo-icon name="home"></kendo-icon></a>
        <kendo-icon name="arrow-chevron-right"></kendo-icon>
        <a routerLink="/benchmark">Benchmarking</a>
        <kendo-icon name="arrow-chevron-right"></kendo-icon>
        <a class="active">{{reportName}}</a>
      </div>
      <div class="back-sec">
        <a>
          <kendo-icon name="arrow-chevron-left"></kendo-icon> Back
        </a>
      </div>
    </div>
  </div>

  <div class="report-wrapper">
    <app-side-nav></app-side-nav>
    <div class="main-content-wrapper">
      <div class="main-content">
        <kendo-pdf-export #pdf paperSize="A4" margin="2cm" [scale]="0.4" forcePageBreak=".page-break">
        <div class="header">
          <h1>{{reportName}}<kendo-icon name="edit"></kendo-icon></h1>
          <div class="header-buttons noExport">
            <kendo-button themeColor="secondary" routerLink="/benchmark"
              >Back To All Reports</kendo-button
            >
            <kendo-button themeColor="secondary" (click)="pdf.saveAs('invoice.pdf')">
              Export To PDF
            </kendo-button
            >
          </div>
        </div>

        <div class="executive-summary">  
          <h2 class="noExport">Executive Summary</h2>
          <div class="summary-content">
            <div class="summary-info">
              <h2>Organization Profile</h2>
              <div class="summary-item"
                ><span class="summary-label">Organization Type:</span>
                {{organizationInfoOrgType}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Geographic Scope:</span>
                {{organizationInfoGeographicScope}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Size category:</span>
                {{organizationInfoOrgSize}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Organization Revenue:</span>
                {{organizationInfoTotalRevenue}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Industry Sector:</span>
                {{organizationInfoIndustryName}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Major Activities:</span>
                {{organizationInfoMajorActivities}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Board Size:</span> N/A
              </div>
              <div class="summary-item"
                ><span class="summary-label">Staff Size (FTE):</span>
                {{organizationInfoNumberEmployees}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Volunteer Size:</span>
                {{organizationInfoNumberVolunteers}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Region:</span>
                {{organizationInfoRegion}}</div
              >
              <div class="summary-item"
                ><span class="summary-label">Metro:</span>
                {{organizationInfoMetroAreaName}}</div
              >
            </div>
            <div class="summary-overview" [innerHTML]="executiveSummary">
            </div>
          </div>
        </div>

        <div class="salary-benchmarking">
          <h2>Methodology</h2>
        <div class="description">
          <p>The methodology section of our Salary Benchmarking report outlines the systematic approach we employ to ensure the accuracy, relevance, and reliability of our compensation data. Firstly, we specify the job titles and roles within our organization, categorizing them based on job family, department, level of experience, and geographic location. This categorization is crucial to ensure that we compare 'like with like' during our benchmarking process. We then determine the relevant industry and geographic areas for each role, focusing on similar companies in terms of size, type, and other pertinent characteristics. Data collection follows, leveraging a combination of salary surveys, online salary databases, compensation software, and input from industry experts. This data is meticulously analyzed to identify gaps between our internal salaries and the external market rates. Any identified discrepancies guide our adjustments, ensuring that our pay scales are competitive and fair. The analysis phase also involves calculating average salaries while accounting for variables such as experience, education, and specific job responsibilities. We document all filters and parameters applied during benchmarking to maintain transparency and repeatability in future exercises. Finally, we create visual representations such as charts and graphs to illustrate salary distributions, comparisons, and trends, facilitating a comprehensive understanding of our compensation positioning relative to the market. This methodological rigor underpins our commitment to equitable and data-driven compensation practices that support our strategic goals and enhance employee satisfaction and retention.</p>
          <h3>Key Statistics in Salary Benchmarking:</h3>
          <ul>
              <li><strong>Mean (Average):</strong> The sum of all salaries divided by the number of data points. This measures the central tendency but can be skewed by extreme values. Useful for understanding the overall trend but must be considered alongside other measures.</li>
              <li><strong>Median:</strong> The middle value in a list of numbers. This provides a better measure of central tendency when data includes outliers. It's often more representative of the typical salary than the mean.</li>
              <li><strong>Interquartile Range (IQR):</strong> The difference between the 75th percentile (Q3) and the 25th percentile (Q1). This measures the spread of the central 50% of the data and helps in understanding the variability.</li>
              <li><strong>Outliers:</strong> Data points that are significantly higher or lower than most of the data. Identifying outliers helps in understanding anomalies and can indicate areas needing further investigation.</li>
              <li><strong>Percentiles:</strong> Values below which a percentage of data falls. For example, the 90th percentile is the value below which 90% of the data are found. This helps in understanding the distribution of salaries and setting competitive pay scales.</li>
              <li><strong>Standard Deviation:</strong> A measure of the amount of variation or dispersion in a set of values. This helps quantify the extent of deviation for a group as a whole.</li>
              <li><strong>Range:</strong> The difference between the highest and lowest values. This gives a quick sense of the overall spread of the data.</li>
          </ul>
          <p>By utilizing these statistics, we ensure a comprehensive analysis of salary data, enabling better decision-making and strategic planning.</p> 
        </div>
        <div class="peer-organizations page-break">
          <h3 class="title">Identifying Peer Organizations</h3>
          <p class="description">
            Researchers have identified how characteristics influence compensation on an individual basis (below), and when combined, they define peer organizations, which are those groups who have the most similar characteristics.
          </p>
          
          <div class="peer-characteristics">
            <div class="characteristic">
              <h4 class="characteristic-title">Size</h4>
              <p class="characteristic-description">
                Size is a critical factor in determining compensation. This is typically measured by both revenue and the number of full-time equivalent (FTE) staff. Higher revenue and larger staff sizes often indicate a more complex organizational structure, which can necessitate higher compensation to attract and retain qualified personnel.
              </p>
            </div>
            
            <div class="characteristic">
              <h4 class="characteristic-title">Type</h4>
              <p class="characteristic-description">
                Type refers to the classification of the tax-exempt organization, which identifies the nature of its primary constituency. For example, a trade group primarily serves companies, while a professional society serves individual professionals. The type of organization shapes the needs and expectations for roles within it.
              </p>
            </div>
            
            <div class="characteristic">
              <h4 class="characteristic-title">Scope</h4>
              <p class="characteristic-description">
                Scope indicates the geographical range of the organization's constituency, whether it is national, state, regional, or local. Wider scopes generally involve greater responsibilities and complexities, thereby influencing higher compensation levels.
              </p>
            </div>
            
            <div class="characteristic">
              <h4 class="characteristic-title">Primary Activity(s)</h4>
              <p class="characteristic-description">
                Primary activities refer to how the organization generates and allocates its revenue. Common revenue-generating activities include hosting meetings and events, offering educational resources and credentialing programs, publishing, and providing products or services. The primary activities define the skillsets and expertise required, thus affecting compensation.
              </p>
            </div>
            
            <div class="characteristic">
              <h4 class="characteristic-title">Sector/Sub-Sector</h4>
              <p class="characteristic-description">
                Sector and sub-sector classifications point to the industry-specific context of the organization. There are 17 broad sectors and 61 sub-sectors, each characterized by their unique mission priorities and challenges. For instance, a retail-focused trade group will have different compensation structures compared to a health care-focused society.
              </p>
            </div>
            
            <div class="characteristic">
              <h4 class="characteristic-title">Location</h4>
              <p class="characteristic-description">
                Location impacts compensation largely due to cost of living and the local job market dynamics. In regions with a high concentration of similar organizations, competition for top talent may drive up compensation rates.
              </p>
            </div>
          </div>
        </div>
      </div>

        <div class="salary-benchmarking page-break">
          <h2>Findings</h2>
          <p [innerHTML]="reportJSON.FindingsDescription"></p>
          <div class="demo-section wide">
            <kendo-chart>
              <kendo-chart-series>
                <kendo-chart-title
                  text="Compensation Distribution"
                ></kendo-chart-title>
                <kendo-chart-series-item
                    type="boxPlot"
                    [data]="boxplotTestData"
                    lowerField="lower"
                    q1Field="q1"
                    medianField="median"
                    q3Field="q3"
                    upperField="upper"
                    outliersField="outliers"
                    meanField="mean"
                    categoryField="year"
                    color="#28533F" 
                    [mean]="{ color: '#28533F' }" 
                    [median]="{ color: '#E9EDEB' }" 
                    [whiskers]="{ color: '#28533F' }" 
                    [outliers]="{ border: { color: '#28533F' } }" 
                    vertical="false"
                  >
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
          </div>
        </div>


        <div class="salary-benchmarking">
          <h2>Salary Benchmarking</h2>
          <p>{{ salaryBenchmarkingDescription }}</p>

          <!-- Filter Bar -->
          <div class="filter-bar noExport">
            <div *ngFor="let filter of filters" [class.active]="true">
              <kendo-dropdownbutton
                [data]="filter.options"
                [textField]="'label'"
                (itemClick)="applyFilter($event)"
                [ngClass]="{'active': filter.active}"
              >
                {{ filter.label }}
                <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                  {{ dataItem }}
                </ng-template>
              </kendo-dropdownbutton>
            </div>
          </div>

          <div class="salary-table">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Title</th>
                  <th>2023</th>
                  <th>2024</th>
                  <th>Comp. Rating</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let employee of filteredEmployees">
                  <td>{{ employee.name }}</td>
                  <td>{{ employee.role }}</td>
                  <td>{{ employee.title }}</td>
                  <td>{{ employee.salary2023 | currency }}</td>
                  <td>{{ employee.salary2024 | currency }}</td>
                  <td [ngClass]="getCompensationClass(employee.compRating)">
                    {{ employee.compRating }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="strategy page-break">
          <h2>Strategy</h2>
          <div class="strategy-content">
            <div class="opportunities">
              <h3>Opportunities</h3>
              <div
                class="strategy-item-content"
                *ngFor="let opportunity of reportJSON.InsightSummary.answer.Opportunities"
              >
                <div class="strategy-item">
                  <h3>{{ opportunity.Name }}</h3>
                  <p>{{ opportunity.Explanation }}</p>
                  <p>
                    <strong>Justification:</strong> {{ opportunity.Justification }}
                  </p>
                </div>
              </div>
            </div>
            <div class="risks">
              <h3>Risks</h3>
              <div
                class="strategy-item-content"
                *ngFor="let risk of reportJSON.InsightSummary.answer.Risks"
              >
                <div class="strategy-item">
                  <h3>{{ risk.Name }}</h3>
                  <p>{{ risk.Explanation }}</p>
                  <p><strong>Justification:</strong> {{ risk.Justification }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="conclusion">
          <h2>Additional Info</h2>
          <div class="conclusion-content" >
              <h3><strong>Question: </strong>{{reportJSON.AdditionalInfo.question}}</h3>
              <div><strong>Answer: </strong>{{reportJSON.AdditionalInfo.answer}}</div>
          </div>
        </div>


        <div class="conclusion">
          <h2>Conclusion</h2>
          <div class="conclusion-content" [innerHTML]="conclusion"></div>
        </div>

      <div class="salary-benchmarking page-break">
        <h2>Similar Organizations</h2>
        <p>{{ reportJSON.ComparabaleOrganizationDescription }}</p>

        <!-- Filter Bar -->
        <div class="filter-bar noExport">
          <div *ngFor="let filter of filters" [class.active]="true">
            <kendo-dropdownbutton
              [data]="filter.options"
              [textField]="'label'"
              (itemClick)="applyFilter($event)"
              [ngClass]="{'active': filter.active}"
            >
              {{ filter.label }}
              <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                {{ dataItem }}
              </ng-template>
            </kendo-dropdownbutton>
          </div>
        </div>

        <div class="salary-table">
          <table>
            <thead>
              <tr>
                <th>Organization Name</th>
                <th>Total Revenue</th>
                <th>State</th>
                <th>Number Of Employees</th>
                <th>Role</th>
                <th>Total Compensation</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let key of objectKeys(reportJSON.PeerCompensation)">
                <tr *ngFor="let org of reportJSON.PeerCompensation[key]">
                  <td>{{org.OrganizationName}}</td>
                  <td>{{org.TotalRevenue | currency}}</td>
                  <td>{{org.StateProvince}}</td>
                  <td>{{org.NumberEmployees}}</td>
                  <td>{{reportJSON.TargetPositions[key].Name}}</td>
                  <td>{{org.TotalCompensation | currency}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      </kendo-pdf-export>

      </div>

      <!-- Kendo Chat Toggle Button -->
      <div class="chatbox-container">
        <button kendoButton look="flat" (click)="toggleChat()" class="test">
          <kendo-icon *ngIf="isChatExpanded" name="arrow-chevron-down"></kendo-icon>
          <kendo-icon *ngIf="!isChatExpanded" name="arrow-chevron-up"></kendo-icon>
          {{ !isChatExpanded ? 'Open Chat' : 'Close Chat' }}
        </button>
        <kendo-chat
          *ngIf="isChatExpanded"
          messageBoxType="textarea"
          [user]="user"
          [messages]="inputMessages"
          (sendMessage)="sendMessage($event)"
        ></kendo-chat>
      </div>

    </div>
  </div>
</div>