import { NgModule } from '@angular/core';
import { RouterModule, Routes, NavigationEnd } from '@angular/router';
import { RoleSelectComponent } from './role-select/role-select.component';
import { RoleComponent } from './role/role.component';
import { RoleLevelComponent } from './role-level/role-level.component';
import { HomeComponent } from './home/home.component';
import { OrganizationSearchComponent } from './organization-search/organization-search.component';
import { OrganizationComponent } from './organization/organization.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { SalaryDrilldownComponent } from './salary-drilldown/salary-drilldown.component';
import { TrendsComponent } from './trends/trends.component';
import { OrgDrilldownComponent } from './org-drilldown/org-drilldown.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { BenchmarkComponent } from './benchmark/benchmark.component';
import { ReportComponent } from './report/report.component';
import { BreadcrumbService } from './breadcrumb.service';
import { filter } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NewReportComponent } from './new-report/new-report.component';

const routes: Routes = [
  { 
    path: '',
    component: HomeComponent,
    data: { breadcrumb: 'Home' }
  },
  { 
    path: 'no-access',
    component: NoAccessComponent
  },
  {
    path: 'orgs',
    component: OrganizationSearchComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'orgs/:taxId',
    component: OrganizationComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'select-role',
    component: RoleSelectComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'trends',
    component: TrendsComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'role/:roleId',
    component: RoleComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'role/:roleId/level/:levelId',
    component: RoleLevelComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'salary-drill-down',
    component: SalaryDrilldownComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'org-drill-down',
    component: OrgDrilldownComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'benchmark',
    component: BenchmarkComponent,
    canActivate: [AuthGuard], // Protect this route
    data: { breadcrumb: 'Benchmark' }
  },
  {
    path: 'benchmark/:reportId',
    component: ReportComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: 'new-report',
    component: NewReportComponent,
    canActivate: [AuthGuard] // Protect this route
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule] 
})
export class AppRoutingModule {}
