"use strict";

// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Translations = void 0;
const resource_1 = require("../../resource.js");
const core_1 = require("../../core.js");
class Translations extends resource_1.APIResource {
  /**
   * Translates audio into English.
   */
  create(body, options) {
    return this._client.post('/openai/v1/audio/translations', (0, core_1.multipartFormRequestOptions)({
      body,
      ...options
    }));
  }
}
exports.Translations = Translations;
(function (Translations) {})(Translations = exports.Translations || (exports.Translations = {}));
