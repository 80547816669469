"use strict";

// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Embeddings = void 0;
const resource_1 = require("../resource.js");
class Embeddings extends resource_1.APIResource {
  /**
   * Creates an embedding vector representing the input text.
   */
  create(body, options) {
    return this._client.post('/openai/v1/embeddings', {
      body,
      ...options
    });
  }
}
exports.Embeddings = Embeddings;
(function (Embeddings) {})(Embeddings = exports.Embeddings || (exports.Embeddings = {}));
