"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChatResult = exports.GetSystemPromptFromChatParams = exports.GetUserMessageFromChatParams = exports.ChatParams = exports.ChatMessageRole = void 0;
const baseModel_1 = require("./baseModel");
exports.ChatMessageRole = {
  system: 'system',
  user: 'user',
  assistant: 'assistant'
};
class ChatParams extends baseModel_1.BaseParams {
  constructor() {
    super(...arguments);
    this.messages = [];
  }
}
exports.ChatParams = ChatParams;
function GetUserMessageFromChatParams(p) {
  return p.messages.find(m => m.role === exports.ChatMessageRole.user)?.content;
}
exports.GetUserMessageFromChatParams = GetUserMessageFromChatParams;
function GetSystemPromptFromChatParams(p) {
  return p.messages.find(m => m.role === exports.ChatMessageRole.system)?.content;
}
exports.GetSystemPromptFromChatParams = GetSystemPromptFromChatParams;
class ChatResult extends baseModel_1.BaseResult {}
exports.ChatResult = ChatResult;
