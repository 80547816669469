"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationInfo = exports.ApplicationEntityInfo = exports.ApplicationSettingInfo = void 0;
const baseInfo_1 = require("./baseInfo");
class ApplicationSettingInfo extends baseInfo_1.BaseInfo {
  constructor(initData = null) {
    super();
    this.ID = null;
    this.ApplicationName = null;
    this.Name = null;
    this.Value = null;
    this.Comments = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this.copyInitData(initData);
  }
}
exports.ApplicationSettingInfo = ApplicationSettingInfo;
class ApplicationEntityInfo extends baseInfo_1.BaseInfo {
  get EntityInfo() {
    return this._EntityInfo;
  }
  _setEntity(entity) {
    this._EntityInfo = entity;
  }
  constructor(initData = null) {
    super();
    this.ID = null;
    this.ApplicationName = null;
    this.EntityID = null;
    this.Sequence = null;
    this.DefaultForNewUser = null;
    this.Application = null;
    this.Entity = null;
    this.EntityBaseTable = null;
    this.EntityCodeName = null;
    this.EntityClassName = null;
    this.EntityBaseTableCodeName = null;
    this._EntityInfo = null;
    this.copyInitData(initData);
  }
}
exports.ApplicationEntityInfo = ApplicationEntityInfo;
/**
 * Information about an application
 */
class ApplicationInfo extends baseInfo_1.BaseInfo {
  get ApplicationEntities() {
    return this._ApplicationEntities;
  }
  get ApplicationSettings() {
    return this._ApplicationSettings;
  }
  constructor(initData = null, md) {
    super();
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.Icon = null;
    this.DefaultForNewUser = null;
    this._ApplicationEntities = [];
    this._ApplicationSettings = [];
    this.copyInitData(initData);
    if (initData) {
      let ae = initData.ApplicationEntities || initData._ApplicationEntities;
      if (ae) {
        const mdEntities = md.Entities;
        this._ApplicationEntities = [];
        for (let i = 0; i < ae.length; i++) {
          // 
          const aei = new ApplicationEntityInfo(ae[i]);
          this._ApplicationEntities.push(aei);
          const match = mdEntities.find(e => e.ID == ae[i].EntityID);
          if (match) aei._setEntity(match);
        }
      }
      let as = initData.ApplicationSettings || initData._ApplicationSettings;
      if (as) this._ApplicationSettings = as.map(s => new ApplicationSettingInfo(s));
    }
  }
}
exports.ApplicationInfo = ApplicationInfo;
