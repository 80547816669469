"use strict";

// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Models = exports.Embeddings = exports.Completions = exports.Chat = exports.Audio = void 0;
__exportStar(require("./shared.js"), exports);
var audio_1 = require("./audio/audio.js");
Object.defineProperty(exports, "Audio", {
  enumerable: true,
  get: function () {
    return audio_1.Audio;
  }
});
var chat_1 = require("./chat/chat.js");
Object.defineProperty(exports, "Chat", {
  enumerable: true,
  get: function () {
    return chat_1.Chat;
  }
});
var completions_1 = require("./completions.js");
Object.defineProperty(exports, "Completions", {
  enumerable: true,
  get: function () {
    return completions_1.Completions;
  }
});
var embeddings_1 = require("./embeddings.js");
Object.defineProperty(exports, "Embeddings", {
  enumerable: true,
  get: function () {
    return embeddings_1.Embeddings;
  }
});
var models_1 = require("./models.js");
Object.defineProperty(exports, "Models", {
  enumerable: true,
  get: function () {
    return models_1.Models;
  }
});
