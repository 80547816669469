"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoadTemplateEntityExtended = exports.TemplateEntityExtended = void 0;
const core_1 = require("@memberjunction/core");
const core_entities_1 = require("@memberjunction/core-entities");
const global_1 = require("@memberjunction/global");
let TemplateEntityExtended = class TemplateEntityExtended extends core_entities_1.TemplateEntity {
  constructor() {
    super(...arguments);
    this._Content = [];
    this._Params = [];
  }
  get Content() {
    return this._Content;
  }
  set Content(value) {
    this._Content = value;
  }
  get Params() {
    return this._Params;
  }
  set Params(value) {
    this._Params = value;
  }
  /**
   * Returns all content for a given type for the template
   * @param type
   * @returns
   */
  GetContentByType(type) {
    return this.Content.filter(c => c.Type.trim().toLowerCase() === type.trim().toLowerCase());
  }
  /**
   * Returns the highest priority content for the template
   * @param type If provided, returns the highest priority content of the specified type
   * @returns
   */
  GetHighestPriorityContent(type) {
    if (type) {
      return this.Content.filter(c => c.Type.trim().toLowerCase() === type.trim().toLowerCase()).sort((a, b) => a.Priority - b.Priority)[0];
    } else {
      return this.Content.sort((a, b) => a.Priority - b.Priority)[0];
    }
  }
  /**
   * This method is different from the Validate() method which validates the state of the Template itself. This method validates the data object provided meets the requirements for the template's parameter definitions.
   * @param data - the data object to validate against the template's parameter definitions
   */
  ValidateTemplateInput(data) {
    const result = new core_1.ValidationResult();
    this.Params.forEach(p => {
      if (p.IsRequired) {
        if (!data || data[p.Name] === undefined || data[p.Name] === null || typeof data[p.Name] === 'string' && data[p.Name].toString().trim() === '') result.Errors.push({
          Source: p.Name,
          Message: `Parameter ${p.Name} is required.`,
          Value: data[p.Name],
          Type: 'Failure'
        });
      }
    });
    // now set result's top level success falg based on the existence of ANY failure record within the errors collection
    result.Success = result.Errors.some(e => e.Type === 'Failure') ? false : true;
    return result;
  }
};
exports.TemplateEntityExtended = TemplateEntityExtended;
exports.TemplateEntityExtended = TemplateEntityExtended = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Templates')], TemplateEntityExtended);
function LoadTemplateEntityExtended() {
  // this does nothing but prevents the class from being removed by the tree shaker
}
exports.LoadTemplateEntityExtended = LoadTemplateEntityExtended;
