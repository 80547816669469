"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegisterClass = exports.MJGlobal = exports.ClassRegistration = exports.ClassFactory = void 0;
const rxjs_1 = require("rxjs");
const ClassFactory_1 = require("./ClassFactory");
const ObjectCache_1 = require("./ObjectCache");
const BaseSingleton_1 = require("./BaseSingleton");
var ClassFactory_2 = require("./ClassFactory");
Object.defineProperty(exports, "ClassFactory", {
  enumerable: true,
  get: function () {
    return ClassFactory_2.ClassFactory;
  }
});
Object.defineProperty(exports, "ClassRegistration", {
  enumerable: true,
  get: function () {
    return ClassFactory_2.ClassRegistration;
  }
});
__exportStar(require("./interface"), exports);
__exportStar(require("./util"), exports);
__exportStar(require("./ObjectCache"), exports);
__exportStar(require("./BaseSingleton"), exports);
/**
 * Global class used for coordinating events, creating class instances, and managing components across MemberJunction
 */
class MJGlobal extends BaseSingleton_1.BaseSingleton {
  constructor() {
    super(...arguments);
    // subjects for observables to handle eventing
    this._eventsSubject = new rxjs_1.Subject();
    this._eventsReplaySubject = new rxjs_1.ReplaySubject();
    // Convert the Subjects to Observables for public use.
    this._events$ = this._eventsSubject.asObservable();
    this._eventsReplay$ = this._eventsReplaySubject.asObservable();
    this._components = [];
    this._classFactory = new ClassFactory_1.ClassFactory();
    this._properties = [];
    this._objectCache = new ObjectCache_1.ObjectCache();
  }
  /**
   * Returns the global instance of the MJGlobal class. This is a singleton class, so there is only one instance of it in the application. Do not directly create new instances of MJGlobal, always use this method to get the instance.
   */
  static get Instance() {
    return super.getInstance();
  }
  RegisterComponent(component) {
    this._components.push(component);
  }
  /**
   * Resets the class to its initial state. Use very carefully and sparingly.
   */
  Reset() {
    this._components = [];
    this._eventsSubject = new rxjs_1.Subject();
    this._eventsReplaySubject = new rxjs_1.ReplaySubject();
    // Convert the Subjects to Observables for public use.
    this._events$ = this._eventsSubject.asObservable();
    this._eventsReplay$ = this._eventsReplaySubject.asObservable();
  }
  /**
   * Use this method to raise an event to all component who are listening for the event.
   * @param event
   */
  RaiseEvent(event) {
    this._eventsSubject.next(event);
    this._eventsReplaySubject.next(event);
  }
  /**
   * Use this method to get an observable that will fire when an event is raised.
   * @param withReplay
   * @returns
   */
  GetEventListener(withReplay = false) {
    return withReplay ? this._eventsReplay$ : this._events$;
  }
  /**
   * Returns the instance of ClassFactory you should use in your application. Access this via the MJGlobal.Instance.ClassFactory property.
   */
  get ClassFactory() {
    return this._classFactory;
  }
  /**
   * Global property bag
   */
  get Properties() {
    return this._properties;
  }
  /**
   * ObjectCache can be used to cache objects as needed by any application in memory. These objects are NOT persisted to disk or any other storage medium, so they are only good for the lifetime of the application
   */
  get ObjectCache() {
    return this._objectCache;
  }
}
exports.MJGlobal = MJGlobal;
/**
 * Decorate your class with this to register it with the MJGlobal class factory.
 * @param baseClass
 * @param key a string that is later used to retrieve a given registration - this should be unique for each baseClass/key combination, if multiple registrations exist for a given baseClass/key combination, the highest priority registration will be used to create class instances
 * @param priority Higher priority registrations will be used over lower priority registrations. If there are multiple registrations for a given base class/sub-class/key combination, the one with the highest priority will be used. If there are multiple registrations with the same priority, the last one registered will be used. Finally, if you do NOT provide this setting, the order of registrations will increment the priority automatically so dependency injection will typically care care of this. That is, in order for Class B, a subclass of Class A, to be registered properly, Class A code has to already have been loaded and therefore Class A's RegisterClass decorator was run. In that scenario, if neither Class A or B has a priority setting, Class A would be 1 and Class B would be 2 automatically. For this reason, you only need to explicitly set priority if you want to do something atypical as this mechanism normally will solve for setting the priority correctly based on the furthest descendant class that is registered.
 * @returns an instance of the class that was registered for the combination of baseClass/key (with highest priority if more than one)
 */
function RegisterClass(baseClass, key = null, priority = 0) {
  return function (constructor) {
    // Invoke the registration method
    MJGlobal.Instance.ClassFactory.Register(baseClass, constructor, key, priority);
  };
}
exports.RegisterClass = RegisterClass;
