<div *ngIf="!loaded">
    <kendo-loader type="infinite-spinner" themeColor="primary" size="large"></kendo-loader>
</div>
<div *ngIf="loaded && roleRecord && levelRecord" class="interior-component"> 
    <div class="breadcrumb"><a routerLink="/"><kendo-icon name="home"></kendo-icon></a> : <a routerLink="/select-role">Roles</a> : <a [routerLink]="'/role/' + roleRecord.ID">{{roleRecord.Name}}</a> : {{levelRecord.Name}}</div>
    <div *ngIf="taxData && taxData.length > 0">
        <kendo-chart>
            <kendo-chart-title [text]="'Average ' + roleRecord.Name + ' (' + levelRecord.Name + ') Salaries By Year'"></kendo-chart-title>
            <kendo-chart-series>
                <kendo-chart-series-item
                    type="line"
                    [data]="getYearlyAverageSalaries()"
                    field="averageComp"
                    categoryField="year"
                >
                    <kendo-chart-series-item-labels
                        [content]="labelContent"
                        position="above"
                        color="black"
                        >
                    </kendo-chart-series-item-labels>                
                </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item
                    [categories]="taxYears"
                    [title]="{ text: 'Tax Year' }"
                >
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: 'Average Compensation' }">
                    <kendo-chart-value-axis-item-labels format="c0">
                    </kendo-chart-value-axis-item-labels>
                </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
        </kendo-chart>
    </div>
    <hr>
    <h3>Data Dump - Test Only</h3>
    <kendo-grid
        [data]='taxData'
    >
        <kendo-grid-column field="ContactLevel"></kendo-grid-column>
        <kendo-grid-column field="MetroAreaSize"></kendo-grid-column>
        <kendo-grid-column field="OrgSizeRevenue"></kendo-grid-column>
        <kendo-grid-column field="OrgSizeEmployees"></kendo-grid-column>
        <kendo-grid-column field="Industry"></kendo-grid-column>
        <kendo-grid-column field="RecordCount"></kendo-grid-column>
        <kendo-grid-column field="AverageComp"></kendo-grid-column>
    </kendo-grid>
</div>

 