"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MJEventType = exports.MJEvent = exports.MJGlobalProperty = void 0;
class MJGlobalProperty {}
exports.MJGlobalProperty = MJGlobalProperty;
class MJEvent {}
exports.MJEvent = MJEvent;
exports.MJEventType = {
  ComponentRegistered: 'ComponentRegistered',
  ComponentUnregistered: 'ComponentUnregistered',
  ComponentEvent: 'ComponentEvent',
  LoggedIn: 'LoggedIn',
  LoggedOut: 'LoggedOut',
  LoginFailed: 'LoginFailed',
  LogoutFailed: 'LogoutFailed',
  ManualResizeRequest: 'ManualResizeRequest',
  DisplaySimpleNotificationRequest: 'DisplaySimpleNotificationRequest'
};
