"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RunView = void 0;
const global_1 = require("@memberjunction/global");
const metadata_1 = require("../generic/metadata");
/**
 * Class for running views in a generic, tier-independent manner - uses a provider model for
 * implementation transparently from the viewpoint of the consumer of the class.
 */
class RunView {
  /**
   * Runs a view based on the provided parameters, see documentation for RunViewParams for more
   * @param params
   * @param contextUser if provided, this user is used for permissions and logging. For server based calls, this is generally required because there is no "Current User" since this object is shared across all requests.
   * @returns
   */
  async RunView(params, contextUser) {
    // FIRST, if the resultType is entity_object, we need to run the view with ALL fields in the entity
    // so that we can get the data to populate the entity object with.
    if (params.ResultType === 'entity_object') {
      // we need to get the entity definition and then get all the fields for it
      const md = new metadata_1.Metadata();
      const entity = md.Entities.find(e => e.Name.trim().toLowerCase() === params.EntityName.trim().toLowerCase());
      if (!entity) throw new Error(`Entity ${params.EntityName} not found in metadata`);
      params.Fields = entity.Fields.map(f => f.Name); // just override whatever was passed in with all the fields - or if nothing was passed in, we set it. For loading the entity object, we need ALL the fields.
    }
    // NOW, run the view
    const result = await RunView.Provider.RunView(params, contextUser);
    // FINALLY, if needed, transform the result set into BaseEntity-derived objects
    if (params.ResultType === 'entity_object' && result && result.Success) {
      // we need to transform each of the items in the result set into a BaseEntity-derived object
      const md = new metadata_1.Metadata();
      const newItems = [];
      for (const item of result.Results) {
        const entity = await md.GetEntityObject(params.EntityName, contextUser);
        entity.LoadFromData(item);
        newItems.push(entity);
      }
      result.Results = newItems;
    }
    return result;
  }
  async RunViews(params, contextUser) {
    let md = null;
    for (const param of params) {
      // FIRST, if the resultType is entity_object, we need to run the view with ALL fields in the entity
      // so that we can get the data to populate the entity object with.
      if (param.ResultType === 'entity_object') {
        // we need to get the entity definition and then get all the fields for it
        md = md || new metadata_1.Metadata();
        const entity = md.Entities.find(e => e.Name.trim().toLowerCase() === param.EntityName.trim().toLowerCase());
        if (!entity) {
          throw new Error(`Entity ${param.EntityName} not found in metadata`);
        }
        param.Fields = entity.Fields.map(f => f.Name); // just override whatever was passed in with all the fields - or if nothing was passed in, we set it. For loading the entity object, we need ALL the fields.
      }
    }
    // NOW, run the view
    const results = await RunView.Provider.RunViews(params, contextUser);
    for (const [index, result] of results.entries()) {
      const param = params[index];
      // FINALLY, if needed, transform the result set into BaseEntity-derived objects
      if (param.ResultType === 'entity_object' && result && result.Success) {
        // we need to transform each of the items in the result set into a BaseEntity-derived object
        md = md || new metadata_1.Metadata();
        const newItems = [];
        for (const item of result.Results) {
          const entity = await md.GetEntityObject(param.EntityName, contextUser);
          entity.LoadFromData(item);
          newItems.push(entity);
        }
        result.Results = newItems;
      }
    }
    return results;
  }
  static get Provider() {
    const g = global_1.MJGlobal.Instance.GetGlobalObjectStore();
    if (g) return g[RunView._globalProviderKey];else throw new Error('No global object store, so we cant get the static provider');
  }
  static set Provider(value) {
    const g = global_1.MJGlobal.Instance.GetGlobalObjectStore();
    if (g) g[RunView._globalProviderKey] = value;else throw new Error('No global object store, so we cant set the static provider');
  }
  /**
   * Utility method that calculates the entity name for a given RunViewParams object by looking at the EntityName property as well as the ViewID/ViewName/ViewEntity properties as needed.
   * @param params
   * @returns
   */
  static async GetEntityNameFromRunViewParams(params) {
    if (params.EntityName) return params.EntityName;else if (params.ViewEntity) {
      const entityID = params.ViewEntity.Get('EntityID'); // using weak typing because this is MJCore and we don't want to use the sub-classes from core-entities as that would create a circular dependency
      const md = new metadata_1.Metadata();
      const entity = md.Entities.find(e => e.ID === entityID);
      if (entity) return entity.Name;
    } else if (params.ViewID || params.ViewName) {
      // we don't have a view entity loaded, so load it up now
      const rv = new RunView();
      const result = await rv.RunView({
        EntityName: "User Views",
        ExtraFilter: params.ViewID ? `ID = '${params.ViewID}'` : `Name = '${params.ViewName}'`,
        ResultType: 'entity_object'
      });
      if (result && result.Success && result.Results.length > 0) {
        return result.Results[0].Entity; // virtual field in the User Views entity called Entity
      }
    } else return null;
  }
}
exports.RunView = RunView;
RunView._globalProviderKey = 'MJ_RunViewProvider';
