<div *ngIf="!loaded">
  <div class="gg-loader">
    <img src="../../assets/loader.gif"/>
</div>
</div>
<div *ngIf="loaded" class="role-wrap">
  <div class="breadcrumbWrapper">
    <div class="container-large">
      <div class="breadcrumb">
        <a routerLink="/">
          <kendo-icon name="home"></kendo-icon>
        </a><kendo-icon name="arrow-chevron-right"></kendo-icon>
        <a class="active">Revenue Trends</a>
      </div>
      <div class="back-sec">
     <a>
        <kendo-icon name="arrow-chevron-left"></kendo-icon>
        Back
      </a>
      </div>
    </div>
  </div>

  <section class="tab-main-wrap">
    <div class="container-large">
      <div class="editorial-main-wrapper">
        <div class="header-wrapper-role">
          <div class="filter-wrapper">
            <div class="configuration-section-text" (click)="toggleFilter()">
              <div class="title"><kendo-icon name="saturation"></kendo-icon> Filters <span *ngIf="activeFiltersCount"> {{activeFiltersCount}}</span></div>
              <kendo-icon name="arrow-chevron-right" *ngIf="hideFilters"></kendo-icon>
              <kendo-icon name="arrow-chevron-down" *ngIf="!hideFilters"></kendo-icon>
            </div>
            <div class="menubar-open" *ngIf="!hideFilters">
              <div class="drop-menu">
                <label [for]="filterMetroAreaSize">Metro Size</label>
                <kendo-multicolumncombobox
                  #filterMetroAreaSize
                  class="filter-item-large"
                  [data]="sharedData.MetroAreaSizes"
                  textField="NameWithRange"
                  valueField="Name"
                  placeholder="Metro Size"
                  (valueChange)="groupAndFilterData()"
                  [(value)]="selectedMetroAreaSize"
                >
                  <kendo-combobox-column field="Name" title="Name" [width]="200">
                  </kendo-combobox-column>
                  <kendo-combobox-column
                    field="MinVal"
                    title="Min"
                    [width]="150"
                    class="k-text-right"
                  >
                    <ng-template
                      kendoMultiColumnComboBoxColumnCellTemplate
                      let-dataItem
                    >
                      {{
                        dataItem.MinVal > 0
                          ? (dataItem.MinVal | number : "1.0-0")
                          : ""
                      }}
                    </ng-template>
                  </kendo-combobox-column>
                  <kendo-combobox-column
                    field="MaxVal"
                    title="Max"
                    [width]="150"
                    class="k-text-right"
                  >
                    <ng-template
                      kendoMultiColumnComboBoxColumnCellTemplate
                      let-dataItem
                    >
                      {{
                        dataItem.MaxVal > 0
                          ? (dataItem.MaxVal | number : "1.0-0")
                          : ""
                      }}
                    </ng-template>
                  </kendo-combobox-column>
                </kendo-multicolumncombobox>
              </div>

              <div class="drop-menu">
                <label [for]="filterIndustry">Industry</label>
                <kendo-multicolumncombobox
                  #filterIndustry
                  class="filter-item-large"
                  [data]="sharedData.Industries"
                  textField="Name"
                  valueField="Name"
                  placeholder="Industry"
                  (valueChange)="groupAndFilterData()"
                  [(value)]="selectedIndustry"
                >
                  <kendo-combobox-column field="Name" title="Name" [width]="225">
                  </kendo-combobox-column>
                  <kendo-combobox-column
                    field="Description"
                    title="Description"
                    [width]="300"
                  >
                  </kendo-combobox-column>
                  <kendo-combobox-column
                    field="Keywords"
                    title="Keywords"
                    [width]="400"
                  >
                  </kendo-combobox-column>
                </kendo-multicolumncombobox>
              </div>

              <div class="drop-menu">
                <label [for]="filterOrgSizeEmployees">Org Size/Employees</label>
                <kendo-multicolumncombobox
                  #filterOrgSizeEmployees
                  class="filter-item-large"
                  [data]="sharedData.OrgEmployeeSizes"
                  textField="NameWithRange"
                  valueField="Name"
                  placeholder="Org Size/# Employees"
                  (valueChange)="groupAndFilterData()"
                  [(value)]="selectedOrgSizeEmployees"
                >
                  <kendo-combobox-column field="Name" title="Name" [width]="200">
                  </kendo-combobox-column>
                  <kendo-combobox-column
                    field="MinVal"
                    title="Min"
                    [width]="150"
                    class="k-text-right"
                  >
                    <ng-template
                      kendoMultiColumnComboBoxColumnCellTemplate
                      let-dataItem
                    >
                      {{ dataItem.MinVal > 0 ? dataItem.MinVal : "" }}
                    </ng-template>
                  </kendo-combobox-column>
                  <kendo-combobox-column
                    field="MaxVal"
                    title="Max"
                    [width]="150"
                    class="k-text-right"
                  >
                    <ng-template
                      kendoMultiColumnComboBoxColumnCellTemplate
                      let-dataItem
                    >
                      {{ dataItem.MaxVal > 0 ? dataItem.MaxVal : "" }}
                    </ng-template>
                  </kendo-combobox-column>
                </kendo-multicolumncombobox>
              </div>

              <div class="drop-menu">
                <label [for]="filterOrgSizeRevenue">Org Size/Revenue</label>
                <kendo-multicolumncombobox 
                  #filterOrgSizeRevenue 
                  class="filter-item-large"
                  [data]="sharedData.OrgRevenueSizes" 
                  textField="NameWithRange" 
                  valueField="Name"
                  placeholder="Org Size/Revenue" 
                  (valueChange)="groupAndFilterData()"
                  [(value)]="selectedOrgSizeRevenue"
                >
                  <kendo-combobox-column field="Name" title="Name" [width]="200">
                  </kendo-combobox-column>
                  <kendo-combobox-column field="MinVal" title="Min" [width]="150" class="k-text-right">
                    <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                      {{ dataItem.MinVal > 0 ? (dataItem.MinVal | currency:'USD':'symbol':'1.0-0') : '' }}
                    </ng-template>
                  </kendo-combobox-column>
                  <kendo-combobox-column field="MaxVal" title="Max" [width]="150" class="k-text-right">
                    <ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
                      {{ dataItem.MaxVal > 0 ? (dataItem.MaxVal | currency:'USD':'symbol':'1.0-0') : '' }}
                    </ng-template>
                  </kendo-combobox-column>
                </kendo-multicolumncombobox>
              </div>

              <div class="drop-menu">
                <label [for]="date">Date</label>
                <div class="filter-tax-years" #date>
                  <kendo-rangeslider 
                    [(ngModel)]="selectedTaxYears" 
                    [min]="minTaxYear" 
                    [title]="yearTitles"
                    [max]="maxTaxYear"
                    [smallStep]="1" 
                    [largeStep]="1" 
                    (valueChange)="groupAndFilterData()"
                    class="filter-tax-years-slider"
                  ></kendo-rangeslider>
                </div>
              </div>

              <div class="drop-menu">
                <label class="configuration-section-text">Group By</label>
                <kendo-buttongroup selection="single" class="filter-item">
                  <button
                    kendoButton
                    [selected]="groupByField === 'TaxYear'"
                    [toggleable]="true"
                    (click)="handleGroupBy('TaxYear', 'Tax Year')"
                  >
                    Tax Year
                  </button>
                  <button
                    kendoButton
                    [selected]="groupByField === 'OrgSizeRevenue'"
                    [toggleable]="true"
                    [disabled]="!!selectedOrgSizeRevenue"
                    (click)="handleGroupBy('OrgSizeRevenue', 'Org Size/Revenue')"
                  >
                    Org Size/Revenue
                  </button>
                  <button
                    kendoButton
                    [selected]="groupByField === 'OrgSizeEmployees'"
                    [toggleable]="true"
                    [disabled]="!!selectedOrgSizeEmployees"
                    (click)="handleGroupBy('OrgSizeEmployees', 'Org Size/# Employees')"
                  >
                    Org Size/Employees
                  </button>
                  <button
                    kendoButton
                    [selected]="groupByField === 'MetroAreaSize'"
                    [toggleable]="true"
                    [disabled]="!!selectedMetroAreaSize"
                    (click)="handleGroupBy('MetroAreaSize', 'Metro Area Size')"
                  >
                    Metro Area Size
                  </button>
                  <button
                    kendoButton
                    [selected]="groupByField === 'Industry'"
                    [toggleable]="true"
                    [disabled]="!!selectedIndustry"
                    (click)="handleGroupBy('Industry', 'Industry')"
                  >
                    Industry
                  </button>
                </kendo-buttongroup>
              </div>
            </div>
          </div>
          <div class="role-title">
            <h3>Revenue By {{groupByFieldTitle}} - {{filterLabel()}} - ({{taxYearsLabel}})</h3>
            <div class="mob-layout-wrapper">
              <div class="mobile-filter-btn"><a (click)="openFilterDialog()"><div class="title"><kendo-icon name="saturation"></kendo-icon> Filters <span *ngIf="activeFiltersCount">{{activeFiltersCount}}</span></div></a></div>
              <kendo-buttongroup selection="single">
                <button kendoButton [selected]="activeState==='chart'" title="Chart" (click)="setActiveState('chart')">
                  <kendo-icon name="chart-line-markers"></kendo-icon>
                </button>
                <button kendoButton [selected]="activeState==='grid'" title="Table" (click)="setActiveState('grid')">
                  <kendo-icon name="grid"></kendo-icon>
                </button>
              </kendo-buttongroup>
            </div>
          </div>
        </div>
        <div class="layout-wrapper" [ngClass]="{'filter-open': !hideFilters, 'filter-close': hideFilters}">
          <div class="chart-wrap" *ngIf="activeState === 'chart'">
            <kendo-chart (seriesClick)="onSeriesClick($event)">
              <kendo-chart-series>
                <kendo-chart-series-item
                  [type]="seriesType()"
                  [data]="averageRevenues"
                  field="AverageRevenue"
                  [color]="getBarColor"
                >
                  <kendo-chart-series-item-labels
                    [content]="labelContent"
                    position="outsideEnd"
                    color="black"
                  ></kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item
                  [categories]="chartCategories"
                >
                <kendo-chart-category-axis-item-labels
                  [content]="xAxisLabels"
                >
                </kendo-chart-category-axis-item-labels>
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-value-axis>
                <kendo-chart-value-axis-item>
                  <kendo-chart-value-axis-item-labels format="c0" [content]="YAxisLabels">
                  </kendo-chart-value-axis-item-labels>
                </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
            </kendo-chart>
          </div>

          <div class="grid-wrap" *ngIf="activeState === 'grid'">
            <kendo-grid
              [data]="averageRevenues"
              (cellClick)="onGridRowClick($event)"
            >
              <kendo-grid-column
                field="Group"
                [title]="groupByFieldTitle"
              ></kendo-grid-column>
              <kendo-grid-column
                field="AverageRevenue"
                title="Average Revenue"
                format="c0"
              ></kendo-grid-column>
              <kendo-grid-column
                field="RecordCount"
                title="# records"
              ></kendo-grid-column>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<app-filter-window 
  *ngIf="filterDialogOpened" 
  [initialData]="filterDialogData" 
  (onClose)="closeFilterDialog($event)" 
  [showPositionLevel]="false"
  [showTaxYearGroup]="true"
></app-filter-window>