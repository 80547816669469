"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DashboardEntityExtended = void 0;
const core_1 = require("@memberjunction/core");
const global_1 = require("@memberjunction/global");
const entity_subclasses_1 = require("../generated/entity_subclasses");
let DashboardEntityExtended = class DashboardEntityExtended extends entity_subclasses_1.DashboardEntity {
  NewRecord() {
    try {
      super.NewRecord();
      let defaultConfigDetails = {
        columns: 4,
        rowHeight: 150,
        resizable: true,
        reorderable: true,
        items: []
      };
      const configJSON = JSON.stringify(defaultConfigDetails);
      this.Set("UIConfigDetails", configJSON);
      const md = new core_1.Metadata();
      if (md.CurrentUser) {
        this.Set("UserID", md.CurrentUser.ID);
      }
      return true;
    } catch (error) {
      (0, core_1.LogError)("Error in NewRecord: ");
      (0, core_1.LogError)(error);
      return false;
    }
  }
};
exports.DashboardEntityExtended = DashboardEntityExtended;
exports.DashboardEntityExtended = DashboardEntityExtended = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Dashboards', 2) // 2 priority so this gets used ahead of the generated sub-class
], DashboardEntityExtended);
