"use strict";

// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Transcriptions = void 0;
const resource_1 = require("../../resource.js");
const core_1 = require("../../core.js");
class Transcriptions extends resource_1.APIResource {
  /**
   * Transcribes audio into the input language.
   */
  create(body, options) {
    return this._client.post('/openai/v1/audio/transcriptions', (0, core_1.multipartFormRequestOptions)({
      body,
      ...options
    }));
  }
}
exports.Transcriptions = Transcriptions;
(function (Transcriptions) {})(Transcriptions = exports.Transcriptions || (exports.Transcriptions = {}));
