"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AIModelEntityExtended = void 0;
const core_1 = require("@memberjunction/core");
const global_1 = require("@memberjunction/global");
const entity_subclasses_1 = require("../generated/entity_subclasses");
let AIModelEntityExtended = class AIModelEntityExtended extends entity_subclasses_1.AIModelEntity {
  /**
   * Returns the APIName if it exists, otherwise returns the Name
   */
  get APINameOrName() {
    return this.APIName ? this.APIName : this.Name;
  }
};
exports.AIModelEntityExtended = AIModelEntityExtended;
exports.AIModelEntityExtended = AIModelEntityExtended = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'AI Models', 2) // 2 priority so this gets used ahead of the generated sub-class
], AIModelEntityExtended);
