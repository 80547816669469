"use strict";

// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.APIResource = void 0;
class APIResource {
  constructor(client) {
    this._client = client;
  }
}
exports.APIResource = APIResource;
