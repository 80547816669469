"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EntityRecordNameResult = exports.EntityRecordNameInput = exports.EntityDeleteOptions = exports.EntitySaveOptions = exports.PotentialDuplicateResponse = exports.PotentialDuplicateResult = exports.PotentialDuplicateRequest = exports.PotentialDuplicate = exports.ProviderType = exports.MetadataInfo = exports.ProviderConfigDataBase = void 0;
const compositeKey_1 = require("./compositeKey");
class ProviderConfigDataBase {
  get Data() {
    return this._data;
  }
  get IncludeSchemas() {
    return this._includeSchemas;
  }
  get MJCoreSchemaName() {
    return this._MJCoreSchemaName;
  }
  get ExcludeSchemas() {
    return this._excludeSchemas;
  }
  constructor(data, MJCoreScemaName = '__mj', includeSchemas, excludeSchemas) {
    this._includeSchemas = [];
    this._excludeSchemas = [];
    this._MJCoreSchemaName = '__mj';
    this._data = data;
    this._MJCoreSchemaName = MJCoreScemaName;
    if (includeSchemas) this._includeSchemas = includeSchemas;
    if (excludeSchemas) this._excludeSchemas = excludeSchemas;
  }
}
exports.ProviderConfigDataBase = ProviderConfigDataBase;
class MetadataInfo {}
exports.MetadataInfo = MetadataInfo;
exports.ProviderType = {
  Database: 'Database',
  Network: 'Network'
};
class PotentialDuplicate extends compositeKey_1.CompositeKey {}
exports.PotentialDuplicate = PotentialDuplicate;
class PotentialDuplicateRequest {}
exports.PotentialDuplicateRequest = PotentialDuplicateRequest;
class PotentialDuplicateResult {
  constructor() {
    this.RecordCompositeKey = new compositeKey_1.CompositeKey();
    this.Duplicates = [];
    this.DuplicateRunDetailMatchRecordIDs = [];
  }
}
exports.PotentialDuplicateResult = PotentialDuplicateResult;
//Wrapper for the PotentialDuplicateResponse class that includes  additional properties
class PotentialDuplicateResponse {}
exports.PotentialDuplicateResponse = PotentialDuplicateResponse;
/**
 * Save options used when saving an entity record
 */
class EntitySaveOptions {
  constructor() {
    /**
     * If set to true, the record will be saved to the database even if nothing is detected to be "dirty" or changed since the prior load.
     */
    this.IgnoreDirtyState = false;
    /**
     * If set to true, an AI actions associated with the entity will be skipped during the save operation
     */
    this.SkipEntityAIActions = false;
    /**
     * If set to true, any Entity Actions associated with invocation types of Create or Update will be skipped during the save operation
     */
    this.SkipEntityActions = false;
    /**
     * When set to true, the save operation will BYPASS Validate() and the actual process of saving changes to the database but WILL invoke any associated actions (AI Actions, Entity Actions, etc...)
     * Subclasses can also override the Save() method to provide custom logic that will be invoked when ReplayOnly is set to true
     */
    this.ReplayOnly = false;
  }
}
exports.EntitySaveOptions = EntitySaveOptions;
/**
 * Options used when deleting an entity record
 */
class EntityDeleteOptions {
  constructor() {
    /**
     * If set to true, an AI actions associated with the entity will be skipped during the delete operation
     */
    this.SkipEntityAIActions = false;
    /**
     * If set to true, any Entity Actions associated with invocation types of Delete will be skipped during the delete operation
     */
    this.SkipEntityActions = false;
    /**
     * When set to true, the save operation will BYPASS Validate() and the actual process of deleting the record from the database but WILL invoke any associated actions (AI Actions, Entity Actions, etc...)
     * Subclasses can also override the Delete() method to provide custom logic that will be invoked when ReplayOnly is set to true
     */
    this.ReplayOnly = false;
  }
}
exports.EntityDeleteOptions = EntityDeleteOptions;
class EntityRecordNameInput {}
exports.EntityRecordNameInput = EntityRecordNameInput;
class EntityRecordNameResult {}
exports.EntityRecordNameResult = EntityRecordNameResult;
