<div class="interior-component org-wrap">
    <div class="breadcrumbWrapper">
        <div class="container-large">
            <div class="breadcrumb">
                <a routerLink="/">
                    <kendo-icon name="home"></kendo-icon>
                </a>
                <!-- <kendo-icon name="arrow-chevron-right"></kendo-icon><a>Salary by Role</a>
                <kendo-icon name="arrow-chevron-right"></kendo-icon><a>Editorial</a>
                <kendo-icon name="arrow-chevron-right"></kendo-icon><a>Salary Details</a> -->
                <kendo-icon name="arrow-chevron-right"></kendo-icon><a routerLink="/orgs">Search Organizations</a>
                <kendo-icon name="arrow-chevron-right"></kendo-icon><a class="active">{{orgName}}</a>
            </div>
            <div class="back-sec">
                <a routerLink="/orgs">
                    <kendo-icon name="arrow-chevron-left"></kendo-icon>
                    Back
                </a>
              </div>
    </div>
    </div>
    <section class="tab-main-wrap">
        <div class="container-large">
            <h2>{{orgName}}</h2>
            <div class="org-body">
        
                <kendo-tabstrip>
                    <kendo-tabstrip-tab title="General Information" [selected]="true">
                      <ng-template kendoTabContent>
                        <div class="content">
                            <div *ngIf="!latestTaxReturn">
                                <div class="gg-loader">
                                    <img src="../../assets/loader.gif"/>
                                </div>
                            </div>
                            <div *ngIf="latestTaxReturn !== null" class="org-info">
                                <div class="info-row">
                                    <span class="info-label">Name: </span>
                                    <span class="info-value">{{orgName}}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label">Website: </span>
                                    <span class="info-value"><a [href]="getOrgWebsite(latestTaxReturn)" target="_blank">{{latestTaxReturn.Website}}</a></span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label">Federal Tax ID: </span>
                                    <span class="info-value">{{latestTaxReturn.TaxID}}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label">Latest Tax Return Year: </span>
                                    <span class="info-value">{{latestTaxReturn.TaxYear}}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label">Subsection: </span>
                                    <span class="info-value">{{latestTaxReturn.Subsection}}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label">Address: </span>
                                    <span class="info-value">{{latestTaxReturn.AddressLine1}} {{latestTaxReturn.AddressLine2}}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label"></span>
                                    <span class="info-value">{{latestTaxReturn.City}}, {{latestTaxReturn.StateProvince}} {{latestTaxReturn.PostalCode}}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label">Year Formed: </span>
                                    <span class="info-value">{{latestTaxReturn.YearFormed}}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label"># Employees: </span>
                                    <span class="info-value">{{(latestTaxReturn.NumberEmployees ?? 0) > 0 ? latestTaxReturn.NumberEmployees : 'Not Reported'}}</span>
                                </div>
                                <div class="info-row" *ngIf="latestTaxReturn.MissionDescription && latestTaxReturn.MissionDescription.length > 0">
                                    <span class="info-label">Mission: </span>
                                    <span class="info-value">{{latestTaxReturn.MissionDescription}}</span>
                                </div>
                                <div class="info-row" *ngIf="latestTaxReturn.ActivityDescription && latestTaxReturn.ActivityDescription.length > 0 && latestTaxReturn.ActivityDescription != latestTaxReturn.MissionDescription">
                                    <span class="info-label">Activities: </span>
                                    <span class="info-value">{{latestTaxReturn.ActivityDescription}}</span>
                                </div>
                                <div class="info-row" *ngIf="latestTaxReturn.Industry && latestTaxReturn.Industry.length > 0">
                                    <span class="info-label">Industry: </span>
                                    <span class="info-value">{{latestTaxReturn.Industry}}  <i>(AI Generated)</i></span>
                                </div>
                                <div class="info-row" *ngIf="latestTaxReturn.FinancialStrength">
                                    <span class="info-label">Financial Strength: </span>
                                    <span class="info-value"><i>Disclaimer: The following financial strength rating was AI Generated</i></span>
                                </div>
                                <div class="info-row" *ngIf="latestTaxReturn.FinancialStrength">
                                    <span class="info-label"></span>
                                    <span class="info-value">{{latestTaxReturn.FinancialStrength}} of 10</span>
                                </div>
                                <div class="info-row" *ngIf="latestTaxReturn.SummaryText && latestTaxReturn.SummaryText.length > 0">
                                    <span class="info-label">{{latestTaxReturn.TaxYear}} Summary: </span>
                                    <span class="info-value"><i>Disclaimer: The following summary text was AI Generated</i></span>
                                </div>
                                <div class="info-row" *ngIf="latestTaxReturn.SummaryText && latestTaxReturn.SummaryText.length > 0">
                                    <span class="info-label"></span>
                                    <span class="info-value">{{latestTaxReturn.SummaryText}}</span>
                                </div>
                            </div> 
                        </div>
                      </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab title="Compensation">
                        <ng-template kendoTabContent>
                          <div class="content">
                            <div *ngIf="loadingCompData">
                                <div class="gg-loader">
                                    <img src="../../assets/loader.gif"/>
                                </div>
                            </div>
                            <div class="org-compensation" *ngIf="latestTaxReturn !== null && compensationData && !loadingCompData && !loading">
                                <div *ngIf="compensationData.length > 0">
                                    <p> <img src="../../assets/info.svg" alt=""> {{orgName}} reported a total of {{getTotalHCECompByTaxReturn(latestTaxReturn.ID) | currency:'USD':'symbol':'1.0-0' }} in compensation for {{getTotalHCECountByTaxReturn(latestTaxReturn.ID) | number}} highly compensated employees in {{latestTaxReturn.TaxYear}}.</p>
                                    <kendo-grid 
                                        [kendoGridBinding]="processedCompensationData"
                                        [sortable]="true"        
                                    >
                                        <kendo-grid-column *ngFor="let col of compensationGridColumns"
                                            [title]="col.Title"
                                            [field]="col.Name"
                                            [width]="col.Width ? col.Width : 100"
                                            [format]="col.Format ? col.Format : ''"
                                        >
                            
                                        </kendo-grid-column>
                                       
                                    </kendo-grid>    
                                </div>
                            </div>
                            </div>
                        </ng-template>
                      </kendo-tabstrip-tab>
                      <kendo-tabstrip-tab title="Financials" >
                        <ng-template kendoTabContent>
                          <div class="content">
                            <div *ngIf="loadingFinancials">
                                <div class="gg-loader">
                                    <img src="../../assets/loader.gif"/>
                                </div>
                            </div>
                            <div class="org-financials" *ngIf="latestTaxReturn !== null && summaryFinancials && !loadingFinancials && !loading">
                                <kendo-grid 
                                    [kendoGridBinding]="summaryFinancials"
                                    [sortable]="true"        
                            >
                                    <kendo-grid-column *ngFor="let col of summaryFinancialsGridColumns"
                                        [title]="col.Title"
                                        [field]="col.Name"
                                        [width]="col.Width ? col.Width : 100" 
                                        [format]="col.Format ? col.Format : ''"
                                        [class]="col.Class ? col.Class : ''"
                                    >
                        
                                    </kendo-grid-column>
                                </kendo-grid>
                            </div>
                              </div>
                        </ng-template>
                      </kendo-tabstrip-tab>
                      <kendo-tabstrip-tab title="Benchmark">
                        <ng-template kendoTabContent>
                            <div class="content">
                                <div *ngIf="loadingBenchmark">
                                    <div class="gg-loader">
                                        <img src="../../assets/loader.gif"/>
                                    </div>
                                </div>
                            </div>
                            <div class="org-benchmark" *ngIf="personBenchmarkData !== null && !loadingBenchmark && !loading">
                                <kendo-grid [kendoGridBinding]="personBenchmarkData" [sortable]="true">
                                    <kendo-grid-column *ngFor="let col of benchmarkGridColumns"
                                        [title]="col.Title"
                                        [field]="col.Name"
                                        [width]="col.Width ? col.Width : 100"
                                        [format]="col.Format ? col.Format : ''"
                                    >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div [ngClass]="getCellClass(col.Name, dataItem)">
                                          {{ col.Format ? (dataItem[col.Name] |  currency: 'USD':'symbol':'1.0-0') : dataItem[col.Name] }}
                                        </div>
                                      </ng-template>
                                </kendo-grid-column>
                                </kendo-grid>
                            </div>
                        </ng-template>
                      </kendo-tabstrip-tab>
                    </kendo-tabstrip>
            </div>
        </div>
</section>
</div>