"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseEngine = exports.BaseEnginePropertyConfig = void 0;
const global_1 = require("@memberjunction/global");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const runView_1 = require("../views/runView");
const logging_1 = require("./logging");
const metadata_1 = require("./metadata");
const interfaces_1 = require("./interfaces");
const baseInfo_1 = require("./baseInfo");
const baseEntity_1 = require("./baseEntity");
/**
 * Property configuration for the BaseEngine class to automatically load/set properties on the class.
 */
class BaseEnginePropertyConfig extends baseInfo_1.BaseInfo {
  constructor(init) {
    super();
    /**
     * The type of item to load, either 'entity' or 'dataset', defaults to 'entity'
     */
    this.Type = 'entity';
    /**
     * Optional, only used if Type is 'dataset', specifies how to handle the results of the dataset load. Defaults to 'single_property' if not specified. When set to 'single_property', the entire dataset is set to the property specified by PropertyName.
     * When set to 'individual_properties', each item in the dataset is set to a property on the object with the name of the item's key plus the item's Code name.
     * For example, if the item's key is 'Demo' and the item's Code name is 'FirstItem', the property set on the object would be 'Demo_FirstItem'.
     */
    this.DatasetResultHandling = 'single_property';
    /**
     * Optional, defaults to true. If set to false, AutoRefresh for this item will be disabled. By default, whenever a BaseEntity event is emitted for a save/delete, if the entity name
     * for this config matches the BaseEntity's entity name, the config will be refreshed. If this is set to false, that will not happen. NOTE: This is not a network notification mechanism,
     * it only works within the local tier, so for example within a browser application, that brower's engine sub-classes will be updated when changes are made to entities in that application
     * environment, and the same is true for MJAPI/Server based environments. If you need network based notification, additional infrastructure will be needed to implement that.
     */
    this.AutoRefresh = true;
    // now copy the values from init to this object
    if (init) Object.assign(this, init);
  }
}
exports.BaseEnginePropertyConfig = BaseEnginePropertyConfig;
/**
 * Abstract base class for any engine-style class which executes work on behalf of a caller typically using a provider-style architecture with plug-ins. This base class
 * provides a mechanism for loading metadata from the database and caching it for use by the engine. Subclasses must implement the Config abstract method and within that
 * generally it is recommended to call the Load method to load the metadata. Subclasses can also override the AdditionalLoading method to perform additional loading tasks.
 */
class BaseEngine extends global_1.BaseSingleton {
  constructor() {
    super(...arguments);
    this._loaded = false;
    this._loadingSubject = new rxjs_1.BehaviorSubject(false);
    this._metadataConfigs = [];
    this._dynamicConfigs = new Map();
    this._dataMap = new Map();
    this._expirationTimers = new Map();
    this._entityEventSubjects = new Map();
    this._entityEventDebounceTime = 5000; // Default debounce time in milliseconds (5 seconds)
  }
  /**
   * Returns a COPY of the metadata configs array for the engine. This is a copy so you can't modify the original configs by modifying this array.
   */
  get Configs() {
    // do a full deep copy of the array to ensure no tampering
    return JSON.parse(JSON.stringify(this._metadataConfigs));
  }
  /**
   * This method should be called by sub-classes to load up their specific metadata requirements. For more complex metadata
   * loading or for post-processing of metadata loading done here, overide the AdditionalLoading method to add your logic.
   * @param configs
   * @param contextUser
   * @returns
   */
  async Load(configs, forceRefresh = false, contextUser) {
    if (metadata_1.Metadata.Provider.ProviderType === interfaces_1.ProviderType.Database && !contextUser) throw new Error('For server-side use of all engine classes, you must provide the contextUser parameter');
    if (this._loadingSubject.value) {
      return new Promise(resolve => {
        const subscription = this._loadingSubject.subscribe(loading => {
          if (!loading) {
            subscription.unsubscribe();
            resolve();
          }
        });
      });
    }
    if (!this._loaded || forceRefresh) {
      this._loadingSubject.next(true);
      try {
        this._contextUser = contextUser;
        await this.LoadConfigs(configs, contextUser);
        await this.AdditionalLoading(contextUser); // Call the additional loading method
        await this.SetupGlobalEventListener();
        this._loaded = true;
      } catch (e) {
        (0, logging_1.LogError)(e);
      } finally {
        this._loadingSubject.next(false);
      }
    }
  }
  /**
   * This method is responsible for registering for MJGlobal events and listening for BaseEntity events where those
   * BaseEntity are related to the engine's configuration metadata. The idea is to auto-refresh the releated configs
   * when the BaseEntity is updated.
   */
  async SetupGlobalEventListener() {
    try {
      if (!this._eventListener) {
        this._eventListener = global_1.MJGlobal.Instance.GetEventListener(false);
        this._eventListener.subscribe(async event => {
          await this.HandleIndividualEvent(event);
        });
      }
    } catch (e) {
      (0, logging_1.LogError)(e);
      return false;
    }
  }
  /**
   * Subclasses of BaseEngine can override this method to handle individual MJGlobal events. This is typically done to optimize
   * the way refreshes are done when a BaseEntity is updated. If you are interested in only BaseEntity events, override
   * the HandleIndividualBaseEntityEvent method instead as this method primarily serves to filter all the events we get from MJGlobal
   * and only pass on BaseEntity events to HandleIndividualBaseEntityEvent.
   * @param event
   */
  async HandleIndividualEvent(event) {
    // base class algo is simple - we just check to see if the event's entity name matches any of our entity names
    // and if so, we refresh the data by calling 
    if (event.event === global_1.MJEventType.ComponentEvent && event.eventCode === baseEntity_1.BaseEntity.BaseEventCode) {
      // we have an entity event
      const baseEntityEvent = event.args;
      return await this.HandleIndividualBaseEntityEvent(baseEntityEvent);
    }
  }
  /**
   * This method handles the individual base entity event and just checks to see if it is a delete or save event and if so, debounces it.
   * Override this method if you want to have a different handling for the filtering of events that are debounced or if you don't want to debounce
   * at all you can do that in an override of this method.
   * @param event
   */
  async HandleIndividualBaseEntityEvent(event) {
    try {
      if (event.type === 'delete' || event.type === 'save') {
        const eName = event.baseEntity.EntityInfo.Name.toLowerCase().trim();
        const matchingAutoRefreshConfig = this.Configs.some(c => c.AutoRefresh && c.EntityName.trim().toLowerCase() === eName);
        if (matchingAutoRefreshConfig) return this.DebounceIndividualBaseEntityEvent(event);
      }
      return true;
    } catch (e) {
      (0, logging_1.LogError)(e);
      return false;
    }
  }
  /**
   * This method handles the debouncing process, by default using the EntityEventDebounceTime property to set the debounce time. Debouncing is
   * done on a per-entity basis, meaning that if the debounce time passes for a specific entity name, the event will be processed. This is done to
   * prevent multiple events from being processed in quick succession for a single entity which would cause a lot of wasted processing.
   *
   * Override this method if you want to change how debouncing time such as having variable debounce times per-entity, etc.
   * @param event
   * @returns
   */
  async DebounceIndividualBaseEntityEvent(event) {
    try {
      const entityName = event.baseEntity.EntityInfo.Name.toLowerCase().trim();
      if (!this._entityEventSubjects.has(entityName)) {
        const subject = new rxjs_1.Subject();
        subject.pipe((0, operators_1.debounceTime)(this.EntityEventDebounceTime)).subscribe(async e => {
          await this.ProcessEntityEvent(e);
        });
        this._entityEventSubjects.set(entityName, subject);
      }
      this._entityEventSubjects.get(entityName).next(event);
      return true;
    } catch (e) {
      (0, logging_1.LogError)(e);
      return false;
    }
  }
  /**
   * Overridable property to set the debounce time for entity events. Default is 5000 milliseconds (5 seconds).
   */
  get EntityEventDebounceTime() {
    return this._entityEventDebounceTime;
  }
  /**
   * This method does the actual work of processing the entity event. It is not directly called from the event handler because we want to first debounce the events
   * which also introduces a delay which is usually desirable so that our processing is typically outside of the scope of any transaction processing that would have
   * originated the event.
   *
   * This is the best method to override if you want to change the actual processing of an entity event but do NOT want to modify the debouncing behavior.
   */
  async ProcessEntityEvent(event) {
    const entityName = event.baseEntity.EntityInfo.Name.toLowerCase().trim();
    let refreshCount = 0;
    for (const config of this.Configs) {
      if (config.AutoRefresh && config.EntityName.trim().toLowerCase() === entityName) {
        (0, logging_1.LogStatus)(`>>> Refreshing metadata for ${config.PropertyName} due to BaseEntity ${event.type} event for: ${event.baseEntity.EntityInfo.Name}, pkey: ${event.baseEntity.PrimaryKey.ToString()}`);
        await this.LoadSingleConfig(config, this._contextUser);
        refreshCount++;
      }
    }
    if (refreshCount > 0) {
      // we need to call AdditionalLoading here - because in many cases engine sub-classes do various kinds of data mashups 
      // after we have loaded - for example the TemplateEngine takes the TemplateContents and TemplateParams and stuffs them
      // into arrays in each template to make it easier to get Params/Contents for each Template. Such operations are common
      // and need to be done after the initial load and after any refreshes.
      await this.AdditionalLoading(this._contextUser);
    }
  }
  /**
   * Utility method to upgrade an object to a BaseEnginePropertyConfig object.
   * @param obj
   * @returns
   */
  UpgradeObjectToConfig(obj) {
    // if obj is not already an instance of BaseEnginePropertyConfig, create one
    if (obj instanceof BaseEnginePropertyConfig) return obj;else return new BaseEnginePropertyConfig(obj);
  }
  /**
   * Loads the specified metadata configurations.
   * @param configs - The metadata configurations to load
   * @param contextUser - The context user information
   */
  async LoadConfigs(configs, contextUser) {
    this._metadataConfigs = configs.map(c => this.UpgradeObjectToConfig(c));
    await Promise.all(this._metadataConfigs.map(c => this.LoadSingleConfig(c, contextUser)));
  }
  /**
   * Loads a single metadata configuration.
   * @param config - The metadata configuration to load
   * @param contextUser - The context user information
   */
  async LoadSingleConfig(config, contextUser) {
    if (config.Type === 'dataset') return await this.LoadSingleDatasetConfig(config, contextUser);else return await this.LoadSingleEntityConfig(config, contextUser);
  }
  /**
   * Handles the process of loading a single config of type 'entity'.
   * @param config
   * @param contextUser
   */
  async LoadSingleEntityConfig(config, contextUser) {
    const rv = new runView_1.RunView();
    const result = await rv.RunView({
      EntityName: config.EntityName,
      ResultType: 'entity_object',
      ExtraFilter: config.Filter,
      OrderBy: config.OrderBy
    }, contextUser);
    if (result.Success) {
      if (config.AddToObject !== false) {
        this[config.PropertyName] = result.Results;
      }
      this._dataMap.set(config.PropertyName, {
        entityName: config.EntityName,
        data: result.Results
      });
      if (config.Expiration) {
        this.SetExpirationTimer(config.PropertyName, config.Expiration);
      }
    }
  }
  /**
   * Handles the process of loading a single config of type 'dataset'.
   * @param config
   * @param contextUser
   */
  async LoadSingleDatasetConfig(config, contextUser) {
    const md = new metadata_1.Metadata();
    const result = await md.GetAndCacheDatasetByName(config.DatasetName, config.DatasetItemFilters);
    if (result.Success) {
      if (config.AddToObject !== false) {
        if (config.DatasetResultHandling === 'single_property') {
          this[config.PropertyName] = result.Results;
        } else {
          // explode out the items within the DS into individual properties
          for (const item of result.Results) {
            this[`${config.PropertyName}_${item.Code}`] = item.Results;
          }
        }
      }
      this._dataMap.set(config.PropertyName, {
        datasetName: config.DatasetName,
        data: result.Results
      });
      if (config.Expiration) {
        this.SetExpirationTimer(config.PropertyName, config.Expiration);
      }
    }
  }
  /**
   * Sets an expiration timer for a metadata property.
   * @param propertyName - The name of the property
   * @param expiration - The expiration time in milliseconds
   */
  SetExpirationTimer(propertyName, expiration) {
    if (this._expirationTimers.has(propertyName)) {
      clearTimeout(this._expirationTimers.get(propertyName));
    }
    const timer = setTimeout(() => this.RefreshItem(propertyName), expiration);
    this._expirationTimers.set(propertyName, timer);
  }
  /**
   * Adds a dynamic metadata configuration at runtime.
   * @param config - The metadata configuration to add
   * @param contextUser - The context user information
   */
  async AddDynamicConfig(config, contextUser) {
    const c = this.UpgradeObjectToConfig(config);
    this._dynamicConfigs.set(c.PropertyName, c);
    await this.LoadSingleConfig(c, contextUser || this._contextUser);
  }
  /**
   * Removes a dynamic metadata configuration at runtime.
   * @param propertyName - The name of the property to remove
   */
  RemoveDynamicConfig(propertyName) {
    this._dynamicConfigs.delete(propertyName);
    this._dataMap.delete(propertyName);
    if (this._expirationTimers.has(propertyName)) {
      clearTimeout(this._expirationTimers.get(propertyName));
      this._expirationTimers.delete(propertyName);
    }
  }
  /**
   * Refreshes a specific item.
   * @param propertyName - The name of the property to refresh
   */
  async RefreshItem(propertyName) {
    const config = this._metadataConfigs.find(c => c.PropertyName === propertyName) || this._dynamicConfigs.get(propertyName);
    if (config) {
      await this.LoadSingleConfig(config, this._contextUser);
    }
  }
  /**
   * Refreshes all items
   */
  async RefreshAllItems() {
    await this.LoadConfigs([...this._metadataConfigs, ...Array.from(this._dynamicConfigs.values())], this._contextUser);
  }
  /**
   * Subclasses can override this method to perform additional loading tasks
   * @param contextUser
   */
  async AdditionalLoading(contextUser) {
    // Subclasses can override this method to perform additional loading tasks
  }
  /**
   * Returns true if the data has been loaded, false otherwise.
   */
  get Loaded() {
    return this._loaded;
  }
  /**
   * Returns the loading subject. You can call await Config() and after Config() comes back as true that means you're loaded. However you can also directly subscribe to this subject to get updates on the loading status.
   */
  get LoadingSubject() {
    return this._loadingSubject;
  }
  /**
   * Returns the context user set for the object, this is set via the Config() method.
   */
  get ContextUser() {
    return this._contextUser;
  }
  /**
   * Helper method for sub-classes to have a single line of code that will make sure the data is loaded before proceeding and will throw an error if not loaded.
   */
  TryThrowIfNotLoaded() {
    if (!this.Loaded) throw new Error("Data not loaded, call Config() first.");
  }
}
exports.BaseEngine = BaseEngine;
