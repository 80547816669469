import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedData } from '../shared-data';
import { ContactRoleEntity } from 'mj_generatedentities';

@Component({
  selector: 'app-filter-window',
  templateUrl: './filter-window.component.html',
  styleUrls: ['./filter-window.component.css']
})
export class FilterWindowComponent implements OnInit {
  @Input() initialData: any = {};
  @Input() public showPositionLevel: boolean = false;
  @Input() public showTaxYearGroup: boolean = false;
  @Output() public onClose = new EventEmitter();
  @Input() public roleRecord: ContactRoleEntity | undefined = undefined;
  public data: any = {};


  public yearTitles = (value: number): string => {
    return this.data.years[0] === value || this.data.years[1] === value ? value.toString() : '';
  };
  
  constructor(public sharedData: SharedData) { }

  ngOnInit() {
    this.data = JSON.parse(JSON.stringify(this.initialData));
  }
  
  
  handleGroupBy(groupByField: string, groupByFieldTitle: string) {
    this.data.groupByField = groupByField;
    this.data.groupByFieldTitle = groupByFieldTitle;
  }
  
  public close() {
    this.onClose.emit();
  }

  public apply() {
    this.onClose.emit({ ...this.data, selectedTaxYears: this.initialData.selectedTaxYears});
  }
}
