<div class="app-wrap">
    <div class="app-header">
        <app-header></app-header>
    </div>
    <div class="app-body">
        <div *ngIf="auth.isAuthenticated$ | async; else loggedOut">
          <div class="layout">
            <app-sidebar></app-sidebar>
            <div class="main-content">
                <!-- <div class="app-header">
                    <app-header></app-header>
                </div> -->
                <router-outlet></router-outlet>
            </div>
          </div>
        </div>

        <ng-template #loggedOut>
            <div class="home">
                <div class="section-home">
                    <div class="header-banner">
                        <div class="home-auth-button-container k-d-flex k-flex-row">
                            <button kendoButton (click)="auth.loginWithRedirect()">Log In</button>
                            <button kendoButton (click)="doSignUp()">Sign Up</button>
                        </div>
                    </div>
                    <div class="container-large">
                        <div class="card-wrapper">
                            <img class="home-img" src="../assets/home-img.png" />
                        </div>
                    </div>
                </div>
                <div>
                    
                </div>
            </div>
        </ng-template>
    </div>    
</div>