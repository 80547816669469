"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScheduledActionEntityExtended = void 0;
const entity_subclasses_1 = require("../generated/entity_subclasses");
class ScheduledActionEntityExtended extends entity_subclasses_1.ScheduledActionEntity {
  constructor() {
    super(...arguments);
    this._params = [];
  }
  async Save(options) {
    // The purpose of this override is to generate the the CronExpression field in the ScheduledAction entity
    // based on the settings we have for Type/IntervalDays/DayOfWeek/DayOfMonth/Month
    // we only generate the CronExpression if the Type != Custom
    if (this.Type !== 'Custom') {
      let cronExpression = '';
      if (this.Type === 'Daily') {
        cronExpression = `0 0 0 * * ?`;
      } else if (this.Type === 'Weekly') {
        cronExpression = `0 0 0 ? * ${this.DayOfWeek}`;
      } else if (this.Type === 'Monthly') {
        cronExpression = `0 0 0 ${this.DayOfMonth} * ?`;
      } else if (this.Type === 'Yearly') {
        cronExpression = `0 0 0 ${this.DayOfMonth} ${this.Month} ?`;
      }
      this.CronExpression = cronExpression;
    }
    // does the cronexpression need to use the Timezone field we have?
    return await super.Save(options);
  }
  get Params() {
    return this._params;
  }
  set Params(value) {
    this._params = value;
  }
}
exports.ScheduledActionEntityExtended = ScheduledActionEntityExtended;
