import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';
import { LogError, Metadata, RunView, RunViewParams, EntityInfo } from '@memberjunction/core';
import { MJEventType, MJGlobal } from '@memberjunction/global';
import { SharedData } from '../shared-data';
import { SharedService } from '../shared-service';
import { MultiColumnComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { Meta, Title } from '@angular/platform-browser';
import { NumberSuffixPipe } from '../NumberSuffixPipe';
import { TemplateEditingDirective } from '@progress/kendo-angular-grid';
import { TemplateEngineServer } from '@memberjunction/templates';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as d3 from 'd3';
import {
  Message,
  User,
  SendMessageEvent,
} from "@progress/kendo-angular-conversational-ui";
import { ReportChatEntity, ReportChatMessageEntity } from 'mj_generatedentities';

interface BoxPlotData {
  lower: number;
  q1: number;
  median: number;
  q3: number;
  upper: number;
  mean: number;
  outliers: number[];
  year: string;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  reportHTML: SafeHtml;
  loaded: boolean = false;
  formSubmitted: boolean = false;
  existingRecords: any[] = [];
  result: any = {};
  reportId: string | null = null
  reportName: string;
  reportJSON: any;

  constructor(private route: ActivatedRoute, 
    private router: Router, 
    private sharedService: SharedService, 
    public sharedData: SharedData, 
    public titleService: Title,  
    public numberSuffixPipe: NumberSuffixPipe, 
    private sanitizer: DomSanitizer
  ) { }

  // Salary Benchmarking description
  salaryBenchmarkingDescription = `
    Broadly, compensation serves many purposes, including to reward and to incentivize appropriately. 
    In addition, ensuring a person is paid fairly serves a retention purpose as well as a tool to persuade talented executives to join an organization. 
    That’s the recruiting marketplace. And in the recruiting marketplace, compensation has two important areas of investigation. 
    One is to benchmark how chief executives are compensated among similar organizations focusing on similar activities. 
    Second is to consider compensation in similar but smaller—and ostensibly less complicated—organizations.
  `;

  // Filter options
  
  public filters = [
    { label: 'Job Role', value: 'jobRole', options: ['Executive Director/CEO', 'IT', 'Operations', 'Editorial'], active: false },
    { label: 'Position Level', value: 'positionLevel', options: ['Junior', 'Mid', 'Senior', 'Executive'], active: false },
    { label: 'Time Range', value: 'timeRange', options: ['2023', '2024'], active: false },
    { label: 'Total Compensation', value: 'totalCompensation', options: ['Below Average', 'Competitive', 'Slightly Low'], active: false }
  ]; 

  // Employee data
  employees = [
    { name: 'Nancy Urbanowicz', role: 'Executive Director/CEO', title: 'Executive Director', salary2023: 365000, salary2024: 382000, compRating: 'Competitive' },
    { name: 'Samuel Smith', role: 'IT', title: 'Chief Information Officer', salary2023: 240000, salary2024: 252000, compRating: 'Slightly Low' },
    { name: 'Pauline Brown', role: 'Operations', title: 'Chief Operating Officer', salary2023: 239000, salary2024: 251000, compRating: 'Below Average' },
    { name: 'John Lee', role: 'Editorial', title: 'Managing Director', salary2023: 218000, salary2024: 223000, compRating: 'Competitive' },
  ];

  // Filtered employee data
  filteredEmployees = [...this.employees];

  // Current applied filters
  currentFilters: { [key: string]: string } = {};

  // Get filter options for a specific filter type
  getFilterOptions(filterType: any): string[] {
    const options = new Set(this.employees.map(employee => employee[filterType]));
    return Array.from(options);
  }

  applyFilter(selectedOption: any) {
    // Logic to filter employees based on selectedOption
    // Example: if selectedOption is "Executive Director/CEO", filter employees by role
    this.filteredEmployees = this.employees.filter(employee => {
      return Object.values(employee).includes(selectedOption);
    });
  }

  // Filter employees based on the selected filters
  filterEmployees() {
    this.filteredEmployees = this.employees.filter(employee => {
      for (let filterType in this.currentFilters) {
        if (this.currentFilters[filterType] && employee[filterType] !== this.currentFilters[filterType]) {
          return false;
        }
      }
      return true;
    });
  }

  // Get class for compensation rating
  getCompensationClass(compRating: string): string {
    switch (compRating) {
      case 'Competitive': return 'competitive';
      case 'Slightly Low': return 'slightly-low';
      case 'Below Average': return 'below-average';
      default: return '';
    }
  }

  // Export to PDF function (stubbed for demonstration)
  exportToPDF(): void {
    console.log('Export to PDF triggered');
  }

  getTargetPositionName(key: any): string {
    return this.reportJSON.TargetPositions[key].Name;
  }

  public user: User = { id: 1 };
  public bot: User = { id: 0 };
  public inputMessages: Message[] = [
    {
      author: this.bot,
      text: `Hi there, I'm GreenGopherAI.\n\nI can help you with any questions you have about your report.`,
    },
  ];
  reportChatId: number;
  public async sendMessage(event: SendMessageEvent) { 
    this.inputMessages = [...this.inputMessages, event.message, {author: this.bot, typing:true}];

    const md = new Metadata();
    if (this.reportChatId === undefined) {
      const newReportChat = await md.GetEntityObject('Report Chats') as unknown as ReportChatEntity;
      newReportChat.NewRecord();
      newReportChat.ReportID = Number(this.reportId);
      newReportChat.CreatedAt = new Date();
      newReportChat.UpdatedAt = new Date();
      await newReportChat.Save();
      this.reportChatId = newReportChat.ID;
    }

    const newReportMessage = await md.GetEntityObject('Report Chat Messages') as unknown as ReportChatMessageEntity;
    newReportMessage.NewRecord();
    newReportMessage.ReportChatID = this.reportChatId;
    newReportMessage.UserQuery = event.message.text;
    newReportMessage.LLMResponse = '';
    await newReportMessage.Save();
    this.inputMessages = [...this.inputMessages, {author: this.bot, text: newReportMessage.LLMResponse}];
  }

  isChatExpanded = false;
  toggleChat(): void {
    this.isChatExpanded = !this.isChatExpanded;
  }

  //new html
  executiveSummary: string;
  organizationInfoOrgType: string;
  organizationInfoGeographicScope: string;
  organizationInfoOrgSize: string;
  organizationInfoRegion: string;
  organizationInfoMajorActivities: string;
  organizationInfoTotalRevenue: string;
  organizationInfoNumberEmployees: string;
  organizationInfoNumberVolunteers: string;
  organizationInfoMetroAreaName: string;
  organizationInfoIndustryName: string;
  conclusion: string;

  async ngOnInit(): Promise<void> {
    MJGlobal.Instance.GetEventListener(true).subscribe(async (e) => {
      if (e.event === MJEventType.LoggedIn) {
        this.reportId = this.route.snapshot.paramMap.get('reportId');
        if (this.reportId) {
          const rv = new RunView();
          const existingResult = await rv.RunView({
            EntityName: 'Reports__gg',
            ExtraFilter: `ID=${this.reportId}`,
          })
          this.existingRecords = existingResult.Results
          // Parse the JSON string to an object
          for (let record of this.existingRecords) {
            if (record.JSON) {
              record.JSON = JSON.parse(record.JSON);
            }
          }

          this.reportJSON = this.existingRecords[0].JSON;
          this.reportName = this.existingRecords[0].JSON.ReportTitle;

          console.log(this.reportJSON)

          let roleId = 5
          for (let compType of ['Base', 'Bonus', 'Total']) {
            this.boxplotTestData.push({
              lower: this.reportJSON.CompensationDescriptives[roleId][compType].min,
              q1: this.reportJSON.CompensationDescriptives[roleId][compType].percentile25,
              median: this.reportJSON.CompensationDescriptives[roleId][compType].median,
              q3: this.reportJSON.CompensationDescriptives[roleId][compType].percentile75,
              upper: this.reportJSON.CompensationDescriptives[roleId][compType].max,
              mean: this.reportJSON.CompensationDescriptives[roleId][compType].average,
              outliers: [],
              year: compType,
          });}


          // new html variables
          this.executiveSummary = this.reportJSON.InsightSummary.answer.Summary.replace('<h2>Executive Summary</h2>', '<h2>Overview</h2>')

          this.organizationInfoOrgType = this.reportJSON.OrganizationProfile.OrganizationType.answer
          this.organizationInfoGeographicScope = this.reportJSON.OrganizationProfile.GeographicScope.answer
          this.organizationInfoOrgSize = this.reportJSON.OrganizationProfile.OrganizationSizeCategory.answer
          this.organizationInfoRegion = this.reportJSON.OrganizationProfile.Region.answer
          this.organizationInfoMajorActivities = this.reportJSON.OrganizationProfile.MajorActivities.answer.join(', ')
          this.organizationInfoTotalRevenue = this.reportJSON.SourceInfo.TotalRevenue
          this.organizationInfoNumberEmployees = this.reportJSON.SourceInfo.NumberEmployees
          this.organizationInfoNumberVolunteers = this.reportJSON.SourceInfo.NumberVolunteers
          this.organizationInfoMetroAreaName = this.reportJSON.SourceInfo.MetroAreaName
          this.organizationInfoIndustryName = this.reportJSON.SourceInfo.IndustryName

          this.conclusion = this.reportJSON.InsightSummary.answer.Conclusion.replace('<h2>Conclusion</h2>', '')

          await this.renderTemplate('Benchmarking Report v0', 'a88abde5-a64a-ef11-86c3-6045bdd4ba3d', this.existingRecords[0].JSON)
          this.loaded = true;
        }
      }
    });
  } 


  boxplotTestData: BoxPlotData[] = [];
  async renderTemplate(templateName: string, templateContentID: string, templateData: any) {
    //setup the template engine
    const md = new Metadata();
    await TemplateEngineServer.Instance.Config(true, md.CurrentUser);
    //get the template and template content
    const template: any = TemplateEngineServer.Instance.FindTemplate(templateName);
    if(!template) {
        throw new Error("no template found");
    }
    const templateContent: any = template.Content.find((content: any) => content.ID.toLowerCase() === templateContentID);
    if(!templateContent){
        throw new Error("no template content found");
    }

    this.result = await TemplateEngineServer.Instance.RenderTemplate(template, templateContent, templateData, true);
    if(!this.result.Success){
        LogError(`Error rendering template`, undefined, this.result.Message);
    }

    // Position comparison plots
    let d3data = await this.createChart(templateData, this.result.Output)
    if (templateData.PeerCompensation) {
      for (const roleId of Object.keys(templateData.TargetPositions)) {
        d3data = await this.createBoxPlot(`${templateData.TargetPositions[roleId].Name} Compensation Distribution`, `#peer-box-plot-${roleId}`, templateData.PeerCompensation[roleId].map((peer: any) => peer.TotalCompensation), d3data)
      }
    }

    // Industry comparison plots
    if (templateData.IndustryCompensationDistribution) {
      for (const roleId of Object.keys(templateData.TargetPositions)) {
        d3data = await this.createBoxPlot(`Industry Compensation Distribution - ${templateData.SourceInfo.IndustryName} - ${roleId}`, `#industry-box-plot-${roleId}`, templateData.IndustryCompensationDistribution[roleId].map((peer:any) => peer.TotalCompensation), d3data)
      }
    }

    for (const roleId of Object.keys(templateData.TargetPositions)) {
      d3data = await this.createPeerTable(`#peer-table-${roleId}`, `Similar Organizations - ${templateData.TargetPositions[roleId].Name}`, templateData.PeerCompensation[roleId], d3data)
    }
    this.reportHTML = this.sanitizer.bypassSecurityTrustHtml(d3data); // Use DomSanitizer to trust the HTML
  }

  async createPeerData(element, roleId, data) {
    // join data.PeerInfo with data.PeerCompensation on PeerInfo.TaxReturnID = PeerCompensation.TaxReturnID
    return data.PeerInfo.map(peerInfoItem => {
      const matchingPeerCompensation = data.PeerCompensation[roleId].find(peerCompensationItem => peerCompensationItem.TaxReturnID === peerInfoItem.TaxReturnID);
      if (matchingPeerCompensation) {
        return {...peerInfoItem, ...matchingPeerCompensation};
      }
      return {};
    }).filter(item => Object.keys(item).length > 0);
  }

  objectKeys = Object.keys;

  async createPeerTable(element, title, data, html) {
    // THIS IS A HACK BECAUSE I CANT FIGURE OUT WHY IM LOSING THE FIRST ELEMENT OF `data`
    // data.unshift({
    //   OrganizationName: 'Organization Name',
    //   TotalRevenue: 'Total Revenue',
    //   StateProvince: 'State',
    //   NumberEmployees: 'Number of Employees',
    //   TotalCompensation: 'Total Compensation'
    // });
    ///


    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = html
    let selection = d3.select(tempDiv);

    let table = selection.select(element).append('table');

    // Add table title
    table.append('caption').text(title)
    .attr('text-anchor', 'start')
    .style('font-size', '20px')
    .style('fill', 'black')
    .style('font-weight', 'bold')
    .style('text-align', 'left')
    .style('margin-left', '10px');

    // Create table headers
    const headerValues = ['OrganizationName', 'TotalRevenue', 'StateProvince', 'NumberEmployees', 'TotalCompensation'];
    let headers = table.append('tr');
    headers.selectAll('th')
      .data(headerValues)
      .enter()
      .append('th')
      .text(function(d) { return d; });

    let rows = table.selectAll('tr').data(data).enter().append('tr');


    // For each row, select all cells, bind data, enter new cells for each data item
    rows.each(function(rowData) {
      let row = d3.select(this);
      let cells = row.selectAll('td')
        .data(headerValues.map(header => {
          if (header === 'TotalCompensation' || header === 'TotalRevenue') {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(rowData[header]);
          }
          return rowData[header];
        }))
        .enter()
        .append('td')
        .text(d => d)
        .style('text-align', 'center')
        .style('border-bottom', '1px solid black');
    });
    return tempDiv.innerHTML
  }

  private async toMoney(value) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  }

  private async createChart(data, html) {

    for (const roleId of Object.keys(data.CompensationDescriptives)) {
      let tempDiv = document.createElement('div');
      tempDiv.innerHTML = html
      let selection = d3.select(tempDiv);

      const chartData = {
        categories: [
          'Base salary', 'Bonus pay*', 'Deferred salary', 'Base + Bonus'
        ],
        average: [
          await this.toMoney(data.CompensationDescriptives[roleId].Base.average),
          await this.toMoney(data.CompensationDescriptives[roleId].Bonus.average),
          await this.toMoney(0),
          await this.toMoney(data.CompensationDescriptives[roleId].Total.average)
        ],
        median: [
          await this.toMoney(data.CompensationDescriptives[roleId].Base.median),
          await this.toMoney(data.CompensationDescriptives[roleId].Bonus.median),
          await this.toMoney(0),
          await this.toMoney(data.CompensationDescriptives[roleId].Total.median)
        ],
        interquartileRange: [
          `${await this.toMoney(data.CompensationDescriptives[roleId].Base.percentile25)}—${await this.toMoney(data.CompensationDescriptives[roleId].Base.percentile75)}`,
          `${await this.toMoney(data.CompensationDescriptives[roleId].Bonus.percentile25)}—${await this.toMoney(data.CompensationDescriptives[roleId].Bonus.percentile75)}`,
          `$0—$0`,
          `${await this.toMoney(data.CompensationDescriptives[roleId].Total.percentile25)}—${await this.toMoney(data.CompensationDescriptives[roleId].Total.percentile75)}`
        ],
        excludesOutliers: [
          `${await this.toMoney(data.CompensationDescriptives[roleId].Base.percentile10)}—${await this.toMoney(data.CompensationDescriptives[roleId].Base.percentile90)}`,
          `${await this.toMoney(data.CompensationDescriptives[roleId].Bonus.percentile10)}—${await this.toMoney(data.CompensationDescriptives[roleId].Bonus.percentile90)}`,
          `$0—$0`,
          `${await this.toMoney(data.CompensationDescriptives[roleId].Total.percentile10)}—${await this.toMoney(data.CompensationDescriptives[roleId].Total.percentile90)}`
        ],
      };

      const element = `#chart-${roleId}`;
      const margin = { top: 40, right: 30, bottom: 40, left: 30 };
      const width = 1000 - margin.left - margin.right; // minus 40 for the <li> padding
      const height = 400 - margin.top - margin.bottom;

      const svg = selection.select(element)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const tableWidth = width;
      const tableHeight = height;
      const rowHeight = tableHeight / (chartData.categories.length + 1);
      const columnWidth = tableWidth / 5;


      // Background color
      svg.append('rect')
        .attr('width', tableWidth + margin.left + margin.right)
        .attr('height', tableHeight + margin.top + margin.bottom)
        .attr('fill', 'rgba(113, 191, 100, 0.5)') 
        .attr('x', -margin.left)
        .attr('y', -margin.top);

      // Add title
      svg.append('text')
      .attr('x', 0)
      .attr('y', 0 - (margin.top / 4))
      .attr('text-anchor', 'start')
      .style('font-size', '30px')
      .style('fill', 'black')
      .style('font-weight', 'bold')
      .text(`${data.TargetPositions[roleId].Name} Compensation Comparison (N=${data.CompensationDescriptives[roleId].Total.count})`)

      // Add headers
    const headers = ['PAY CATEGORY', 'AVERAGE', 'MEDIAN', 'IQR', 'NO OUTLIERS'];
    headers.forEach((header, i) => {
    svg.append('text')
      .attr('x', i * columnWidth + columnWidth / 2) // Add half of the column width to the 'x' attribute
      .attr('y', rowHeight / 2)
      .attr('dy', '.35em')
      .attr('text-anchor', 'middle')
      .text(header)
      .style('font-weight', 'bold')
      .style('font-size', '20px')
      .style('fill', 'black');
    });

      // Add data rows
      chartData.categories.forEach((category, i) => {
        svg.append('text')
          .attr('x', columnWidth / 2)
          .attr('y', (i + 1.5) * rowHeight)
          // .attr('dy', '.3em')
          .attr('text-anchor', 'middle')
          .text(category)
          .style('fill', 'black');

        svg.append('text')
          .attr('x', columnWidth + columnWidth / 2)
          .attr('y', (i + 1.5) * rowHeight)
          // .attr('dy', '.35em')
          .attr('text-anchor', 'middle')
          .text(`${chartData.average[i]}`)
          .style('fill', 'black');

        svg.append('text')
          .attr('x', 2 * columnWidth + columnWidth / 2)
          .attr('y', (i + 1.5) * rowHeight)
          // .attr('dy', '.35em')
          .attr('text-anchor', 'middle')
          .text(`${chartData.median[i]}`)
          .style('fill', 'black');

        svg.append('text')
          .attr('x', 3 * columnWidth + columnWidth / 2)
          .attr('y', (i + 1.5) * rowHeight)
          // .attr('dy', '.35em')
          .attr('text-anchor', 'middle')
          .text(chartData.interquartileRange[i])
          .style('fill', 'black');

        svg.append('text')
          .attr('x', 4 * columnWidth + columnWidth / 2)
          .attr('y', (i + 1.5) * rowHeight)
          // .attr('dy', '.35em')
          .attr('text-anchor', 'middle')
          .text(chartData.excludesOutliers[i])
          .style('fill', 'black');
      });

      // Add total row
      svg.append('text')
        .attr('x', columnWidth / 2)
        .attr('y', (chartData.categories.length + 1.5) * rowHeight)
        // .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .text('Total Compensation')
        .style('font-weight', 'bold')
        .style('fill', 'black');

      svg.append('text')
        .attr('x', columnWidth + columnWidth / 2)
        .attr('y', (chartData.categories.length + 1.5) * rowHeight)
        // .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .text(`${chartData.average[chartData.average.length - 1]}`)
        .style('font-weight', 'bold')
        .style('fill', 'black');

      svg.append('text')
        .attr('x', 2 * columnWidth + columnWidth / 2)
        .attr('y', (chartData.categories.length + 1.5) * rowHeight)
        // .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .text(`${chartData.median[chartData.median.length - 1]}`)
        .style('font-weight', 'bold')
        .style('fill', 'black');

      svg.append('text')
        .attr('x', 3 * columnWidth + columnWidth / 2)
        .attr('y', (chartData.categories.length + 1.5) * rowHeight)
        // .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .text(chartData.interquartileRange[chartData.interquartileRange.length - 1])
        .style('font-weight', 'bold')
        .style('fill', 'black');

      svg.append('text')
        .attr('x', 4 * columnWidth + columnWidth / 2)
        .attr('y', (chartData.categories.length + 1.5) * rowHeight)
        // .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .text(chartData.excludesOutliers[chartData.excludesOutliers.length - 1])
        .style('font-weight', 'bold')
        .style('fill', 'black');

      html = tempDiv.innerHTML;
    }

      return html;
  }

  makeLabel(svg: any, pointX: any, value: any, title: any, yoffset = 0, xoffset = 0) {
    const format = d3.format("$,.0f"); // Define the format

    svg.append('text')
      .attr('x', pointX(value) + xoffset)
      .attr('y', yoffset)
      .attr('dy', '-1em') // Offset the label slightly above the line
      .style('text-anchor', 'middle')
      .style('font-size', '12px')
      .text(`${title}: ${format(value)}`);
}

  private async createBoxPlot(title, element, data, html) {
      let tempDiv = document.createElement('div');
      tempDiv.innerHTML = html
      let selection = d3.select(tempDiv);

      // const element = "#boxplot";
      const margin = { top: 10, right: 30, bottom: 10, left: 0 };
      const width = 1000;
      const height = 400;

      const svg = selection.select(element)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      // Add a translucent background color
      svg.append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'rgba(217, 145, 89, 0.5)'); // Set the color to black with 10% opacity


      const q1 = d3.quantile(data, .25) || 0;
      const median = d3.quantile(data, .5) || 0;
      const q3 = d3.quantile(data, .75) || 0;
      const interQuantileRange = q3 - q1;
      const min = d3.min(data) || 0;
      const max = d3.max(data) || 0;
      const q10 = d3.quantile(data, .1) || 0;
      const q90 = d3.quantile(data, .9) || 0;

      const x = d3.scaleBand()
        .range([0, width])
        .domain(['Data'])
        .padding(0.05);


      const y = d3.scaleLinear()
        .domain([0, max || 0 ])
        .range([0, height]);

      const pointX = d3.scaleLinear()
        .domain([min, max])
        .range([100, width - 100]);

      // Define the outliers box
      const heightDy = 0.15 //0.5
      const boxDy = 0.10
      svg.selectAll('boxes')
      .data([data])
      .enter()
      .append('rect')
      .attr('x', pointX(q10))
      .attr('y', height * (0.5 - boxDy / 2))
      .attr('width', pointX(q90) - pointX(q10))
      .attr('height', height * heightDy)
      .attr('stroke', 'black')
      .style('fill', 'rgb(113,191,100)');

      // Define the IQR box
      svg.selectAll('boxes')
      .data([data])
      .enter()
      .append('rect')
      .attr('x', pointX(q1))
      .attr('y', height * (0.5 - boxDy / 2))
      .attr('width', pointX(q3) - pointX(q1))
      .attr('height', height * heightDy)
      .attr('stroke', 'black')
      .style('fill', 'rgb(40,84,63)');

  // Draw the median line inside the box
    svg.selectAll('medianLines')
      .data([data])
      .enter()
      .append('line')
      .attr('x1', pointX(median))
      .attr('x2', pointX(median))
      .attr('y1', height * (0.5 - boxDy / 2))
      .attr('y2', height * (0.5 + boxDy))
      .attr('stroke', 'black')
      .style('width', 80);

    // Labels
    this.makeLabel(svg, pointX, min, "Min", height * (0.55 + boxDy / 2), -25);
    this.makeLabel(svg, pointX, q10, "Q10", height * (0.5 - boxDy / 2));
    this.makeLabel(svg, pointX, q1, "Q25", height * (0.65 + boxDy / 2));
    this.makeLabel(svg, pointX, median, "Median", height * (0.5 - boxDy));
    this.makeLabel(svg, pointX, q3, "Q75", height * (0.65 + boxDy / 2));
    this.makeLabel(svg, pointX, q90, "Q90", height * (0.5 - boxDy / 2));
    this.makeLabel(svg, pointX, max, "Max", height * (0.55 + boxDy / 2), + 25);

  // Draw the whiskers
    svg.selectAll('verticalLines')
      .data([data])
      .enter()
      .append('line')
      .attr('x1', pointX(q10))
      .attr('x2', pointX(q90))
      .attr('y1', height * (0.5))
      .attr('y2', height * (0.5))
      .attr('stroke', 'black')
      .style('width', 40);


  // Plot individual data points as outliers
  svg.selectAll('individualPoints')
    .data(data)
    .enter()
    .append('circle')
    // .attr('cy', (x('Data') || 0) + x.bandwidth() * (boxDy + heightDy / 2))
    .attr('cy', height / 2 )
    // .attr('cx', function (d) { return y(d); })
    .attr('cx', function (d) { return pointX(d); })
    .attr('r', 3)
    .style('fill', 'black')
    .attr('stroke', 'black');

    // Title
    svg.append("text")
    .attr("x", margin.right)
    .attr("y", (x('Data') || 0))
    .attr('text-anchor', 'start')
    .style('font-size', '30px')
    .style('fill', 'black')
    .style('font-weight', 'bold')
    .text(`${title} (N=${data.length})`);

    html = tempDiv.innerHTML;
    return html;
  }
} 