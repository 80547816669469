"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SetProvider = void 0;
const baseEntity_1 = require("./generic/baseEntity");
const metadata_1 = require("./generic/metadata");
const runReport_1 = require("./generic/runReport");
const runView_1 = require("./views/runView");
__exportStar(require("./generic/metadata"), exports);
__exportStar(require("./generic/baseInfo"), exports);
__exportStar(require("./generic/baseEngine"), exports);
__exportStar(require("./views/runView"), exports);
__exportStar(require("./generic/runReport"), exports);
__exportStar(require("./generic/runQuery"), exports);
__exportStar(require("./generic/interfaces"), exports);
__exportStar(require("./generic/baseEntity"), exports);
__exportStar(require("./generic/applicationInfo"), exports);
__exportStar(require("./generic/providerBase"), exports);
__exportStar(require("./generic/entityInfo"), exports);
__exportStar(require("./generic/securityInfo"), exports);
__exportStar(require("./generic/transactionGroup"), exports);
__exportStar(require("./generic/util"), exports);
__exportStar(require("./generic/logging"), exports);
__exportStar(require("./generic/queryInfo"), exports);
__exportStar(require("./generic/compositeKey"), exports);
function SetProvider(provider) {
  metadata_1.Metadata.Provider = provider;
  baseEntity_1.BaseEntity.Provider = provider;
  runView_1.RunView.Provider = provider;
  runReport_1.RunReport.Provider = provider;
}
exports.SetProvider = SetProvider;
