<div *ngIf="!loaded">
  <div class="gg-loader">
    <img src="../../assets/loader.gif"/>
  </div>
</div>
<div *ngIf="loaded">
  <div class="breadcrumbWrapper">
    <div class="container-large">
      <div class="breadcrumb">
        <a routerLink="/">
          <kendo-icon name="home"></kendo-icon>
        </a><kendo-icon name="arrow-chevron-right"></kendo-icon>
        <a class="active">Benchmarking</a>
      </div>
      <div class="back-sec">
     <a>
        <kendo-icon name="arrow-chevron-left"></kendo-icon>
        Back
      </a>
      </div>
    </div>
  </div>

  <div class="content-wrapper">
    <!-- <app-side-nav></app-side-nav> -->
    <div class="main-contentx">
      <div class="first-line">
        <h1 class="report-header">Reports</h1>
        <button class="big-orange-button" routerLink="/new-report"> + Create New Report </button>
      </div>  
      <!-- TODO: add date created -->
    <div class="existing-reports">
    <kendo-grid  [data]="existingRecords" (cellClick)="onRowClick($event)">
      <kendo-grid-column field="ID" title="ID" [width]="50"></kendo-grid-column>
      <kendo-grid-column field="Name" title="Report Title"></kendo-grid-column>
      <kendo-grid-column field="Status" title="Status">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [ngClass]="{
              'status-pending': dataItem.Status === 'Pending',
              'status-complete': dataItem.Status === 'Complete',
              'status-error': dataItem.Status === 'Error'
            }">
            {{dataItem.Status }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="StatusMessage" title="Status Message"></kendo-grid-column>
      <kendo-grid-column field="CreatedAt" title="Date Created">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.CreatedAt | date: 'short'}}  
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column [width]="70">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton >
            <img src="../../assets/trash.svg" alt="">
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
    </div>
    </div> 
  </div>
  </div>