"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AIEngine = exports.EntityAIActionParams = exports.AIActionParams = void 0;
const ai_1 = require("@memberjunction/ai");
const core_1 = require("@memberjunction/core");
const global_1 = require("@memberjunction/global");
class AIActionParams {}
exports.AIActionParams = AIActionParams;
class EntityAIActionParams extends AIActionParams {}
exports.EntityAIActionParams = EntityAIActionParams;
class AIEngine extends core_1.BaseEngine {
  constructor() {
    super(...arguments);
    this._models = [];
    this._vectorDatabases = [];
    this._actions = [];
    this._entityActions = [];
    this._modelActions = [];
    this._entityDocumentTypes = [];
  }
  async Config(forceRefresh, contextUser) {
    const params = [{
      PropertyName: '_models',
      EntityName: 'AI Models'
    }, {
      PropertyName: '_vectorDatabases',
      EntityName: 'Vector Databases'
    }, {
      PropertyName: '_actions',
      EntityName: 'AI Actions'
    }, {
      PropertyName: '_entityActions',
      EntityName: 'Entity AI Actions'
    }, {
      PropertyName: '_modelActions',
      EntityName: 'AI Model Actions'
    }, {
      PropertyName: '_entityDocumentTypes',
      EntityName: 'Entity Document Types'
    }];
    return await this.Load(params, forceRefresh, contextUser);
  }
  async GetHighestPowerModel(vendorName, modelType, contextUser) {
    await AIEngine.Instance.Config(false, contextUser);
    const models = AIEngine.Models.filter(m => m.AIModelType.trim().toLowerCase() === modelType.trim().toLowerCase() && m.Vendor.trim().toLowerCase() === vendorName.trim().toLowerCase());
    models.sort((a, b) => b.PowerRank - a.PowerRank);
    return models[0];
  }
  static get Models() {
    AIEngine.checkMetadataLoaded();
    return AIEngine.Instance._models;
  }
  static get VectorDatabases() {
    AIEngine.checkMetadataLoaded();
    return AIEngine.Instance._vectorDatabases;
  }
  static get ModelActions() {
    AIEngine.checkMetadataLoaded();
    return AIEngine.Instance._modelActions;
  }
  static get Actions() {
    AIEngine.checkMetadataLoaded();
    return AIEngine.Instance._actions;
  }
  static get EntityAIActions() {
    AIEngine.checkMetadataLoaded();
    return AIEngine.Instance._entityActions;
  }
  static get EntityDocumentTypes() {
    AIEngine.checkMetadataLoaded();
    return AIEngine.Instance._entityDocumentTypes;
  }
  static get Instance() {
    return super.getInstance();
  }
  static checkMetadataLoaded() {
    if (!AIEngine.Instance.Loaded) throw new Error("AI Metadata not loaded, call AIEngine.Config() first.");
  }
  async ExecuteEntityAIAction(params) {
    const startTime = new Date();
    try {
      const entityAction = AIEngine.EntityAIActions.find(ea => ea.ID === params.entityAIActionId);
      if (!entityAction) throw new Error(`Entity AI Action ${params.entityAIActionId} not found.`);
      const action = AIEngine.Actions.find(a => a.ID === entityAction.AIActionID);
      if (!action) throw new Error(`Action ${entityAction.AIActionID} not found, from the EntityAIAction ${params.entityAIActionId}.`);
      if (entityAction.SkipIfOutputFieldNotEmpty && entityAction.OutputType.trim().toLowerCase() === 'field') {
        const val = params.entityRecord.Get(entityAction.OutputField);
        if (val && val.length > 0) return null;
      }
      const entityPrompt = params.systemPrompt ? params.systemPrompt : entityAction.Prompt && entityAction.Prompt.length > 0 ? entityAction.Prompt : action.DefaultPrompt;
      const userMessage = params.userPrompt ? params.userPrompt : this.markupUserMessage(params.entityRecord, entityAction.UserMessage);
      const modelId = entityAction.AIModelID || action.DefaultModelID;
      const model = AIEngine.Models.find(m => m.ID === modelId);
      const entityParams = {
        name: entityAction.Name,
        actionId: entityAction.AIActionID,
        modelId: modelId,
        systemPrompt: entityPrompt,
        userMessage: userMessage,
        apiKey: (0, ai_1.GetAIAPIKey)(model.DriverClass),
        result: null
      };
      if (!(await params.entityRecord.BeforeEntityAIAction(entityParams))) return null;
      const results = await this.ExecuteAIAction({
        actionId: entityParams.actionId,
        modelId: entityParams.modelId,
        systemPrompt: entityParams.systemPrompt,
        userPrompt: entityParams.userMessage,
        modelName: model.Name
      });
      if (results) {
        const sOutput = this.GetStringOutputFromActionResults(action, results);
        entityParams.result = results;
        if (!(await params.entityRecord.AfterEntityAIAction(entityParams))) return results;
        if (entityAction.OutputType.trim().toLowerCase() === 'field') {
          params.entityRecord.Set(entityAction.OutputField, sOutput);
          if (entityAction.TriggerEvent.trim().toLowerCase() === 'after save') {
            await params.entityRecord.Save({
              SkipEntityAIActions: true,
              IgnoreDirtyState: false
            });
          }
        } else if (entityAction.OutputType.trim().toLowerCase() === 'entity') {
          const md = new core_1.Metadata();
          const newRecord = await md.GetEntityObject(entityAction.OutputEntity);
          newRecord.NewRecord();
          newRecord.Set('EntityID', params.entityRecord.EntityInfo.ID);
          newRecord.Set('RecordID', params.entityRecord.FirstPrimaryKey.Value);
          newRecord.Set(entityAction.OutputField, sOutput);
          await newRecord.Save();
        }
      }
      return results;
    } catch (err) {
      console.error(err);
      return {
        success: false,
        startTime: startTime,
        endTime: new Date(),
        timeElapsed: new Date().getTime() - startTime.getTime(),
        errorMessage: err.message,
        exception: err
      };
    }
  }
  markupUserMessage(entityRecord, userMessage) {
    let temp = userMessage;
    const markupTokens = temp.match(/{[a-zA-Z0-9]+}/g);
    if (markupTokens && markupTokens.length > 0) {
      markupTokens.forEach(token => {
        const fieldName = token.replace('{', '').replace('}', '');
        const fieldValue = entityRecord.Get(fieldName);
        temp = temp.replace(token, fieldValue ? fieldValue : '');
      });
    }
    return temp;
  }
  async ExecuteAIAction(params) {
    const action = AIEngine.Actions.find(a => a.ID === params.actionId);
    if (!action) throw new Error(`Action ${params.actionId} not found.`);
    if (action.IsActive === false) throw new Error(`Action ${params.actionId} is not active.`);
    const model = AIEngine.Models.find(m => m.ID === params.modelId);
    if (!model) throw new Error(`Model ${params.modelId} not found.`);
    if (model.IsActive === false) throw new Error(`Model ${params.modelId} is not active.`);
    const driver = await this.getDriver(model, (0, ai_1.GetAIAPIKey)(model.DriverClass));
    if (driver) {
      const modelParams = {
        model: params.modelName,
        messages: [{
          role: 'system',
          content: params.systemPrompt
        }, {
          role: 'user',
          content: params.userPrompt
        }]
      };
      switch (action.Name.trim().toLowerCase()) {
        case 'classify':
          const classifyResult = await driver.ClassifyText(modelParams);
          return classifyResult;
        case 'summarize':
          const summarizeResult = await driver.SummarizeText(modelParams);
          return summarizeResult;
        case 'chat':
          const chatResult = await driver.ChatCompletion(modelParams);
          return chatResult;
        default:
          throw new Error(`Action ${action.Name} not supported.`);
      }
    } else throw new Error(`Driver ${model.DriverClass} not found or couldn't be loaded.`);
  }
  GetStringOutputFromActionResults(action, result) {
    switch (action.Name.trim().toLowerCase()) {
      case 'classify':
        const classifyResult = result;
        return classifyResult.tags.map(t => t.tag).join(', ');
      case 'summarize':
        const summarizeResult = result;
        return summarizeResult.summaryText;
      case 'chat':
        const chatResult = result;
        return chatResult.data.choices[0].message.content;
      default:
        throw new Error(`Action ${action.Get('Name').Name} not supported.`);
    }
  }
  async getDriver(model, apiKey) {
    const driverClassName = model.DriverClass;
    const driverModuleName = model.DriverImportPath;
    try {
      if (driverModuleName && driverModuleName.length > 0) {
        const driverModule = await Promise.resolve(`${driverModuleName}`).then(s => __importStar(require(s)));
        if (!driverModule) throw new Error(`Error loading driver module '${driverModuleName}'`);
      }
      return global_1.MJGlobal.Instance.ClassFactory.CreateInstance(ai_1.BaseModel, driverClassName, apiKey);
    } catch (e) {
      throw new Error(`Error loading driver '${driverModuleName}' / '${driverClassName}' : ${e.message}`);
    }
  }
}
exports.AIEngine = AIEngine;
