"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseModel = exports.ModelUsage = exports.BaseParams = exports.BaseResult = void 0;
class BaseResult {
  get timeElapsed() {
    return this.endTime.getTime() - this.startTime.getTime();
  }
  constructor(success, startTime, endTime) {
    this.success = success;
    this.startTime = startTime;
    this.endTime = endTime;
  }
}
exports.BaseResult = BaseResult;
class BaseParams {}
exports.BaseParams = BaseParams;
class ModelUsage {
  constructor(promptTokens, completionTokens) {
    this.promptTokens = promptTokens;
    this.completionTokens = completionTokens;
  }
  get totalTokens() {
    return this.promptTokens + this.completionTokens;
  }
}
exports.ModelUsage = ModelUsage;
class BaseModel {
  get apiKey() {
    return this._apiKey;
  }
  constructor(apiKey) {
    if (!apiKey || apiKey.trim().length === 0) throw new Error('@memberjuction/AI.baseModel: API key cannot be empty');
    this._apiKey = apiKey;
  }
}
exports.BaseModel = BaseModel;
