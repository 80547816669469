"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListDetailEntityExtended = void 0;
const core_1 = require("@memberjunction/core");
const global_1 = require("@memberjunction/global");
const entity_subclasses_1 = require("../generated/entity_subclasses");
let ListDetailEntityExtended = class ListDetailEntityExtended extends entity_subclasses_1.ListDetailEntity {
  async Save() {
    const currentResultCount = this.ResultHistory.length;
    const newResult = new core_1.BaseEntityResult();
    newResult.StartedAt = new Date();
    try {
      const rv = new core_1.RunView();
      if (!this.ListID) {
        throw new Error('ListID cannot be null');
      }
      if (!this.RecordID) {
        throw new Error('RecordID cannot be null');
      }
      if (!this.ContextCurrentUser) {
        throw new Error('ContextCurrentUser cannot be null');
      }
      const rvResult = await rv.RunView({
        EntityName: 'List Details',
        ExtraFilter: `ListID = '${this.ListID}' AND RecordID = '${this.RecordID}'`
      }, this.ContextCurrentUser);
      if (!rvResult.Success) {
        throw new Error(rvResult.ErrorMessage);
      }
      if (rvResult.Results.length > 0) {
        throw new Error(`Record ${this.RecordID} already exists in List ${this.ListID}`);
      }
      const saveResult = await super.Save();
      return saveResult;
    } catch (e) {
      if (currentResultCount === this.ResultHistory.length) {
        0;
        // this means that NO new results were added to the history anywhere 
        // so we need to add a new result to the history here
        newResult.Success = false;
        newResult.Type = this.IsSaved ? 'update' : 'create';
        newResult.Message = e.message;
        newResult.OriginalValues = this.Fields.map(f => {
          return {
            FieldName: f.CodeName,
            Value: f.OldValue
          };
        });
        newResult.EndedAt = new Date();
        this.ResultHistory.push(newResult);
      }
      return false;
    }
  }
};
exports.ListDetailEntityExtended = ListDetailEntityExtended;
exports.ListDetailEntityExtended = ListDetailEntityExtended = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'List Details', 2)], ListDetailEntityExtended);
