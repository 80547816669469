import { Injectable } from '@angular/core';
import { CurrencyPipe, DecimalPipe  } from "@angular/common";
import { Metadata, RunView } from "@memberjunction/core";
import { AccountEntity, ContactLevelEntity, ContactRoleEntity, IndustryEntity, RangeClassEntity, TaxReturnAggregationEntity, TaxReturnCompensationAggregationEntity } from "mj_generatedentities";


@Injectable({
    providedIn: 'root'
})
export class SharedData {
    [key: string]: any; // Index signature

    protected contactLevels: ContactLevelEntity[] = []
    protected contactRoles: ContactRoleEntity[] = []
    protected rangeClasses: RangeClassEntity[] = []
    protected industries: IndustryEntity[] = []
    protected taxReturnComp: TaxReturnCompensationAggregationEntity[] = []
    protected taxReturnAggregations: TaxReturnAggregationEntity[] = []
    protected accounts: AccountEntity[] = []
    private static _instance: SharedData;
    private _keyPrefix = "__green_gopher_";
    private _updatedDateKeyBase = this._keyPrefix + "updatedDate";

    private loadStructure = [
        {
            datasetName: 'MJ_Metadata',
            entities: [
                {
                    entityName: 'Entities',
                    code: 'Entities'
                }
            ]
        },
        { 
            datasetName: 'ContactClassification', 
            entities: [ 
                {
                    entityName: 'Contact Roles', 
                    code: 'contactRoles' 
                }, 
                {
                    entityName: 'Contact Levels', 
                    code: 'contactLevels'
                } 
            ] 
        },
        { 
            datasetName: 'TaxReturnAggregation', 
            entities: [ 
                {
                    entityName: 'Tax Return Aggregations', 
                    code: 'taxReturnAggregations' 
                } 
            ] 
        },
        { 
            datasetName: 'TaxReturnComp', 
            entities: [ 
                {
                    entityName: 'Tax Return Compensation Aggregations', 
                    code: 'taxReturnComp' 
                } 
            ] 
        },
        { 
            datasetName: 'RangeClasses', 
            entities: [ 
                {
                    entityName: 'Range Classes', 
                    code: 'rangeClasses' 
                } 
            ] 
        },
        { 
            datasetName: 'Industries', 
            entities: [ 
                {
                    entityName: 'Industries', 
                    code: 'industries' 
                } 
            ] 
        },
        {
            datasetName: 'Accounts',
            entities: [
                {
                    entityName: 'Accounts',
                    code: 'accounts'
                }
            ]
        }
    ]

    constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe) {
        if (SharedData._instance) {
            return SharedData._instance;
        }
        else { 
            // first instance
            SharedData._instance = this;
            return this;// not needed but for clarity
        }
    }

    public async Refresh() {
        // get the data from the database, but check local storage first
        // first, check the database and see if anything has been updated. If any updates, we wipe out all our keys in the local storage to ensure that we load fresh data from the DB
        const md = new Metadata();
        for (let i = 0; i < this.loadStructure.length; i++) {
            // check each dataset for updates
            const dataset = this.loadStructure[i];
            const data = await md.GetAndCacheDatasetByName(dataset.datasetName);
            for (let j = 0; j < dataset.entities.length; j++) {
                const item = dataset.entities[j];
                const dItem = data.Results.find(d => d.EntityName === item.entityName)
                if (dItem)
                    this[item.code] = dItem.Results
            }
        }
    }   

    private _rangeClassesProcessed = false;
    public get RangeClasses(): RangeClassEntity[] {
        // first time we need to do a little procdessing to create a new column in each range class item that has the range class name and the min/max
        // like this Name (Min - Max), but if Min is 0, we say "Up to Max" and if Max is 0, we say "Min and up"
        if (!this._rangeClassesProcessed) {
            this.rangeClasses.forEach((r: any) => { // casted r as any to allow us to add a new field called NameWithRange
                let min: any = r.MinVal;
                let max: any = r.MaxVal;
                if (min === 0) {
                    min = null;
                }
                if (max === 0) {
                    max = null;
                }

                if (r.Type === 'Revenue') {
                    if (min !== null)
                        min = this.currencyPipe.transform(min, 'USD', 'symbol', '1.0-0')
                    if (max !== null)   
                        max = this.currencyPipe.transform(max, 'USD', 'symbol', '1.0-0')
                }
                else {
                    if (min !== null)
                        min = this.decimalPipe.transform(min, '1.0-0')
                    if (max !== null)   
                        max = this.decimalPipe.transform(max, '1.0-0')
                }

                if (min === null && max === null) {
                    r.NameWithRange = 'All';
                }
                else if (min === null) {
                    r.NameWithRange = `Up to ${max}`;
                }
                else if (max === null) {
                    r.NameWithRange = `${min} and up`;
                }   
                else {
                    r.NameWithRange = `${min} - ${max}`;
                }

                r.NameWithRange = `${r.Name} (${r.NameWithRange})`;
            });
            this._rangeClassesProcessed = true;

            // now sort the range classes by the Type first and then by the Rank
            this.rangeClasses.sort((a: RangeClassEntity, b: RangeClassEntity) => {
                if (a.Type === b.Type) {
                    return a.Rank - b.Rank;
                }
                else {
                    return a.Type.localeCompare(b.Type);
                }
            }); 
        }
        return this.rangeClasses;
    }
    public get MetroAreaSizes(): RangeClassEntity[] {
        return this.RangeClasses.filter(r => r.Type.trim().toLowerCase() === 'metro areas');
    }
    public get OrgRevenueSizes(): RangeClassEntity[] {
        return this.RangeClasses.filter(r => r.Type.trim().toLowerCase() === 'revenue');
    }
    public get OrgEmployeeSizes(): RangeClassEntity[] {
        return this.RangeClasses.filter(r => r.Type.trim().toLowerCase() === 'employees');
    }


    private _industriesProcessed = false;
    public get Industries(): IndustryEntity[] {
        if (!this._industriesProcessed) {
            //sort by name
            this.industries.sort((a: IndustryEntity, b: IndustryEntity) => {
                return a.Name.localeCompare(b.Name);
            });

            // now wipe out null values in the keywords field and replace with empty string
            this.industries.forEach(i => {
                if (i.Keywords === null || i.Keywords === undefined || i.Keywords.trim().toUpperCase() === 'NULL') {
                    i.Keywords = '';
                }
            });
            this._industriesProcessed = true;
        }
        return this.industries;
    }

    private _contactLevelsProcessed = false;
    public get ContactLevels(): ContactLevelEntity[] {
        // sort them by rank
        if (!this._contactLevelsProcessed) {
            this.contactLevels.sort((a: ContactLevelEntity, b: ContactLevelEntity) => {
                return a.Rank - b.Rank;
            });
            this._contactLevelsProcessed = true;
        }
        return this.contactLevels;
    }

    private _contactRolesProcessed = false;
    public get ContactRoles(): ContactRoleEntity[] {
        // sort them by name
        if (!this._contactRolesProcessed) {
            this.contactRoles.sort((a: ContactRoleEntity, b: ContactRoleEntity) => {
                return a.Name.localeCompare(b.Name);
            });
            this._contactRolesProcessed = true;
        }
        return this.contactRoles;
    }

    public get TaxReturnComp(): TaxReturnCompensationAggregationEntity[] {
        return this.taxReturnComp;
    }

    public get TaxReturnAggregations(): TaxReturnAggregationEntity[] {
        return this.taxReturnAggregations;
    }

    public get Accounts(): AccountEntity[] {
        return this.accounts
    }
}