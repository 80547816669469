<div>
  <kendo-appbar class="app-header">
      <kendo-appbar-section>
        <img src="../../assets/Green Gopher - Mark Only - White Circle.png" alt="logo" class="logo" (click)="goToHome()"/>
      </kendo-appbar-section>
      <kendo-appbar-section>
        <img src="../../assets/Green Gopher - Text Only.png" (click)="goToHome()" class="title">
      </kendo-appbar-section>

      <kendo-appbar-spacer width="32px"></kendo-appbar-spacer>

      <kendo-appbar-spacer></kendo-appbar-spacer>
      <kendo-appbar-section>
      </kendo-appbar-section>


      <kendo-appbar-section class="login-section">
          <!-- <a href="https://www.sidecarglobal.com/" target="_blank"><img src="../../assets/Sidecar Logo.png" alt="Sidecar logo" class="sidecar-logo"/></a> -->
          <app-auth-button></app-auth-button>
          <!-- <kendo-icon *ngIf="sharedService.LoggedIn && !sharedService.AccessDenied" name="user" class="user-icon" (click)="router.navigate(['/user-profile'])"></kendo-icon> -->
      </kendo-appbar-section>
    </kendo-appbar>
</div>
