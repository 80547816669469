import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ContactRoleEntity } from 'mj_generatedentities';
import { MJEventType, MJGlobal } from '@memberjunction/global';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { SharedData } from '../shared-data';
import { SharedService } from '../shared-service';

@Component({
  selector: 'app-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.css']
})
export class RoleSelectComponent implements OnInit {
  public roles: any[] = [];
  public loaded: boolean = false;
  public readyToLoad: boolean = false;

  // Inject the authentication service into your component through the constructor
  constructor(@Inject(DOCUMENT) public document: Document, public auth: AuthService, private router: Router, private sharedService: SharedService, private sharedData: SharedData) {}

  async ngOnInit(): Promise<void> {
    this.sharedService.setupComplete$.subscribe(setupComplete => {
      if (setupComplete) {
        // Perform actions that depend on the setup being complete
        this.loadData();
      }
    });
  }

  public async loadData() {
    try {
      if (this.loaded) return;

      // get the data from the database on our list of roles
      this.roles =  this.sharedData.ContactRoles.sort((a: ContactRoleEntity, b: ContactRoleEntity) => { return a.Name > b.Name ? 1 : -1 });
      this.loaded = true;
  }
    catch (e) {
      console.log(e);
    }
  }    

  public roleChanged(role: ContactRoleEntity) {
    // role selected, navigate to the role overview page
    // tell angular to navigate

    this.router.navigate(['role', role.ID]);
  }
}
