"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryPermissionInfo = exports.QueryFieldInfo = exports.QueryCategoryInfo = exports.QueryInfo = void 0;
const baseInfo_1 = require("./baseInfo");
const metadata_1 = require("./metadata");
/**
 * Metadata about a single stored query in the database.
 */
class QueryInfo extends baseInfo_1.BaseInfo {
  get Fields() {
    if (this._fields === null) {
      this._fields = metadata_1.Metadata.Provider.QueryFields.filter(f => f.QueryID === this.ID);
    }
    return this._fields;
  }
  get Permissions() {
    return metadata_1.Metadata.Provider.QueryPermissions.filter(p => p.QueryID === this.ID);
  }
  constructor(initData = null) {
    super();
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.CategoryID = null;
    this.SQL = null;
    this.OriginalSQL = null;
    this.Feedback = null;
    this.Status = null;
    this.QualityRank = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    this.Category = null;
    this._fields = null;
    if (initData) {
      this.copyInitData(initData);
      // do some special handling to create class instances instead of just data objects
      // copy the Entity Field Values
      this._fields = [];
      const f = initData.Fields || initData._Fields;
      if (f) {
        for (let j = 0; j < f.length; j++) {
          this._fields.push(new QueryFieldInfo(f));
        }
      }
    }
  }
  get CategoryInfo() {
    return metadata_1.Metadata.Provider.QueryCategories.find(c => c.ID === this.CategoryID);
  }
}
exports.QueryInfo = QueryInfo;
class QueryCategoryInfo extends baseInfo_1.BaseInfo {
  constructor(initData = null) {
    super();
    this.ID = null;
    this.Name = null;
    this.ParentID = null;
    this.Description = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    this.Parent = null;
    if (initData) {
      this.copyInitData(initData);
    }
  }
  get ParentCategoryInfo() {
    return metadata_1.Metadata.Provider.QueryCategories.find(c => c.ID === this.ParentID);
  }
  get Queries() {
    return metadata_1.Metadata.Provider.Queries.filter(q => q.CategoryID === this.ID);
  }
}
exports.QueryCategoryInfo = QueryCategoryInfo;
class QueryFieldInfo extends baseInfo_1.BaseInfo {
  constructor(initData = null) {
    super();
    this.Name = null;
    this.QueryID = null;
    this.Description = null;
    this.Sequence = null;
    /**
     * The base type, not including parameters, in SQL. For example this field would be nvarchar or decimal, and wouldn't include type parameters. The SQLFullType field provides that information.
     */
    this.SQLBaseType = null;
    /**
     * The full SQL type for the field, for example datetime or nvarchar(10) etc.
     */
    this.SQLFullType = null;
    this.SourceEntityID = null;
    this.SourceFieldName = null;
    this.IsComputed = null;
    this.ComputationDescription = null;
    this.IsSummary = null;
    this.SummaryDescription = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    this.SourceEntity = null;
    if (initData) {
      this.copyInitData(initData);
    }
  }
  get SourceEntityInfo() {
    return metadata_1.Metadata.Provider.Entities.find(e => e.ID === this.SourceEntityID);
  }
  get QueryInfo() {
    return metadata_1.Metadata.Provider.Queries.find(q => q.ID === this.ID);
  }
}
exports.QueryFieldInfo = QueryFieldInfo;
class QueryPermissionInfo extends baseInfo_1.BaseInfo {
  constructor(initData = null) {
    super();
    this.QueryID = null;
    this.RoleName = null;
    // virtual fields - returned by the database VIEW
    this.Query = null;
    if (initData) {
      this.copyInitData(initData);
    }
  }
  get QueryInfo() {
    return metadata_1.Metadata.Provider.Queries.find(q => q.ID === this.QueryID);
  }
}
exports.QueryPermissionInfo = QueryPermissionInfo;
