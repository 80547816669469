"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemplateRenderResult = void 0;
/**
 * Contains the results of a call to render a template
 */
class TemplateRenderResult {}
exports.TemplateRenderResult = TemplateRenderResult;
