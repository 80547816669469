"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecordMergeResult = exports.RecordMergeDetailResult = exports.RecordMergeRequest = exports.RecordDependency = exports.EntityDependency = exports.ValidationResult = exports.ValidationErrorInfo = exports.ValidationErrorType = exports.EntityInfo = exports.EntitySettingInfo = exports.EntityDocumentTypeInfo = exports.EntityFieldInfo = exports.GeneratedFormSectionType = exports.EntityFieldValueInfo = exports.EntityFieldValueListType = exports.EntityFieldGraphQLType = exports.EntityFieldTSType = exports.EntityPermissionInfo = exports.EntityUserPermissionInfo = exports.EntityPermissionType = exports.EntityRelationshipInfo = exports.RecordChange = exports.RecordChangeStatus = void 0;
const baseInfo_1 = require("./baseInfo");
const metadata_1 = require("./metadata");
const util_1 = require("./util");
const logging_1 = require("./logging");
/**
 * The possible status values for a record change
 */
exports.RecordChangeStatus = {
  Pending: 'Pending',
  Complete: 'Complete',
  Error: 'Error'
};
/**
 * Record Change object has information on a change to a record in the Record Changes entity
 */
class RecordChange extends baseInfo_1.BaseInfo {
  get StatusValue() {
    return exports.RecordChangeStatus[this.Status?.trim()];
  }
  get Changes() {
    return JSON.parse(this.ChangesJSON);
  }
  get FullRecord() {
    return JSON.parse(this.FullRecordJSON);
  }
  constructor(initData) {
    super();
    this.ID = null;
    this.EntityID = null;
    this.RecordID = null;
    this.ChangedAt = null;
    this.ChangesJSON = null;
    this.ChangesDescription = null;
    this.FullRecordJSON = null;
    this.Status = null;
    this.copyInitData(initData);
  }
}
exports.RecordChange = RecordChange;
/**
 * Information about the Entity Relationship between the Entity and the Related Entity - this class
 * maps to information in the Entity Relationships metadata entity.
 */
class EntityRelationshipInfo extends baseInfo_1.BaseInfo {
  constructor(initData) {
    super();
    this.ID = null;
    this.EntityID = null;
    this.Sequence = null;
    this.RelatedEntityID = null;
    this.BundleInAPI = null;
    this.IncludeInParentAllQuery = null;
    this.Type = null;
    this.EntityKeyField = null;
    this.RelatedEntityJoinField = null;
    this.JoinView = null;
    this.JoinEntityJoinField = null;
    this.JoinEntityInverseJoinField = null;
    this.DisplayInForm = null;
    this.DisplayLocation = 'After Field Tabs';
    this.DisplayName = null;
    this.DisplayIconType = 'Related Entity Icon';
    this.DisplayIcon = null;
    this.DisplayUserViewID = null;
    this.DisplayComponentID = null;
    this.DisplayComponentConfiguration = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    this.Entity = null;
    this.EntityBaseTable = null;
    this.EntityBaseView = null;
    this.RelatedEntity = null;
    this.RelatedEntityBaseTable = null;
    this.RelatedEntityBaseView = null;
    this.RelatedEntityCodeName = null;
    this.RelatedEntityClassName = null;
    this.RelatedEntityBaseTableCodeName = null;
    this.DisplayUserViewName = null;
    this.DisplayComponent = null;
    this.copyInitData(initData);
  }
}
exports.EntityRelationshipInfo = EntityRelationshipInfo;
exports.EntityPermissionType = {
  Read: 'Read',
  Create: 'Create',
  Update: 'Update',
  Delete: 'Delete'
};
class EntityUserPermissionInfo {
  constructor() {
    this.ID = null;
  }
}
exports.EntityUserPermissionInfo = EntityUserPermissionInfo;
class EntityPermissionInfo extends baseInfo_1.BaseInfo {
  get CreateRLSFilterObject() {
    return this.RLSFilter(exports.EntityPermissionType.Create);
  }
  get ReadRLSFilterObject() {
    return this.RLSFilter(exports.EntityPermissionType.Read);
  }
  get UpdateRLSFilterObject() {
    return this.RLSFilter(exports.EntityPermissionType.Update);
  }
  get DeleteRLSFilterObject() {
    return this.RLSFilter(exports.EntityPermissionType.Delete);
  }
  RLSFilter(type) {
    let fID = "";
    switch (type) {
      case exports.EntityPermissionType.Read:
        fID = this.ReadRLSFilterID;
        break;
      case exports.EntityPermissionType.Create:
        fID = this.CreateRLSFilterID;
        break;
      case exports.EntityPermissionType.Update:
        fID = this.UpdateRLSFilterID;
        break;
      case exports.EntityPermissionType.Delete:
        fID = this.DeleteRLSFilterID;
        break;
    }
    if (fID && fID.length > 0) return metadata_1.Metadata.Provider.RowLevelSecurityFilters.find(f => f.ID === fID);
  }
  constructor(initData) {
    super();
    this.ID = null;
    this.EntityID = null;
    this.RoleID = null;
    this.CanCreate = null;
    this.CanRead = null;
    this.CanUpdate = null;
    this.CanDelete = null;
    this.ReadRLSFilterID = null;
    this.CreateRLSFilterID = null;
    this.UpdateRLSFilterID = null;
    this.DeleteRLSFilterID = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    this.Entity = null;
    this.Role = null;
    this.RoleSQLName = null;
    this.ReadRLSFilter = null;
    this.CreateRLSFilter = null;
    this.UpdateRLSFilter = null;
    this.DeleteRLSFilter = null;
    this.copyInitData(initData);
  }
}
exports.EntityPermissionInfo = EntityPermissionInfo;
exports.EntityFieldTSType = {
  String: 'string',
  Number: 'number',
  Date: 'Date',
  Boolean: 'boolean'
};
exports.EntityFieldGraphQLType = {
  Int: 'Int',
  Float: 'Float',
  String: 'String',
  Boolean: 'Boolean',
  Timestamp: 'Timestamp'
};
exports.EntityFieldValueListType = {
  None: 'None',
  List: 'List',
  ListOrUserEntry: 'ListOrUserEntry'
};
class EntityFieldValueInfo extends baseInfo_1.BaseInfo {
  constructor(initData) {
    super();
    this.ID = null;
    this.EntityFieldID = null; // EntityFieldID is a uniqueidentifier column
    this.Sequence = null;
    this.Value = null;
    this.Code = null;
    this.Description = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this.copyInitData(initData);
  }
}
exports.EntityFieldValueInfo = EntityFieldValueInfo;
exports.GeneratedFormSectionType = {
  Top: 'Top',
  Details: 'Details',
  Category: 'Category'
};
/**
 * Field information within an entity - object models data from the Entity Fields entity in the metadata
 */
class EntityFieldInfo extends baseInfo_1.BaseInfo {
  get EntityFieldValues() {
    return this._EntityFieldValues;
  }
  /**
   * Returns the ValueListType using the EntityFieldValueListType enum.
   */
  get ValueListTypeEnum() {
    if (this.ValueListType == null) return exports.EntityFieldValueListType.None;else {
      // iterate through list of possibilities from enum and compare lcase
      for (let enumMember in exports.EntityFieldValueListType) {
        if (typeof exports.EntityFieldValueListType[enumMember] === 'string' && enumMember.toLowerCase().trim() === this.ValueListType.toLowerCase().trim()) {
          return exports.EntityFieldValueListType[enumMember];
        }
      }
    }
  }
  get GeneratedFormSectionType() {
    return exports.GeneratedFormSectionType[this.GeneratedFormSection];
  }
  /**
   * Provides the TypeScript type for a given Entity Field. This is useful to map
   * a wide array of database types to a narrower set of TypeScript types.
   */
  get TSType() {
    switch ((0, util_1.TypeScriptTypeFromSQLType)(this.Type).toLowerCase()) {
      case "number":
        return exports.EntityFieldTSType.Number;
      case "boolean":
        return exports.EntityFieldTSType.Boolean;
      case "date":
        return exports.EntityFieldTSType.Date;
      default:
        return exports.EntityFieldTSType.String;
    }
  }
  get IsBinaryFieldType() {
    switch (this.Type.trim().toLowerCase()) {
      case 'binary':
      case 'varbinary':
      case 'image':
        return true;
      default:
        return false;
    }
  }
  /**
   * Returns true if the field type requires quotes around the value when used in a SQL statement
   */
  get NeedsQuotes() {
    switch (this.TSType) {
      case exports.EntityFieldTSType.Number:
      case exports.EntityFieldTSType.Boolean:
        return false;
      default:
        return true;
    }
  }
  get CodeName() {
    if (this._codeName === null) {
      this._codeName = (0, util_1.CodeNameFromString)(this.Name);
    }
    return this._codeName;
  }
  get GraphQLType() {
    switch ((0, util_1.TypeScriptTypeFromSQLType)(this.Type).toLowerCase()) {
      case "number":
        // either an int or float if not an int
        switch (this.Type.toLowerCase().trim()) {
          case "int":
          case "smallint":
          case "tinyint":
          case "bigint":
            return exports.EntityFieldGraphQLType.Int;
          default:
            return exports.EntityFieldGraphQLType.Float;
        }
      case "boolean":
        return exports.EntityFieldGraphQLType.Boolean;
      case "date":
        return exports.EntityFieldGraphQLType.Timestamp;
      default:
        return exports.EntityFieldGraphQLType.String;
    }
  }
  /**
   * Returns a string with the full SQL data type that combines, as appropriate, Type, Length, Precision and Scale where these attributes are relevant to the Type
   */
  get SQLFullType() {
    return (0, util_1.SQLFullType)(this.Type, this.Length, this.Precision, this.Scale);
  }
  get MaxLength() {
    return (0, util_1.SQLMaxLength)(this.Type, this.Length);
  }
  get ReadOnly() {
    return this.IsVirtual || !this.AllowUpdateAPI || this.IsPrimaryKey || this.IsSpecialDateField;
  }
  /**
   * Helper method that returns true if the field is one of the special reserved MJ date fields for tracking CreatedAt and UpdatedAt timestamps as well as the DeletedAt timestamp used for entities that
   * have DeleteType=Soft. This is only used when the entity has TrackRecordChanges=1 or for entities where DeleteType=Soft
   */
  get IsSpecialDateField() {
    return this.IsCreatedAtField || this.IsUpdatedAtField || this.IsDeletedAtField;
  }
  /**
   * Returns true if the field is the CreatedAt field, a special field that is used to track the creation date of a record. This is only used when the entity has TrackRecordChanges=1
   */
  get IsCreatedAtField() {
    return this.Name.trim().toLowerCase() === EntityInfo.CreatedAtFieldName.trim().toLowerCase();
  }
  /**
   * Returns true if the field is the UpdatedAt field, a special field that is used to track the last update date of a record. This is only used when the entity has TrackRecordChanges=1
   */
  get IsUpdatedAtField() {
    return this.Name.trim().toLowerCase() === EntityInfo.UpdatedAtFieldName.trim().toLowerCase();
  }
  /**
   * Returns true if the field is the DeletedAt field, a special field that is used to track the deletion date of a record. This is only used when the entity has DeleteType=Soft
   */
  get IsDeletedAtField() {
    return this.Name.trim().toLowerCase() === EntityInfo.DeletedAtFieldName.trim().toLowerCase();
  }
  /**
   * Returns true if the field is a "special" field (see list below) and is handled inside the DB layer and should be ignored in validation by the BaseEntity architecture
   * Also, we skip validation if we have a field that is:
   *  - the primary key
   *  - an autoincrement field
   *  - the field is virtual
   *  - the field is readonly
   *  - the field is a special date field
   */
  get SkipValidation() {
    const name = this.Name.toLowerCase().trim();
    return this.IsSpecialDateField || this.IsPrimaryKey || this.AutoIncrement === true || this.IsVirtual === true || this.ReadOnly === true;
  }
  /**
   * Returns the DisplayName if it exists, otherwise returns the Name.
   */
  get DisplayNameOrName() {
    return this.DisplayName ? this.DisplayName : this.Name;
  }
  /**
   * Formats a value based on the parameters passed in. This is a wrapper utility method that already know the SQL type from the entity field definition and simply calls the generic FormatValue() function that is also exported by @memberjunction/core
   * @param value - Value to format
   * @param decimals Number of decimals to show, defaults to 2
   * @param currency Currency to use when formatting, defaults to USD
   * @param maxLength Maximum length of the string to return, if the formatted value is longer than this length then the string will be truncated and the trailingChars will be appended to the end of the string
   * @param trailingChars Only used if maxLength is > 0 and the string being formatted is > maxLength, this is the string that will be appended to the end of the string to indicate that it was truncated, defaults to "..."
   * @returns either the original string value or a formatted version. If the format cannot be applied an an exception occurs it is captured and the error is put to the log, and the original value is returned
   */
  FormatValue(value, decimals = 2, currency = 'USD', maxLength = 0, trailingChars = "...") {
    return (0, util_1.FormatValue)(this.Type, value, decimals, currency, maxLength, trailingChars);
  }
  constructor(initData = null) {
    super();
    this.ID = null;
    /**
     * Foreign key to the Entities entity.
     */
    this.EntityID = null;
    /**
     * The sequence of the field within the entity, typically the intended display order
     */
    this.Sequence = null;
    this.Name = null;
    /**
     * Optional property that provides the display name for the field, if null, use the Name property.
     * The DisplayNameOrName() method is a helper function that does this for you with a single method call.
     */
    this.DisplayName = null;
    this.Description = null;
    /**
     * If true, the field is the primary key for the entity. There must be one primary key field per entity.
     */
    this.IsPrimaryKey = null;
    /**
     * If true, the field is a unique key for the entity. There can be zero to many unique key fields per entity.
     */
    this.IsUnique = null;
    this.Category = null;
    this.Type = null;
    this.Length = null;
    this.Precision = null;
    this.Scale = null;
    this.AllowsNull = null;
    this.DefaultValue = null;
    this.AutoIncrement = null;
    this.ValueListType = null;
    this.ExtendedType = null;
    this.DefaultInView = null;
    this.ViewCellTemplate = null;
    this.DefaultColumnWidth = null;
    this.AllowUpdateAPI = null;
    this.AllowUpdateInView = null;
    this.IncludeInUserSearchAPI = null;
    this.FullTextSearchEnabled = false;
    this.UserSearchParamFormatAPI = null;
    this.IncludeInGeneratedForm = null;
    this.GeneratedFormSection = null;
    this.IsVirtual = null;
    this.IsNameField = null;
    this.RelatedEntityID = null;
    this.RelatedEntityFieldName = null;
    this.IncludeRelatedEntityNameFieldInBaseView = null;
    this.RelatedEntityNameFieldMap = null;
    this.RelatedEntityDisplayType = null;
    this.EntityIDFieldName = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    this.Entity = null;
    this.SchemaName = null;
    this.BaseTable = null;
    this.BaseView = null;
    this.EntityCodeName = null;
    this.EntityClassName = null;
    this.RelatedEntity = null;
    this.RelatedEntitySchemaName = null;
    this.RelatedEntityBaseTable = null;
    this.RelatedEntityBaseView = null;
    this.RelatedEntityCodeName = null;
    this.RelatedEntityClassName = null;
    /**
     * For fields in the database that have characters invalid for SQL identifiers in them, we need to replace those characters with _ in order to create variables for stored procedures.
     * This property returns a consistent CodeName you can use everywhere to refer to the field when generated variable names
     */
    this._codeName = null;
    if (initData) {
      this.copyInitData(initData);
      // do some special handling to create class instances instead of just data objects
      // copy the Entity Field Values
      this._EntityFieldValues = [];
      const efv = initData.EntityFieldValues || initData._EntityFieldValues;
      if (efv) {
        for (let j = 0; j < efv.length; j++) {
          this._EntityFieldValues.push(new EntityFieldValueInfo(efv[j]));
        }
      }
    }
  }
}
exports.EntityFieldInfo = EntityFieldInfo;
/**
 * Entity Document Type Info object has information about the document types that exist across all entities. When Entity Documents are created they are associated with a document type.
 */
class EntityDocumentTypeInfo extends baseInfo_1.BaseInfo {
  constructor(initData = null) {
    super();
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this.copyInitData(initData);
  }
}
exports.EntityDocumentTypeInfo = EntityDocumentTypeInfo;
/**
 * Settings allow you to store key/value pairs of information that can be used to configure the behavior of the entity.
 */
class EntitySettingInfo extends baseInfo_1.BaseInfo {
  constructor(initData = null) {
    super();
    this.ID = null;
    this.EntityID = null;
    this.Name = null;
    this.Value = null;
    this.Comments = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this.copyInitData(initData);
  }
}
exports.EntitySettingInfo = EntitySettingInfo;
/**
 * Metadata about an entity
 */
class EntityInfo extends baseInfo_1.BaseInfo {
  /**
   * Returns the primary key field for the entity. For entities with a composite primary key, use the PrimaryKeys property which returns all.
   * In the case of a composite primary key, the PrimaryKey property will return the first field in the sequence of the primary key fields.
   */
  get FirstPrimaryKey() {
    return this.Fields.find(f => f.IsPrimaryKey);
  }
  /**
   * Returns an array of all fields that are part of the primary key for the entity. If the entity has a single primary key, the array will have a single element.
   */
  get PrimaryKeys() {
    return this.Fields.filter(f => f.IsPrimaryKey);
  }
  get UniqueKeys() {
    return this.Fields.filter(f => f.IsUnique);
  }
  get ForeignKeys() {
    return this.Fields.filter(f => f.RelatedEntityID && f.RelatedEntityID.length > 0);
  }
  get Fields() {
    return this._Fields;
  }
  get RelatedEntities() {
    return this._RelatedEntities;
  }
  get Permissions() {
    return this._Permissions;
  }
  get Settings() {
    return this._Settings;
  }
  /**
   * Returns the name of the special reserved field that is used to store the CreatedAt timestamp across all of MJ. This is only used when an entity has TrackRecordChanges turned on
   */
  static get CreatedAtFieldName() {
    return EntityInfo.__createdAtFieldName;
  }
  /**
   * Returns the name of the special reserved field that is used to store the UpdatedAt timestamp across all of MJ. This is only used when an entity has TrackRecordChanges turned on
   */
  static get UpdatedAtFieldName() {
    return EntityInfo.__updatedAtFieldName;
  }
  /**
   * Returns the name of the special reserved field that is used to store the DeletedAt timestamp across all of MJ. This is only used when an entity has DeleteType=Soft
   */
  static get DeletedAtFieldName() {
    return EntityInfo.__deletedAtFieldName;
  }
  /**
   * @returns The BaseTable but with spaces inbetween capital letters
   * */
  get DisplayName() {
    return this.BaseTable.replace(/([A-Z])/g, ' $1').trim();
  }
  /**
   * Returns the EntityField object for the Field that has IsNameField set to true. If multiple fields have IsNameField on, the function will return the first field (by sequence) that matches.
   * If no fields match, if there is a field called "Name", that is returned. If there is no field called "Name", null is returned.
   */
  get NameField() {
    for (let j = 0; j < this.Fields.length; j++) {
      const ef = this.Fields[j];
      if (ef.IsNameField) return ef;
    }
    // at this point, we return the first field called "Name" if it exists, and the below line will return NULL if we can't find a field called "Name"
    return this.Fields.find(f => f.Name.toLowerCase() === 'name');
  }
  /**
   * Returns the Permissions for this entity for a given user, based on the roles the user is part of
   * @param user
   * @returns
   */
  GetUserPermisions(user) {
    try {
      const permissionList = [];
      for (let j = 0; j < this.Permissions.length; j++) {
        const ep = this.Permissions[j];
        const roleMatch = user.UserRoles.find(r => r.RoleID === ep.RoleID);
        if (roleMatch)
          // user has this role
          permissionList.push(ep);
      }
      // now that we have matched any number of EntityPermissions to the current user, aggregate the permissions
      const userPermission = new EntityUserPermissionInfo();
      userPermission.CanCreate = false;
      userPermission.CanDelete = false;
      userPermission.CanRead = false;
      userPermission.CanUpdate = false;
      for (let j = 0; j < permissionList.length; j++) {
        const ep = permissionList[j];
        userPermission.CanCreate = userPermission.CanCreate || ep.CanCreate;
        userPermission.CanRead = userPermission.CanRead || ep.CanRead;
        userPermission.CanUpdate = userPermission.CanUpdate || ep.CanUpdate;
        userPermission.CanDelete = userPermission.CanDelete || ep.CanDelete;
      }
      userPermission.Entity = this;
      userPermission.User = user;
      return userPermission;
    } catch (err) {
      console.log(err);
      return null;
    }
  }
  /**
   * Determines if a given user, for a given permission type, is exempt from RowLevelSecurity or not
   * @param user
   * @param type
   * @returns
   */
  UserExemptFromRowLevelSecurity(user, type) {
    for (let j = 0; j < this.Permissions.length; j++) {
      const ep = this.Permissions[j];
      const roleMatch = user.UserRoles.find(r => r.RoleID === ep.RoleID);
      if (roleMatch) {
        // user has this role 
        switch (type) {
          case exports.EntityPermissionType.Create:
            if (!ep.CreateRLSFilterID) return true;
            break;
          case exports.EntityPermissionType.Read:
            if (!ep.ReadRLSFilterID) return true;
            break;
          case exports.EntityPermissionType.Update:
            if (!ep.UpdateRLSFilterID) return true;
            break;
          case exports.EntityPermissionType.Delete:
            if (!ep.DeleteRLSFilterID) return true;
            break;
        }
      }
    }
    return false; // if we get here, the user is NOT exempt from RLS for this Permission Type
  }
  /**
   * Returns RLS security info attributes for a given user and permission type
   * @param user
   * @param type
   * @returns
   */
  GetUserRowLevelSecurityInfo(user, type) {
    const rlsList = [];
    for (let j = 0; j < this.Permissions.length; j++) {
      const ep = this.Permissions[j];
      const roleMatch = user.UserRoles.find(r => r.RoleID === ep.RoleID);
      if (roleMatch) {
        // user has this role
        let matchObject = null;
        switch (type) {
          case exports.EntityPermissionType.Create:
            if (ep.CreateRLSFilterID) matchObject = ep.CreateRLSFilterObject;
            break;
          case exports.EntityPermissionType.Read:
            if (ep.ReadRLSFilterID) matchObject = ep.ReadRLSFilterObject;
            break;
          case exports.EntityPermissionType.Update:
            if (ep.UpdateRLSFilterID) matchObject = ep.UpdateRLSFilterObject;
            break;
          case exports.EntityPermissionType.Delete:
            if (ep.DeleteRLSFilterID) matchObject = ep.DeleteRLSFilterObject;
            break;
        }
        if (matchObject) {
          // we have a match, so add it to the list if it isn't already there
          const existingMatch = rlsList.find(r => r.ID === matchObject.ID);
          if (!existingMatch) rlsList.push(matchObject);
        }
      }
    }
    return rlsList;
  }
  /**
   * Generates a where clause for SQL filtering for a given entity for a given user and permission type. If there is no RLS for a given entity or the user is exempt from RLS for the entity, a blank string is returned.
   * @param user
   * @param type
   * @param returnPrefix
   * @returns
   */
  GetUserRowLevelSecurityWhereClause(user, type, returnPrefix) {
    const userRLS = this.GetUserRowLevelSecurityInfo(user, type);
    if (userRLS && userRLS.length > 0) {
      // userRLS has all of the objects that apply to this user. The user is NOT exempt from RLS, so we need to OR together all of the RLS object filters
      let sRLSSQL = '';
      userRLS.forEach(rls => {
        if (sRLSSQL.length > 0) sRLSSQL += ' OR ';
        sRLSSQL += `(${rls.MarkupFilterText(user)})`;
      });
      return sRLSSQL.length > 0 ? `${returnPrefix && returnPrefix.length > 0 ? returnPrefix + ' ' : ''}${sRLSSQL}` : '';
    } else return '';
  }
  /**
   * Returns a RunViewParams object that is setup to filter the related entity for the provided record
   * @param record
   * @param relationship
   * @param filter
   * @returns
   */
  static BuildRelationshipViewParams(record, relationship, filter, maxRecords) {
    const params = {};
    let quotes = '';
    let keyValue = '';
    if (relationship.EntityKeyField && relationship.EntityKeyField.length > 0) {
      keyValue = record.Get(relationship.EntityKeyField);
      quotes = record.EntityInfo.Fields.find(f => f.Name.trim().toLowerCase() === relationship.EntityKeyField.trim().toLowerCase()).NeedsQuotes ? "'" : '';
    } else {
      // currently we only support a single value for FOREIGN KEYS, so we can just grab the first value in the primary key
      const firstKey = record.FirstPrimaryKey;
      keyValue = firstKey.Value;
      //When creating a new record, the keyValue is null and the quotes are not needed
      quotes = keyValue && firstKey.NeedsQuotes ? "'" : '';
    }
    if (relationship.Type.trim().toLowerCase() === 'one to many') {
      // one to many
      params.ExtraFilter = `[${relationship.RelatedEntityJoinField}] = ${quotes}${keyValue}${quotes}`;
    } else {
      // many to many
      params.ExtraFilter = `[${relationship.RelatedEntityJoinField}] IN (SELECT [${relationship.JoinEntityInverseJoinField}] FROM [${relationship.JoinView}] WHERE [${relationship.JoinEntityJoinField}] = ${quotes}${keyValue}${quotes})`;
    }
    if (filter && filter.length > 0) params.ExtraFilter = `(${params.ExtraFilter}) AND (${filter})`; // caller provided their own filter, so AND it in with the relationship filter we have here
    if (relationship.DisplayUserViewID && relationship.DisplayUserViewID.length > 0) {
      // we have been given a specific view to run, use it
      params.ViewID = relationship.DisplayUserViewID;
    } else {
      // no view specified, so specify the entity instead
      params.EntityName = relationship.RelatedEntity;
    }
    if (maxRecords && maxRecords > 0) params.MaxRows = maxRecords;
    return params;
  }
  /**
   * Builds a simple javascript object that will pre-populate a new record in the related entity with values that link back to the specified record.
   * This is useful, for example, when creating a new contact from an account, we want to pre-populate the account ID in the new contact record
   */
  static BuildRelationshipNewRecordValues(record, relationship) {
    // we want to build a simple javascript object that will pre-populate a new record in the related entity with values that link
    // abck to the current record. This is useful for example when creating a new contact from an account, we want to pre-populate the
    // account ID in the new contact record
    const obj = {};
    if (record && relationship) {
      const keyField = relationship.EntityKeyField && relationship.EntityKeyField.trim().length > 0 ? relationship.EntityKeyField : record.FirstPrimaryKey.Name;
      obj[relationship.RelatedEntityJoinField] = record.Get(keyField);
    }
    return obj;
  }
  constructor(initData = null) {
    super();
    this.ID = null;
    /**
     * Reserved for future use
     */
    this.ParentID = null;
    /**
     * Unique name of the entity
     */
    this.Name = null;
    this.Description = null;
    this.BaseTable = null;
    this.BaseView = null;
    this.BaseViewGenerated = null;
    this.SchemaName = null;
    this.VirtualEntity = null;
    this.TrackRecordChanges = null;
    this.AuditRecordAccess = null;
    this.AuditViewRuns = null;
    this.IncludeInAPI = false;
    this.AllowAllRowsAPI = false;
    this.AllowUpdateAPI = false;
    this.AllowCreateAPI = false;
    this.AllowDeleteAPI = false;
    this.CustomResolverAPI = false;
    this.AllowUserSearchAPI = false;
    this.FullTextSearchEnabled = false;
    this.FullTextCatalog = null;
    this.FullTextCatalogGenerated = true;
    this.FullTextIndex = null;
    this.FullTextIndexGenerated = true;
    this.FullTextSearchFunction = null;
    this.FullTextSearchFunctionGenerated = true;
    this.UserViewMaxRows = null;
    this.spCreate = null;
    this.spUpdate = null;
    this.spDelete = null;
    this.spCreateGenerated = null;
    this.spUpdateGenerated = null;
    this.spDeleteGenerated = null;
    this.CascadeDeletes = null;
    this.DeleteType = 'Hard';
    this.AllowRecordMerge = null;
    this.spMatch = null;
    this.RelationshipDefaultDisplayType = null;
    this.UserFormGenerated = null;
    this.EntityObjectSubclassName = null;
    this.EntityObjectSubclassImport = null;
    this.PreferredCommunicationField = null;
    this.Icon = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    /**
     * CodeName is a unique name that can be used for various programatic purposes, singular version of the entity name but modified from entity name in some cases to remove whitespace and prefix with _ in the event that the entity name begins with a number or other non-alpha character
     */
    this.CodeName = null;
    this.ClassName = null;
    this.BaseTableCodeName = null;
    this.ParentEntity = null;
    this.ParentBaseTable = null;
    this.ParentBaseView = null;
    this._hasIdField = false;
    this._virtualCount = 0;
    this._manyToManyCount = 0;
    this._oneToManyCount = 0;
    this._floatCount = 0;
    if (initData) {
      this.copyInitData(initData);
      // do some special handling to create class instances instead of just data objects
      // copy the Entity Fields
      this._Fields = [];
      const ef = initData.EntityFields || initData._Fields;
      if (ef) {
        for (let j = 0; j < ef.length; j++) {
          this._Fields.push(new EntityFieldInfo(ef[j]));
        }
      }
      // copy the Entity Permissions
      this._Permissions = [];
      const ep = initData.EntityPermissions || initData._Permissions;
      if (ep) {
        for (let j = 0; j < ep.length; j++) {
          this._Permissions.push(new EntityPermissionInfo(ep[j]));
        }
      }
      // copy the Entity settings
      this._Settings = [];
      const es = initData.EntitySettings || initData._Settings;
      if (es) {
        es.map(s => this._Settings.push(new EntitySettingInfo(s)));
      }
      // copy the Related Entities
      this._RelatedEntities = [];
      const er = initData.EntityRelationships || initData._RelatedEntities;
      if (er) {
        // check to see if ANY of the records in the er array have a non-null or non-zero sequence value. The reason is 
        // if we have any sequence values populated we want to sort by that sequence, and we want to consider null to be a high number
        // so that it sorts to the end of the list
        let bHasSequence = false;
        for (const j of er) {
          if (j.Sequence !== null && j.Sequence !== undefined && j.Sequence !== 0) {
            bHasSequence = true;
            break;
          }
        }
        if (bHasSequence) {
          // sort by sequence if we have any populated sequence values
          er.sort((a, b) => {
            const aSeq = a.Sequence !== null && a.Sequence !== undefined ? a.Sequence : 999999;
            const bSeq = b.Sequence !== null && b.Sequence !== undefined ? b.Sequence : 999999;
            return aSeq - bSeq;
          });
        }
        // now that we have prepared the er array by sorting it, if needed, let's load up the related entities
        for (let j = 0; j < er.length; j++) {
          this._RelatedEntities.push(new EntityRelationshipInfo(er[j]));
        }
      }
      this.prepareSpecialFields();
    }
  }
  prepareSpecialFields() {
    try {
      let virtualCount = 0;
      let manyToManyCount = 0;
      let oneToManyCount = 0;
      let floatCount = 0;
      let hasIdField = false;
      for (let j = 0; j < this.Fields.length; ++j) {
        const f = this.Fields[j];
        if (f.Name.trim().toUpperCase() === 'ID') hasIdField = true;
        virtualCount += f.IsVirtual ? 1 : 0;
        floatCount += f.IsFloat ? 1 : 0;
      }
      this._hasIdField = hasIdField;
      this._floatCount = floatCount;
      this._virtualCount = virtualCount;
      // now see if there are any relationships and count the one to many and many to many
      for (let j = 0; j < this.RelatedEntities.length; ++j) {
        const r = this.RelatedEntities[j];
        if (r.Type.trim().toUpperCase() === 'ONE TO MANY') oneToManyCount++;else manyToManyCount++;
      }
      this._manyToManyCount = manyToManyCount;
      this._oneToManyCount = oneToManyCount;
    } catch (e) {
      (0, logging_1.LogError)(e);
    }
  }
}
exports.EntityInfo = EntityInfo;
EntityInfo.__createdAtFieldName = '__mj_CreatedAt';
EntityInfo.__updatedAtFieldName = '__mj_UpdatedAt';
EntityInfo.__deletedAtFieldName = '__mj_DeletedAt';
exports.ValidationErrorType = {
  Failure: 'Failure',
  Warning: 'Warning'
};
/**
 * Information about a single validation error
 */
class ValidationErrorInfo {
  constructor(Source, Message, Value, Type = exports.ValidationErrorType.Failure) {
    this.Source = Source;
    this.Message = Message;
    this.Value = Value;
    this.Type = Type;
  }
}
exports.ValidationErrorInfo = ValidationErrorInfo;
/**
 * The result of a validation check
 */
class ValidationResult {
  constructor() {
    this.Errors = [];
  }
}
exports.ValidationResult = ValidationResult;
/**
 * Information about the link between two entities
 */
class EntityDependency {}
exports.EntityDependency = EntityDependency;
/**
 * Information about the link between two records
 */
class RecordDependency {}
exports.RecordDependency = RecordDependency;
/**
 * Information about a merge request including the entity, the surviving record and the records to merge into the surviving record. Additionally, there is an optional field map that can be used to override field values in the surviving record to values specified.
 */
class RecordMergeRequest {}
exports.RecordMergeRequest = RecordMergeRequest;
/**
 * The result of a merge request for a single record
 */
class RecordMergeDetailResult {}
exports.RecordMergeDetailResult = RecordMergeDetailResult;
/**
 * The result of a merge request
 */
class RecordMergeResult {}
exports.RecordMergeResult = RecordMergeResult;
