import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { DrawerItem, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { homeIcon, SVGIcon, bellIcon, calendarIcon, envelopeLinkIcon, inboxIcon, menuIcon, starOutlineIcon, gearIcon, graphIcon, userIcon, logoutIcon, searchIcon, starIcon, importIcon } from '@progress/kendo-svg-icons';
import { Router } from "@angular/router";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  constructor(private router: Router) {};

  public selected = 'Inbox';
  public menuSvg: SVGIcon = menuIcon;
  public currentPath: string;

  public items: Array<{ [Key: string]: unknown }> = [
      { text: 'Dashboard', svgIcon: homeIcon, selected: true, path: '/' },
      { text: 'Employees', svgIcon: userIcon },
      { text: 'Reports', svgIcon: graphIcon, path: '/benchmark' },
      { text: 'Financials', svgIcon: starIcon },
      { text: 'Integrations', svgIcon: importIcon },
      { text: 'Explore', svgIcon: searchIcon },
      { separator: true },
      { text: 'Settings', svgIcon: gearIcon },
      { text: 'Log Out', svgIcon: logoutIcon },
  ];

  public onSelect(ev: DrawerSelectEvent): void {
    this.selected = ev.item.text;
  }

  ngOnInit(): void {
    this.currentPath = this.router.url;
    console.log(this.currentPath);

    // loop through the items array and set the selected item based on the current path
    this.items.forEach((item) => {
      if (item['path'] === this.currentPath) {
        item['selected'] = true;
      }
      else {
        item['selected'] = false;
      }
    });
  }
}