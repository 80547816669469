"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemplateExtensionBase = void 0;
/**
 * Used for extending the functionality of the template engine (Nunjucks). Sub-class this class to create a new template extension.
 */
class TemplateExtensionBase {
  get ContextUser() {
    return this._contextUser;
  }
  constructor(contextUser) {
    /**
     * One or more tags that represent case-sensitive tag names that will invoke this extension.
     */
    this.tags = [];
    this._contextUser = contextUser;
  }
}
exports.TemplateExtensionBase = TemplateExtensionBase;
