"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemplateEngineBase = void 0;
const core_1 = require("@memberjunction/core");
/**
 * TemplateEngine is used for accessing template metadata/caching it, and rendering templates
 */
class TemplateEngineBase extends core_1.BaseEngine {
  /**
   * Returns the global instance of the class. This is a singleton class, so there is only one instance of it in the application. Do not directly create new instances of it, always use this method to get the instance.
   */
  static get Instance() {
    return super.getInstance();
  }
  async Config(forceRefresh, contextUser) {
    const c = [{
      EntityName: 'Template Content Types',
      PropertyName: '_TemplateContentTypes'
    }, {
      EntityName: 'Template Categories',
      PropertyName: '_TemplateCategories'
    }, {
      EntityName: 'Templates',
      PropertyName: '_Templates'
    }, {
      EntityName: 'Template Contents',
      PropertyName: '_TemplateContents'
    }, {
      EntityName: 'Template Params',
      PropertyName: '_TemplateParams'
    }];
    await this.Load(c, forceRefresh, contextUser);
  }
  async AdditionalLoading(contextUser) {
    // post-process the template content and params to associate them with a template
    this.Templates.forEach(t => {
      t.Content = this.TemplateContents.filter(tc => tc.TemplateID === t.ID);
      t.Params = this.TemplateParams.filter(tp => tp.TemplateID === t.ID);
    });
  }
  get Templates() {
    return this._Templates;
  }
  get TemplateContentTypes() {
    return this._TemplateContentTypes;
  }
  get TemplateCategories() {
    return this._TemplateCategories;
  }
  get TemplateContents() {
    return this._TemplateContents;
  }
  get TemplateParams() {
    return this._TemplateParams;
  }
  /**
   * Convenience method to find a template by name, case-insensitive
   * @param templateName
   * @returns
   */
  FindTemplate(templateName) {
    return this.Templates.find(t => t.Name.trim().toLowerCase() === templateName.trim().toLowerCase());
  }
}
exports.TemplateEngineBase = TemplateEngineBase;
