import { Component, OnInit } from '@angular/core';
import { MJEventType, MJGlobal } from '@memberjunction/global';
import { SharedData } from '../shared-data';
import { SharedService } from '../shared-service';
import { RunView, Metadata } from '@memberjunction/core';
import { ReportRequestEntity } from 'mj_generatedentities';
import { Router } from '@angular/router';


@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.css']
})
export class NewReportComponent implements OnInit {
  loaded: boolean = false;

  // Form Bindings
  selectedRoles = [];
  selectedMetroSize: any;
  selectedOrgSize: any;
  selectedOrgType: any;
  selectedOrganizations = {};
  selectedIndustries = [];
  selectedRevenueSize = '';
  accounts = [];
  reportName = '';
  maxNumberOfPeers: any;

  newReportRequest: any;

  // Max number of peers to compare against
  peers = [{Value: 10}, {Value: 20}, {Value:50}, {Value:100}]

  // Report sections.  Should be moved to DB. NOTIMPLEMENTED
  sections = [
    { label: 'Salary Benchmarking', selected: false },
    { label: 'Compensation Distribution', selected: false },
    { label: 'Average Pay Composition', selected: false },
    { label: 'Revenue Trends', selected: false }
  ];

  // Position Types.  Can get from sharedData.ContactLevels. NOTIMPLEMENTED
  positionTypes = [
    { label: 'Individual', selected: false },
    { label: 'Manager', selected: false },
    { label: 'Director', selected: false },
    { label: 'VP', selected: false },
    { label: 'CxO', selected: false }
  ];

  // Time slider.  Should be moved to DB. NOTIMPLEMENTED
  timeRange = [ 2015, 2024 ];

  // For organization selection.  Need to update sharedData to not query always
  public onFilterChange(filter: any): void {
    if (filter) {
      this.accounts = this.sharedData.Accounts.filter(account => account.Name.toLowerCase().includes(filter.toLowerCase())).slice(0, 20);
    } else {
      this.accounts = this.sharedData.Accounts.slice(0, 20)
    }
  }

  fetchAccountById(id: number) {
    return this.sharedData.Accounts.find(account => account.ID === id)
  }

  public async onSourceAccountChange(event) {
    // TODO: autofill more fields
    if (event) {
      const thisAccount = this.fetchAccountById(event)

      this.accounts.push(thisAccount) // Need this for "Back" navigation to work with slicing

      if (thisAccount.IndustryID) {
        this.selectedIndustries = [{ID : thisAccount.IndustryID, Name: thisAccount.Industry}]
      }

      // restrict roles to those that exist in the account
      // const contactRoles = await this.getContacts(event.ID)
      // this.roles = this.sharedData.ContactRoles.filter(role => contactRoles.has(role.ID));
      // // default to CEO, if exists
      // if (this.roles.some(role => role.Name.trim().toLowerCase() === 'executive director/ceo')) {
      //   this.entity.TargetRoles = [this.roles.find(role => role.Name.trim().toLowerCase() === 'executive director/ceo')]
      // } else {
      //   this.entity.TargetRoles = [this.roles[0]] 
      // }

    }
  }

  constructor(private router: Router, public sharedData: SharedData, private sharedService: SharedService) { }

  // Tracks the current step of the wizard
  currentStep = 1;
  nextStep() {
    if (this.currentStep < 3) {
      this.currentStep++;
    }
  }
  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  async ngOnInit(): Promise<void> {
    MJGlobal.Instance.GetEventListener(true).subscribe(async (e) => {
      if (e.event === MJEventType.LoggedIn) {
        this.sharedService.setupComplete$.subscribe(async (isComplete) => {
          if (isComplete) {
            this.accounts = this.sharedData.Accounts.slice(0, 20) // to keep combobox loading reasonable


            const md = new Metadata()
            this.newReportRequest = await md.GetEntityObject<ReportRequestEntity>('Report Requests');
            this.newReportRequest.NewRecord();

            // default some values
            this.maxNumberOfPeers = this.peers[2]
            this.selectedRoles = [this.sharedData.ContactRoles[3]]
            this.selectedMetroSize = this.sharedData.MetroAreaSizes[1]
            this.selectedIndustries = [this.sharedData.Industries[9]]
            this.selectedOrgSize = this.sharedData.OrgRevenueSizes[1]
            this.selectedOrgType = this.sharedData.OrgEmployeeSizes[2]
            this.newReportRequest.SourceAccountID = 7676
            this.newReportRequest.Name = `Compensation Benchmarking Report ${new Date().toLocaleDateString()}`

            this.loaded = true;
          }
      });
    }});
  }

  backToReports() {
    // Logic for going back to the reports list
  }

  async generateReport() {
    if (!this.newReportRequest.Name) {
      this.newReportRequest.Name = ''
    }
    this.newReportRequest.TargetPositions = JSON.stringify(this.selectedRoles) 
    this.newReportRequest.TargetMinMetroSize = this.selectedMetroSize.MinVal
    this.newReportRequest.TargetMaxMetroSize = this.selectedMetroSize.MaxVal
    this.newReportRequest.TargetIndustryID = this.selectedIndustries[0].ID // Do we need to handle multiple industries?
    this.newReportRequest.TargetMinRevenue = this.selectedOrgSize.MinVal
    this.newReportRequest.TargetMaxRevenue = this.selectedOrgSize.MaxVal
    this.newReportRequest.TargetMinNumberOfEmployees = this.selectedOrgType.MinVal
    this.newReportRequest.TargetMaxNumberOfEmployees = this.selectedOrgType.MaxVal
    this.newReportRequest.Status = 'Pending'
    this.newReportRequest.RequestingUserID = 9
    await this.newReportRequest.Save()
    this.router.navigate(['/benchmark']);
  }
}
