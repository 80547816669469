"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpdateCurrentConsoleProgress = exports.UpdateCurrentConsoleLine = exports.getAnsiColorCode = exports.ConsoleColor = exports.SeverityType = exports.FormatFileMessage = exports.FormatConsoleMessage = exports.SetProductionStatus = exports.GetProductionStatus = exports.LogStatus = exports.LogError = void 0;
let fs;
if (runningOnNode()) {
  try {
    fs = eval("require('fs')"); // wrap the require in eval to avoid bundling attempt and also avoid runtime scanning issues in the browser.
  } catch (err) {
    // shouldn't get here since we're checking for the node variables above, but have this 
    // try/catch block just in case. 
    // fs module is not available, normal in browser situation, we can't log to "Files" as that doesn't make sense in browser
  }
}
function LogError(message, logToFileName = null, ...args) {
  if (logToFileName !== null && logToFileName !== undefined && logToFileName.length >= 0) logToFile(message, true, logToFileName, ...args);else logToConsole(message, true, ...args);
}
exports.LogError = LogError;
function LogStatus(message, logToFileName = null, ...args) {
  if (logToFileName !== null && logToFileName !== undefined && logToFileName.length >= 0) logToFile(message, false, logToFileName, ...args);else logToConsole(message, false, ...args);
}
exports.LogStatus = LogStatus;
function logToConsole(message, isError, ...args) {
  if (isError)
    // always do console.error() for errors even in production
    console.error(message, ...args);else if (!GetProductionStatus())
    // only do console.log() if we're not in production
    console.log(message, ...args);
}
function logToFile(message, isError, logToFileName, ...args) {
  if (fs === null || fs === undefined) {
    console.error('Attempting to log to file, but fs module is not available, logging to console instead');
    logToConsole(message, isError, ...args);
  } else fs.appendFileSync(logToFileName, `${isError ? 'ERROR' : 'STATUS'} (${new Date()}: ${message}${args && args.length > 0 && args.join('').length > 0 ? '\n   ARGS' + args.join('\n   ') : ''}` + '\n');
}
let _productionStatus = null;
function GetProductionStatus() {
  if (_productionStatus) return _productionStatus;else {
    if (runningOnNode()) {
      return process.env.NODE_ENV === 'production';
    } else if (runningInBrowser()) {
      // no status set if we get here, so return false, default to false to be safe and log stuff
      // for debug scenarios on staging environments/etc.
      return false;
    }
  }
}
exports.GetProductionStatus = GetProductionStatus;
function SetProductionStatus(isProduction) {
  _productionStatus = isProduction;
}
exports.SetProductionStatus = SetProductionStatus;
function runningInBrowser() {
  return typeof window !== 'undefined' && window.location !== null;
}
function runningOnNode() {
  return typeof process !== 'undefined' && process.versions !== null && process.versions.node !== null;
}
function FormatConsoleMessage(message, serverity) {
  switch (serverity) {
    case 'Trace':
    case 'Debug':
    case 'Info':
      return FormatConsoleMessageInternal(exports.ConsoleColor.white, message);
    case 'Warning':
      return FormatConsoleMessageInternal(exports.ConsoleColor.yellow, message);
    case 'Critical':
      return FormatConsoleMessageInternal(exports.ConsoleColor.red, message);
    default:
      return message;
  }
}
exports.FormatConsoleMessage = FormatConsoleMessage;
function FormatConsoleMessageInternal(color, message) {
  return `\r\x1b[${getAnsiColorCode(exports.ConsoleColor.white)}m${message}\x1b[0m`;
}
function FormatFileMessage(message, serverity) {
  switch (serverity) {
    case 'Trace':
      return `[Trace] ${message}`;
    case 'Debug':
      return `[Debug] ${message}`;
    case 'Info':
      return `[Info] ${message}`;
    case 'Warning':
      return `[Warning] ${message}`;
    case 'Critical':
      return `[Critical] ${message}`;
    default:
      return message;
  }
}
exports.FormatFileMessage = FormatFileMessage;
exports.SeverityType = {
  Trace: 'Trace',
  Debug: 'Debug',
  Info: 'Info',
  Warning: 'Warning',
  Critical: 'Critical'
};
/**
 * Enum of console colors that can be used to update the console line color.
 */
exports.ConsoleColor = {
  black: 'black',
  red: 'red',
  green: 'green',
  yellow: 'yellow',
  blue: 'blue',
  magenta: 'magenta',
  cyan: 'cyan',
  white: 'white',
  gray: 'gray',
  crimson: 'crimson'
};
/**
 * Helper function to get the ANSI color code for the given console color.
 * @param color
 */
function getAnsiColorCode(color) {
  switch (color) {
    case exports.ConsoleColor.black:
      return 30;
    case exports.ConsoleColor.red:
      return 31;
    case exports.ConsoleColor.green:
      return 32;
    case exports.ConsoleColor.yellow:
      return 33;
    case exports.ConsoleColor.blue:
      return 34;
    case exports.ConsoleColor.magenta:
      return 35;
    case exports.ConsoleColor.cyan:
      return 36;
    case exports.ConsoleColor.white:
      return 37;
    case exports.ConsoleColor.gray:
      return 90;
    case exports.ConsoleColor.crimson:
      return 38;
    default:
      return 37;
  }
}
exports.getAnsiColorCode = getAnsiColorCode;
/**
 * Utility function that udpates the current console line with the provided message and color.
 * @param message
 * @param color
 */
function UpdateCurrentConsoleLine(message, color = exports.ConsoleColor.white) {
  if (runningOnNode()) {
    // Running in Node.js environment
    //console.log(`\r\x1b[${getAnsiColorCode(color)}m${message}\x1b[0m`);
    //process.stdout.write(`\r\x1b[${getAnsiColorCode(color)}m${message}\x1b[0m`);
    //        console.log(`\x1b[2K\r\x1b[${getAnsiColorCode(color)}m${message}\x1b[0m`);
    console.log(`\x1b[${getAnsiColorCode(color)}m${message}\x1b[0m`);
  } else {
    // Running in browser environment
    console.log(`\r%c${message}`, `color: ${color}`);
  }
}
exports.UpdateCurrentConsoleLine = UpdateCurrentConsoleLine;
function UpdateCurrentConsoleProgress(message, current, total, color = exports.ConsoleColor.white) {
  // show the message, current count of total count, and % complete formated as 0.0%
  UpdateCurrentConsoleLine(`${message} ${current} of ${total} (${(current / total * 100).toFixed(1)}%)`, color);
}
exports.UpdateCurrentConsoleProgress = UpdateCurrentConsoleProgress;
