"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClassifyResult = exports.ClassifyTag = exports.ClassifyParams = void 0;
const baseModel_1 = require("./baseModel");
const chat_types_1 = require("./chat.types");
class ClassifyParams extends chat_types_1.ChatParams {}
exports.ClassifyParams = ClassifyParams;
class ClassifyTag {
  constructor(tag, confidence) {
    this.tag = tag;
    this.confidence = confidence;
  }
}
exports.ClassifyTag = ClassifyTag;
class ClassifyResult extends baseModel_1.BaseResult {}
exports.ClassifyResult = ClassifyResult;
