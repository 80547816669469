"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var AIAPIKeys_1;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetAIAPIKey = exports.AIAPIKeys = void 0;
const global_1 = require("@memberjunction/global");
let AIAPIKeys = AIAPIKeys_1 = class AIAPIKeys {
  GetCachedAPIKey(AIDriverName) {
    const normalizedKey = AIDriverName.toUpperCase();
    return AIAPIKeys_1._cachedAPIKeys[normalizedKey];
  }
  SetCachedAPIKey(AIDriverName, value) {
    const normalizedKey = AIDriverName.toUpperCase();
    AIAPIKeys_1._cachedAPIKeys[normalizedKey] = value;
  }
  GetAPIKey(AIDriverName) {
    const normalizedKey = AIDriverName.toUpperCase();
    const cached = this.GetCachedAPIKey(normalizedKey);
    if (cached) {
      return cached;
    } else {
      const envKey = AIAPIKeys_1._apiKeyPrefix + normalizedKey;
      const value = this.getEnvVariableCaseInsensitive(envKey);
      if (value) {
        this.SetCachedAPIKey(normalizedKey, value);
        return value;
      } else return undefined;
    }
  }
  getEnvVariableCaseInsensitive(name) {
    const upperName = name.toUpperCase();
    const envKey = Object.keys(process.env).find(key => key.toUpperCase() === upperName);
    return envKey ? process.env[envKey] : undefined;
  }
};
exports.AIAPIKeys = AIAPIKeys;
AIAPIKeys._apiKeyPrefix = 'AI_VENDOR_API_KEY__';
AIAPIKeys._cachedAPIKeys = {};
exports.AIAPIKeys = AIAPIKeys = AIAPIKeys_1 = __decorate([(0, global_1.RegisterClass)(AIAPIKeys, null, 1)], AIAPIKeys);
function GetAIAPIKey(AIDriverName) {
  const obj = global_1.MJGlobal.Instance.ClassFactory.CreateInstance(AIAPIKeys);
  if (obj) return obj.GetAPIKey(AIDriverName);else throw new Error('Could not instantiate AIAPIKeys class');
}
exports.GetAIAPIKey = GetAIAPIKey;
