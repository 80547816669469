"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TransactionGroupBase = exports.TransactionPreprocessingItem = exports.TransactionResult = exports.TransactionItem = void 0;
const logging_1 = require("./logging");
/**
 * Internal class used by TransactionGroupBase and sub-classes to manage individual transactions
 */
class TransactionItem {
  get Vars() {
    return this._vars;
  }
  get ExtraData() {
    return this._extraData;
  }
  get Instruction() {
    return this._instruction;
  }
  get CallBack() {
    return this._callBack;
  }
  get BaseEntity() {
    return this._baseEntity;
  }
  constructor(baseEntity, instruction, vars, extraData, callBack) {
    this._baseEntity = baseEntity;
    this._instruction = instruction;
    this._vars = vars;
    this._extraData = extraData;
    this._callBack = callBack;
  }
}
exports.TransactionItem = TransactionItem;
/**
 * Tracks the individual transactions within a transaction group and their commit results
 */
class TransactionResult {
  constructor(transaction, result, success) {
    this.Transaction = transaction;
    this.Result = result;
    this.Success = success;
  }
}
exports.TransactionResult = TransactionResult;
/**
 * Used internally within the transaction group to manage the preprocessing of entities before a transaction is submitted
 */
class TransactionPreprocessingItem {
  constructor(entity, completionPromise) {
    this.complete = false;
    this.entity = entity;
    this.completionPromise = completionPromise;
  }
}
exports.TransactionPreprocessingItem = TransactionPreprocessingItem;
/**
 * TransactionGroup is a class that handles the bundling of multiple transactions into a single request. The provider handles
 * the implementation details. If a transaction group is provided to the baseEntity object before either Save() or Delete() is called
 * instead of just immediately executing its SQL or GQL, it provides the instructions to the TransactionGroup object instead.
 *
 * Then, whenever the TransactionGroup object instance has its Submit() method called, all of the requests will be bundled into a single
 * request and handled. For example in the case of the GraphQLDataProvider, we queue up all of the GQL statements for all of the
 * mutations and send them across as a single GraphQL request. The GraphQL server handles the actual DB transaction stuff.
 *
 * TransactionGroup will call a callback function, if provided, after the transaction has either completed succesfully or failed.
 * If it is succesful, for Save() method calls, the latest data for that record will be provided back.
 * For Delete() method calls, the callback will be called with no data.
 *
 * This class is the base class for managing a group of transactions and submitting it to the provider so it can be handled as an ATOMic transaction
 */
class TransactionGroupBase {
  constructor() {
    this._pendingTransactions = [];
    this._preprocessingItems = [];
  }
  PendingTransactions() {
    return this._pendingTransactions;
  }
  /**
   * If an entity object needs to conduct any type of asynchronous preprocessing before a transaction is submitted, it must notify its transaction group
   * that it is doing so with this method. This causes the TransactionGroup to wait for all preprocessing to be completed before submitting the transaction.
   * This method checks to see if an the entity has already been registered for preprocessing and if so, does nothing.
   * @param entity
   */
  RegisterPreprocessing(entity) {
    const existingEntry = this._preprocessingItems.find(i => i.entity === entity);
    if (!existingEntry) {
      const preprocessingPromise = new Promise(resolve => {
        entity.RegisterEventHandler(e => {
          if (e.type === 'transaction_ready' && e.baseEntity === entity) {
            const found = this._preprocessingItems.find(i => i.entity === entity);
            if (found) {
              found.complete = true;
              resolve();
            }
          }
        });
      });
      const newItem = new TransactionPreprocessingItem(entity, preprocessingPromise);
      this._preprocessingItems.push(newItem);
    }
  }
  /**
   * Indicates whether all of the entities that have registered with this transaction group have completed their preprocessing
   * @returns
   */
  PreprocessingComplete() {
    if (this._preprocessingItems.length === 0) return true;else return this._preprocessingItems.every(i => i.complete);
  }
  /**
   * Waits for all preprocessing to be complete.
   */
  async waitForPreprocessing() {
    try {
      await Promise.all(this._preprocessingItems.map(item => item.completionPromise));
      this._preprocessingItems = []; // clear out the preprocessing items
    } catch (e) {
      (0, logging_1.LogError)(`Error during preprocessing TransactionGroupBase. Error: ${e.message}`);
    }
  }
  /**
   * This is used by the BaseEntity/Provider objects to manage transactions on your behalf. Do not directly interact with this method. Instead use the TransactionGroup property on
   * the @BaseEntity class to make an entity object part of a transaction group.
   * @param transaction
   */
  AddTransaction(transaction) {
    this._pendingTransactions.push(transaction);
  }
  /**
   * Submits the transaction group to the provider for handling. The provider will handle the actual transaction and call the callback functions
   * @returns true if the transaction was successful, false if it failed. If the method fails, check each of the individual BaseEntity objects within
   * the TransactionGroup for their result histories using BaseEntity.ResultHistory and BaseEntity.LatestResult
   */
  async Submit() {
    try {
      // Wait for all preprocessing to be complete
      await this.waitForPreprocessing();
      if (this._pendingTransactions.length > 0) {
        // subclass handles the actual submit implementation whatever that does
        let results = await this.HandleSubmit(this._pendingTransactions);
        // now we have the results back, so we can call the callback functions
        for (let i = 0; i < results.length; i++) {
          await results[i].Transaction.CallBack(results[i].Result, results[i].Success);
        }
        // now, see if there are any false values for results[x].Success, if so, we have to return false
        return results.every(r => r.Success);
      }
      return true;
    } catch (err) {
      console.error(err);
      // it failed, so we have to call the callback functions with the error
      for (let i = 0; i < this._pendingTransactions.length; i++) {
        await this._pendingTransactions[i].CallBack(err, false);
      }
      return false;
    }
  }
}
exports.TransactionGroupBase = TransactionGroupBase;
