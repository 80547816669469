<ng-container *ngIf="auth.isAuthenticated$ | async">
    <div *ngIf="loading">
        <!-- <kendo-loader type="infinite-spinner" themeColor="primary" size="large"></kendo-loader> -->
        <div class="gg-loader">
            <img src="../../assets/loader.gif"/>
        </div>
    </div>
    <div *ngIf="!loading" class="interior-component">
        <div class="breadcrumbWrapper">
            <div class="container-large">
                <div class="breadcrumb">
                    <a routerLink="/">
                        <kendo-icon name="home"></kendo-icon>
                    </a>
                    <kendo-icon name="arrow-chevron-right"></kendo-icon><a routerLink="/select-role">Salary by Role</a>
                    <kendo-icon name="arrow-chevron-right"></kendo-icon><a [routerLink]="['/role/', roleRecord?.ID]">{{roleRecord?.Name}}</a>
                    <kendo-icon name="arrow-chevron-right"></kendo-icon><a class="active">Salary Details</a>
                </div>
                <div class="back-sec">
                    <a [routerLink]="['/role/', roleRecord?.ID]">
                        <kendo-icon name="arrow-chevron-left"></kendo-icon>
                        Back
                    </a>
                </div>
            </div>
        </div>
        <section class="salary-info">
            <div class="container-large">
                <div class="salary-header">
                    <h4>Salary Details</h4>
                    <p>{{latestResult?.RowCount}} results</p>
                    <!-- <div class="content-header" *ngIf="
                                latestResult && latestResult.TotalRowCount > latestResult.RowCount
                              ">
                        <p>
                            Showing {{ latestResult.RowCount }} of
                            {{ latestResult.TotalRowCount }} results, you are limited to viewing
                            {{ latestResult.RowCount }} rows with your usage plan.
                        </p>
                    </div>
                    <div class="content-header" *ngIf="
                                latestResult && latestResult.TotalRowCount === latestResult.RowCount
                              ">
                        <p>Showing {{ latestResult.RowCount }} rows.</p>
                    </div> -->
                </div>
                <div class="grid-container">
                    <kendo-grid
                        *ngIf="!loading"
                        [kendoGridBinding]="gridData"
                        [sortable]="true"
                    >
                        <kendo-grid-column field="TaxYear" title="Year" [width]="75">
                        </kendo-grid-column>
                        <kendo-grid-column field="PersonName" title="Name" [width]="150">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div>
                                    {{ dataItem.PersonName }}
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="ContactRole" title="Role" [width]="100">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div>
                                    {{ dataItem.ContactRole }} / {{ dataItem.ContactLevel }}
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                            field="TotalCompensation"
                            title="Compensation"
                            [width]="175"
                            [format]="{
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0
                            }"
                        >
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div>
                                    {{dataItem.TotalCompensation | currency : "USD" : "symbol" : "1.0-0"}}<br />
                                    <span style="font-size: smaller;color: #8F8F8F">
                                        {{ dataItem.TotalCompensation / dataItem.TotalRevenue | percent : "1.0-2" }} of Revenue
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                        field="OrganizationName"
                        title="Organization"
                        [width]="200"
                        >
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div>
                                    <a class="org-link"[routerLink]="'/orgs/' + dataItem.TaxID">{{ dataItem.OrganizationName }}</a><br />
                                    <span style="font-size: smaller;color: #8F8F8F">{{ dataItem.OrgSizeRevenue }} (by revenue)</span><br />
                                    <span style="font-size: smaller;color: #8F8F8F">{{ dataItem.OrgSizeEmployees }} (by # employees)</span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                        field="TotalRevenue"
                        title="Revenue"
                        [width]="100"
                        [format]="{
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0
                        }"
                        >
                        </kendo-grid-column>
        
                        <kendo-grid-column
                        field="NumberEmployees"
                        title="# Employees"
                        [width]="120"
                        [format]="{ style: 'decimal', minimumFractionDigits: 0 }"
                        >
                        </kendo-grid-column>
                        <kendo-grid-column field="Industry" title="Industry" [width]="100">
                        </kendo-grid-column>
                        <kendo-grid-column field="MetroArea" title="Metro" [width]="135">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div>
                                    {{ dataItem.MetroArea }}<br />
                                    <span style="font-size: smaller;color: #8F8F8F">{{
                                        dataItem.MetroAreaSize
                                        }}</span><br />
                                    <span style="font-size: smaller;color: #8F8F8F">{{
                                        dataItem.MetroAreaPopulation | number : "1.0-0"
                                        }}</span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </section>
    </div>
</ng-container>
    