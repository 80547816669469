"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LibraryInfo = void 0;
const baseInfo_1 = require("./baseInfo");
/**
 * Metadata about a single library that's available for code generation or other purposes.
 */
class LibraryInfo extends baseInfo_1.BaseInfo {
  /**
   * Helper method to get the ExportedItems as an array of strings, parsing the comma delimited string in the ExportedItems property
   */
  get ExportItemsArray() {
    if (!this._exportItemsArray && this.ExportedItems && this.ExportedItems.length > 0) {
      this._exportItemsArray = this.ExportedItems.split(',').map(item => item.trim());
    } else this._exportItemsArray = [];
    return this._exportItemsArray;
  }
  constructor(initData = null) {
    super();
    this.ID = null;
    /**
     * Name of the library - used for import statements and within package.json
     */
    this.Name = null;
    /**
     * User readable/AI readable description of the library
     */
    this.Description = null;
    /**
     * Only Active libraries are used for new code generation but disabling a library means it won't be used for new code generation, but doesn't remove it from use from previously generated code.
     */
    this.Status = null;
    /**
     * Comma-delimted list of items that are exported from the library such as classes, functions, types, etc.
     */
    this.ExportedItems = null;
    /**
     * Code definitions for the types that are exported from the library
     */
    this.TypeDefinitions = null;
    /**
     * Sample code that demonstrates how to use the library
     */
    this.SampleCode = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this._exportItemsArray = null;
    if (initData) {
      this.copyInitData(initData);
    }
  }
}
exports.LibraryInfo = LibraryInfo;
