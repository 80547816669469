"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SummarizeResult = exports.SummarizeParams = void 0;
const baseModel_1 = require("./baseModel");
const chat_types_1 = require("./chat.types");
class SummarizeParams extends chat_types_1.ChatParams {}
exports.SummarizeParams = SummarizeParams;
class SummarizeResult extends baseModel_1.BaseResult {
  constructor(text, summaryText, success, startTime, endTime) {
    super(success, startTime, endTime);
    this.text = text;
    this.summaryText = summaryText;
  }
}
exports.SummarizeResult = SummarizeResult;
