<div *ngIf="!loaded">
    <div class="gg-loader">
      <img src="../../assets/loader.gif"/>
    </div>
</div>

<div *ngIf="loaded">
    <div class="breadcrumbWrapper">
        <div class="container-large">
            <div class="breadcrumb">
                <a routerLink="/"><kendo-icon name="home"></kendo-icon></a>
                <kendo-icon name="arrow-chevron-right"></kendo-icon>
                <a routerLink="/benchmark">Benchmarking</a>
                <kendo-icon name="arrow-chevron-right"></kendo-icon>
                <a class="active">New Report</a>
            </div>
            <div class="back-sec">
                <a>
                    <kendo-icon name="arrow-chevron-left"></kendo-icon>
                    Back
                </a>
            </div>
        </div>
    </div>

    <div class="content-wrapper">
        <app-side-nav></app-side-nav>
        <div class="main-content">
            <div class="report-container">
                <div class="report-header">
                    <h1>New Report</h1>
                    <kendo-button themeColor="primary" routerLink="/benchmark">Back To All Reports</kendo-button>
                </div>
              
                <div class="report-steps">
                    <!-- Step 1 -->
                    <div *ngIf="currentStep === 1" class="step">
                        <h2>Step 1</h2>
                        <div class="input-group">
                            <label for="name">Report Name</label>
                            <input kendoTextBox name="name" [(ngModel)]="newReportRequest.Name" placeholder="Default Report Name"/>
                        </div>
                        <div class="input-group">
                            <label>Organization to Analyze</label>
                            <kendo-combobox 
                                [data]="accounts" 
                                [(ngModel)]="newReportRequest.SourceAccountID" 
                                textField="Name"
                                valueField="ID"
                                [filterable]="true"
                                (filterChange)="onFilterChange($event)"
                                (valueChange)="onSourceAccountChange($event)"
                                [valuePrimitive]="true"
                                placeholder="Search Organizations"></kendo-combobox>
                        </div>
                        <div class="input-group">
                            <label for="maxNumberOfPeers">Maximum Number of Peers</label>
                            <kendo-combobox 
                                name="maxNumberOfPeers" 
                                [(ngModel)]="newReportRequest.MaxNumberOfPeers" 
                                [data]="peers" 
                                textField="Value" 
                                valueField="Value"
                                [valuePrimitive]="true"
                                placeholder="Select Maximum Number of Peers">
                            </kendo-combobox>
                        </div>
                        <h3>Which sections would you like to include?</h3>
                        <p>Select all that apply.</p>
                        <div *ngFor="let section of sections">
                            <label class="custom-checkbox">
                                <kendo-checkbox [(ngModel)]="section.selected"></kendo-checkbox>
                                {{ section.label }}
                            </label>
                        </div>
                    </div>
              
                    <!-- Step 2 -->
                    <div *ngIf="currentStep === 2" class="step">
                        <h2>Step 2</h2>
                        <h3>What data from your organization do you want to analyze?</h3>
                        <p>Select all that apply.</p>
                        <div class="input-group">
                            <label>Job Role</label>
                            <kendo-multiselect 
                                [data]="sharedData.ContactRoles" 
                                [(ngModel)]="selectedRoles" 
                                placeholder="Search Roles"
                                textField="Name"
                                valueField="ID"></kendo-multiselect>
                        </div>
                        <div class="input-group">
                            <label>Job Level</label>
                            <div *ngFor="let type of positionTypes">
                                <label>
                                    <kendo-checkbox [(ngModel)]="type.selected"></kendo-checkbox>
                                    {{ type.label }}
                                </label>
                            </div>
                        </div>
                        <div class="input-group">
                            <label>Time Range</label>
                            <kendo-rangeslider 
                                [min]="2015" 
                                [max]="2024" 
                                [smallStep]="1" 
                                [largeStep]="1" 
                                [(ngModel)]="timeRange" 
                                tickPlacement="both">
                            </kendo-rangeslider>
                        </div>
                    </div>
              
                    <!-- Step 3 -->
                    <div *ngIf="currentStep === 3" class="step">
                        <h2>Step 3</h2>
                        <h3>What comparisons would you like to include?</h3>
                        <p>Select all that apply.</p>
                        <div class="input-group">
                            <label>Metro Size</label>
                            <kendo-dropdownlist 
                                name="metroSize"
                                [data]="sharedData.MetroAreaSizes" 
                                [(ngModel)]="selectedMetroSize" 
                                placeholder="Select Metro Size"
                                textField="NameWithRange"
                                valueField="ID"
                            >
                            </kendo-dropdownlist>
                        </div>
                        <div class="input-group">
                            <label>Industry</label>
                            <kendo-multiselect 
                                [data]="sharedData.Industries" 
                                [(ngModel)]="selectedIndustries" 
                                placeholder="Search Industries"
                                textField="Name"
                                valueField="ID"
                            >
                            </kendo-multiselect>
                        </div>
                        <div class="input-group">
                            <label>Organization Revenue Size</label>
                            <kendo-dropdownlist 
                                [data]="sharedData.OrgRevenueSizes" 
                                [(ngModel)]="selectedOrgSize" 
                                placeholder="Select Organization Size"
                                textField="NameWithRange"
                                valueField="ID"></kendo-dropdownlist>
                        </div>
                        <div class="input-group">
                            <label>Organization Employee Size</label>
                            <kendo-dropdownlist 
                                [data]="sharedData.OrgEmployeeSizes" 
                                [(ngModel)]="selectedOrgType" 
                                textField="NameWithRange"
                                valueField="ID"
                                placeholder="Select Organization Type"></kendo-dropdownlist>
                        </div>
                        <div class="input-group">
                            <label for="freeTextField">Additional Question(s)?</label>
                            <div class="free-text-field">
                                <input kendoTextBox name="freeTextField" [(ngModel)]="newReportRequest.LLMQuestion" />
                            </div>
                                        
                    </div>
                </div>
                <!-- Navigation Buttons -->
                <div class="navigation-buttons">
                    <kendo-button *ngIf="currentStep > 1" (click)="previousStep()">Back</kendo-button>
                    <kendo-button *ngIf="currentStep < 3" (click)="nextStep()">Next</kendo-button>
                    <kendo-button *ngIf="currentStep === 3" themeColor="secondary" (click)="generateReport()">Generate Report</kendo-button>
                </div>
            </div>
        </div>
    </div>
</div>