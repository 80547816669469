"use strict";

// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileFromPath = exports.toFile = exports.UnprocessableEntityError = exports.PermissionDeniedError = exports.InternalServerError = exports.AuthenticationError = exports.BadRequestError = exports.RateLimitError = exports.ConflictError = exports.NotFoundError = exports.APIUserAbortError = exports.APIConnectionTimeoutError = exports.APIConnectionError = exports.APIError = exports.GroqError = exports.Groq = void 0;
const Core = __importStar(require("./core.js"));
const Errors = __importStar(require("./error.js"));
const Uploads = __importStar(require("./uploads.js"));
const API = __importStar(require("./resources/index.js"));
/** API Client for interfacing with the Groq API. */
class Groq extends Core.APIClient {
  /**
   * API Client for interfacing with the Groq API.
   *
   * @param {string | undefined} [opts.apiKey=process.env['GROQ_API_KEY'] ?? undefined]
   * @param {string} [opts.baseURL=process.env['GROQ_BASE_URL'] ?? https://api.groq.com] - Override the default base URL for the API.
   * @param {number} [opts.timeout=1 minute] - The maximum amount of time (in milliseconds) the client will wait for a response before timing out.
   * @param {number} [opts.httpAgent] - An HTTP agent used to manage HTTP(s) connections.
   * @param {Core.Fetch} [opts.fetch] - Specify a custom `fetch` function implementation.
   * @param {number} [opts.maxRetries=2] - The maximum number of times the client will retry a request.
   * @param {Core.Headers} opts.defaultHeaders - Default headers to include with every request to the API.
   * @param {Core.DefaultQuery} opts.defaultQuery - Default query parameters to include with every request to the API.
   * @param {boolean} [opts.dangerouslyAllowBrowser=false] - By default, client-side use of this library is not allowed, as it risks exposing your secret API credentials to attackers.
   */
  constructor({
    baseURL = Core.readEnv('GROQ_BASE_URL'),
    apiKey = Core.readEnv('GROQ_API_KEY'),
    ...opts
  } = {}) {
    if (apiKey === undefined) {
      throw new Errors.GroqError("The GROQ_API_KEY environment variable is missing or empty; either provide it, or instantiate the Groq client with an apiKey option, like new Groq({ apiKey: 'My API Key' }).");
    }
    const options = {
      apiKey,
      ...opts,
      baseURL: baseURL || `https://api.groq.com`
    };
    if (!options.dangerouslyAllowBrowser && Core.isRunningInBrowser()) {
      throw new Errors.GroqError("It looks like you're running in a browser-like environment.\n\nThis is disabled by default, as it risks exposing your secret API credentials to attackers.\nIf you understand the risks and have appropriate mitigations in place,\nyou can set the `dangerouslyAllowBrowser` option to `true`, e.g.,\n\nnew Groq({ apiKey, dangerouslyAllowBrowser: true })");
    }
    super({
      baseURL: options.baseURL,
      timeout: options.timeout ?? 60000 /* 1 minute */,
      httpAgent: options.httpAgent,
      maxRetries: options.maxRetries,
      fetch: options.fetch
    });
    this.completions = new API.Completions(this);
    this.chat = new API.Chat(this);
    this.embeddings = new API.Embeddings(this);
    this.audio = new API.Audio(this);
    this.models = new API.Models(this);
    this._options = options;
    this.apiKey = apiKey;
  }
  defaultQuery() {
    return this._options.defaultQuery;
  }
  defaultHeaders(opts) {
    return {
      ...super.defaultHeaders(opts),
      ...this._options.defaultHeaders
    };
  }
  authHeaders(opts) {
    return {
      Authorization: `Bearer ${this.apiKey}`
    };
  }
}
exports.Groq = Groq;
_a = Groq;
Groq.Groq = _a;
Groq.GroqError = Errors.GroqError;
Groq.APIError = Errors.APIError;
Groq.APIConnectionError = Errors.APIConnectionError;
Groq.APIConnectionTimeoutError = Errors.APIConnectionTimeoutError;
Groq.APIUserAbortError = Errors.APIUserAbortError;
Groq.NotFoundError = Errors.NotFoundError;
Groq.ConflictError = Errors.ConflictError;
Groq.RateLimitError = Errors.RateLimitError;
Groq.BadRequestError = Errors.BadRequestError;
Groq.AuthenticationError = Errors.AuthenticationError;
Groq.InternalServerError = Errors.InternalServerError;
Groq.PermissionDeniedError = Errors.PermissionDeniedError;
Groq.UnprocessableEntityError = Errors.UnprocessableEntityError;
Groq.toFile = Uploads.toFile;
Groq.fileFromPath = Uploads.fileFromPath;
exports.GroqError = Errors.GroqError, exports.APIError = Errors.APIError, exports.APIConnectionError = Errors.APIConnectionError, exports.APIConnectionTimeoutError = Errors.APIConnectionTimeoutError, exports.APIUserAbortError = Errors.APIUserAbortError, exports.NotFoundError = Errors.NotFoundError, exports.ConflictError = Errors.ConflictError, exports.RateLimitError = Errors.RateLimitError, exports.BadRequestError = Errors.BadRequestError, exports.AuthenticationError = Errors.AuthenticationError, exports.InternalServerError = Errors.InternalServerError, exports.PermissionDeniedError = Errors.PermissionDeniedError, exports.UnprocessableEntityError = Errors.UnprocessableEntityError;
exports.toFile = Uploads.toFile;
exports.fileFromPath = Uploads.fileFromPath;
(function (Groq) {
  Groq.Completions = API.Completions;
  Groq.Chat = API.Chat;
  Groq.Embeddings = API.Embeddings;
  Groq.Audio = API.Audio;
  Groq.Models = API.Models;
})(Groq = exports.Groq || (exports.Groq = {}));
exports = module.exports = Groq;
exports.default = Groq;
