export const uppercase = str => str.toUpperCase();
/**
 * Convert Headers instance into regular object
 */
export const HeadersInstanceToPlainObject = headers => {
  const o = {};
  headers.forEach((v, k) => {
    o[k] = v;
  });
  return o;
};
