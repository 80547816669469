import { deepExtend, addClass, Observable, keys, setDefaultOptions, renderIcon, on, off } from '../common';
import { proxy, setDefaultEvents, convertToHtml } from './utils';
const PAN = "pan";
const directionsMap = {
  up: {
    className: "k-navigator-n",
    iconClass: "k-i-caret-alt-up"
  },
  down: {
    className: "k-navigator-s",
    iconClass: "k-i-caret-alt-down"
  },
  right: {
    className: "k-navigator-e",
    iconClass: "k-i-caret-alt-right"
  },
  left: {
    className: "k-navigator-w",
    iconClass: "k-i-caret-alt-left"
  }
};
function createButton(direction, iconOptions) {
  const html = '<button class="k-button k-button-square k-rounded-full k-button-flat k-button-flat-base k-icon-button ' + directionsMap[direction].className + '" aria-label="move ' + direction + '">' + renderIcon({
    icon: `caret-alt-${direction}`,
    iconClass: "k-button-icon",
    svgIcons: iconOptions.svgIcons,
    type: iconOptions.type
  }) + '</button>';
  return convertToHtml(html);
}
export class Navigator extends Observable {
  constructor(element, options) {
    super();
    this.element = element;
    this._initOptions(options);
    const navigateUpButton = createButton("up", options.icons);
    const navigateRightlButton = createButton("right", options.icons);
    const navigateDownButton = createButton("down", options.icons);
    const navigateLeftButton = createButton("left", options.icons);
    this.element.appendChild(navigateUpButton);
    this.element.appendChild(navigateRightlButton);
    this.element.appendChild(navigateDownButton);
    this.element.appendChild(navigateLeftButton);
    addClass(this.element, 'k-widget k-navigator');
    on(this.element, "click", ".k-button", proxy(this._click, this));
    let parentElement = this.element.parentNode.closest("[data-role]");
    this._keyroot = parentElement ? parentElement : this.element;
    this._tabindex(this._keyroot);
    this._keydownHandler = proxy(this._keydown, this);
    on(this._keyroot, "keydown", this._keydownHandler);
  }
  destroy() {
    this.dispose();
  }

  // originates from the kendo.jquery version
  dispose() {
    off(this._keyroot, "keydown", this._keydownHandler);
  }
  _tabindex(target) {
    const targetElement = target || this.wrapper || this.element;
    let element = this.element,
      TABINDEX = "tabindex",
      tabindex = targetElement.getAttribute(TABINDEX) || element.getAttribute(TABINDEX);
    element.removeAttribute(TABINDEX);
    targetElement.setAttribute(TABINDEX, !isNaN(tabindex) ? tabindex : 0);
  }
  _initOptions(options) {
    this.options = deepExtend({}, this.options, options);
  }
  _pan(x, y) {
    let panStep = this.options.panStep;
    this.trigger(PAN, {
      x: x * panStep,
      y: y * panStep
    });
  }
  _click(e) {
    let x = 0;
    let y = 0;
    let button = e.currentTarget;
    if (button.matches('.k-navigator-n')) {
      y = 1;
    } else if (button.matches('.k-navigator-s')) {
      y = -1;
    } else if (button.matches('.k-navigator-e')) {
      x = 1;
    } else if (button.matches('.k-navigator-w')) {
      x = -1;
    }
    this._pan(x, y);
    e.preventDefault();
  }
  _keydown(e) {
    switch (e.which) {
      case keys.UP:
        this._pan(0, 1);
        e.preventDefault();
        break;
      case keys.DOWN:
        this._pan(0, -1);
        e.preventDefault();
        break;
      case keys.RIGHT:
        this._pan(1, 0);
        e.preventDefault();
        break;
      case keys.LEFT:
        this._pan(-1, 0);
        e.preventDefault();
        break;
      default:
        break;
    }
  }
}
setDefaultOptions(Navigator, {
  name: 'Navigator',
  panStep: 1
});
setDefaultEvents(Navigator, [PAN]);