import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RoleSelectComponent } from './role-select/role-select.component';

import { AuthModule } from '@auth0/auth0-angular';
import { AuthButtonComponent } from './auth-button/auth-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { RoleComponent } from './role/role.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { RoleLevelComponent } from './role-level/role-level.component';
import 'hammerjs'
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { HeaderComponent } from './header/header.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { HomeComponent } from './home/home.component';
import { OrganizationSearchComponent } from './organization-search/organization-search.component';
import { OrganizationComponent } from './organization/organization.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { WindowModule } from "@progress/kendo-angular-dialog";
import { SalaryDrilldownComponent } from './salary-drilldown/salary-drilldown.component';
import { environment } from 'src/environments/environment';
import { TrendsComponent } from './trends/trends.component';
import { OrgDrilldownComponent } from './org-drilldown/org-drilldown.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { FilterWindowComponent } from './filter-window/filter-window.component';
import { NumberSuffixPipe } from './NumberSuffixPipe';
import { BenchmarkComponent } from './benchmark/benchmark.component';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { ReportComponent } from './report/report.component';
import { PanelBarModule } from '@progress/kendo-angular-layout';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SplitterModule } from '@progress/kendo-angular-layout';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatListModule } from '@angular/material/list';
// import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
// import { DomSanitizer } from '@angular/platform-browser';
// import { HttpClient, HttpClientModule } from '@angular/common/http';  // Import HttpClientModule
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NewReportComponent } from './new-report/new-report.component';
import { ConversationalUIModule } from '@progress/kendo-angular-conversational-ui';
import { NbLayoutModule, NbSidebarModule, NbMenuModule, NbIconModule, NbThemeModule, NbIconLibraries } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { SidebarComponent } from './sidebar/sidebar.component';

import { MenuModule } from '@progress/kendo-angular-menu';
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { pdf } from '@progress/kendo-drawing';


@NgModule({
  declarations: [
    AppComponent,
    RoleSelectComponent,
    AuthButtonComponent,
    RoleComponent,
    RoleLevelComponent,
    HeaderComponent,
    HomeComponent,
    OrganizationSearchComponent,
    OrganizationComponent,
    SalaryDrilldownComponent,
    TrendsComponent,
    OrgDrilldownComponent,
    NoAccessComponent,
    FilterWindowComponent,
    NumberSuffixPipe,
    BenchmarkComponent,
    ReportComponent,
    SideNavComponent,
    BreadcrumbComponent,
    NewReportComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AuthModule.forRoot({
      domain: environment.CLIENT_AUTHORITY,
      clientId: environment.CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin
      },
      cacheLocation: 'localstorage', 
    }),
    //DropDownsModule,
    BrowserAnimationsModule,
    DropDownsModule,
    GridModule,
    ChartsModule,
    IndicatorsModule,
    LayoutModule,
    ButtonsModule,
    NavigationModule,
    IconsModule,
    InputsModule,
    NotificationModule,
    WindowModule,
    ListViewModule,
    PanelBarModule,
    SplitterModule,
    // MatListModule,
    // MatSidenavModule,
    // MatIconModule,
    // HttpClientModule,  // Add HttpClientModule
    ConversationalUIModule,
    // NbLayoutModule,
    // NbSidebarModule.forRoot(),
    // NbMenuModule.forRoot(),
    // NbIconModule,
    // NbThemeModule.forRoot({ name: 'corporate' }),
    // NbEvaIconsModule,
    MenuModule,
    PDFExportModule,
  ],
  providers: [
    CurrencyPipe, 
    DecimalPipe,
    NumberSuffixPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { 
  constructor(private iconLibraries: NbIconLibraries) {
    // const icons = ['logoutWhite', 'settingsWhite', 'searchWhite', 'dashboardWhite', 'employeesWhite', 'reportsWhite', 'financialsWhite', 'integrationsWhite'];
    // icons.forEach(icon => {
    //   matIconRegistry.addSvgIcon(
    //     icon,
    //     domSanitizer.bypassSecurityTrustResourceUrl(`../assets/${icon}.svg`)
    //   );
    // });

    this.iconLibraries.registerFontPack('eva', { packClass: 'eva', iconClassPrefix: 'eva'});
    this.iconLibraries.setDefaultPack('eva');  // Set 'eva' as the default icon pack
  }
}
