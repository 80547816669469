"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RunReport = void 0;
const global_1 = require("@memberjunction/global");
/**
 * Class used to run a report and return the results.
 */
class RunReport {
  //private static _Provider: IRunViewProvider;
  async RunReport(params, contextUser) {
    return RunReport.Provider.RunReport(params, contextUser);
  }
  static get Provider() {
    const g = global_1.MJGlobal.Instance.GetGlobalObjectStore();
    if (g) return g[RunReport._globalProviderKey];else throw new Error('No global object store, so we cant get the static provider');
  }
  static set Provider(value) {
    const g = global_1.MJGlobal.Instance.GetGlobalObjectStore();
    if (g) g[RunReport._globalProviderKey] = value;else throw new Error('No global object store, so we cant set the static provider');
  }
}
exports.RunReport = RunReport;
RunReport._globalProviderKey = 'MJ_RunReportProvider';
