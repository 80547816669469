var Q = Object.defineProperty;
var E = (V, e) => Q(V, "name", {
  value: e,
  configurable: !0
});
import { gql as R, GraphQLClient as k } from "graphql-request";
import { gql as we } from "graphql-request";
import { ProviderConfigDataBase as A, ProviderBase as O, LogError as D, UserInfo as M, ProviderType as q, BaseEntityResult as _, EntityFieldTSType as S, TransactionItem as B, UserRoleInfo as b, TransactionGroupBase as J, TransactionResult as j, BaseEntity as W, Metadata as z, RunView as H, RunReport as X, RunQuery as Y } from "@memberjunction/core";
import { ViewInfo as K } from "@memberjunction/core-entities";
import { openDB as Z } from "@tempfix/idb";
import { Observable as ee } from "rxjs";
import { createClient as te } from "graphql-ws";
import { MJGlobal as ae, MJEventType as se } from "@memberjunction/global";
const G = class G {
  constructor() {
    this._fieldMap = {
      __mj_CreatedAt: "_mj__CreatedAt",
      __mj_UpdatedAt: "_mj__UpdatedAt"
    };
  }
  MapFields(e) {
    if (e) for (const s in e) s in this._fieldMap && (e[this._fieldMap[s]] = e[s], delete e[s]);
    return e;
  }
  MapFieldName(e) {
    return this._fieldMap[e] ?? e;
  }
  ReverseMapFieldName(e) {
    return Object.entries(this._fieldMap).find(([s, a]) => a === e)?.[0] ?? e;
  }
  ReverseMapFields(e) {
    const s = Object.fromEntries(Object.entries(this._fieldMap).map(([a, t]) => [t, a]));
    for (const a in e) a in s && (e[s[a]] = e[a], delete e[a]);
    return e;
  }
};
E(G, "FieldMapper");
let g = G;
const T = class T extends A {
  get Token() {
    return this.Data.Token;
  }
  set Token(e) {
    this.Data.Token = e;
  }
  get URL() {
    return this.Data.URL;
  }
  get WSURL() {
    return this.Data.WSURL;
  }
  get RefreshTokenFunction() {
    return this.Data.RefreshFunction;
  }
  constructor(e, s, a, t, r, u, i) {
    super({
      Token: e,
      URL: s,
      WSURL: a,
      RefreshTokenFunction: t
    }, r, u, i);
  }
};
E(T, "GraphQLProviderConfigData");
let F = T;
const d = class d extends O {
  constructor() {
    super(...arguments), this._innerCurrentUserQueryString = `CurrentUser {
        ${this.userInfoString()}
        UserRolesArray {
            ${this.userRoleInfoString()}
        }
    }
    `, this._currentUserQuery = R`query CurrentUserAndRoles {
        ${this._innerCurrentUserQueryString}
    }`, this._wsClient = null, this._pushStatusRequests = [];
  }
  get ConfigData() {
    return d._configData;
  }
  GenerateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (e) {
      var s = Math.random() * 16 | 0,
        a = e === "x" ? s : s & 3 | 8;
      return a.toString(16);
    });
  }
  async Config(e) {
    try {
      return d._sessionId === void 0 && (d._sessionId = this.GenerateUUID()), d._configData = e, d._client || (d._client = d.CreateNewGraphQLClient(e.URL, e.Token, d._sessionId)), super.Config(e);
    } catch (s) {
      throw D(s), s;
    }
  }
  get sessionId() {
    return d._sessionId;
  }
  get AllowRefresh() {
    return !0;
  }
  async GetCurrentUser() {
    const e = await d.ExecuteGQL(this._currentUserQuery, null);
    if (e) {
      const s = this.ConvertBackToMJFields(e.CurrentUser),
        a = s.UserRolesArray.map(t => this.ConvertBackToMJFields(t));
      return s.UserRolesArray = a, new M(this, {
        ...s,
        UserRoles: a
      });
    }
  }
  async RunReport(e, s) {
    const a = R`
        query GetReportDataQuery ($ReportID: String!) {
            GetReportData(ReportID: $ReportID) {
                Success
                Results
                RowCount
                ExecutionTime
                ErrorMessage
            }
        }`,
      t = await d.ExecuteGQL(a, {
        ReportID: e.ReportID
      });
    if (t && t.GetReportData) return {
      ReportID: e.ReportID,
      Success: t.GetReportData.Success,
      Results: JSON.parse(t.GetReportData.Results),
      RowCount: t.GetReportData.RowCount,
      ExecutionTime: t.GetReportData.ExecutionTime,
      ErrorMessage: t.GetReportData.ErrorMessage
    };
  }
  async RunQuery(e, s) {
    const a = R`
        query GetQueryDataQuery ($QueryID: String!) {
            GetQueryData(QueryID: $QueryID) {
                Success
                Results
                RowCount
                ExecutionTime
                ErrorMessage
            }
        }`,
      t = await d.ExecuteGQL(a, {
        QueryID: e.QueryID
      });
    if (t && t.GetQueryData) return {
      QueryID: e.QueryID,
      Success: t.GetQueryData.Success,
      Results: JSON.parse(t.GetQueryData.Results),
      RowCount: t.GetQueryData.RowCount,
      ExecutionTime: t.GetQueryData.ExecutionTime,
      ErrorMessage: t.GetQueryData.ErrorMessage
    };
  }
  async RunView(e, s) {
    try {
      let a = "",
        t = "";
      if (e) {
        const r = {};
        let u, i;
        if (e.ViewEntity) i = e.ViewEntity, u = i.Entity;else {
          const {
            entityName: o,
            v: c
          } = await this.getEntityNameAndUserView(e, s);
          i = c, u = o;
        }
        const n = this.Entities.find(o => o.Name === u);
        if (!n) throw new Error(`Entity ${u} not found in metadata`);
        let p = !1;
        e.ViewID ? (a = `Run${n.ClassName}ViewByID`, t = "RunViewByIDInput", r.ViewID = e.ViewID) : e.ViewName ? (a = `Run${n.ClassName}ViewByName`, t = "RunViewByNameInput", r.ViewName = e.ViewName) : (p = !0, a = `Run${n.ClassName}DynamicView`, t = "RunDynamicViewInput", r.EntityName = e.EntityName), r.ExtraFilter = e.ExtraFilter ? e.ExtraFilter : "", r.OrderBy = e.OrderBy ? e.OrderBy : "", r.UserSearchString = e.UserSearchString ? e.UserSearchString : "", r.Fields = e.Fields, r.IgnoreMaxRows = e.IgnoreMaxRows ? e.IgnoreMaxRows : !1, r.MaxRows = e.MaxRows ? e.MaxRows : 0, r.ForceAuditLog = e.ForceAuditLog ? e.ForceAuditLog : !1, r.ResultType = e.ResultType ? e.ResultType : "simple", e.AuditLogDescription && e.AuditLogDescription.length > 0 && (r.AuditLogDescription = e.AuditLogDescription), p || (r.ExcludeUserViewRunID = e.ExcludeUserViewRunID ? e.ExcludeUserViewRunID : "", r.ExcludeDataFromAllPriorViewRuns = e.ExcludeDataFromAllPriorViewRuns ? e.ExcludeDataFromAllPriorViewRuns : !1, r.OverrideExcludeFilter = e.OverrideExcludeFilter ? e.OverrideExcludeFilter : "", r.SaveViewResults = e.SaveViewResults ? e.SaveViewResults : !1);
        const f = this.getViewRunTimeFieldList(n, i, e, p),
          y = R`
                    query RunViewQuery ($input: ${t}!) {
                    ${a}(input: $input) {
                        Results {
                            ${f.join(`
                        `)}
                        }
                        UserViewRunID
                        RowCount
                        TotalRowCount
                        ExecutionTime
                        Success
                        ErrorMessage
                    }
                }`,
          l = await d.ExecuteGQL(y, {
            input: r
          });
        if (l && l[a]) {
          const o = l[a].Results;
          if (o && o.length > 0) {
            const c = n.Fields.filter(m => m.CodeName !== m.Name && m.CodeName !== void 0);
            o.forEach(m => {
              this.ConvertBackToMJFields(m), c.forEach(w => {
                m[w.Name] = m[w.CodeName];
              });
            });
          }
          return l[a];
        }
      } else throw "No parameters passed to RunView";
      return null;
    } catch (a) {
      throw D(a), a;
    }
  }
  async RunViews(e, s) {
    try {
      let a = [],
        t = [],
        r = [];
      for (const n of e) {
        let p = "",
          f = "";
        const y = {};
        let l = null,
          o = null;
        if (n.ViewEntity) o = n.ViewEntity, l = o.Get("Entity");else {
          const {
            entityName: w,
            v: N
          } = await this.getEntityNameAndUserView(n, s);
          o = N, l = w;
        }
        const c = this.Entities.find(w => w.Name === l);
        if (!c) throw new Error(`Entity ${l} not found in metadata`);
        t.push(c);
        let m = !1;
        n.ViewID ? (p = `Run${c.ClassName}ViewByID`, f = "RunViewByIDInput", y.ViewID = n.ViewID) : n.ViewName ? (p = `Run${c.ClassName}ViewByName`, f = "RunViewByNameInput", y.ViewName = n.ViewName) : (m = !0, p = `Run${c.ClassName}DynamicView`, f = "RunDynamicViewInput", y.EntityName = n.EntityName), y.ExtraFilter = n.ExtraFilter || "", y.OrderBy = n.OrderBy || "", y.UserSearchString = n.UserSearchString || "", y.Fields = n.Fields, y.IgnoreMaxRows = n.IgnoreMaxRows || !1, y.MaxRows = n.MaxRows || 0, y.ForceAuditLog = n.ForceAuditLog || !1, y.ResultType = n.ResultType || "simple", n.AuditLogDescription && n.AuditLogDescription.length > 0 && (y.AuditLogDescription = n.AuditLogDescription), m || (y.ExcludeUserViewRunID = n.ExcludeUserViewRunID || "", y.ExcludeDataFromAllPriorViewRuns = n.ExcludeDataFromAllPriorViewRuns || !1, y.OverrideExcludeFilter = n.OverrideExcludeFilter || "", y.SaveViewResults = n.SaveViewResults || !1), a.push(y), r.push(...this.getViewRunTimeFieldList(c, o, n, m));
      }
      const u = R`
                query RunViewsQuery ($input: [RunViewGenericInput!]!) {
                RunViews(input: $input) {
                    Results {
                        ID
                        EntityID
                        Data
                    }
                    UserViewRunID
                    RowCount
                    TotalRowCount
                    ExecutionTime
                    Success
                    ErrorMessage
                }
            }`,
        i = await d.ExecuteGQL(u, {
          input: a
        });
      if (i && i.RunViews) {
        const n = i.RunViews;
        for (const [p, f] of n.entries()) f.Results = f.Results.map(y => {
          let l = JSON.parse(y.Data);
          return this.ConvertBackToMJFields(l), l;
        });
        return n;
      }
      return null;
    } catch (a) {
      throw D(a), a;
    }
  }
  async getEntityNameAndUserView(e, s) {
    let a, t;
    if (e.EntityName) a = e.EntityName;else if (e.ViewID) t = await K.GetViewEntity(e.ViewID, s), a = t.Entity;else if (e.ViewName) t = await K.GetViewEntityByName(e.ViewName, s), a = t.Entity;else throw new Error("No EntityName, ViewID or ViewName passed to RunView");
    return {
      entityName: a,
      v: t
    };
  }
  getViewRunTimeFieldList(e, s, a, t) {
    const r = [],
      u = new g();
    if (a.Fields) {
      for (const i of e.PrimaryKeys) a.Fields.find(n => n.trim().toLowerCase() === i.Name.toLowerCase()) === void 0 && r.push(i.Name);
      a.Fields.forEach(i => {
        r.push(u.MapFieldName(i));
      });
    } else if (t) e.Fields.forEach(i => {
      i.IsBinaryFieldType || r.push(u.MapFieldName(i.CodeName));
    });else {
      for (const i of e.PrimaryKeys) r.find(n => n.trim().toLowerCase() === i.Name.toLowerCase()) === void 0 && r.push(i.Name);
      s.Columns.forEach(i => {
        i.hidden === !1 && !r.find(n => n.trim().toLowerCase() === i.EntityField?.Name.trim().toLowerCase()) && i.EntityField && r.push(u.MapFieldName(i.EntityField.CodeName));
      });
    }
    return r;
  }
  get ProviderType() {
    return q.Network;
  }
  async GetRecordChanges(e, s) {
    try {
      const a = {
          EntityName: "Record Changes",
          ExtraFilter: `RecordID = '${s.Values()}' AND Entity = '${e}'`
        },
        t = await this.RunView(a);
      return t ? t.Results.sort((r, u) => r.ChangedAt > u.ChangedAt ? -1 : 1) : null;
    } catch (a) {
      throw D(a), a;
    }
  }
  async GetRecordDependencies(e, s) {
    try {
      const a = R`query GetRecordDependenciesQuery ($entityName: String!, $CompositeKey: CompositeKeyInputType!) {
                GetRecordDependencies(entityName: $entityName, CompositeKey: $CompositeKey) {
                    EntityName
                    RelatedEntityName
                    FieldName
                    CompositeKey {
                        KeyValuePairs {
                            FieldName
                            Value
                        }
                    }
                }
            }`,
        t = {
          entityName: e,
          CompositeKey: {
            KeyValuePairs: this.ensureKeyValuePairValueIsString(s.KeyValuePairs)
          }
        };
      return (await d.ExecuteGQL(a, t))?.GetRecordDependencies;
    } catch (a) {
      throw D(a), a;
    }
  }
  ensureKeyValuePairValueIsString(e) {
    return e.map(s => ({
      FieldName: s.FieldName,
      Value: s.Value.toString()
    }));
  }
  async GetRecordDuplicates(e, s) {
    if (!e) return null;
    const a = R`query GetRecordDuplicatesQuery ($params: PotentialDuplicateRequestType!) {
            GetRecordDuplicates(params: $params) {
                Status
                ErrorMessage
                PotentialDuplicateResult {
                    EntityID
                    DuplicateRunDetailMatchRecordIDs
                    RecordPrimaryKeys {
                        KeyValuePairs {
                            FieldName
                            Value
                    }
                }
                    Duplicates {
                        ProbabilityScore
                        KeyValuePairs {
                            FieldName
                            Value
                        }
                    }
                }
            }
        }`;
    let t = {
      EntityID: e.EntityID,
      EntityDocumentID: e.EntityDocumentID,
      ListID: e.ListID,
      ProbabilityScore: e.ProbabilityScore,
      Options: e.Options,
      RecordIDs: e.RecordIDs.map(u => u.Copy())
    };
    const r = await d.ExecuteGQL(a, {
      params: t
    });
    if (r && r.GetRecordDuplicates) return r.GetRecordDuplicates;
  }
  async MergeRecords(e) {
    const s = this.Entities.find(a => a.Name.trim().toLowerCase() === e.EntityName.trim().toLowerCase());
    if (!s || !s.AllowRecordMerge) throw new Error(`Entity ${e.EntityName} does not allow record merging, check the AllowRecordMerge property in the entity metadata`);
    try {
      const a = R`mutation MergeRecordsMutation ($request: RecordMergeRequest!) {
                MergeRecords(request: $request) {
                    Success
                    OverallStatus
                    RecordMergeLogID
                    RecordStatus {
                        CompositeKey {
                            KeyValuePairs {
                                FieldName
                                Value
                            }
                        }
                        Success
                        RecordMergeDeletionLogID
                        Message
                    }
                }
            }`,
        t = {
          EntityName: e.EntityName,
          SurvivingRecordCompositeKey: {
            KeyValuePairs: this.ensureKeyValuePairValueIsString(e.SurvivingRecordCompositeKey.KeyValuePairs)
          },
          FieldMap: e.FieldMap?.map(u => ({
            FieldName: u.FieldName,
            Value: u.Value.toString()
          })),
          RecordsToMerge: e.RecordsToMerge.map(u => u.Copy())
        };
      return (await d.ExecuteGQL(a, {
        request: t
      }))?.MergeRecords;
    } catch (a) {
      return D(a), {
        Success: !1,
        OverallStatus: a && a.message ? a.message : a,
        RecordStatus: [],
        RecordMergeLogID: "",
        Request: e
      };
    }
  }
  async Save(e, s, a) {
    const t = new _();
    try {
      e.RegisterTransactionPreprocessing();
      const r = {
          input: {}
        },
        u = e.IsSaved ? "Update" : "Create";
      t.StartedAt = new Date(), t.Type = e.IsSaved ? "update" : "create", t.OriginalValues = e.Fields.map(l => ({
        FieldName: l.CodeName,
        Value: l.Value
      })), e.ResultHistory.push(t);
      const i = `${u}${e.EntityInfo.ClassName}`,
        n = e.Fields.filter(l => !l.ReadOnly || l.IsPrimaryKey && e.IsSaved),
        p = new g(),
        f = `                ${i}(input: $input) {
                ${e.Fields.map(l => p.MapFieldName(l.CodeName)).join(`
                    `)}
            }`,
        y = R`mutation ${u}${e.EntityInfo.ClassName} ($input: ${i}Input!) {
                ${f}
            }
            `;
      for (let l = 0; l < n.length; l++) {
        const o = n[l];
        let c = o.Value;
        c && o.EntityFieldInfo.TSType === S.Date && (c = c.getTime()), c && o.EntityFieldInfo.TSType === S.Boolean && typeof c != "boolean" && (c = parseInt(c) !== 0), c == null && o.EntityFieldInfo.AllowsNull == !1 && (o.EntityFieldInfo.DefaultValue != null ? c = o.EntityFieldInfo.DefaultValue : o.FieldType == S.Number || o.FieldType == S.Boolean ? c = 0 : c = ""), r.input[o.CodeName] = c;
      }
      if (u.trim().toLowerCase() === "update") {
        const l = [];
        e.Fields.forEach(o => {
          const c = o.OldValue ? typeof o.OldValue == "string" ? o.OldValue : o.OldValue.toString() : null;
          l.push({
            Key: o.CodeName,
            Value: c
          });
        }), r.input.OldValues___ = l;
      }
      if (e.TransactionGroup) return new Promise((l, o) => {
        const c = [{
          varName: "input",
          inputType: i + "Input!"
        }];
        e.RaiseReadyForTransaction(), e.TransactionGroup.AddTransaction(new B(e, f, r, {
          mutationName: i,
          mutationInputTypes: c
        }, (m, w) => {
          t.EndedAt = new Date(), w && m ? (t.Success = !0, l(this.ConvertBackToMJFields(m))) : (t.Success = !1, t.Message = "Transaction failed", o());
        }));
      });
      {
        const l = await d.ExecuteGQL(y, r);
        if (l && l[u + e.EntityInfo.ClassName]) return t.Success = !0, t.EndedAt = new Date(), this.ConvertBackToMJFields(l[u + e.EntityInfo.ClassName]);
        throw new Error(`Save failed for ${e.EntityInfo.ClassName}`);
      }
    } catch (r) {
      return t.Success = !1, t.EndedAt = new Date(), t.Message = r.response?.errors?.length > 0 ? r.response.errors[0].message : r.message, D(r), null;
    }
  }
  async Load(e, s, a = null, t) {
    try {
      const r = {};
      let u = "",
        i = "";
      for (let l = 0; l < s.KeyValuePairs.length; l++) {
        const o = e.Fields.find(w => w.Name.trim().toLowerCase() === s.KeyValuePairs[l].FieldName.trim().toLowerCase()).EntityFieldInfo,
          c = s.GetValueByIndex(l),
          m = o.GraphQLType;
        if (i.length > 0 && (i += ", "), i += `$${o.CodeName}: ${m}!`, u.length > 0 && (u += ", "), u += `${o.CodeName}: $${o.CodeName}`, o.TSType === S.Number) {
          if (isNaN(s.GetValueByIndex(l))) throw new Error(`Primary Key value ${c} (${o.Name}) is not a valid number`);
          r[o.CodeName] = parseInt(c);
        } else r[o.CodeName] = c;
      }
      const n = a && a.length > 0 ? this.getRelatedEntityString(e.EntityInfo, a) : "",
        p = new g(),
        f = R`query Single${e.EntityInfo.ClassName}${n.length > 0 ? "Full" : ""} (${i}) {
                ${e.EntityInfo.ClassName}(${u}) {
                                    ${e.Fields.filter(l => !l.EntityFieldInfo.IsBinaryFieldType).map(l => l.EntityFieldInfo.Name.trim().toLowerCase().startsWith("__mj_") ? l.CodeName.replace("__mj_", "_mj__") : l.CodeName).join(`
                    `)}
                    ${n}
                }
            }
            `,
        y = await d.ExecuteGQL(f, r);
      return y && y[e.EntityInfo.ClassName] ? this.ConvertBackToMJFields(y[e.EntityInfo.ClassName]) : null;
    } catch (r) {
      return D(r), null;
    }
  }
  ConvertBackToMJFields(e) {
    return new g().ReverseMapFields(e), e;
  }
  getRelatedEntityString(e, s) {
    let a = "";
    for (let t = 0; t < e.RelatedEntities.length; t++) if (s.indexOf(e.RelatedEntities[t].RelatedEntity) >= 0) {
      const r = e.RelatedEntities[t],
        u = this.Entities.find(i => i.ID === r.RelatedEntityID);
      a += `
                ${u.CodeName} {
                    ${u.Fields.map(i => i.CodeName).join(`
                    `)}
                }
                `;
    }
    return a;
  }
  async Delete(e, s, a) {
    const t = new _();
    try {
      e.RegisterTransactionPreprocessing(), t.StartedAt = new Date(), t.Type = "delete", t.OriginalValues = e.Fields.map(o => ({
        FieldName: o.CodeName,
        Value: o.Value
      })), e.ResultHistory.push(t);
      const r = {},
        u = [];
      let i = "",
        n = "",
        p = "";
      for (let o of e.PrimaryKey.KeyValuePairs) {
        const c = e.Fields.find(m => m.Name.trim().toLowerCase() === o.FieldName.trim().toLowerCase());
        r[c.CodeName] = c.Value, u.push({
          varName: c.CodeName,
          inputType: c.EntityFieldInfo.GraphQLType + "!"
        }), i.length > 0 && (i += ", "), i += `${c.CodeName}: $${c.CodeName}`, n.length > 0 && (n += ", "), n += `$${c.CodeName}: ${c.EntityFieldInfo.GraphQLType}!`, p.length > 0 && (p += `
                    `), p += `${c.CodeName}`;
      }
      u.push({
        varName: "options___",
        inputType: "DeleteOptionsInput!"
      }), r.options___ = s || {
        SkipEntityAIActions: !1,
        SkipEntityActions: !1
      };
      const f = "Delete" + e.EntityInfo.ClassName,
        y = R`${f}(${i}, options___: $options___) {
                ${p}
            }
            `,
        l = R`mutation ${f} (${n}, $options___: DeleteOptionsInput!) {
                ${y}
            }
            `;
      if (e.TransactionGroup) return e.RaiseReadyForTransaction(), new Promise((o, c) => {
        e.TransactionGroup.AddTransaction(new B(e, y, r, {
          mutationName: f,
          mutationInputTypes: u
        }, (m, w) => {
          if (t.EndedAt = new Date(), w && m) {
            let N = !0;
            for (const U of e.PrimaryKey.KeyValuePairs) U.Value !== m[U.FieldName] && (N = !1);
            N ? (t.Success = !0, o(!0)) : (t.Success = !1, t.Message = "Transaction failed to commit", c());
          } else t.Success = !1, t.Message = "Transaction failed to commit", c();
        }));
      });
      {
        const o = await d.ExecuteGQL(l, r);
        if (o && o[f]) {
          const c = o[f];
          for (let m of e.PrimaryKey.KeyValuePairs) {
            let w = c[m.FieldName],
              N = m.Value;
            if (typeof N == "number" && (N = N.toString()), typeof w == "number" && (w = w.toString()), N !== w) throw new Error(`Primary key value mismatch in server Delete response. Field: ${m.FieldName}, Original: ${N}, Returned: ${w}`);
          }
          return t.Success = !0, t.EndedAt = new Date(), !0;
        } else throw new Error(`Delete failed for ${e.EntityInfo.Name}: ${e.PrimaryKey.ToString()} `);
      }
    } catch (r) {
      return t.EndedAt = new Date(), t.Success = !1, t.Message = r.response?.errors?.length > 0 ? r.response.errors[0].message : r.message, D(r), !1;
    }
  }
  async GetDatasetByName(e, s) {
    const a = R`query GetDatasetByName($DatasetName: String!, $ItemFilters: [DatasetItemFilterTypeGQL!]) {
            GetDatasetByName(DatasetName: $DatasetName, ItemFilters: $ItemFilters) {
                DatasetID
                DatasetName
                Success
                Status
                LatestUpdateDate
                Results
            }
        }`,
      t = await d.ExecuteGQL(a, {
        DatasetName: e,
        ItemFilters: s
      });
    return t && t.GetDatasetByName && t.GetDatasetByName.Success ? {
      DatasetID: t.GetDatasetByName.DatasetID,
      DatasetName: t.GetDatasetByName.DatasetName,
      Success: t.GetDatasetByName.Success,
      Status: t.GetDatasetByName.Status,
      LatestUpdateDate: new Date(t.GetDatasetByName.LatestUpdateDate),
      Results: JSON.parse(t.GetDatasetByName.Results)
    } : {
      DatasetID: "",
      DatasetName: e,
      Success: !1,
      Status: "Unknown",
      LatestUpdateDate: null,
      Results: null
    };
  }
  async GetDatasetStatusByName(e, s) {
    const a = R`query GetDatasetStatusByName($DatasetName: String!, $ItemFilters: [DatasetItemFilterTypeGQL!]) {
            GetDatasetStatusByName(DatasetName: $DatasetName, ItemFilters: $ItemFilters) {
                DatasetID
                DatasetName
                Success
                Status
                LatestUpdateDate
                EntityUpdateDates
            }
        }`,
      t = await d.ExecuteGQL(a, {
        DatasetName: e,
        ItemFilters: s
      });
    return t && t.GetDatasetStatusByName && t.GetDatasetStatusByName.Success ? {
      DatasetID: t.GetDatasetStatusByName.DatasetID,
      DatasetName: t.GetDatasetStatusByName.DatasetName,
      Success: t.GetDatasetStatusByName.Success,
      Status: t.GetDatasetStatusByName.Status,
      LatestUpdateDate: new Date(t.GetDatasetStatusByName.LatestUpdateDate),
      EntityUpdateDates: JSON.parse(t.GetDatasetStatusByName.EntityUpdateDates)
    } : {
      DatasetID: "",
      DatasetName: e,
      Success: !1,
      Status: "Unknown",
      LatestUpdateDate: null,
      EntityUpdateDates: null
    };
  }
  async CreateTransactionGroup() {
    return new $();
  }
  async GetRecordFavoriteStatus(e, s, a) {
    if (!a.Validate().IsValid) return !1;
    const r = this.Entities.find(n => n.Name === s);
    if (!r) throw new Error(`Entity ${s} not found in metadata`);
    const u = R`query GetRecordFavoriteStatus($params: UserFavoriteSearchParams!) {
            GetRecordFavoriteStatus(params: $params) {
                Success
                IsFavorite
            }
        }`,
      i = await d.ExecuteGQL(u, {
        params: {
          UserID: e,
          EntityID: r.ID,
          CompositeKey: {
            KeyValuePairs: this.ensureKeyValuePairValueIsString(a.KeyValuePairs)
          }
        }
      });
    if (i && i.GetRecordFavoriteStatus && i.GetRecordFavoriteStatus.Success) return i.GetRecordFavoriteStatus.IsFavorite;
  }
  async SetRecordFavoriteStatus(e, s, a, t, r) {
    const u = this.Entities.find(p => p.Name === s);
    if (!u) throw new Error(`Entity ${s} not found in metadata`);
    const i = R`mutation SetRecordFavoriteStatus($params: UserFavoriteSetParams!) {
            SetRecordFavoriteStatus(params: $params){
                Success
            }
        }`,
      n = await d.ExecuteGQL(i, {
        params: {
          UserID: e,
          EntityID: u.ID,
          CompositeKey: {
            KeyValuePairs: this.ensureKeyValuePairValueIsString(a.KeyValuePairs)
          },
          IsFavorite: t
        }
      });
    if (n && n.SetRecordFavoriteStatus !== null) return n.SetRecordFavoriteStatus.Success;
  }
  async GetEntityRecordName(e, s) {
    if (!e || !s || s.KeyValuePairs?.length === 0) return null;
    const a = R`query GetEntityRecordNameQuery ($EntityName: String!, $CompositeKey: CompositeKeyInputType!) {
            GetEntityRecordName(EntityName: $EntityName, CompositeKey: $CompositeKey) {
                Success
                Status
                RecordName
            }
        }`,
      t = await d.ExecuteGQL(a, {
        EntityName: e,
        CompositeKey: {
          KeyValuePairs: this.ensureKeyValuePairValueIsString(s.KeyValuePairs)
        }
      });
    if (t && t.GetEntityRecordName && t.GetEntityRecordName.Success) return t.GetEntityRecordName.RecordName;
  }
  async GetEntityRecordNames(e) {
    if (!e) return null;
    const s = R`query GetEntityRecordNamesQuery ($info: [EntityRecordNameInput!]!) {
            GetEntityRecordNames(info: $info) {
                Success
                Status
                CompositeKey {
                    KeyValuePairs {
                        FieldName
                        Value
                    }
                }
                EntityName
                RecordName
            }
        }`,
      a = await d.ExecuteGQL(s, {
        info: e.map(t => ({
          EntityName: t.EntityName,
          CompositeKey: {
            KeyValuePairs: this.ensureKeyValuePairValueIsString(t.CompositeKey.KeyValuePairs)
          }
        }))
      });
    if (a && a.GetEntityRecordNames) return a.GetEntityRecordNames;
  }
  static async ExecuteGQL(e, s, a = !0) {
    try {
      return await d._client.request(e, s);
    } catch (t) {
      if (t && t.response && t.response.errors?.length > 0) {
        if (t.response.errors[0]?.extensions?.code?.toUpperCase().trim() === "JWT_EXPIRED") {
          if (a) return await d.RefreshToken(), await d.ExecuteGQL(e, s, !1);
          throw D("JWT_EXPIRED and refreshTokenIfNeeded is false"), t;
        } else throw t;
      } else throw D(t), t;
    }
  }
  static async RefreshToken() {
    if (d._configData.Data.RefreshTokenFunction) {
      const e = await d._configData.Data.RefreshTokenFunction();
      if (e) d._configData.Token = e, d._client = this.CreateNewGraphQLClient(d._configData.URL, d._configData.Token, d._sessionId);else throw new Error("Refresh token function returned null or undefined token");
    } else throw new Error("No refresh token function provided");
  }
  static CreateNewGraphQLClient(e, s, a) {
    return new k(e, {
      headers: {
        authorization: "Bearer " + s,
        "x-session-id": a
      }
    });
  }
  userInfoString() {
    return this.infoString(new M(null, null));
  }
  userRoleInfoString() {
    return this.infoString(new b(null));
  }
  infoString(e) {
    let s = "";
    const a = Object.keys(e);
    for (const t of a) t.startsWith("__mj_") ? s += t.replace("__mj_", "_mj__") + `
            ` : t.startsWith("_") || (s += t + `
            `);
    return s;
  }
  get LocalStorageProvider() {
    return this._localStorageProvider || (this._localStorageProvider = new x()), this._localStorageProvider;
  }
  get Metadata() {
    return this;
  }
  PushStatusUpdates(e = null) {
    e || (e = this.sessionId), this._wsClient || (this._wsClient = te({
      url: this.ConfigData.WSURL,
      connectionParams: {
        Authorization: "Bearer " + this.ConfigData.Token
      }
    }));
    const s = this._pushStatusRequests.find(r => r.sessionId === e);
    if (s) return s.observable;
    const a = R`subscription StatusUpdates($sessionId: String!) {
            statusUpdates(sessionId: $sessionId) {
                date
                message
                sessionId
            }
        }
        `,
      t = new ee(r => (this._wsClient.subscribe({
        query: a,
        variables: {
          sessionId: e
        }
      }, {
        next: u => r.next(u.data.statusUpdates),
        error: u => r.error(u),
        complete: () => r.complete()
      }), () => {
        console.log("would unsub here");
      }));
    return this._pushStatusRequests.push({
      sessionId: e,
      observable: t
    }), t;
  }
};
E(d, "GraphQLDataProvider");
let I = d;
const v = class v {
  constructor() {
    this._localStorage = {};
  }
  async getItem(e) {
    return new Promise(s => {
      this._localStorage.hasOwnProperty(e) ? s(this._localStorage[e]) : s(null);
    });
  }
  async setItem(e, s) {
    return new Promise(a => {
      this._localStorage[e] = s, a();
    });
  }
  async remove(e) {
    return new Promise(s => {
      this._localStorage.hasOwnProperty(e) && delete this._localStorage[e], s();
    });
  }
};
E(v, "BrowserStorageProviderBase");
let C = v;
const re = "MJ_Metadata",
  h = "Metadata_KVPairs",
  P = class P extends C {
    constructor() {
      super(), this.dbPromise = Z(re, 1, {
        upgrade(e) {
          e.objectStoreNames.contains(h) || e.createObjectStore(h);
        }
      });
    }
    async setItem(e, s) {
      const t = (await this.dbPromise).transaction(h, "readwrite");
      await t.objectStore(h).put(s, e), await t.done;
    }
    async getItem(e) {
      return await (await this.dbPromise).transaction(h).objectStore(h).get(e);
    }
    async remove(e) {
      const a = (await this.dbPromise).transaction(h, "readwrite");
      await a.objectStore(h).delete(e), await a.done;
    }
  };
E(P, "BrowserIndexedDBStorageProvider");
let x = P;
const L = class L extends J {
  async HandleSubmit(e) {
    let s = "",
      a = "";
    const t = {};
    for (let i = 0; i < e.length; i++) {
      const n = e[i];
      let p = n.Instruction;
      if (n.Vars) {
        const f = Object.keys(n.Vars);
        for (let y = 0; y < f.length; y++) {
          const l = f[y],
            o = `${l}_${i}`;
          t[o] = n.Vars[l];
          const c = new RegExp("\\$" + l, "g");
          p = p.replace(c, "$" + o);
          const m = n.ExtraData.mutationInputTypes.find(w => w.varName === l)?.inputType;
          a += `$${o}: ${m} 
`;
        }
      }
      s += `mutation_${i}: ` + p + `
`;
    }
    s = `mutation TransactionGroup(${a}){ 
` + s + `
}`;
    const r = await I.ExecuteGQL(s, t),
      u = [];
    for (let i = 0; i < e.length; i++) {
      const n = r[`mutation_${i}`],
        p = e[i];
      u.push(new j(p, n, n !== null));
    }
    return u;
  }
};
E(L, "GraphQLTransactionGroup");
let $ = L;
async function ne(V) {
  const e = new I();
  return W.Provider = e, z.Provider = e, H.Provider = e, X.Provider = e, Y.Provider = e, await e.Config(V), ae.Instance.RaiseEvent({
    event: se.LoggedIn,
    eventCode: null,
    component: this,
    args: null
  }), e;
}
E(ne, "setupGraphQLClient");
export { g as FieldMapper, I as GraphQLDataProvider, F as GraphQLProviderConfigData, we as gql, ne as setupGraphQLClient };
