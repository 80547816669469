"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemplateEngineServer = exports.TemplateEntityLoader = void 0;
const nunjucks = __importStar(require("nunjucks"));
const global_1 = require("@memberjunction/global");
const TemplateExtensionBase_1 = require("./extensions/TemplateExtensionBase");
const templates_base_types_1 = require("@memberjunction/templates-base-types");
/**
 * This class extends the nunjucks loader to allow adding templates directly to the loader
 */
class TemplateEntityLoader extends nunjucks.Loader {
  constructor() {
    super(...arguments);
    this.templates = {};
  }
  /**
   * Add a new template to the loader
   * @param templateId
   * @param template
   */
  AddTemplate(templateId, template) {
    this.templates[templateId] = template;
  }
  /**
   * This method is required to be implemented by a subclass of Loader. It is used to get the source of a template by name.
   * @param name - this is actually the templateId but nunjucks calls it name and makes it a string, we handle it as a number internally
   * @returns
   */
  getSource(name, callBack) {
    const templateId = Number(name);
    const template = this.templates[templateId];
    if (template) {
      callBack({
        src: template.Get,
        path: templateId,
        noCache: true
      });
    }
  }
}
exports.TemplateEntityLoader = TemplateEntityLoader;
/**
 * TemplateEngine is used for accessing template metadata/caching it, and rendering templates
 */
class TemplateEngineServer extends templates_base_types_1.TemplateEngineBase {
  constructor() {
    super(...arguments);
    this._oneTimeLoadingComplete = false;
    /**
     * Cache for templates that have been created by nunjucks so we don't have to create them over and over
     */
    this._templateCache = new Map();
  }
  static get Instance() {
    return super.getInstance();
  }
  async AdditionalLoading(contextUser) {
    // pass along the call to our base class so it can do whatever it wants
    await super.AdditionalLoading(contextUser);
    // clear our template cache as we are going to reload all of the templates
    this.ClearTemplateCache();
    if (!this._oneTimeLoadingComplete) {
      this._oneTimeLoadingComplete = true; // flag to make sure we don't do this again
      // do this after the templates are loaded and doing it inside AdditionalLoading() ensures it is done after the templates are loaded and
      // only done once
      this._templateLoader = new TemplateEntityLoader();
      this._nunjucksEnv = new nunjucks.Environment(this._templateLoader, {
        autoescape: true,
        dev: true
      });
      // get all of the extensions that are registered and register them with nunjucks
      const extensions = global_1.MJGlobal.Instance.ClassFactory.GetAllRegistrations(TemplateExtensionBase_1.TemplateExtensionBase);
      if (extensions && extensions.length > 0) {
        for (const ext of extensions) {
          const instance = new ext.SubClass(contextUser);
          this._nunjucksEnv.addExtension(ext.Key, instance);
        }
      }
    }
  }
  SetupNunjucks() {
    this._templateLoader = new TemplateEntityLoader();
    this._nunjucksEnv = new nunjucks.Environment(this._templateLoader, {
      autoescape: true,
      dev: true
    });
  }
  AddTemplate(templateEntity) {
    this._templateLoader.AddTemplate(templateEntity.ID, templateEntity);
  }
  /**
   * Renders a template with the given data.
   * @param templateEntity the template object to render
   * @param templateContent the template content item (within the template)
   * @param data
   */
  async RenderTemplate(templateEntity, templateContent, data, SkipValidation) {
    try {
      if (!templateContent) {
        return {
          Success: false,
          Output: null,
          Message: 'templateContent variable is required'
        };
      }
      if (!templateContent.TemplateText) {
        return {
          Success: false,
          Output: null,
          Message: 'TemplateContent.TemplateText variable is required'
        };
      }
      if (!SkipValidation) {
        const valResult = templateEntity.ValidateTemplateInput(data);
        if (!valResult.Success) {
          return {
            Success: false,
            Output: null,
            Message: valResult.Errors.map(error => {
              return error.Message;
            }).join(', ')
          };
        }
      }
      const template = this.getNunjucksTemplate(templateContent.ID, templateContent.TemplateText);
      const result = await this.renderTemplateAsync(template, data);
      return {
        Success: true,
        Output: result,
        Message: undefined
      };
    } catch (e) {
      return {
        Success: false,
        Output: null,
        Message: e.message
      };
    }
  }
  /**
   * This method is responsible for creating a new Nunjucks template, caching it, and returning it.
   * If the templateContentId already had a template created, it will return that template from the cache.
   * @param templateId
   * @param templateText
   */
  getNunjucksTemplate(templateContentId, templateText) {
    let template = this._templateCache.get(templateContentId);
    if (!template) {
      template = new nunjucks.Template(templateText, this._nunjucksEnv);
      this._templateCache.set(templateContentId, template);
    }
    return template;
  }
  ClearTemplateCache() {
    this._templateCache.clear();
  }
  /**
   * Promisifies the Nunjucks template rendering process.
   * @param template the Nunjucks template object
   * @param data the data to render the template with
   */
  async renderTemplateAsync(template, data) {
    return new Promise((resolve, reject) => {
      template.render(data, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }
}
exports.TemplateEngineServer = TemplateEngineServer;
