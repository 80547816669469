"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuditLogTypeInfo = exports.AuthorizationEvaluator = exports.AuthorizationRoleInfo = exports.AuthorizationRoleType = exports.AuthorizationInfo = exports.RowLevelSecurityFilterInfo = exports.RoleInfo = exports.UserRoleInfo = exports.UserInfo = void 0;
const baseInfo_1 = require("./baseInfo");
const metadata_1 = require("./metadata");
/**
 * Information about a single user
 */
class UserInfo extends baseInfo_1.BaseInfo {
  get UserRoles() {
    return this._UserRoles;
  }
  constructor(md, initData = null) {
    super();
    this.ID = null;
    /* Name of the user that is used in various places in UIs/etc, can be anything including FirstLast, Email, a Handle, etc */
    this.Name = null;
    this.FirstName = null;
    this.LastName = null;
    this.Title = null;
    this.Email = null;
    this.Type = null;
    this.IsActive = null;
    this.LinkedRecordType = null;
    this.EmployeeID = null;
    this.LinkedEntityID = null;
    this.LinkedEntityRecordID = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    this.FirstLast = null;
    this.EmployeeFirstLast = null;
    this.EmployeeEmail = null;
    this.EmployeeTitle = null;
    this.EmployeeSupervisor = null;
    this.EmployeeSupervisorEmail = null;
    this._UserRoles = [];
    this.copyInitData(initData);
    if (initData) this.SetupUserRoles(md, initData.UserRoles || initData._UserRoles);
  }
  SetupUserRoles(md, userRoles) {
    if (userRoles) {
      const mdRoles = md.Roles;
      this._UserRoles = [];
      for (let i = 0; i < userRoles.length; i++) {
        // 
        const uri = new UserRoleInfo(userRoles[i]);
        this._UserRoles.push(uri);
        const match = mdRoles.find(r => r.ID == uri.RoleID);
        if (match) uri._setRole(match);
      }
    }
  }
}
exports.UserInfo = UserInfo;
/**
 * Information about a role that a user is linked to
 */
class UserRoleInfo extends baseInfo_1.BaseInfo {
  get RoleInfo() {
    return this._RoleInfo;
  }
  _setRole(role) {
    this._RoleInfo = role;
  }
  constructor(initData) {
    super();
    this.UserID = null;
    this.RoleID = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    // virtual fields - returned by the database VIEW
    this.User = null;
    this.Role = null;
    this._RoleInfo = null;
    this.copyInitData(initData);
  }
}
exports.UserRoleInfo = UserRoleInfo;
/**
 * Information about a single role
 */
class RoleInfo extends baseInfo_1.BaseInfo {
  constructor(initData) {
    super();
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.DirectoryID = null;
    this.SQLName = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this.copyInitData(initData);
  }
}
exports.RoleInfo = RoleInfo;
class RowLevelSecurityFilterInfo extends baseInfo_1.BaseInfo {
  constructor(initData) {
    super();
    this.ID = null;
    this.Name = null;
    this.Description = null;
    this.FilterText = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this.copyInitData(initData);
  }
  MarkupFilterText(user) {
    let ret = this.FilterText;
    if (user) {
      const keys = Object.keys(user);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const val = user[key];
        if (val && typeof val == 'string') {
          ret = ret.replace(new RegExp(`{{User${key}}}`, 'g'), val);
        }
      }
    }
    return ret;
  }
}
exports.RowLevelSecurityFilterInfo = RowLevelSecurityFilterInfo;
/**
 * Represents detailed information about an authorization in the system,
 * including its relationship to roles and the ability for a given user to execute actions that require this authorization.
 *
 **/
class AuthorizationInfo extends baseInfo_1.BaseInfo {
  get Roles() {
    return this._AuthorizationRoles;
  }
  constructor(md, initData = null) {
    super();
    this.ID = null;
    /**
     * The unique identifier for the parent authorization, if applicable.
     * @type {string|null}
     */
    this.ParentID = null;
    this.Name = null;
    /**
     * Indicates whether the authorization is active.
     * @type {boolean|null}
     */
    this.IsActive = null;
    /**
     * Determines whether actions under this authorization will be logged for audit purposes.
     * @type {boolean|null}
     */
    this.UseAuditLog = null;
    this.Description = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this._AuthorizationRoles = [];
    this.copyInitData(initData);
    if (initData) this.SetupAuthorizationRoles(md, initData.AuthorizationRoles || initData._AuthorizationRoles);
  }
  /**
   * Sets up the roles associated with this authorization using the provided metadata and initial data.
   *
   * @param {IMetadataProvider} md - The metadata provider to fetch role information.
   * @param {AuthorizationRoleInfo[]} authorizationRoles - An array of `AuthorizationRoleInfo` instances or equivalent data to be associated with this authorization.
   */
  SetupAuthorizationRoles(md, authorizationRoles) {
    if (authorizationRoles) {
      const mdRoles = md.Roles;
      this._AuthorizationRoles = [];
      for (let i = 0; i < authorizationRoles.length; i++) {
        // 
        const ari = new AuthorizationRoleInfo(authorizationRoles[i]);
        this._AuthorizationRoles.push(ari);
        const match = mdRoles.find(r => r.ID === ari.RoleID);
        if (match) ari._setRole(match);
      }
    }
  }
  /**
   * Determines if a given user can execute actions under this authorization based on their roles.
   *
   * @param {UserInfo} user - The user to check for execution rights.
   * @returns {boolean} True if the user can execute actions under this authorization, otherwise false.
   */
  UserCanExecute(user) {
    if (this.IsActive && user && user.UserRoles) {
      for (let i = 0; i < user.UserRoles.length; i++) {
        const matchingRole = this.Roles.find(r => r.ID === user.UserRoles[i].RoleID);
        if (matchingRole) return true; // as soon as we find a single matching role we can bail out as the user can execute
      }
    }
    return false;
  }
  /**
   * Determines if a given role can execute actions under this authorization.
   *
   * @param {RoleInfo} role - The role to check for execution rights.
   * @returns {boolean} True if the role can execute actions under this authorization, otherwise false.
   */
  RoleCanExecute(role) {
    if (this.IsActive) {
      return this.Roles.find(r => r.ID === role.ID) != null;
    }
    return false;
  }
}
exports.AuthorizationInfo = AuthorizationInfo;
exports.AuthorizationRoleType = {
  Allow: 'Allow',
  Deny: 'Deny'
};
class AuthorizationRoleInfo extends baseInfo_1.BaseInfo {
  get RoleInfo() {
    return this._RoleInfo;
  }
  AuthorizationType() {
    return this.Type.trim().toLowerCase() === 'allow' ? exports.AuthorizationRoleType.Allow : exports.AuthorizationRoleType.Deny;
  }
  _setRole(role) {
    this._RoleInfo = role;
  }
  constructor(initData) {
    super();
    this.ID = null;
    this.AuthorizationID = null;
    this.RoleID = null;
    this.Type = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this._RoleInfo = null;
    this.copyInitData(initData);
  }
}
exports.AuthorizationRoleInfo = AuthorizationRoleInfo;
/**
 * This class handles the execution of various types of authorization evaluations and contains utility methods as well.
 */
class AuthorizationEvaluator {
  /**
   * Determines if the current user can execute actions under the provided authorization.
   * @param auth
   * @returns
   */
  CurrentUserCanExecute(auth) {
    const md = new metadata_1.Metadata();
    if (!md.CurrentUser) throw new Error('No current user is set for authorization evaluation');
    return this.UserCanExecute(auth, md.CurrentUser);
  }
  /**
   * Determines if a given user can execute actions under the provided authorization.
   *
   * @param {AuthorizationInfo} auth - The authorization to check for execution rights.
   * @param {UserInfo} user - The user to check for execution rights.
   * @returns {boolean} True if the user can execute actions under the authorization, otherwise false.
   */
  UserCanExecute(auth, user) {
    return auth.UserCanExecute(user);
  }
  /**
   * Returns an array of authorizations that a given user can execute based on their roles.
   */
  GetUserAuthorizations(user) {
    const md = new metadata_1.Metadata();
    const ret = [];
    if (user && user.UserRoles) {
      for (const a of md.Authorizations) {
        // for each system authorization, check to see if any of our roles can execute it
        if (a.UserCanExecute(user)) ret.push(a);
      }
      return ret;
    } else throw new Error('User must be provided to evaluate authorizations');
  }
}
exports.AuthorizationEvaluator = AuthorizationEvaluator;
class AuditLogTypeInfo extends baseInfo_1.BaseInfo {
  constructor(initData) {
    super();
    this.ID = null;
    this.ParentID = null;
    this.Name = null;
    this.Description = null;
    this.AuthorizationName = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    this.copyInitData(initData);
  }
}
exports.AuditLogTypeInfo = AuditLogTypeInfo;
