
<ng-container *ngIf="auth.isAuthenticated$ | async">
    <div *ngIf="loading">
      <div class="gg-loader">
        <img src="../../assets/loader.gif"/>
    </div>
    </div>
    <div *ngIf="!loading" class="interior-component">
        <div class="breadcrumbWrapper">
            <div class="container-large">
                <div class="breadcrumb">
                    <a routerLink="/">
                        <kendo-icon name="home"></kendo-icon>
                    </a>
                    <kendo-icon name="arrow-chevron-right"></kendo-icon><a routerLink="/trends">Revenue Trends</a>
                    <kendo-icon name="arrow-chevron-right"></kendo-icon><a class="active">Tax Return Details</a>
                </div>
                <div class="back-sec">
                    <a routerLink="/trends">
                    <kendo-icon name="arrow-chevron-left"></kendo-icon>
                    Back
                    </a>
                </div>
            </div>
        </div>
      
        <section class="salary-info">
            <div class="container-large">
                <div class="salary-header">
                    <h4>Tax Return Details</h4>
                    <p>{{latestResult?.RowCount}} results</p>
                    <!-- <div class="content-header" *ngIf="latestResult && latestResult.TotalRowCount > latestResult.RowCount">
                        <p>Showing {{latestResult.RowCount}} of {{latestResult.TotalRowCount}} results, you are limited to viewing {{latestResult.RowCount}} rows with your usage plan.</p>
                    </div>
                    <div class="content-header" *ngIf="latestResult && latestResult.TotalRowCount === latestResult.RowCount">
                        <p>Showing {{latestResult.RowCount}} rows.</p>
                    </div> -->
                </div>
                <div class="grid-container">
                    <kendo-grid
                        *ngIf="!loading"
                        [kendoGridBinding]="gridData"
                        [sortable]="true"
                    >
                        <kendo-grid-column
                            field="TaxYear"
                            title="Year"
                            [width]="75"
                        >
                        </kendo-grid-column>
    
                        <kendo-grid-column
                            field="Name"
                            title="Organization"
                            [width]="200"
                        >
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div >
                                    <a class="org-link" [routerLink]="'/orgs/' + dataItem.TaxID">{{dataItem.Name}}</a><br/> 
                                    <span style="font-size: smaller;">{{ dataItem.OrgSizeRevenue }} (by revenue)</span><br/>
                                    <span style="font-size: smaller;">{{ dataItem.OrgSizeEmployees }} (by # employees)</span>
                                </div>

                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column
                            field="TotalRevenue"
                            title="Revenue"
                            [width]="100"
                            [format]="{ style: 'currency', currency: 'USD', minimumFractionDigits: 0 }"
                        >
                        </kendo-grid-column> 

                        <kendo-grid-column
                            field="NumberEmployees"
                            title="# Employees"
                            [width]="120"
                            [format]="{ style: 'decimal', minimumFractionDigits: 0 }"
                        >
                        </kendo-grid-column> 

                        <kendo-grid-column
                            field="Industry"
                            title="Industry"
                            [width]="100"
                        >
                        </kendo-grid-column>
                        <kendo-grid-column
                            field="MetroArea"
                            title="Metro"
                            [width]="135"
                        >
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div>
                                    {{dataItem.MetroArea}}<br/> 
                                    <span style="font-size: smaller;">{{ dataItem.MetroAreaSize }}</span><br/>
                                    <span style="font-size: smaller;">{{ dataItem.MetroAreaPopulation | number:'1.0-0' }}</span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </section>
    </div>
</ng-container>