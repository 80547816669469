export const ARC = "arc";
export const ARROW_UP = "ArrowUp";
export const ARROW_DOWN = "ArrowDown";
export const ARROW_LEFT = "ArrowLeft";
export const ARROW_RIGHT = "ArrowRight";
export const TAB = "Tab";
export const ARIA_ACTIVE_DESCENDANT = "aria-activedescendant";
export const AXIS_LABEL_CLICK = "axisLabelClick";
export const BLACK = "#000";
export const BOTTOM = "bottom";
export const CENTER = "center";
export const CIRCLE = "circle";
export const COORD_PRECISION = 3;
export const CROSS = "cross";
export const DATE = "date";
export const DEFAULT_FONT = "12px sans-serif";
export const DEFAULT_HEIGHT = 400;
export const DEFAULT_PRECISION = 10;
export const DEFAULT_WIDTH = 600;
export const END = "end";
export const ENTER = "Enter";
export const ESCAPE = "Escape";
export const FORMAT_REGEX = /\{\d+:?/;
export const HEIGHT = "height";
export const HIGHLIGHT_ZINDEX = 100;
export const INSIDE = "inside";
export const INHERIT = "inherit";
export const LEFT = "left";
export const MAX_VALUE = Number.MAX_VALUE;
export const MIN_VALUE = -Number.MAX_VALUE;
export const NONE = "none";
export const NOTE_CLICK = "noteClick";
export const NOTE_HOVER = "noteHover";
export const NOTE_LEAVE = "noteLeave";
export const OBJECT = "object";
export const OUTSIDE = "outside";
export const RIGHT = "right";
export const ROUNDED_RECT = "roundedRect";
export const START = "start";
export const STRING = "string";
export const TOP = "top";
export const TRIANGLE = "triangle";
export const SQUARE = "square";
export const RECT = "rect";
export const VALUE = "value";
export const WHITE = "#fff";
export const WIDTH = "width";
export const X = "x";
export const Y = "y";
export const DEFAULT_SERIES_OPACITY = 1;
export const POINTER = "pointer";
export const HORIZONTAL = "horizontal";
export const VERTICAL = "vertical";