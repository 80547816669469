import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Metadata, RunView, RunViewParams } from '@memberjunction/core';
import { ContactLevelEntity, ContactRoleEntity } from 'mj_generatedentities';
import { SharedService } from '../shared-service';
import { SharedData } from '../shared-data';

@Component({
  selector: 'app-role-level',
  templateUrl: './role-level.component.html',
  styleUrls: ['./role-level.component.css']
})
export class RoleLevelComponent implements OnInit {
  public roleId: number | null = null;
  public levelId: number | null = null;
  public roleRecord: ContactRoleEntity | undefined = undefined;
  public levelRecord: ContactLevelEntity | undefined = undefined;
  public loaded: boolean = false;
  public taxData: any[] = [];
  public taxYears: number[] = [];

  constructor(private route: ActivatedRoute, private router: Router, private sharedService: SharedService, private sharedData: SharedData) { 
    this.route.params.subscribe(params => {
      const roleId = params['roleId'];
      if (roleId !== undefined && roleId !== null && !this.roleId) 
        this.roleId = parseInt(roleId);

        const levelId = params['levelId'];
        if (levelId !== undefined && levelId !== null && !this.levelId) 
          this.levelId = parseInt(levelId);
      });
  }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    this.sharedService.setupComplete$.subscribe(async (isComplete) => {
      if (isComplete) {
        if (this.roleId !== null && this.roleId !== undefined && this.levelId !== null && this.levelId !== undefined) {
          if (this.roleRecord === null || this.roleRecord === undefined) {
            // get it from the SharedData cache
            this.roleRecord = this.sharedData.ContactRoles.find(r => r.ID === this.roleId);
          }
          if (this.levelRecord === null || this.levelRecord === undefined) {
            // get it from the SharedData cache
            this.levelRecord = this.sharedData.ContactLevels.find(r => r.ID === this.levelId);
          }
          if (this.roleRecord && this.levelRecord) {
            // const rv = new RunView();
    
            // const params: RunViewParams = {
            //   EntityName: "Tax Return Compensation Aggregations",
            //   ExtraFilter: `ContactRole='${this.roleRecord.Name}' AND ContactLevel='${this.levelRecord.Name}'` 
            // }
            // const data = await rv.RunView(params)
            const r = this.roleRecord.Name;
            const l = this.levelRecord.Name;
            const data = this.sharedData.TaxReturnComp.filter(t => t.ContactRole === r && t.ContactLevel === l)
            this.taxData = data
            this.taxData.sort((a, b) => a.TaxYear - b.TaxYear);
            this.taxYears = this.taxData.map(data => data.TaxYear);
            this.loaded = true;
          }        
        }
      }
    });
  }


  public getYearlyAverageSalaries() {
    // Group data by TaxYear and calculate the average salary
    const groupedData: any = {};
    for (let data of this.taxData) {
        if (groupedData[data.TaxYear]) {
            groupedData[data.TaxYear].totalComp += data.AverageComp * data.RecordCount;
            groupedData[data.TaxYear].totalCount += data.RecordCount;
        } else {
            groupedData[data.TaxYear] = {
                totalComp: data.AverageComp * data.RecordCount,
                totalCount: data.RecordCount
            };
        }
    }

    // Compute average compensation and record count for each year
    const averages = Object.keys(groupedData).map(year => ({
        year: +year, // + is used to convert string to number
        averageComp: groupedData[year].totalComp / groupedData[year].totalCount,
        recordCount: groupedData[year].totalCount
    }));

    // Sort the data by the year
    averages.sort((a, b) => a.year - b.year);

    return averages;
  }

  labelContent(e: any): string {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    return `${formatter.format(e.dataItem.averageComp)} (${e.dataItem.recordCount} records)`;
  }

  


}
