<div class="k-overlay"></div>
<div class="filter-window">
<kendo-window class="kendo-window-hide-restore" title="Filters" [resizable]="false" [minWidth]="250" (close)="close()"
    state="maximized" [width]="450">
    <div class="k-d-flex k-flex-col k-justify-content-between k-h-full custom-dialog-body">
        <div class="dialog-content">
            <div class="drop-menu">
                <label [for]="filterMetroAreaSize">Metro Size</label>
                <kendo-combobox 
                    #filterMetroAreaSize 
                    class="filter-item-large"
                    [data]="sharedData.MetroAreaSizes" 
                    textField="NameWithRange" 
                    valueField="Name"
                    placeholder="Metro Size" 
                    [(value)]="data.selectedMetroAreaSize"
                >
                </kendo-combobox>
            </div>
            <div class="drop-menu">
                <label [for]="filterIndustry">Industry</label>
                <kendo-combobox 
                    #filterIndustry 
                    class="filter-item-large" 
                    [data]="sharedData.Industries"
                    textField="Name" 
                    valueField="Name" 
                    placeholder="Industry" 
                    [(value)]="data.selectedIndustry"
                >
                </kendo-combobox>
            </div>
            <div class="drop-menu">
                <label [for]="filterOrgSizeEmployees">Org Size/Employees</label>
                <kendo-combobox 
                    #filterOrgSizeEmployees 
                    class="filter-item-large"
                    [data]="sharedData.OrgEmployeeSizes" 
                    textField="NameWithRange" 
                    valueField="Name"
                    placeholder="Org Size/# Employees" 
                    [(value)]="data.selectedOrgSizeEmployees"
                >
                </kendo-combobox>
            </div>
            <div class="drop-menu">
                <label [for]="filterOrgSizeRevenue">Org Size/Revenue</label>
                <kendo-combobox 
                    #filterOrgSizeRevenue 
                    class="filter-item-large"
                    [data]="sharedData.OrgRevenueSizes" 
                    textField="NameWithRange"
                    valueField="Name"
                    placeholder="Org Size/Revenue" 
                    [(value)]="data.selectedOrgSizeRevenue"
                >
                </kendo-combobox>
            </div>
            <div class="drop-menu" *ngIf="showPositionLevel">
                <label [for]="filterContactLevel">Position Level</label>
                <kendo-combobox 
                    #filterContactLevel 
                    class="filter-item-large"
                    [data]="sharedData.ContactLevels" 
                    textField="Name" 
                    valueField="Name"
                    placeholder="Position Level"
                    [(value)]="data.selectedContactLevel"
                >
                </kendo-combobox>
            </div>
            <div class="drop-menu">
                <label [for]="date">Date</label>
                <div class="filter-tax-years" #date>
                    <kendo-rangeslider 
                        [(ngModel)]="initialData.selectedTaxYears" 
                        [min]="data.years[0]"
                        [max]="data.years[1]" 
                        [smallStep]="1" 
                        [title]="yearTitles"
                        [largeStep]="1"
                        class="filter-tax-years-slider "
                    ></kendo-rangeslider>
                </div>
            </div>
            <div class="drop-menu">
                <label class="configuration-section-text">Group By</label>
                <kendo-buttongroup selection="single" class="filter-item">
                    <button 
                        *ngIf="showTaxYearGroup" 
                        kendoButton 
                        [selected]="data.groupByField === 'TaxYear'"
                        [toggleable]="true"
                        (click)="handleGroupBy('TaxYear', 'Tax Year')"
                    >Tax Year</button>
                    <button 
                        kendoButton 
                        [selected]="data.groupByField === 'OrgSizeRevenue'" 
                        [toggleable]="true"
                        [disabled]="!!data.selectedOrgSizeRevenue"
                        (click)="handleGroupBy('OrgSizeRevenue', 'Org Size/Revenue')"
                    >Org Size/Revenue</button>
                    <button 
                        kendoButton 
                        [selected]="data.groupByField === 'OrgSizeEmployees'" 
                        [toggleable]="true"
                        [disabled]="!!data.selectedOrgSizeEmployees"
                        (click)="handleGroupBy('OrgSizeEmployees', 'Org Size/# Employees')"
                    >Org Size/Employees</button>
                    <button 
                        kendoButton 
                        [selected]="data.groupByField === 'MetroAreaSize'" 
                        [toggleable]="true"
                        [disabled]="!!data.selectedMetroAreaSize"
                        (click)="handleGroupBy('MetroAreaSize', 'Metro Area Size')"
                    >Metro Area Size</button>{{showPositionLevel}}
                    <button 
                        kendoButton 
                        [selected]="data.groupByField === 'Industry'"
                        [toggleable]="true"
                        [disabled]="!!data.selectedIndustry"
                        (click)="handleGroupBy('Industry', 'Industry')"
                    >Industry</button>
                    <button 
                        *ngIf="!roleRecord?.Name?.includes('CEO') && showPositionLevel" 
                        kendoButton
                        [selected]="data.groupByField === 'ContactLevel'" 
                        [toggleable]="true"
                        [disabled]="!!data.selectedContactLevel"
                        (click)="handleGroupBy('ContactLevel', 'Position Level')"
                    >Level</button>
                </kendo-buttongroup>
            </div>
        </div>
        <div class="dialog-footer">
            <div class="k-card-footer !k-border-none">
                <kendo-button (click)="apply()" >
                    <span class="k-icon k-i-check"></span>
                    Apply
                </kendo-button>
            </div>
        </div>
    </div>
</kendo-window>
</div>