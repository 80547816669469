export const parseRequestArgs = (documentOrOptions, variables, requestHeaders) => {
  return documentOrOptions.document ? documentOrOptions : {
    document: documentOrOptions,
    variables: variables,
    requestHeaders: requestHeaders,
    signal: undefined
  };
};
export const parseRawRequestArgs = (queryOrOptions, variables, requestHeaders) => {
  return queryOrOptions.query ? queryOrOptions : {
    query: queryOrOptions,
    variables: variables,
    requestHeaders: requestHeaders,
    signal: undefined
  };
};
export const parseBatchRequestArgs = (documentsOrOptions, requestHeaders) => {
  return documentsOrOptions.documents ? documentsOrOptions : {
    documents: documentsOrOptions,
    requestHeaders: requestHeaders,
    signal: undefined
  };
};
export const parseRequestExtendedArgs = (urlOrOptions, document, ...variablesAndRequestHeaders) => {
  const [variables, requestHeaders] = variablesAndRequestHeaders;
  return urlOrOptions.document ? urlOrOptions : {
    url: urlOrOptions,
    document: document,
    variables,
    requestHeaders,
    signal: undefined
  };
};
export const parseRawRequestExtendedArgs = (urlOrOptions, query, ...variablesAndRequestHeaders) => {
  const [variables, requestHeaders] = variablesAndRequestHeaders;
  return urlOrOptions.query ? urlOrOptions : {
    url: urlOrOptions,
    query: query,
    variables,
    requestHeaders,
    signal: undefined
  };
};
