"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EntityActionInvocationTypeEntity = exports.EntityActionFilterEntity = exports.EntityEntity = exports.EmployeeEntity = exports.EmployeeSkillEntity = exports.EmployeeRoleEntity = exports.EmployeeCompanyIntegrationEntity = exports.DuplicateRunEntity = exports.DuplicateRunDetailEntity = exports.DuplicateRunDetailMatchEntity = exports.DatasetEntity = exports.DatasetItemEntity = exports.DataContextEntity = exports.DataContextItemEntity = exports.DashboardEntity = exports.DashboardCategoryEntity = exports.ConversationEntity = exports.ConversationDetailEntity = exports.CompanyIntegrationEntity = exports.CompanyIntegrationRunEntity = exports.CompanyIntegrationRunDetailEntity = exports.CompanyIntegrationRunAPILogEntity = exports.CompanyIntegrationRecordMapEntity = exports.CompanyEntity = exports.CommunicationRunEntity = exports.CommunicationProviderEntity = exports.CommunicationProviderMessageTypeEntity = exports.CommunicationLogEntity = exports.CommunicationBaseMessageTypeEntity = exports.AuthorizationEntity = exports.AuthorizationRoleEntity = exports.AuditLogEntity = exports.AuditLogTypeEntity = exports.ApplicationEntity = exports.ApplicationSettingEntity = exports.ApplicationEntityEntity = exports.AIModelEntity = exports.AIModelTypeEntity = exports.AIModelActionEntity = exports.AIActionEntity = exports.ActionEntity = exports.ActionResultCodeEntity = exports.ActionParamEntity = exports.ActionLibraryEntity = exports.ActionFilterEntity = exports.ActionExecutionLogEntity = exports.ActionContextEntity = exports.ActionContextTypeEntity = exports.ActionCategoryEntity = exports.ActionAuthorizationEntity = void 0;
exports.ReportCategoryEntity = exports.RecordMergeLogEntity = exports.RecordMergeDeletionLogEntity = exports.RecordChangeEntity = exports.RecordChangeReplayRunEntity = exports.RecommendationEntity = exports.RecommendationRunEntity = exports.RecommendationProviderEntity = exports.RecommendationItemEntity = exports.QueueEntity = exports.QueueTypeEntity = exports.QueueTaskEntity = exports.QueryPermissionEntity = exports.QueryFieldEntity = exports.QueryCategoryEntity = exports.QueryEntity = exports.OutputTriggerTypeEntity = exports.OutputFormatTypeEntity = exports.OutputDeliveryTypeEntity = exports.ListEntity = exports.ListDetailEntity = exports.ListCategoryEntity = exports.LibraryItemEntity = exports.LibraryEntity = exports.IntegrationEntity = exports.IntegrationURLFormatEntity = exports.flyway_schema_historyEntity = exports.FileEntity = exports.FileStorageProviderEntity = exports.FileEntityRecordLinkEntity = exports.FileCategoryEntity = exports.ExplorerNavigationItemEntity = exports.ErrorLogEntity = exports.EntitySettingEntity = exports.EntityRelationshipEntity = exports.EntityRelationshipDisplayComponentEntity = exports.EntityRecordDocumentEntity = exports.EntityPermissionEntity = exports.EntityFieldEntity = exports.EntityFieldValueEntity = exports.EntityDocumentEntity = exports.EntityDocumentTypeEntity = exports.EntityDocumentSettingEntity = exports.EntityDocumentRunEntity = exports.EntityCommunicationMessageTypeEntity = exports.EntityCommunicationFieldEntity = exports.EntityAIActionEntity = exports.EntityActionEntity = exports.EntityActionParamEntity = exports.EntityActionInvocationEntity = void 0;
exports.WorkspaceEntity = exports.WorkspaceItemEntity = exports.WorkflowEntity = exports.WorkflowRunEntity = exports.WorkflowEngineEntity = exports.VersionInstallationEntity = exports.VectorIndexEntity = exports.VectorDatabaseEntity = exports.UserEntity = exports.UserViewEntity = exports.UserViewRunEntity = exports.UserViewRunDetailEntity = exports.UserViewCategoryEntity = exports.UserRoleEntity = exports.UserRecordLogEntity = exports.UserNotificationEntity = exports.UserFavoriteEntity = exports.UserApplicationEntity = exports.UserApplicationEntityEntity = exports.TemplateEntity = exports.TemplateParamEntity = exports.TemplateContentEntity = exports.TemplateContentTypeEntity = exports.TemplateCategoryEntity = exports.TagEntity = exports.TaggedItemEntity = exports.SkillEntity = exports.SchemaInfoEntity = exports.ScheduledActionEntity = exports.ScheduledActionParamEntity = exports.RowLevelSecurityFilterEntity = exports.RoleEntity = exports.ResourceTypeEntity = exports.ReportEntity = exports.ReportSnapshotEntity = void 0;
const core_1 = require("@memberjunction/core");
const global_1 = require("@memberjunction/global");
/**
 * Action Authorizations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionAuthorization
 * * Base View: vwActionAuthorizations
 * * @description Links actions to authorizations, one or more of these must be possessed by a user in order to execute the action.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionAuthorizationEntity = class ActionAuthorizationEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Authorizations record from the database
  * @param ID: string - primary key value to load the Action Authorizations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionAuthorizationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ActionID
  * * Display Name: Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Actions (vwActions.ID)
  */
  get ActionID() {
    return this.Get('ActionID');
  }
  set ActionID(value) {
    this.Set('ActionID', value);
  }
  /**
  * * Field Name: AuthorizationID
  * * Display Name: Authorization ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Authorizations (vwAuthorizations.ID)
  */
  get AuthorizationID() {
    return this.Get('AuthorizationID');
  }
  set AuthorizationID(value) {
    this.Set('AuthorizationID', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Action
  * * Display Name: Action
  * * SQL Data Type: nvarchar(425)
  */
  get Action() {
    return this.Get('Action');
  }
};
exports.ActionAuthorizationEntity = ActionAuthorizationEntity;
exports.ActionAuthorizationEntity = ActionAuthorizationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Authorizations')], ActionAuthorizationEntity);
/**
 * Action Categories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionCategory
 * * Base View: vwActionCategories
 * * @description Organizes actions into categories, including name, description, and optional parent category for hierarchy.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionCategoryEntity = class ActionCategoryEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Categories record from the database
  * @param ID: string - primary key value to load the Action Categories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionCategoryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the action category.
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the action category.
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Action Categories (vwActionCategories.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Disabled
  *   * Active
  *   * Pending
  * * Description: Status of the action category (Pending, Active, Disabled).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(255)
  */
  get Parent() {
    return this.Get('Parent');
  }
};
exports.ActionCategoryEntity = ActionCategoryEntity;
exports.ActionCategoryEntity = ActionCategoryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Categories')], ActionCategoryEntity);
/**
 * Action Context Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionContextType
 * * Base View: vwActionContextTypes
 * * @description Lists possible contexts for action execution with optional descriptions.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionContextTypeEntity = class ActionContextTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Context Types record from the database
  * @param ID: string - primary key value to load the Action Context Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionContextTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the context type.
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the context type.
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ActionContextTypeEntity = ActionContextTypeEntity;
exports.ActionContextTypeEntity = ActionContextTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Context Types')], ActionContextTypeEntity);
/**
 * Action Contexts - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionContext
 * * Base View: vwActionContexts
 * * @description Links actions to their supported context types enabling a given action to be executable in more than one context.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionContextEntity = class ActionContextEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Contexts record from the database
  * @param ID: string - primary key value to load the Action Contexts record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionContextEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ActionID
  * * Display Name: Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Actions (vwActions.ID)
  */
  get ActionID() {
    return this.Get('ActionID');
  }
  set ActionID(value) {
    this.Set('ActionID', value);
  }
  /**
  * * Field Name: ContextTypeID
  * * Display Name: Context Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Action Context Types (vwActionContextTypes.ID)
  */
  get ContextTypeID() {
    return this.Get('ContextTypeID');
  }
  set ContextTypeID(value) {
    this.Set('ContextTypeID', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Disabled
  *   * Active
  *   * Pending
  * * Description: Status of the action context (Pending, Active, Disabled).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Action
  * * Display Name: Action
  * * SQL Data Type: nvarchar(425)
  */
  get Action() {
    return this.Get('Action');
  }
  /**
  * * Field Name: ContextType
  * * Display Name: Context Type
  * * SQL Data Type: nvarchar(255)
  */
  get ContextType() {
    return this.Get('ContextType');
  }
};
exports.ActionContextEntity = ActionContextEntity;
exports.ActionContextEntity = ActionContextEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Contexts')], ActionContextEntity);
/**
 * Action Execution Logs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionExecutionLog
 * * Base View: vwActionExecutionLogs
 * * @description Tracks every execution of an action, including start and end times, inputs, outputs, and result codes.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionExecutionLogEntity = class ActionExecutionLogEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Execution Logs record from the database
  * @param ID: string - primary key value to load the Action Execution Logs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionExecutionLogEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ActionID
  * * Display Name: Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Actions (vwActions.ID)
  */
  get ActionID() {
    return this.Get('ActionID');
  }
  set ActionID(value) {
    this.Set('ActionID', value);
  }
  /**
  * * Field Name: StartedAt
  * * Display Name: Started At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  * * Description: Timestamp of when the action started execution.
  */
  get StartedAt() {
    return this.Get('StartedAt');
  }
  set StartedAt(value) {
    this.Set('StartedAt', value);
  }
  /**
  * * Field Name: EndedAt
  * * Display Name: Ended At
  * * SQL Data Type: datetime
  * * Description: Timestamp of when the action ended execution.
  */
  get EndedAt() {
    return this.Get('EndedAt');
  }
  set EndedAt(value) {
    this.Set('EndedAt', value);
  }
  /**
  * * Field Name: Params
  * * Display Name: Params
  * * SQL Data Type: nvarchar(MAX)
  */
  get Params() {
    return this.Get('Params');
  }
  set Params(value) {
    this.Set('Params', value);
  }
  /**
  * * Field Name: ResultCode
  * * Display Name: Result Code
  * * SQL Data Type: nvarchar(255)
  */
  get ResultCode() {
    return this.Get('ResultCode');
  }
  set ResultCode(value) {
    this.Set('ResultCode', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: RetentionPeriod
  * * Display Name: Retention Period
  * * SQL Data Type: int
  * * Description: Number of days to retain the log; NULL for indefinite retention.
  */
  get RetentionPeriod() {
    return this.Get('RetentionPeriod');
  }
  set RetentionPeriod(value) {
    this.Set('RetentionPeriod', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Action
  * * Display Name: Action
  * * SQL Data Type: nvarchar(425)
  */
  get Action() {
    return this.Get('Action');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.ActionExecutionLogEntity = ActionExecutionLogEntity;
exports.ActionExecutionLogEntity = ActionExecutionLogEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Execution Logs')], ActionExecutionLogEntity);
/**
 * Action Filters - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionFilter
 * * Base View: vwActionFilters
 * * @description Defines filters that can be evaluated ahead of executing an action. Action Filters are usable in any code pipeline you can execute them with the same context as the action itself and use the outcome to determine if the action should execute or not.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionFilterEntity = class ActionFilterEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Filters record from the database
  * @param ID: string - primary key value to load the Action Filters record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionFilterEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserDescription
  * * Display Name: User Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get UserDescription() {
    return this.Get('UserDescription');
  }
  set UserDescription(value) {
    this.Set('UserDescription', value);
  }
  /**
  * * Field Name: UserComments
  * * Display Name: User Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get UserComments() {
    return this.Get('UserComments');
  }
  set UserComments(value) {
    this.Set('UserComments', value);
  }
  /**
  * * Field Name: Code
  * * Display Name: Code
  * * SQL Data Type: nvarchar(MAX)
  */
  get Code() {
    return this.Get('Code');
  }
  set Code(value) {
    this.Set('Code', value);
  }
  /**
  * * Field Name: CodeExplanation
  * * Display Name: Code Explanation
  * * SQL Data Type: nvarchar(MAX)
  */
  get CodeExplanation() {
    return this.Get('CodeExplanation');
  }
  set CodeExplanation(value) {
    this.Set('CodeExplanation', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ActionFilterEntity = ActionFilterEntity;
exports.ActionFilterEntity = ActionFilterEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Filters')], ActionFilterEntity);
/**
 * Action Libraries - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionLibrary
 * * Base View: vwActionLibraries
 * * @description Tracks the list of libraries that a given Action uses, including a list of classes/functions for each library.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionLibraryEntity = class ActionLibraryEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Libraries record from the database
  * @param ID: string - primary key value to load the Action Libraries record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionLibraryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ActionID
  * * Display Name: Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Actions (vwActions.ID)
  */
  get ActionID() {
    return this.Get('ActionID');
  }
  set ActionID(value) {
    this.Set('ActionID', value);
  }
  /**
  * * Field Name: LibraryID
  * * Display Name: Library ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Libraries (vwLibraries.ID)
  */
  get LibraryID() {
    return this.Get('LibraryID');
  }
  set LibraryID(value) {
    this.Set('LibraryID', value);
  }
  /**
  * * Field Name: ItemsUsed
  * * Display Name: Items Used
  * * SQL Data Type: nvarchar(MAX)
  * * Description: List of classes and functions used by the action from the library.
  */
  get ItemsUsed() {
    return this.Get('ItemsUsed');
  }
  set ItemsUsed(value) {
    this.Set('ItemsUsed', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Action
  * * Display Name: Action
  * * SQL Data Type: nvarchar(425)
  */
  get Action() {
    return this.Get('Action');
  }
  /**
  * * Field Name: Library
  * * Display Name: Library
  * * SQL Data Type: nvarchar(255)
  */
  get Library() {
    return this.Get('Library');
  }
};
exports.ActionLibraryEntity = ActionLibraryEntity;
exports.ActionLibraryEntity = ActionLibraryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Libraries')], ActionLibraryEntity);
/**
 * Action Params - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionParam
 * * Base View: vwActionParams
 * * @description Tracks the input and output parameters for Actions.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionParamEntity = class ActionParamEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Params record from the database
  * @param ID: string - primary key value to load the Action Params record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionParamEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ActionID
  * * Display Name: Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Actions (vwActions.ID)
  */
  get ActionID() {
    return this.Get('ActionID');
  }
  set ActionID(value) {
    this.Set('ActionID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: DefaultValue
  * * Display Name: Default Value
  * * SQL Data Type: nvarchar(MAX)
  */
  get DefaultValue() {
    return this.Get('DefaultValue');
  }
  set DefaultValue(value) {
    this.Set('DefaultValue', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nchar(10)
  * * Value List Type: List
  * * Possible Values
  *   * Input
  *   * Output
  *   * Both
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: ValueType
  * * Display Name: Value Type
  * * SQL Data Type: nvarchar(30)
  * * Value List Type: List
  * * Possible Values
  *   * Scalar
  *   * Simple Object
  *   * BaseEntity Sub-Class
  *   * Other
  * * Description: Tracks the basic value type of the parameter, additional information can be provided in the Description field
  */
  get ValueType() {
    return this.Get('ValueType');
  }
  set ValueType(value) {
    this.Set('ValueType', value);
  }
  /**
  * * Field Name: IsArray
  * * Display Name: Is Array
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsArray() {
    return this.Get('IsArray');
  }
  set IsArray(value) {
    this.Set('IsArray', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: IsRequired
  * * Display Name: Is Required
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsRequired() {
    return this.Get('IsRequired');
  }
  set IsRequired(value) {
    this.Set('IsRequired', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Action
  * * Display Name: Action
  * * SQL Data Type: nvarchar(425)
  */
  get Action() {
    return this.Get('Action');
  }
};
exports.ActionParamEntity = ActionParamEntity;
exports.ActionParamEntity = ActionParamEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Params')], ActionParamEntity);
/**
 * Action Result Codes - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ActionResultCode
 * * Base View: vwActionResultCodes
 * * @description Defines the possible result codes for each action.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionResultCodeEntity = class ActionResultCodeEntity extends core_1.BaseEntity {
  /**
  * Loads the Action Result Codes record from the database
  * @param ID: string - primary key value to load the Action Result Codes record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionResultCodeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ActionID
  * * Display Name: Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Actions (vwActions.ID)
  */
  get ActionID() {
    return this.Get('ActionID');
  }
  set ActionID(value) {
    this.Set('ActionID', value);
  }
  /**
  * * Field Name: ResultCode
  * * Display Name: Result Code
  * * SQL Data Type: nvarchar(255)
  */
  get ResultCode() {
    return this.Get('ResultCode');
  }
  set ResultCode(value) {
    this.Set('ResultCode', value);
  }
  /**
  * * Field Name: IsSuccess
  * * Display Name: Is Success
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Indicates if the result code is a success or not. It is possible an action might have more than one failure condition/result code and same for success conditions.
  */
  get IsSuccess() {
    return this.Get('IsSuccess');
  }
  set IsSuccess(value) {
    this.Set('IsSuccess', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the result code.
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Action
  * * Display Name: Action
  * * SQL Data Type: nvarchar(425)
  */
  get Action() {
    return this.Get('Action');
  }
};
exports.ActionResultCodeEntity = ActionResultCodeEntity;
exports.ActionResultCodeEntity = ActionResultCodeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Action Result Codes')], ActionResultCodeEntity);
/**
 * Actions - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Action
 * * Base View: vwActions
 * * @description Stores action definitions, including prompts, generated code, user comments, and status.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ActionEntity = class ActionEntity extends core_1.BaseEntity {
  /**
  * Loads the Actions record from the database
  * @param ID: string - primary key value to load the Actions record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ActionEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CategoryID
  * * Display Name: Category ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Action Categories (vwActionCategories.ID)
  */
  get CategoryID() {
    return this.Get('CategoryID');
  }
  set CategoryID(value) {
    this.Set('CategoryID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(425)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Generated
  * * Value List Type: List
  * * Possible Values
  *   * Generated
  *   * Custom
  * * Description: Generated or Custom. Generated means the UserPrompt is used to prompt an AI model to automatically create the code for the Action. Custom means that a custom class has been implemented that subclasses the BaseAction class. The custom class needs to use the @RegisterClass decorator and be included in the MJAPI (or other runtime environment) to be available for execution.
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: UserPrompt
  * * Display Name: User Prompt
  * * SQL Data Type: nvarchar(MAX)
  */
  get UserPrompt() {
    return this.Get('UserPrompt');
  }
  set UserPrompt(value) {
    this.Set('UserPrompt', value);
  }
  /**
  * * Field Name: UserComments
  * * Display Name: User Comments
  * * SQL Data Type: nvarchar(MAX)
  * * Description: User's comments not shared with the LLM.
  */
  get UserComments() {
    return this.Get('UserComments');
  }
  set UserComments(value) {
    this.Set('UserComments', value);
  }
  /**
  * * Field Name: Code
  * * Display Name: Code
  * * SQL Data Type: nvarchar(MAX)
  */
  get Code() {
    return this.Get('Code');
  }
  set Code(value) {
    this.Set('Code', value);
  }
  /**
  * * Field Name: CodeComments
  * * Display Name: Code Comments
  * * SQL Data Type: nvarchar(MAX)
  * * Description: AI's explanation of the code.
  */
  get CodeComments() {
    return this.Get('CodeComments');
  }
  set CodeComments(value) {
    this.Set('CodeComments', value);
  }
  /**
  * * Field Name: CodeApprovalStatus
  * * Display Name: Code Approval Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Rejected
  *   * Approved
  *   * Pending
  * * Description: An action won't be usable until the code is approved.
  */
  get CodeApprovalStatus() {
    return this.Get('CodeApprovalStatus');
  }
  set CodeApprovalStatus(value) {
    this.Set('CodeApprovalStatus', value);
  }
  /**
  * * Field Name: CodeApprovalComments
  * * Display Name: Code Approval Comments
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Optional comments when an individual (or an AI) reviews and approves the code.
  */
  get CodeApprovalComments() {
    return this.Get('CodeApprovalComments');
  }
  set CodeApprovalComments(value) {
    this.Set('CodeApprovalComments', value);
  }
  /**
  * * Field Name: CodeApprovedByUserID
  * * Display Name: Code Approved By User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get CodeApprovedByUserID() {
    return this.Get('CodeApprovedByUserID');
  }
  set CodeApprovedByUserID(value) {
    this.Set('CodeApprovedByUserID', value);
  }
  /**
  * * Field Name: CodeApprovedAt
  * * Display Name: Code Approved At
  * * SQL Data Type: datetime
  * * Description: When the code was approved.
  */
  get CodeApprovedAt() {
    return this.Get('CodeApprovedAt');
  }
  set CodeApprovedAt(value) {
    this.Set('CodeApprovedAt', value);
  }
  /**
  * * Field Name: CodeLocked
  * * Display Name: Code Locked
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 1, Code will never be generated by the AI system. This overrides all other settings including the ForceCodeGeneration bit
  */
  get CodeLocked() {
    return this.Get('CodeLocked');
  }
  set CodeLocked(value) {
    this.Set('CodeLocked', value);
  }
  /**
  * * Field Name: ForceCodeGeneration
  * * Display Name: Force Code Generation
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 1, the Action will generate code for the provided UserPrompt on the next Save even if the UserPrompt hasn't changed. This is useful to force regeneration when other candidates (such as a change in Action Inputs/Outputs) occurs or on demand by a user.
  */
  get ForceCodeGeneration() {
    return this.Get('ForceCodeGeneration');
  }
  set ForceCodeGeneration(value) {
    this.Set('ForceCodeGeneration', value);
  }
  /**
  * * Field Name: RetentionPeriod
  * * Display Name: Retention Period
  * * SQL Data Type: int
  * * Description: Number of days to retain execution logs; NULL for indefinite.
  */
  get RetentionPeriod() {
    return this.Get('RetentionPeriod');
  }
  set RetentionPeriod(value) {
    this.Set('RetentionPeriod', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Disabled
  *   * Active
  *   * Pending
  * * Description: Status of the action (Pending, Active, Disabled).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Category
  * * Display Name: Category
  * * SQL Data Type: nvarchar(255)
  */
  get Category() {
    return this.Get('Category');
  }
  /**
  * * Field Name: CodeApprovedByUser
  * * Display Name: Code Approved By User
  * * SQL Data Type: nvarchar(100)
  */
  get CodeApprovedByUser() {
    return this.Get('CodeApprovedByUser');
  }
};
exports.ActionEntity = ActionEntity;
exports.ActionEntity = ActionEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Actions')], ActionEntity);
/**
 * AI Actions - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: AIAction
 * * Base View: vwAIActions
 * * @description List of all actions that are possible across all AI Models
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let AIActionEntity = class AIActionEntity extends core_1.BaseEntity {
  /**
  * Loads the AI Actions record from the database
  * @param ID: string - primary key value to load the AI Actions record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof AIActionEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: DefaultPrompt
  * * Display Name: Default Prompt
  * * SQL Data Type: nvarchar(MAX)
  */
  get DefaultPrompt() {
    return this.Get('DefaultPrompt');
  }
  set DefaultPrompt(value) {
    this.Set('DefaultPrompt', value);
  }
  /**
  * * Field Name: DefaultModelID
  * * Display Name: Default Model ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: AI Models (vwAIModels.ID)
  */
  get DefaultModelID() {
    return this.Get('DefaultModelID');
  }
  set DefaultModelID(value) {
    this.Set('DefaultModelID', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: DefaultModel
  * * Display Name: Default Model
  * * SQL Data Type: nvarchar(50)
  */
  get DefaultModel() {
    return this.Get('DefaultModel');
  }
};
exports.AIActionEntity = AIActionEntity;
exports.AIActionEntity = AIActionEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'AI Actions')], AIActionEntity);
/**
 * AI Model Actions - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: AIModelAction
 * * Base View: vwAIModelActions
 * * @description Tracks the actions supported by each AI Model
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let AIModelActionEntity = class AIModelActionEntity extends core_1.BaseEntity {
  /**
  * Loads the AI Model Actions record from the database
  * @param ID: string - primary key value to load the AI Model Actions record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof AIModelActionEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: AIModelID
  * * Display Name: AI Model ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: AI Models (vwAIModels.ID)
  */
  get AIModelID() {
    return this.Get('AIModelID');
  }
  set AIModelID(value) {
    this.Set('AIModelID', value);
  }
  /**
  * * Field Name: AIActionID
  * * Display Name: AI Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: AI Actions (vwAIActions.ID)
  */
  get AIActionID() {
    return this.Get('AIActionID');
  }
  set AIActionID(value) {
    this.Set('AIActionID', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: AIModel
  * * Display Name: AIModel
  * * SQL Data Type: nvarchar(50)
  */
  get AIModel() {
    return this.Get('AIModel');
  }
  /**
  * * Field Name: AIAction
  * * Display Name: AIAction
  * * SQL Data Type: nvarchar(50)
  */
  get AIAction() {
    return this.Get('AIAction');
  }
};
exports.AIModelActionEntity = AIModelActionEntity;
exports.AIModelActionEntity = AIModelActionEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'AI Model Actions')], AIModelActionEntity);
/**
 * AI Model Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: AIModelType
 * * Base View: vwAIModelTypes
 * * @description Types of AI Models
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let AIModelTypeEntity = class AIModelTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the AI Model Types record from the database
  * @param ID: string - primary key value to load the AI Model Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof AIModelTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.AIModelTypeEntity = AIModelTypeEntity;
exports.AIModelTypeEntity = AIModelTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'AI Model Types')], AIModelTypeEntity);
/**
 * AI Models - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: AIModel
 * * Base View: vwAIModels
 * * @description Catalog of all AI Models configured in the system
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let AIModelEntity = class AIModelEntity extends core_1.BaseEntity {
  /**
  * Loads the AI Models record from the database
  * @param ID: string - primary key value to load the AI Models record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof AIModelEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Vendor
  * * Display Name: Vendor
  * * SQL Data Type: nvarchar(50)
  */
  get Vendor() {
    return this.Get('Vendor');
  }
  set Vendor(value) {
    this.Set('Vendor', value);
  }
  /**
  * * Field Name: AIModelTypeID
  * * Display Name: AI Model Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: AI Model Types (vwAIModelTypes.ID)
  */
  get AIModelTypeID() {
    return this.Get('AIModelTypeID');
  }
  set AIModelTypeID(value) {
    this.Set('AIModelTypeID', value);
  }
  /**
  * * Field Name: PowerRank
  * * Display Name: Power Rank
  * * SQL Data Type: int
  * * Description: A simplified power rank of each model for a given AI Model Type. For example, if we have GPT 3, GPT 3.5, and GPT 4, we would have a PowerRank of 1 for GPT3, 2 for GPT 3.5, and 3 for GPT 4. This can be used within model families like OpenAI or across all models. For example if you had Llama 2 in the mix which is similar to GPT 3.5 it would also have a PowerRank of 2. This can be used at runtime to pick the most/least powerful or compare model relative power.
  */
  get PowerRank() {
    return this.Get('PowerRank');
  }
  set PowerRank(value) {
    this.Set('PowerRank', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: DriverClass
  * * Display Name: Driver Class
  * * SQL Data Type: nvarchar(100)
  */
  get DriverClass() {
    return this.Get('DriverClass');
  }
  set DriverClass(value) {
    this.Set('DriverClass', value);
  }
  /**
  * * Field Name: DriverImportPath
  * * Display Name: Driver Import Path
  * * SQL Data Type: nvarchar(255)
  */
  get DriverImportPath() {
    return this.Get('DriverImportPath');
  }
  set DriverImportPath(value) {
    this.Set('DriverImportPath', value);
  }
  /**
  * * Field Name: APIName
  * * Display Name: APIName
  * * SQL Data Type: nvarchar(100)
  * * Description: The name of the model to use with API calls which might differ from the Name, if APIName is not provided, Name will be used for API calls
  */
  get APIName() {
    return this.Get('APIName');
  }
  set APIName(value) {
    this.Set('APIName', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: AIModelType
  * * Display Name: AIModel Type
  * * SQL Data Type: nvarchar(50)
  */
  get AIModelType() {
    return this.Get('AIModelType');
  }
};
exports.AIModelEntity = AIModelEntity;
exports.AIModelEntity = AIModelEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'AI Models')], AIModelEntity);
/**
 * Application Entities - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ApplicationEntity
 * * Base View: vwApplicationEntities
 * * @description List of entities within each application. An application can have any number of entities and an entity can be part of any number of applications.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ApplicationEntityEntity = class ApplicationEntityEntity extends core_1.BaseEntity {
  /**
  * Loads the Application Entities record from the database
  * @param ID: string - primary key value to load the Application Entities record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ApplicationEntityEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ApplicationID
  * * Display Name: Application ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Applications (vwApplications.ID)
  */
  get ApplicationID() {
    return this.Get('ApplicationID');
  }
  set ApplicationID(value) {
    this.Set('ApplicationID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: Sequence
  * * SQL Data Type: int
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: DefaultForNewUser
  * * Display Name: Default For New User
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When set to 1, the entity will be included by default for a new user when they first access the application in question
  */
  get DefaultForNewUser() {
    return this.Get('DefaultForNewUser');
  }
  set DefaultForNewUser(value) {
    this.Set('DefaultForNewUser', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Application
  * * SQL Data Type: nvarchar(100)
  */
  get Application() {
    return this.Get('Application');
  }
  /**
  * * Field Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: EntityBaseTable
  * * Display Name: Entity Base Table
  * * SQL Data Type: nvarchar(255)
  */
  get EntityBaseTable() {
    return this.Get('EntityBaseTable');
  }
  /**
  * * Field Name: EntityCodeName
  * * Display Name: Entity Code Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get EntityCodeName() {
    return this.Get('EntityCodeName');
  }
  /**
  * * Field Name: EntityClassName
  * * Display Name: Entity Class Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get EntityClassName() {
    return this.Get('EntityClassName');
  }
  /**
  * * Field Name: EntityBaseTableCodeName
  * * Display Name: Entity Base Table Code Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get EntityBaseTableCodeName() {
    return this.Get('EntityBaseTableCodeName');
  }
};
exports.ApplicationEntityEntity = ApplicationEntityEntity;
exports.ApplicationEntityEntity = ApplicationEntityEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Application Entities')], ApplicationEntityEntity);
/**
 * Application Settings - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ApplicationSetting
 * * Base View: vwApplicationSettings
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ApplicationSettingEntity = class ApplicationSettingEntity extends core_1.BaseEntity {
  /**
  * Loads the Application Settings record from the database
  * @param ID: string - primary key value to load the Application Settings record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ApplicationSettingEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ApplicationID
  * * Display Name: Application ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Applications (vwApplications.ID)
  */
  get ApplicationID() {
    return this.Get('ApplicationID');
  }
  set ApplicationID(value) {
    this.Set('ApplicationID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Value
  * * Display Name: Value
  * * SQL Data Type: nvarchar(MAX)
  */
  get Value() {
    return this.Get('Value');
  }
  set Value(value) {
    this.Set('Value', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ApplicationSettingEntity = ApplicationSettingEntity;
exports.ApplicationSettingEntity = ApplicationSettingEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Application Settings')], ApplicationSettingEntity);
/**
 * Applications - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Application
 * * Base View: vwApplications
 * * @description Applications are used to group entities in the user interface for ease of user access
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ApplicationEntity = class ApplicationEntity extends core_1.BaseEntity {
  /**
  * Loads the Applications record from the database
  * @param ID: string - primary key value to load the Applications record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ApplicationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Icon
  * * Display Name: Icon
  * * SQL Data Type: nvarchar(500)
  * * Description: Specify the CSS class information for the display icon for each application.
  */
  get Icon() {
    return this.Get('Icon');
  }
  set Icon(value) {
    this.Set('Icon', value);
  }
  /**
  * * Field Name: DefaultForNewUser
  * * Display Name: Default For New User
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: If turned on, when a new user first uses the MJ Explorer app, the application records with this turned on will have this application included in their selected application list.
  */
  get DefaultForNewUser() {
    return this.Get('DefaultForNewUser');
  }
  set DefaultForNewUser(value) {
    this.Set('DefaultForNewUser', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ApplicationEntity = ApplicationEntity;
exports.ApplicationEntity = ApplicationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Applications')], ApplicationEntity);
/**
 * Audit Log Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: AuditLogType
 * * Base View: vwAuditLogTypes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let AuditLogTypeEntity = class AuditLogTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Audit Log Types record from the database
  * @param ID: string - primary key value to load the Audit Log Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof AuditLogTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Audit Log Types - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof AuditLogTypeEntity
  * @throws {Error} - Save is not allowed for Audit Log Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Audit Log Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Audit Log Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof AuditLogTypeEntity
  * @throws {Error} - Delete is not allowed for Audit Log Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Audit Log Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Audit Log Types (vwAuditLogTypes.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: AuthorizationID
  * * Display Name: Authorization ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Authorizations (vwAuthorizations.ID)
  */
  get AuthorizationID() {
    return this.Get('AuthorizationID');
  }
  set AuthorizationID(value) {
    this.Set('AuthorizationID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(50)
  */
  get Parent() {
    return this.Get('Parent');
  }
};
exports.AuditLogTypeEntity = AuditLogTypeEntity;
exports.AuditLogTypeEntity = AuditLogTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Audit Log Types')], AuditLogTypeEntity);
/**
 * Audit Logs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: AuditLog
 * * Base View: vwAuditLogs
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let AuditLogEntity = class AuditLogEntity extends core_1.BaseEntity {
  /**
  * Loads the Audit Logs record from the database
  * @param ID: string - primary key value to load the Audit Logs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof AuditLogEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Audit Logs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof AuditLogEntity
  * @throws {Error} - Delete is not allowed for Audit Logs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Audit Logs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: AuditLogTypeID
  * * Display Name: Audit Log Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Audit Log Types (vwAuditLogTypes.ID)
  */
  get AuditLogTypeID() {
    return this.Get('AuditLogTypeID');
  }
  set AuditLogTypeID(value) {
    this.Set('AuditLogTypeID', value);
  }
  /**
  * * Field Name: AuthorizationID
  * * Display Name: Authorization ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Authorizations (vwAuthorizations.ID)
  */
  get AuthorizationID() {
    return this.Get('AuthorizationID');
  }
  set AuthorizationID(value) {
    this.Set('AuthorizationID', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(50)
  * * Default Value: Allow
  * * Value List Type: List
  * * Possible Values
  *   * Success
  *   * Failed
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Details
  * * Display Name: Details
  * * SQL Data Type: nvarchar(MAX)
  */
  get Details() {
    return this.Get('Details');
  }
  set Details(value) {
    this.Set('Details', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record
  * * SQL Data Type: nvarchar(450)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.AuditLogEntity = AuditLogEntity;
exports.AuditLogEntity = AuditLogEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Audit Logs')], AuditLogEntity);
/**
 * Authorization Roles - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: AuthorizationRole
 * * Base View: vwAuthorizationRoles
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let AuthorizationRoleEntity = class AuthorizationRoleEntity extends core_1.BaseEntity {
  /**
  * Loads the Authorization Roles record from the database
  * @param ID: string - primary key value to load the Authorization Roles record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof AuthorizationRoleEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Authorization Roles - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof AuthorizationRoleEntity
  * @throws {Error} - Save is not allowed for Authorization Roles, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Authorization Roles, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Authorization Roles - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof AuthorizationRoleEntity
  * @throws {Error} - Delete is not allowed for Authorization Roles, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Authorization Roles, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: AuthorizationID
  * * Display Name: Authorization ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Authorizations (vwAuthorizations.ID)
  */
  get AuthorizationID() {
    return this.Get('AuthorizationID');
  }
  set AuthorizationID(value) {
    this.Set('AuthorizationID', value);
  }
  /**
  * * Field Name: RoleID
  * * Display Name: Role ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Roles (vwRoles.ID)
  */
  get RoleID() {
    return this.Get('RoleID');
  }
  set RoleID(value) {
    this.Set('RoleID', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nchar(10)
  * * Default Value: grant
  * * Value List Type: List
  * * Possible Values
  *   * Allow - User allowed to execute tasks linked to this authorization
  *   * Deny - User NOT allowed to execute tasks linked to this authorization - deny overrides Allow from all other roles a user may be part of
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.AuthorizationRoleEntity = AuthorizationRoleEntity;
exports.AuthorizationRoleEntity = AuthorizationRoleEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Authorization Roles')], AuthorizationRoleEntity);
/**
 * Authorizations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Authorization
 * * Base View: vwAuthorizations
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let AuthorizationEntity = class AuthorizationEntity extends core_1.BaseEntity {
  /**
  * Loads the Authorizations record from the database
  * @param ID: string - primary key value to load the Authorizations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof AuthorizationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Authorizations - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof AuthorizationEntity
  * @throws {Error} - Save is not allowed for Authorizations, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Authorizations, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Authorizations - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof AuthorizationEntity
  * @throws {Error} - Delete is not allowed for Authorizations, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Authorizations, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Authorizations (vwAuthorizations.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: UseAuditLog
  * * Display Name: Use Audit Log
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When set to 1, Audit Log records are created whenever this authorization is invoked for a user
  */
  get UseAuditLog() {
    return this.Get('UseAuditLog');
  }
  set UseAuditLog(value) {
    this.Set('UseAuditLog', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(100)
  */
  get Parent() {
    return this.Get('Parent');
  }
};
exports.AuthorizationEntity = AuthorizationEntity;
exports.AuthorizationEntity = AuthorizationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Authorizations')], AuthorizationEntity);
/**
 * Communication Base Message Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CommunicationBaseMessageType
 * * Base View: vwCommunicationBaseMessageTypes
 * * @description Base message types and their supported functionalities.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CommunicationBaseMessageTypeEntity = class CommunicationBaseMessageTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Communication Base Message Types record from the database
  * @param ID: string - primary key value to load the Communication Base Message Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CommunicationBaseMessageTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Communication Base Message Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CommunicationBaseMessageTypeEntity
  * @throws {Error} - Delete is not allowed for Communication Base Message Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Communication Base Message Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(100)
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: SupportsAttachments
  * * Display Name: Supports Attachments
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Indicates if attachments are supported.
  */
  get SupportsAttachments() {
    return this.Get('SupportsAttachments');
  }
  set SupportsAttachments(value) {
    this.Set('SupportsAttachments', value);
  }
  /**
  * * Field Name: SupportsSubjectLine
  * * Display Name: Supports Subject Line
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Indicates if a subject line is supported.
  */
  get SupportsSubjectLine() {
    return this.Get('SupportsSubjectLine');
  }
  set SupportsSubjectLine(value) {
    this.Set('SupportsSubjectLine', value);
  }
  /**
  * * Field Name: SupportsHtml
  * * Display Name: Supports Html
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Indicates if HTML content is supported.
  */
  get SupportsHtml() {
    return this.Get('SupportsHtml');
  }
  set SupportsHtml(value) {
    this.Set('SupportsHtml', value);
  }
  /**
  * * Field Name: MaxBytes
  * * Display Name: Max Bytes
  * * SQL Data Type: int
  * * Description: The maximum size in bytes for the message.
  */
  get MaxBytes() {
    return this.Get('MaxBytes');
  }
  set MaxBytes(value) {
    this.Set('MaxBytes', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.CommunicationBaseMessageTypeEntity = CommunicationBaseMessageTypeEntity;
exports.CommunicationBaseMessageTypeEntity = CommunicationBaseMessageTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Communication Base Message Types')], CommunicationBaseMessageTypeEntity);
/**
 * Communication Logs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CommunicationLog
 * * Base View: vwCommunicationLogs
 * * @description Logs of sent and received messages.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CommunicationLogEntity = class CommunicationLogEntity extends core_1.BaseEntity {
  /**
  * Loads the Communication Logs record from the database
  * @param ID: string - primary key value to load the Communication Logs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CommunicationLogEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Communication Logs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CommunicationLogEntity
  * @throws {Error} - Delete is not allowed for Communication Logs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Communication Logs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CommunicationProviderID
  * * Display Name: Communication Provider ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Communication Providers (vwCommunicationProviders.ID)
  */
  get CommunicationProviderID() {
    return this.Get('CommunicationProviderID');
  }
  set CommunicationProviderID(value) {
    this.Set('CommunicationProviderID', value);
  }
  /**
  * * Field Name: CommunicationProviderMessageTypeID
  * * Display Name: Communication Provider Message Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Communication Provider Message Types (vwCommunicationProviderMessageTypes.ID)
  */
  get CommunicationProviderMessageTypeID() {
    return this.Get('CommunicationProviderMessageTypeID');
  }
  set CommunicationProviderMessageTypeID(value) {
    this.Set('CommunicationProviderMessageTypeID', value);
  }
  /**
  * * Field Name: CommunicationRunID
  * * Display Name: Communication Run ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Communication Runs (vwCommunicationRuns.ID)
  */
  get CommunicationRunID() {
    return this.Get('CommunicationRunID');
  }
  set CommunicationRunID(value) {
    this.Set('CommunicationRunID', value);
  }
  /**
  * * Field Name: Direction
  * * Display Name: Direction
  * * SQL Data Type: nvarchar(20)
  * * Value List Type: List
  * * Possible Values
  *   * Sending
  *   * Receiving
  * * Description: The direction of the communication log (Sending or Receiving).
  */
  get Direction() {
    return this.Get('Direction');
  }
  set Direction(value) {
    this.Set('Direction', value);
  }
  /**
  * * Field Name: MessageDate
  * * Display Name: Message Date
  * * SQL Data Type: datetime
  * * Description: The date and time when the message was logged.
  */
  get MessageDate() {
    return this.Get('MessageDate');
  }
  set MessageDate(value) {
    this.Set('MessageDate', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * In-Progress
  *   * Complete
  *   * Failed
  * * Description: The status of the logged message (Pending, In-Progress, Complete, Failed).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: MessageContent
  * * Display Name: Message Content
  * * SQL Data Type: nvarchar(MAX)
  * * Description: The content of the logged message.
  */
  get MessageContent() {
    return this.Get('MessageContent');
  }
  set MessageContent(value) {
    this.Set('MessageContent', value);
  }
  /**
  * * Field Name: ErrorMessage
  * * Display Name: Error Message
  * * SQL Data Type: nvarchar(MAX)
  * * Description: The error message if the message sending failed.
  */
  get ErrorMessage() {
    return this.Get('ErrorMessage');
  }
  set ErrorMessage(value) {
    this.Set('ErrorMessage', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: CommunicationProvider
  * * Display Name: Communication Provider
  * * SQL Data Type: nvarchar(255)
  */
  get CommunicationProvider() {
    return this.Get('CommunicationProvider');
  }
  /**
  * * Field Name: CommunicationProviderMessageType
  * * Display Name: Communication Provider Message Type
  * * SQL Data Type: nvarchar(255)
  */
  get CommunicationProviderMessageType() {
    return this.Get('CommunicationProviderMessageType');
  }
};
exports.CommunicationLogEntity = CommunicationLogEntity;
exports.CommunicationLogEntity = CommunicationLogEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Communication Logs')], CommunicationLogEntity);
/**
 * Communication Provider Message Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CommunicationProviderMessageType
 * * Base View: vwCommunicationProviderMessageTypes
 * * @description Providers and their supported message types with additional attributes.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CommunicationProviderMessageTypeEntity = class CommunicationProviderMessageTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Communication Provider Message Types record from the database
  * @param ID: string - primary key value to load the Communication Provider Message Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CommunicationProviderMessageTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CommunicationProviderID
  * * Display Name: Communication Provider ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Communication Providers (vwCommunicationProviders.ID)
  */
  get CommunicationProviderID() {
    return this.Get('CommunicationProviderID');
  }
  set CommunicationProviderID(value) {
    this.Set('CommunicationProviderID', value);
  }
  /**
  * * Field Name: CommunicationBaseMessageTypeID
  * * Display Name: Communication Base Message Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Communication Base Message Types (vwCommunicationBaseMessageTypes.ID)
  */
  get CommunicationBaseMessageTypeID() {
    return this.Get('CommunicationBaseMessageTypeID');
  }
  set CommunicationBaseMessageTypeID(value) {
    this.Set('CommunicationBaseMessageTypeID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Disabled
  * * Value List Type: List
  * * Possible Values
  *   * Disabled
  *   * Active
  * * Description: The status of the provider message type (Disabled or Active).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: AdditionalAttributes
  * * Display Name: Additional Attributes
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Additional attributes specific to the provider message type.
  */
  get AdditionalAttributes() {
    return this.Get('AdditionalAttributes');
  }
  set AdditionalAttributes(value) {
    this.Set('AdditionalAttributes', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: CommunicationProvider
  * * Display Name: Communication Provider
  * * SQL Data Type: nvarchar(255)
  */
  get CommunicationProvider() {
    return this.Get('CommunicationProvider');
  }
  /**
  * * Field Name: CommunicationBaseMessageType
  * * Display Name: Communication Base Message Type
  * * SQL Data Type: nvarchar(100)
  */
  get CommunicationBaseMessageType() {
    return this.Get('CommunicationBaseMessageType');
  }
};
exports.CommunicationProviderMessageTypeEntity = CommunicationProviderMessageTypeEntity;
exports.CommunicationProviderMessageTypeEntity = CommunicationProviderMessageTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Communication Provider Message Types')], CommunicationProviderMessageTypeEntity);
/**
 * Communication Providers - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CommunicationProvider
 * * Base View: vwCommunicationProviders
 * * @description All supported communication providers.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CommunicationProviderEntity = class CommunicationProviderEntity extends core_1.BaseEntity {
  /**
  * Loads the Communication Providers record from the database
  * @param ID: string - primary key value to load the Communication Providers record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CommunicationProviderEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Communication Providers - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CommunicationProviderEntity
  * @throws {Error} - Delete is not allowed for Communication Providers, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Communication Providers, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Disabled
  * * Value List Type: List
  * * Possible Values
  *   * Disabled
  *   * Active
  * * Description: The status of the communication provider (Disabled or Active).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: SupportsSending
  * * Display Name: Supports Sending
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: Indicates if the provider supports sending messages.
  */
  get SupportsSending() {
    return this.Get('SupportsSending');
  }
  set SupportsSending(value) {
    this.Set('SupportsSending', value);
  }
  /**
  * * Field Name: SupportsReceiving
  * * Display Name: Supports Receiving
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Indicates if the provider supports receiving messages.
  */
  get SupportsReceiving() {
    return this.Get('SupportsReceiving');
  }
  set SupportsReceiving(value) {
    this.Set('SupportsReceiving', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.CommunicationProviderEntity = CommunicationProviderEntity;
exports.CommunicationProviderEntity = CommunicationProviderEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Communication Providers')], CommunicationProviderEntity);
/**
 * Communication Runs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CommunicationRun
 * * Base View: vwCommunicationRuns
 * * @description Runs of bulk message sends and receives.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CommunicationRunEntity = class CommunicationRunEntity extends core_1.BaseEntity {
  /**
  * Loads the Communication Runs record from the database
  * @param ID: string - primary key value to load the Communication Runs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CommunicationRunEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Communication Runs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CommunicationRunEntity
  * @throws {Error} - Delete is not allowed for Communication Runs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Communication Runs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: Direction
  * * Display Name: Direction
  * * SQL Data Type: nvarchar(20)
  * * Value List Type: List
  * * Possible Values
  *   * Sending
  *   * Receiving
  * * Description: The direction of the communication run (Sending or Receiving).
  */
  get Direction() {
    return this.Get('Direction');
  }
  set Direction(value) {
    this.Set('Direction', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * In-Progress
  *   * Complete
  *   * Failed
  * * Description: The status of the communication run (Pending, In-Progress, Complete, Failed).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: StartedAt
  * * Display Name: Started At
  * * SQL Data Type: datetime
  */
  get StartedAt() {
    return this.Get('StartedAt');
  }
  set StartedAt(value) {
    this.Set('StartedAt', value);
  }
  /**
  * * Field Name: EndedAt
  * * Display Name: Ended At
  * * SQL Data Type: datetime
  */
  get EndedAt() {
    return this.Get('EndedAt');
  }
  set EndedAt(value) {
    this.Set('EndedAt', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: ErrorMessage
  * * Display Name: Error Message
  * * SQL Data Type: nvarchar(MAX)
  * * Description: The error message if the communication run failed.
  */
  get ErrorMessage() {
    return this.Get('ErrorMessage');
  }
  set ErrorMessage(value) {
    this.Set('ErrorMessage', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.CommunicationRunEntity = CommunicationRunEntity;
exports.CommunicationRunEntity = CommunicationRunEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Communication Runs')], CommunicationRunEntity);
/**
 * Companies - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Company
 * * Base View: vwCompanies
 * * @description A list of organizational units within your business. These can be subsidiaries or divisions or other units. Companies are used to organizae employee records and also for separating integrations if you have multiple integrations of the same type of system.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CompanyEntity = class CompanyEntity extends core_1.BaseEntity {
  /**
  * Loads the Companies record from the database
  * @param ID: string - primary key value to load the Companies record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CompanyEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(200)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Website
  * * SQL Data Type: nvarchar(100)
  */
  get Website() {
    return this.Get('Website');
  }
  set Website(value) {
    this.Set('Website', value);
  }
  /**
  * * Field Name: LogoURL
  * * Display Name: Logo URL
  * * SQL Data Type: nvarchar(500)
  */
  get LogoURL() {
    return this.Get('LogoURL');
  }
  set LogoURL(value) {
    this.Set('LogoURL', value);
  }
  /**
  * * Field Name: Domain
  * * Display Name: Domain
  * * SQL Data Type: nvarchar(255)
  */
  get Domain() {
    return this.Get('Domain');
  }
  set Domain(value) {
    this.Set('Domain', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.CompanyEntity = CompanyEntity;
exports.CompanyEntity = CompanyEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Companies')], CompanyEntity);
/**
 * Company Integration Record Maps - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CompanyIntegrationRecordMap
 * * Base View: vwCompanyIntegrationRecordMaps
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CompanyIntegrationRecordMapEntity = class CompanyIntegrationRecordMapEntity extends core_1.BaseEntity {
  /**
  * Loads the Company Integration Record Maps record from the database
  * @param ID: string - primary key value to load the Company Integration Record Maps record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CompanyIntegrationRecordMapEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Company Integration Record Maps - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CompanyIntegrationRecordMapEntity
  * @throws {Error} - Delete is not allowed for Company Integration Record Maps, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Company Integration Record Maps, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CompanyIntegrationID
  * * Display Name: Company Integration ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Company Integrations (vwCompanyIntegrations.ID)
  */
  get CompanyIntegrationID() {
    return this.Get('CompanyIntegrationID');
  }
  set CompanyIntegrationID(value) {
    this.Set('CompanyIntegrationID', value);
  }
  /**
  * * Field Name: ExternalSystemRecordID
  * * Display Name: External System Record ID
  * * SQL Data Type: nvarchar(750)
  */
  get ExternalSystemRecordID() {
    return this.Get('ExternalSystemRecordID');
  }
  set ExternalSystemRecordID(value) {
    this.Set('ExternalSystemRecordID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: EntityRecordID
  * * Display Name: Entity Record ID
  * * SQL Data Type: nvarchar(750)
  */
  get EntityRecordID() {
    return this.Get('EntityRecordID');
  }
  set EntityRecordID(value) {
    this.Set('EntityRecordID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.CompanyIntegrationRecordMapEntity = CompanyIntegrationRecordMapEntity;
exports.CompanyIntegrationRecordMapEntity = CompanyIntegrationRecordMapEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Company Integration Record Maps')], CompanyIntegrationRecordMapEntity);
/**
 * Company Integration Run API Logs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CompanyIntegrationRunAPILog
 * * Base View: vwCompanyIntegrationRunAPILogs
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CompanyIntegrationRunAPILogEntity = class CompanyIntegrationRunAPILogEntity extends core_1.BaseEntity {
  /**
  * Loads the Company Integration Run API Logs record from the database
  * @param ID: string - primary key value to load the Company Integration Run API Logs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CompanyIntegrationRunAPILogEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Company Integration Run API Logs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CompanyIntegrationRunAPILogEntity
  * @throws {Error} - Delete is not allowed for Company Integration Run API Logs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Company Integration Run API Logs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CompanyIntegrationRunID
  * * Display Name: Company Integration Run ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Company Integration Runs (vwCompanyIntegrationRuns.ID)
  */
  get CompanyIntegrationRunID() {
    return this.Get('CompanyIntegrationRunID');
  }
  set CompanyIntegrationRunID(value) {
    this.Set('CompanyIntegrationRunID', value);
  }
  /**
  * * Field Name: ExecutedAt
  * * Display Name: Executed At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get ExecutedAt() {
    return this.Get('ExecutedAt');
  }
  set ExecutedAt(value) {
    this.Set('ExecutedAt', value);
  }
  /**
  * * Field Name: IsSuccess
  * * Display Name: Is Success
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsSuccess() {
    return this.Get('IsSuccess');
  }
  set IsSuccess(value) {
    this.Set('IsSuccess', value);
  }
  /**
  * * Field Name: RequestMethod
  * * Display Name: Request Method
  * * SQL Data Type: nvarchar(12)
  * * Value List Type: List
  * * Possible Values
  *   * GET
  *   * POST
  *   * PUT
  *   * DELETE
  *   * PATCH
  *   * HEAD
  *   * OPTIONS
  */
  get RequestMethod() {
    return this.Get('RequestMethod');
  }
  set RequestMethod(value) {
    this.Set('RequestMethod', value);
  }
  /**
  * * Field Name: URL
  * * SQL Data Type: nvarchar(MAX)
  */
  get URL() {
    return this.Get('URL');
  }
  set URL(value) {
    this.Set('URL', value);
  }
  /**
  * * Field Name: Parameters
  * * SQL Data Type: nvarchar(MAX)
  */
  get Parameters() {
    return this.Get('Parameters');
  }
  set Parameters(value) {
    this.Set('Parameters', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.CompanyIntegrationRunAPILogEntity = CompanyIntegrationRunAPILogEntity;
exports.CompanyIntegrationRunAPILogEntity = CompanyIntegrationRunAPILogEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Company Integration Run API Logs')], CompanyIntegrationRunAPILogEntity);
/**
 * Company Integration Run Details - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CompanyIntegrationRunDetail
 * * Base View: vwCompanyIntegrationRunDetails
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CompanyIntegrationRunDetailEntity = class CompanyIntegrationRunDetailEntity extends core_1.BaseEntity {
  /**
  * Loads the Company Integration Run Details record from the database
  * @param ID: string - primary key value to load the Company Integration Run Details record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CompanyIntegrationRunDetailEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Company Integration Run Details - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CompanyIntegrationRunDetailEntity
  * @throws {Error} - Delete is not allowed for Company Integration Run Details, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Company Integration Run Details, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CompanyIntegrationRunID
  * * Display Name: CompanyIntegrationRun ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Company Integration Runs (vwCompanyIntegrationRuns.ID)
  */
  get CompanyIntegrationRunID() {
    return this.Get('CompanyIntegrationRunID');
  }
  set CompanyIntegrationRunID(value) {
    this.Set('CompanyIntegrationRunID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record
  * * SQL Data Type: nvarchar(450)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: Action
  * * SQL Data Type: nchar(20)
  */
  get Action() {
    return this.Get('Action');
  }
  set Action(value) {
    this.Set('Action', value);
  }
  /**
  * * Field Name: ExecutedAt
  * * Display Name: Executed At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get ExecutedAt() {
    return this.Get('ExecutedAt');
  }
  set ExecutedAt(value) {
    this.Set('ExecutedAt', value);
  }
  /**
  * * Field Name: IsSuccess
  * * Display Name: Is Success
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsSuccess() {
    return this.Get('IsSuccess');
  }
  set IsSuccess(value) {
    this.Set('IsSuccess', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: RunStartedAt
  * * Display Name: Run Started At
  * * SQL Data Type: datetime
  */
  get RunStartedAt() {
    return this.Get('RunStartedAt');
  }
  /**
  * * Field Name: RunEndedAt
  * * Display Name: Run Ended At
  * * SQL Data Type: datetime
  */
  get RunEndedAt() {
    return this.Get('RunEndedAt');
  }
};
exports.CompanyIntegrationRunDetailEntity = CompanyIntegrationRunDetailEntity;
exports.CompanyIntegrationRunDetailEntity = CompanyIntegrationRunDetailEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Company Integration Run Details')], CompanyIntegrationRunDetailEntity);
/**
 * Company Integration Runs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CompanyIntegrationRun
 * * Base View: vwCompanyIntegrationRuns
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CompanyIntegrationRunEntity = class CompanyIntegrationRunEntity extends core_1.BaseEntity {
  /**
  * Loads the Company Integration Runs record from the database
  * @param ID: string - primary key value to load the Company Integration Runs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CompanyIntegrationRunEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Company Integration Runs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CompanyIntegrationRunEntity
  * @throws {Error} - Delete is not allowed for Company Integration Runs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Company Integration Runs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CompanyIntegrationID
  * * Display Name: CompanyIntegration ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Company Integrations (vwCompanyIntegrations.ID)
  */
  get CompanyIntegrationID() {
    return this.Get('CompanyIntegrationID');
  }
  set CompanyIntegrationID(value) {
    this.Set('CompanyIntegrationID', value);
  }
  /**
  * * Field Name: RunByUserID
  * * Display Name: RunByUser ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get RunByUserID() {
    return this.Get('RunByUserID');
  }
  set RunByUserID(value) {
    this.Set('RunByUserID', value);
  }
  /**
  * * Field Name: StartedAt
  * * Display Name: Started At
  * * SQL Data Type: datetime
  */
  get StartedAt() {
    return this.Get('StartedAt');
  }
  set StartedAt(value) {
    this.Set('StartedAt', value);
  }
  /**
  * * Field Name: EndedAt
  * * Display Name: Ended At
  * * SQL Data Type: datetime
  */
  get EndedAt() {
    return this.Get('EndedAt');
  }
  set EndedAt(value) {
    this.Set('EndedAt', value);
  }
  /**
  * * Field Name: TotalRecords
  * * Display Name: Total Records
  * * SQL Data Type: int
  */
  get TotalRecords() {
    return this.Get('TotalRecords');
  }
  set TotalRecords(value) {
    this.Set('TotalRecords', value);
  }
  /**
  * * Field Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: RunByUser
  * * Display Name: Run By User
  * * SQL Data Type: nvarchar(100)
  */
  get RunByUser() {
    return this.Get('RunByUser');
  }
};
exports.CompanyIntegrationRunEntity = CompanyIntegrationRunEntity;
exports.CompanyIntegrationRunEntity = CompanyIntegrationRunEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Company Integration Runs')], CompanyIntegrationRunEntity);
/**
 * Company Integrations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: CompanyIntegration
 * * Base View: vwCompanyIntegrations
 * * @description Links individual company records to specific integrations
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let CompanyIntegrationEntity = class CompanyIntegrationEntity extends core_1.BaseEntity {
  /**
  * Loads the Company Integrations record from the database
  * @param ID: string - primary key value to load the Company Integrations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof CompanyIntegrationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Company Integrations - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof CompanyIntegrationEntity
  * @throws {Error} - Delete is not allowed for Company Integrations, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Company Integrations, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CompanyID
  * * Display Name: Company ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Companies (vwCompanies.ID)
  */
  get CompanyID() {
    return this.Get('CompanyID');
  }
  set CompanyID(value) {
    this.Set('CompanyID', value);
  }
  /**
  * * Field Name: IntegrationID
  * * Display Name: Integration ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Integrations (vwIntegrations.ID)
  */
  get IntegrationID() {
    return this.Get('IntegrationID');
  }
  set IntegrationID(value) {
    this.Set('IntegrationID', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: AccessToken
  * * Display Name: Access Token
  * * SQL Data Type: nvarchar(255)
  */
  get AccessToken() {
    return this.Get('AccessToken');
  }
  set AccessToken(value) {
    this.Set('AccessToken', value);
  }
  /**
  * * Field Name: RefreshToken
  * * Display Name: Refresh Token
  * * SQL Data Type: nvarchar(255)
  */
  get RefreshToken() {
    return this.Get('RefreshToken');
  }
  set RefreshToken(value) {
    this.Set('RefreshToken', value);
  }
  /**
  * * Field Name: TokenExpirationDate
  * * Display Name: Token Expiration Date
  * * SQL Data Type: datetime
  */
  get TokenExpirationDate() {
    return this.Get('TokenExpirationDate');
  }
  set TokenExpirationDate(value) {
    this.Set('TokenExpirationDate', value);
  }
  /**
  * * Field Name: APIKey
  * * SQL Data Type: nvarchar(255)
  */
  get APIKey() {
    return this.Get('APIKey');
  }
  set APIKey(value) {
    this.Set('APIKey', value);
  }
  /**
  * * Field Name: ExternalSystemID
  * * Display Name: ExternalSystem
  * * SQL Data Type: nvarchar(100)
  */
  get ExternalSystemID() {
    return this.Get('ExternalSystemID');
  }
  set ExternalSystemID(value) {
    this.Set('ExternalSystemID', value);
  }
  /**
  * * Field Name: IsExternalSystemReadOnly
  * * Display Name: Is External System Read Only
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsExternalSystemReadOnly() {
    return this.Get('IsExternalSystemReadOnly');
  }
  set IsExternalSystemReadOnly(value) {
    this.Set('IsExternalSystemReadOnly', value);
  }
  /**
  * * Field Name: ClientID
  * * Display Name: Client
  * * SQL Data Type: nvarchar(255)
  */
  get ClientID() {
    return this.Get('ClientID');
  }
  set ClientID(value) {
    this.Set('ClientID', value);
  }
  /**
  * * Field Name: ClientSecret
  * * Display Name: Client Secret
  * * SQL Data Type: nvarchar(255)
  */
  get ClientSecret() {
    return this.Get('ClientSecret');
  }
  set ClientSecret(value) {
    this.Set('ClientSecret', value);
  }
  /**
  * * Field Name: CustomAttribute1
  * * Display Name: Custom Attribute 1
  * * SQL Data Type: nvarchar(255)
  */
  get CustomAttribute1() {
    return this.Get('CustomAttribute1');
  }
  set CustomAttribute1(value) {
    this.Set('CustomAttribute1', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Company
  * * SQL Data Type: nvarchar(50)
  */
  get Company() {
    return this.Get('Company');
  }
  /**
  * * Field Name: Integration
  * * SQL Data Type: nvarchar(100)
  */
  get Integration() {
    return this.Get('Integration');
  }
  /**
  * * Field Name: DriverClassName
  * * Display Name: Driver Class Name
  * * SQL Data Type: nvarchar(100)
  */
  get DriverClassName() {
    return this.Get('DriverClassName');
  }
  /**
  * * Field Name: DriverImportPath
  * * Display Name: Driver Import Path
  * * SQL Data Type: nvarchar(100)
  */
  get DriverImportPath() {
    return this.Get('DriverImportPath');
  }
  /**
  * * Field Name: LastRunID
  * * Display Name: LastRun
  * * SQL Data Type: uniqueidentifier
  */
  get LastRunID() {
    return this.Get('LastRunID');
  }
  /**
  * * Field Name: LastRunStartedAt
  * * Display Name: Last Run Started At
  * * SQL Data Type: datetime
  */
  get LastRunStartedAt() {
    return this.Get('LastRunStartedAt');
  }
  /**
  * * Field Name: LastRunEndedAt
  * * Display Name: Last Run Ended At
  * * SQL Data Type: datetime
  */
  get LastRunEndedAt() {
    return this.Get('LastRunEndedAt');
  }
};
exports.CompanyIntegrationEntity = CompanyIntegrationEntity;
exports.CompanyIntegrationEntity = CompanyIntegrationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Company Integrations')], CompanyIntegrationEntity);
/**
 * Conversation Details - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ConversationDetail
 * * Base View: vwConversationDetails
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ConversationDetailEntity = class ConversationDetailEntity extends core_1.BaseEntity {
  /**
  * Loads the Conversation Details record from the database
  * @param ID: string - primary key value to load the Conversation Details record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ConversationDetailEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ConversationID
  * * Display Name: Conversation ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Conversations (vwConversations.ID)
  */
  get ConversationID() {
    return this.Get('ConversationID');
  }
  set ConversationID(value) {
    this.Set('ConversationID', value);
  }
  /**
  * * Field Name: ExternalID
  * * Display Name: External ID
  * * SQL Data Type: nvarchar(100)
  */
  get ExternalID() {
    return this.Get('ExternalID');
  }
  set ExternalID(value) {
    this.Set('ExternalID', value);
  }
  /**
  * * Field Name: Role
  * * Display Name: Role
  * * SQL Data Type: nvarchar(20)
  * * Default Value: user_name()
  * * Value List Type: List
  * * Possible Values
  *   * User
  *   * AI
  *   * Error
  */
  get Role() {
    return this.Get('Role');
  }
  set Role(value) {
    this.Set('Role', value);
  }
  /**
  * * Field Name: Message
  * * Display Name: Message
  * * SQL Data Type: nvarchar(MAX)
  */
  get Message() {
    return this.Get('Message');
  }
  set Message(value) {
    this.Set('Message', value);
  }
  /**
  * * Field Name: Error
  * * Display Name: Error
  * * SQL Data Type: nvarchar(MAX)
  */
  get Error() {
    return this.Get('Error');
  }
  set Error(value) {
    this.Set('Error', value);
  }
  /**
  * * Field Name: HiddenToUser
  * * Display Name: Hidden To User
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get HiddenToUser() {
    return this.Get('HiddenToUser');
  }
  set HiddenToUser(value) {
    this.Set('HiddenToUser', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Conversation
  * * Display Name: Conversation
  * * SQL Data Type: nvarchar(255)
  */
  get Conversation() {
    return this.Get('Conversation');
  }
};
exports.ConversationDetailEntity = ConversationDetailEntity;
exports.ConversationDetailEntity = ConversationDetailEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Conversation Details')], ConversationDetailEntity);
/**
 * Conversations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Conversation
 * * Base View: vwConversations
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ConversationEntity = class ConversationEntity extends core_1.BaseEntity {
  /**
  * Loads the Conversations record from the database
  * @param ID: string - primary key value to load the Conversations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ConversationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: ExternalID
  * * Display Name: External ID
  * * SQL Data Type: nvarchar(500)
  */
  get ExternalID() {
    return this.Get('ExternalID');
  }
  set ExternalID(value) {
    this.Set('ExternalID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(50)
  * * Default Value: Skip
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: IsArchived
  * * Display Name: Is Archived
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsArchived() {
    return this.Get('IsArchived');
  }
  set IsArchived(value) {
    this.Set('IsArchived', value);
  }
  /**
  * * Field Name: LinkedEntityID
  * * Display Name: Linked Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get LinkedEntityID() {
    return this.Get('LinkedEntityID');
  }
  set LinkedEntityID(value) {
    this.Set('LinkedEntityID', value);
  }
  /**
  * * Field Name: LinkedRecordID
  * * Display Name: Linked Record ID
  * * SQL Data Type: nvarchar(500)
  */
  get LinkedRecordID() {
    return this.Get('LinkedRecordID');
  }
  set LinkedRecordID(value) {
    this.Set('LinkedRecordID', value);
  }
  /**
  * * Field Name: DataContextID
  * * Display Name: Data Context ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Data Contexts (vwDataContexts.ID)
  */
  get DataContextID() {
    return this.Get('DataContextID');
  }
  set DataContextID(value) {
    this.Set('DataContextID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: LinkedEntity
  * * Display Name: Linked Entity
  * * SQL Data Type: nvarchar(255)
  */
  get LinkedEntity() {
    return this.Get('LinkedEntity');
  }
};
exports.ConversationEntity = ConversationEntity;
exports.ConversationEntity = ConversationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Conversations')], ConversationEntity);
/**
 * Dashboard Categories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: DashboardCategory
 * * Base View: vwDashboardCategories
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DashboardCategoryEntity = class DashboardCategoryEntity extends core_1.BaseEntity {
  /**
  * Loads the Dashboard Categories record from the database
  * @param ID: string - primary key value to load the Dashboard Categories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DashboardCategoryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Dashboard Categories (vwDashboardCategories.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(100)
  */
  get Parent() {
    return this.Get('Parent');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.DashboardCategoryEntity = DashboardCategoryEntity;
exports.DashboardCategoryEntity = DashboardCategoryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Dashboard Categories')], DashboardCategoryEntity);
/**
 * Dashboards - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Dashboard
 * * Base View: vwDashboards
 * * @description Dashboards are used to group resources into a single display pane for an end-user
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DashboardEntity = class DashboardEntity extends core_1.BaseEntity {
  /**
  * Loads the Dashboards record from the database
  * @param ID: string - primary key value to load the Dashboards record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DashboardEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: CategoryID
  * * Display Name: Category ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Dashboard Categories (vwDashboardCategories.ID)
  */
  get CategoryID() {
    return this.Get('CategoryID');
  }
  set CategoryID(value) {
    this.Set('CategoryID', value);
  }
  /**
  * * Field Name: UIConfigDetails
  * * Display Name: UIConfig Details
  * * SQL Data Type: nvarchar(MAX)
  */
  get UIConfigDetails() {
    return this.Get('UIConfigDetails');
  }
  set UIConfigDetails(value) {
    this.Set('UIConfigDetails', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: Category
  * * Display Name: Category
  * * SQL Data Type: nvarchar(100)
  */
  get Category() {
    return this.Get('Category');
  }
};
exports.DashboardEntity = DashboardEntity;
exports.DashboardEntity = DashboardEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Dashboards')], DashboardEntity);
/**
 * Data Context Items - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: DataContextItem
 * * Base View: vwDataContextItems
 * * @description Data Context Items store information about each item within a Data Context. Each item stores a link to a view, query, or raw sql statement and can optionally cache the JSON representing the last run of that data object as well.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DataContextItemEntity = class DataContextItemEntity extends core_1.BaseEntity {
  /**
  * Loads the Data Context Items record from the database
  * @param ID: string - primary key value to load the Data Context Items record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DataContextItemEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: DataContextID
  * * Display Name: Data Context ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Data Contexts (vwDataContexts.ID)
  */
  get DataContextID() {
    return this.Get('DataContextID');
  }
  set DataContextID(value) {
    this.Set('DataContextID', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(50)
  * * Value List Type: List
  * * Possible Values
  *   * view
  *   * sql
  *   * query
  *   * single_record
  *   * full_entity
  * * Description: The type of the item, either "view", "query", "full_entity", "single_record", or "sql"
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: ViewID
  * * Display Name: View ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: User Views (vwUserViews.ID)
  */
  get ViewID() {
    return this.Get('ViewID');
  }
  set ViewID(value) {
    this.Set('ViewID', value);
  }
  /**
  * * Field Name: QueryID
  * * Display Name: Query ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Queries (vwQueries.ID)
  */
  get QueryID() {
    return this.Get('QueryID');
  }
  set QueryID(value) {
    this.Set('QueryID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record ID
  * * SQL Data Type: nvarchar(450)
  * * Description: The Primary Key value for the record, only used when Type='single_record'
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: SQL
  * * Display Name: SQL
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Only used when Type=sql
  */
  get SQL() {
    return this.Get('SQL');
  }
  set SQL(value) {
    this.Set('SQL', value);
  }
  /**
  * * Field Name: DataJSON
  * * Display Name: Data JSON
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Optionally used to cache results of an item. This can be used for performance optimization, and also for having snapshots of data for historical comparisons.
  */
  get DataJSON() {
    return this.Get('DataJSON');
  }
  set DataJSON(value) {
    this.Set('DataJSON', value);
  }
  /**
  * * Field Name: LastRefreshedAt
  * * Display Name: Last Refreshed At
  * * SQL Data Type: datetime
  * * Description: If DataJSON is populated, this field will show the date the the data was captured
  */
  get LastRefreshedAt() {
    return this.Get('LastRefreshedAt');
  }
  set LastRefreshedAt(value) {
    this.Set('LastRefreshedAt', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: DataContext
  * * Display Name: Data Context
  * * SQL Data Type: nvarchar(255)
  */
  get DataContext() {
    return this.Get('DataContext');
  }
  /**
  * * Field Name: View
  * * Display Name: View
  * * SQL Data Type: nvarchar(100)
  */
  get View() {
    return this.Get('View');
  }
  /**
  * * Field Name: Query
  * * Display Name: Query
  * * SQL Data Type: nvarchar(255)
  */
  get Query() {
    return this.Get('Query');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.DataContextItemEntity = DataContextItemEntity;
exports.DataContextItemEntity = DataContextItemEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Data Context Items')], DataContextItemEntity);
/**
 * Data Contexts - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: DataContext
 * * Base View: vwDataContexts
 * * @description Data Contexts are a primitive within the MemberJunction architecture. They store information about data contexts which are groups of data including views, queries, or raw SQL statements. Data contexts can be used in conversations, reports and more.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DataContextEntity = class DataContextEntity extends core_1.BaseEntity {
  /**
  * Loads the Data Contexts record from the database
  * @param ID: string - primary key value to load the Data Contexts record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DataContextEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: LastRefreshedAt
  * * Display Name: Last Refreshed At
  * * SQL Data Type: datetime
  */
  get LastRefreshedAt() {
    return this.Get('LastRefreshedAt');
  }
  set LastRefreshedAt(value) {
    this.Set('LastRefreshedAt', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.DataContextEntity = DataContextEntity;
exports.DataContextEntity = DataContextEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Data Contexts')], DataContextEntity);
/**
 * Dataset Items - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: DatasetItem
 * * Base View: vwDatasetItems
 * * @description A single item in a Dataset and can be sourced from multiple methods.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DatasetItemEntity = class DatasetItemEntity extends core_1.BaseEntity {
  /**
  * Loads the Dataset Items record from the database
  * @param ID: string - primary key value to load the Dataset Items record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DatasetItemEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Dataset Items - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof DatasetItemEntity
  * @throws {Error} - Save is not allowed for Dataset Items, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Dataset Items, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Dataset Items - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof DatasetItemEntity
  * @throws {Error} - Delete is not allowed for Dataset Items, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Dataset Items, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Code
  * * Display Name: Code
  * * SQL Data Type: nvarchar(50)
  */
  get Code() {
    return this.Get('Code');
  }
  set Code(value) {
    this.Set('Code', value);
  }
  /**
  * * Field Name: DatasetID
  * * Display Name: Dataset ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Datasets (vwDatasets.ID)
  */
  get DatasetID() {
    return this.Get('DatasetID');
  }
  set DatasetID(value) {
    this.Set('DatasetID', value);
  }
  /**
  * * Field Name: Sequence
  * * Display Name: Sequence
  * * SQL Data Type: int
  * * Default Value: 0
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: WhereClause
  * * Display Name: Where Clause
  * * SQL Data Type: nvarchar(MAX)
  */
  get WhereClause() {
    return this.Get('WhereClause');
  }
  set WhereClause(value) {
    this.Set('WhereClause', value);
  }
  /**
  * * Field Name: DateFieldToCheck
  * * Display Name: Date Field To Check
  * * SQL Data Type: nvarchar(100)
  */
  get DateFieldToCheck() {
    return this.Get('DateFieldToCheck');
  }
  set DateFieldToCheck(value) {
    this.Set('DateFieldToCheck', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.DatasetItemEntity = DatasetItemEntity;
exports.DatasetItemEntity = DatasetItemEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Dataset Items')], DatasetItemEntity);
/**
 * Datasets - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Dataset
 * * Base View: vwDatasets
 * * @description Cacheable sets of data that can span one or more items
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DatasetEntity = class DatasetEntity extends core_1.BaseEntity {
  /**
  * Loads the Datasets record from the database
  * @param ID: string - primary key value to load the Datasets record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DatasetEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Datasets - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof DatasetEntity
  * @throws {Error} - Save is not allowed for Datasets, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Datasets, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Datasets - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof DatasetEntity
  * @throws {Error} - Delete is not allowed for Datasets, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Datasets, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.DatasetEntity = DatasetEntity;
exports.DatasetEntity = DatasetEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Datasets')], DatasetEntity);
/**
 * Duplicate Run Detail Matches - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: DuplicateRunDetailMatch
 * * Base View: vwDuplicateRunDetailMatches
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DuplicateRunDetailMatchEntity = class DuplicateRunDetailMatchEntity extends core_1.BaseEntity {
  /**
  * Loads the Duplicate Run Detail Matches record from the database
  * @param ID: string - primary key value to load the Duplicate Run Detail Matches record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DuplicateRunDetailMatchEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Duplicate Run Detail Matches - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof DuplicateRunDetailMatchEntity
  * @throws {Error} - Delete is not allowed for Duplicate Run Detail Matches, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Duplicate Run Detail Matches, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: DuplicateRunDetailID
  * * Display Name: Duplicate Run Detail ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Duplicate Run Details (vwDuplicateRunDetails.ID)
  */
  get DuplicateRunDetailID() {
    return this.Get('DuplicateRunDetailID');
  }
  set DuplicateRunDetailID(value) {
    this.Set('DuplicateRunDetailID', value);
  }
  /**
  * * Field Name: MatchSource
  * * Display Name: Match Source
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Vector
  * * Value List Type: List
  * * Possible Values
  *   * SP
  *   * Vector
  * * Description: Either Vector or SP
  */
  get MatchSource() {
    return this.Get('MatchSource');
  }
  set MatchSource(value) {
    this.Set('MatchSource', value);
  }
  /**
  * * Field Name: MatchRecordID
  * * Display Name: Match Record ID
  * * SQL Data Type: nvarchar(500)
  */
  get MatchRecordID() {
    return this.Get('MatchRecordID');
  }
  set MatchRecordID(value) {
    this.Set('MatchRecordID', value);
  }
  /**
  * * Field Name: MatchProbability
  * * Display Name: Match Probability
  * * SQL Data Type: numeric(12, 11)
  * * Default Value: 0
  * * Description: Value between 0 and 1 designating the computed probability of a match
  */
  get MatchProbability() {
    return this.Get('MatchProbability');
  }
  set MatchProbability(value) {
    this.Set('MatchProbability', value);
  }
  /**
  * * Field Name: MatchedAt
  * * Display Name: Matched At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get MatchedAt() {
    return this.Get('MatchedAt');
  }
  set MatchedAt(value) {
    this.Set('MatchedAt', value);
  }
  /**
  * * Field Name: Action
  * * Display Name: Action
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Ignore
  */
  get Action() {
    return this.Get('Action');
  }
  set Action(value) {
    this.Set('Action', value);
  }
  /**
  * * Field Name: ApprovalStatus
  * * Display Name: Approval Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Rejected
  *   * Approved
  *   * Pending
  */
  get ApprovalStatus() {
    return this.Get('ApprovalStatus');
  }
  set ApprovalStatus(value) {
    this.Set('ApprovalStatus', value);
  }
  /**
  * * Field Name: RecordMergeLogID
  * * Display Name: Record Merge Log ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Record Merge Logs (vwRecordMergeLogs.ID)
  */
  get RecordMergeLogID() {
    return this.Get('RecordMergeLogID');
  }
  set RecordMergeLogID(value) {
    this.Set('RecordMergeLogID', value);
  }
  /**
  * * Field Name: MergeStatus
  * * Display Name: Merge Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Error
  *   * Complete
  *   * Pending
  */
  get MergeStatus() {
    return this.Get('MergeStatus');
  }
  set MergeStatus(value) {
    this.Set('MergeStatus', value);
  }
  /**
  * * Field Name: MergedAt
  * * Display Name: Merged At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get MergedAt() {
    return this.Get('MergedAt');
  }
  set MergedAt(value) {
    this.Set('MergedAt', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.DuplicateRunDetailMatchEntity = DuplicateRunDetailMatchEntity;
exports.DuplicateRunDetailMatchEntity = DuplicateRunDetailMatchEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Duplicate Run Detail Matches')], DuplicateRunDetailMatchEntity);
/**
 * Duplicate Run Details - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: DuplicateRunDetail
 * * Base View: vwDuplicateRunDetails
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DuplicateRunDetailEntity = class DuplicateRunDetailEntity extends core_1.BaseEntity {
  /**
  * Loads the Duplicate Run Details record from the database
  * @param ID: string - primary key value to load the Duplicate Run Details record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DuplicateRunDetailEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Duplicate Run Details - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof DuplicateRunDetailEntity
  * @throws {Error} - Delete is not allowed for Duplicate Run Details, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Duplicate Run Details, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: DuplicateRunID
  * * Display Name: Duplicate Run ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Duplicate Runs (vwDuplicateRuns.ID)
  */
  get DuplicateRunID() {
    return this.Get('DuplicateRunID');
  }
  set DuplicateRunID(value) {
    this.Set('DuplicateRunID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record ID
  * * SQL Data Type: nvarchar(500)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: MatchStatus
  * * Display Name: Match Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Error
  *   * Skipped
  *   * Complete
  *   * Pending
  */
  get MatchStatus() {
    return this.Get('MatchStatus');
  }
  set MatchStatus(value) {
    this.Set('MatchStatus', value);
  }
  /**
  * * Field Name: SkippedReason
  * * Display Name: Skipped Reason
  * * SQL Data Type: nvarchar(MAX)
  * * Description: If MatchStatus=Skipped, this field can be used to store the reason why the record was skipped
  */
  get SkippedReason() {
    return this.Get('SkippedReason');
  }
  set SkippedReason(value) {
    this.Set('SkippedReason', value);
  }
  /**
  * * Field Name: MatchErrorMessage
  * * Display Name: Match Error Message
  * * SQL Data Type: nvarchar(MAX)
  * * Description: If MatchStatus='Error' this field can be used to track the error from that phase of the process for logging/diagnostics.
  */
  get MatchErrorMessage() {
    return this.Get('MatchErrorMessage');
  }
  set MatchErrorMessage(value) {
    this.Set('MatchErrorMessage', value);
  }
  /**
  * * Field Name: MergeStatus
  * * Display Name: Merge Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Not Applicable
  * * Value List Type: List
  * * Possible Values
  *   * Error
  *   * Complete
  *   * Pending
  *   * Not Applicable
  */
  get MergeStatus() {
    return this.Get('MergeStatus');
  }
  set MergeStatus(value) {
    this.Set('MergeStatus', value);
  }
  /**
  * * Field Name: MergeErrorMessage
  * * Display Name: Merge Error Message
  * * SQL Data Type: nvarchar(MAX)
  */
  get MergeErrorMessage() {
    return this.Get('MergeErrorMessage');
  }
  set MergeErrorMessage(value) {
    this.Set('MergeErrorMessage', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.DuplicateRunDetailEntity = DuplicateRunDetailEntity;
exports.DuplicateRunDetailEntity = DuplicateRunDetailEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Duplicate Run Details')], DuplicateRunDetailEntity);
/**
 * Duplicate Runs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: DuplicateRun
 * * Base View: vwDuplicateRuns
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let DuplicateRunEntity = class DuplicateRunEntity extends core_1.BaseEntity {
  /**
  * Loads the Duplicate Runs record from the database
  * @param ID: string - primary key value to load the Duplicate Runs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof DuplicateRunEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Duplicate Runs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof DuplicateRunEntity
  * @throws {Error} - Delete is not allowed for Duplicate Runs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Duplicate Runs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: StartedByUserID
  * * Display Name: Started By User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get StartedByUserID() {
    return this.Get('StartedByUserID');
  }
  set StartedByUserID(value) {
    this.Set('StartedByUserID', value);
  }
  /**
  * * Field Name: SourceListID
  * * Display Name: Source List ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Lists (vwLists.ID)
  */
  get SourceListID() {
    return this.Get('SourceListID');
  }
  set SourceListID(value) {
    this.Set('SourceListID', value);
  }
  /**
  * * Field Name: StartedAt
  * * Display Name: Started At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get StartedAt() {
    return this.Get('StartedAt');
  }
  set StartedAt(value) {
    this.Set('StartedAt', value);
  }
  /**
  * * Field Name: EndedAt
  * * Display Name: Ended At
  * * SQL Data Type: datetime
  */
  get EndedAt() {
    return this.Get('EndedAt');
  }
  set EndedAt(value) {
    this.Set('EndedAt', value);
  }
  /**
  * * Field Name: ApprovalStatus
  * * Display Name: Approval Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Rejected
  *   * Approved
  *   * Pending
  */
  get ApprovalStatus() {
    return this.Get('ApprovalStatus');
  }
  set ApprovalStatus(value) {
    this.Set('ApprovalStatus', value);
  }
  /**
  * * Field Name: ApprovalComments
  * * Display Name: Approval Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get ApprovalComments() {
    return this.Get('ApprovalComments');
  }
  set ApprovalComments(value) {
    this.Set('ApprovalComments', value);
  }
  /**
  * * Field Name: ApprovedByUserID
  * * Display Name: Approved By User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get ApprovedByUserID() {
    return this.Get('ApprovedByUserID');
  }
  set ApprovedByUserID(value) {
    this.Set('ApprovedByUserID', value);
  }
  /**
  * * Field Name: ProcessingStatus
  * * Display Name: Processing Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Failed
  *   * Complete
  *   * In Progress
  *   * Pending
  */
  get ProcessingStatus() {
    return this.Get('ProcessingStatus');
  }
  set ProcessingStatus(value) {
    this.Set('ProcessingStatus', value);
  }
  /**
  * * Field Name: ProcessingErrorMessage
  * * Display Name: Processing Error Message
  * * SQL Data Type: nvarchar(MAX)
  */
  get ProcessingErrorMessage() {
    return this.Get('ProcessingErrorMessage');
  }
  set ProcessingErrorMessage(value) {
    this.Set('ProcessingErrorMessage', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: StartedByUser
  * * Display Name: Started By User
  * * SQL Data Type: nvarchar(100)
  */
  get StartedByUser() {
    return this.Get('StartedByUser');
  }
  /**
  * * Field Name: SourceList
  * * Display Name: Source List
  * * SQL Data Type: nvarchar(100)
  */
  get SourceList() {
    return this.Get('SourceList');
  }
  /**
  * * Field Name: ApprovedByUser
  * * Display Name: Approved By User
  * * SQL Data Type: nvarchar(100)
  */
  get ApprovedByUser() {
    return this.Get('ApprovedByUser');
  }
};
exports.DuplicateRunEntity = DuplicateRunEntity;
exports.DuplicateRunEntity = DuplicateRunEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Duplicate Runs')], DuplicateRunEntity);
/**
 * Employee Company Integrations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EmployeeCompanyIntegration
 * * Base View: vwEmployeeCompanyIntegrations
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EmployeeCompanyIntegrationEntity = class EmployeeCompanyIntegrationEntity extends core_1.BaseEntity {
  /**
  * Loads the Employee Company Integrations record from the database
  * @param ID: string - primary key value to load the Employee Company Integrations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EmployeeCompanyIntegrationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Employee Company Integrations - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EmployeeCompanyIntegrationEntity
  * @throws {Error} - Delete is not allowed for Employee Company Integrations, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Employee Company Integrations, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EmployeeID
  * * Display Name: Employee ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Employees (vwEmployees.ID)
  */
  get EmployeeID() {
    return this.Get('EmployeeID');
  }
  set EmployeeID(value) {
    this.Set('EmployeeID', value);
  }
  /**
  * * Field Name: CompanyIntegrationID
  * * Display Name: Company Integration ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Company Integrations (vwCompanyIntegrations.ID)
  */
  get CompanyIntegrationID() {
    return this.Get('CompanyIntegrationID');
  }
  set CompanyIntegrationID(value) {
    this.Set('CompanyIntegrationID', value);
  }
  /**
  * * Field Name: ExternalSystemRecordID
  * * Display Name: External System Record
  * * SQL Data Type: nvarchar(750)
  */
  get ExternalSystemRecordID() {
    return this.Get('ExternalSystemRecordID');
  }
  set ExternalSystemRecordID(value) {
    this.Set('ExternalSystemRecordID', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.EmployeeCompanyIntegrationEntity = EmployeeCompanyIntegrationEntity;
exports.EmployeeCompanyIntegrationEntity = EmployeeCompanyIntegrationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Employee Company Integrations')], EmployeeCompanyIntegrationEntity);
/**
 * Employee Roles - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EmployeeRole
 * * Base View: vwEmployeeRoles
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EmployeeRoleEntity = class EmployeeRoleEntity extends core_1.BaseEntity {
  /**
  * Loads the Employee Roles record from the database
  * @param ID: string - primary key value to load the Employee Roles record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EmployeeRoleEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Employee Roles - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EmployeeRoleEntity
  * @throws {Error} - Delete is not allowed for Employee Roles, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Employee Roles, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EmployeeID
  * * Display Name: Employee ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Employees (vwEmployees.ID)
  */
  get EmployeeID() {
    return this.Get('EmployeeID');
  }
  set EmployeeID(value) {
    this.Set('EmployeeID', value);
  }
  /**
  * * Field Name: RoleID
  * * Display Name: Role ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Roles (vwRoles.ID)
  */
  get RoleID() {
    return this.Get('RoleID');
  }
  set RoleID(value) {
    this.Set('RoleID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Role
  * * Display Name: Role
  * * SQL Data Type: nvarchar(50)
  */
  get Role() {
    return this.Get('Role');
  }
};
exports.EmployeeRoleEntity = EmployeeRoleEntity;
exports.EmployeeRoleEntity = EmployeeRoleEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Employee Roles')], EmployeeRoleEntity);
/**
 * Employee Skills - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EmployeeSkill
 * * Base View: vwEmployeeSkills
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EmployeeSkillEntity = class EmployeeSkillEntity extends core_1.BaseEntity {
  /**
  * Loads the Employee Skills record from the database
  * @param ID: string - primary key value to load the Employee Skills record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EmployeeSkillEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Employee Skills - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EmployeeSkillEntity
  * @throws {Error} - Delete is not allowed for Employee Skills, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Employee Skills, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EmployeeID
  * * Display Name: Employee ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Employees (vwEmployees.ID)
  */
  get EmployeeID() {
    return this.Get('EmployeeID');
  }
  set EmployeeID(value) {
    this.Set('EmployeeID', value);
  }
  /**
  * * Field Name: SkillID
  * * Display Name: Skill ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Skills (vwSkills.ID)
  */
  get SkillID() {
    return this.Get('SkillID');
  }
  set SkillID(value) {
    this.Set('SkillID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Skill
  * * Display Name: Skill
  * * SQL Data Type: nvarchar(50)
  */
  get Skill() {
    return this.Get('Skill');
  }
};
exports.EmployeeSkillEntity = EmployeeSkillEntity;
exports.EmployeeSkillEntity = EmployeeSkillEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Employee Skills')], EmployeeSkillEntity);
/**
 * Employees - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Employee
 * * Base View: vwEmployees
 * * @description A list of employees across all units of your organization
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EmployeeEntity = class EmployeeEntity extends core_1.BaseEntity {
  /**
  * Loads the Employees record from the database
  * @param ID: string - primary key value to load the Employees record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EmployeeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: BCMID
  * * Display Name: BCMID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newid()
  */
  get BCMID() {
    return this.Get('BCMID');
  }
  /**
  * * Field Name: FirstName
  * * Display Name: First Name
  * * SQL Data Type: nvarchar(30)
  */
  get FirstName() {
    return this.Get('FirstName');
  }
  set FirstName(value) {
    this.Set('FirstName', value);
  }
  /**
  * * Field Name: LastName
  * * Display Name: Last Name
  * * SQL Data Type: nvarchar(50)
  */
  get LastName() {
    return this.Get('LastName');
  }
  set LastName(value) {
    this.Set('LastName', value);
  }
  /**
  * * Field Name: CompanyID
  * * Display Name: Company ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Companies (vwCompanies.ID)
  */
  get CompanyID() {
    return this.Get('CompanyID');
  }
  set CompanyID(value) {
    this.Set('CompanyID', value);
  }
  /**
  * * Field Name: SupervisorID
  * * Display Name: Supervisor ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Employees (vwEmployees.ID)
  */
  get SupervisorID() {
    return this.Get('SupervisorID');
  }
  set SupervisorID(value) {
    this.Set('SupervisorID', value);
  }
  /**
  * * Field Name: Title
  * * SQL Data Type: nvarchar(50)
  */
  get Title() {
    return this.Get('Title');
  }
  set Title(value) {
    this.Set('Title', value);
  }
  /**
  * * Field Name: Email
  * * SQL Data Type: nvarchar(100)
  */
  get Email() {
    return this.Get('Email');
  }
  set Email(value) {
    this.Set('Email', value);
  }
  /**
  * * Field Name: Phone
  * * SQL Data Type: nvarchar(20)
  */
  get Phone() {
    return this.Get('Phone');
  }
  set Phone(value) {
    this.Set('Phone', value);
  }
  /**
  * * Field Name: Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get Active() {
    return this.Get('Active');
  }
  set Active(value) {
    this.Set('Active', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: FirstLast
  * * Display Name: First Last
  * * SQL Data Type: nvarchar(81)
  */
  get FirstLast() {
    return this.Get('FirstLast');
  }
  /**
  * * Field Name: Supervisor
  * * Display Name: Supervisor
  * * SQL Data Type: nvarchar(81)
  */
  get Supervisor() {
    return this.Get('Supervisor');
  }
  /**
  * * Field Name: SupervisorFirstName
  * * Display Name: Supervisor First Name
  * * SQL Data Type: nvarchar(30)
  */
  get SupervisorFirstName() {
    return this.Get('SupervisorFirstName');
  }
  /**
  * * Field Name: SupervisorLastName
  * * Display Name: Supervisor Last Name
  * * SQL Data Type: nvarchar(50)
  */
  get SupervisorLastName() {
    return this.Get('SupervisorLastName');
  }
  /**
  * * Field Name: SupervisorEmail
  * * Display Name: Supervisor Email
  * * SQL Data Type: nvarchar(100)
  */
  get SupervisorEmail() {
    return this.Get('SupervisorEmail');
  }
};
exports.EmployeeEntity = EmployeeEntity;
exports.EmployeeEntity = EmployeeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Employees')], EmployeeEntity);
/**
 * Entities - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Entity
 * * Base View: vwEntities
 * * @description Catalog of all entities across all schemas
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityEntity = class EntityEntity extends core_1.BaseEntity {
  /**
  * Loads the Entities record from the database
  * @param ID: string - primary key value to load the Entities record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: NameSuffix
  * * Display Name: Name Suffix
  * * SQL Data Type: nvarchar(255)
  */
  get NameSuffix() {
    return this.Get('NameSuffix');
  }
  set NameSuffix(value) {
    this.Set('NameSuffix', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: AutoUpdateDescription
  * * Display Name: Auto Update Description
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When set to 1 (default), whenever a description is modified in the underlying view (first choice) or table (second choice), the Description column in the entity definition will be automatically updated. If you never set metadata in the database directly, you can leave this alone. However, if you have metadata set in the database level for description, and you want to provide a DIFFERENT description in this entity definition, turn this bit off and then set the Description field and future CodeGen runs will NOT override the Description field here.
  */
  get AutoUpdateDescription() {
    return this.Get('AutoUpdateDescription');
  }
  set AutoUpdateDescription(value) {
    this.Set('AutoUpdateDescription', value);
  }
  /**
  * * Field Name: BaseTable
  * * Display Name: Base Table
  * * SQL Data Type: nvarchar(255)
  */
  get BaseTable() {
    return this.Get('BaseTable');
  }
  /**
  * * Field Name: BaseView
  * * Display Name: Base View
  * * SQL Data Type: nvarchar(255)
  */
  get BaseView() {
    return this.Get('BaseView');
  }
  set BaseView(value) {
    this.Set('BaseView', value);
  }
  /**
  * * Field Name: BaseViewGenerated
  * * Display Name: Base View Generated
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When set to 0, CodeGen no longer generates a base view for the entity.
  */
  get BaseViewGenerated() {
    return this.Get('BaseViewGenerated');
  }
  set BaseViewGenerated(value) {
    this.Set('BaseViewGenerated', value);
  }
  /**
  * * Field Name: SchemaName
  * * Display Name: Schema Name
  * * SQL Data Type: nvarchar(255)
  * * Default Value: dbo
  */
  get SchemaName() {
    return this.Get('SchemaName');
  }
  /**
  * * Field Name: VirtualEntity
  * * Display Name: Virtual Entity
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get VirtualEntity() {
    return this.Get('VirtualEntity');
  }
  set VirtualEntity(value) {
    this.Set('VirtualEntity', value);
  }
  /**
  * * Field Name: TrackRecordChanges
  * * Display Name: Track Record Changes
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When set to 1, changes made via the MemberJunction architecture will result in tracking records being created in the RecordChange table. In addition, when turned on CodeGen will ensure that your table has two fields: __mj_CreatedAt and __mj_UpdatedAt which are special fields used in conjunction with the RecordChange table to track changes to rows in your entity.
  */
  get TrackRecordChanges() {
    return this.Get('TrackRecordChanges');
  }
  set TrackRecordChanges(value) {
    this.Set('TrackRecordChanges', value);
  }
  /**
  * * Field Name: AuditRecordAccess
  * * Display Name: Audit Record Access
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When set to 1, accessing a record by an end-user will result in an Audit Log record being created
  */
  get AuditRecordAccess() {
    return this.Get('AuditRecordAccess');
  }
  set AuditRecordAccess(value) {
    this.Set('AuditRecordAccess', value);
  }
  /**
  * * Field Name: AuditViewRuns
  * * Display Name: Audit View Runs
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When set to 1, users running a view against this entity will result in an Audit Log record being created.
  */
  get AuditViewRuns() {
    return this.Get('AuditViewRuns');
  }
  set AuditViewRuns(value) {
    this.Set('AuditViewRuns', value);
  }
  /**
  * * Field Name: IncludeInAPI
  * * Display Name: Include In API
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 0, the entity will not be available at all in the GraphQL API or the object model.
  */
  get IncludeInAPI() {
    return this.Get('IncludeInAPI');
  }
  set IncludeInAPI(value) {
    this.Set('IncludeInAPI', value);
  }
  /**
  * * Field Name: AllowAllRowsAPI
  * * Display Name: Allow All Rows API
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 1, a GraphQL query will be enabled that allows access to all rows in the entity.
  */
  get AllowAllRowsAPI() {
    return this.Get('AllowAllRowsAPI');
  }
  set AllowAllRowsAPI(value) {
    this.Set('AllowAllRowsAPI', value);
  }
  /**
  * * Field Name: AllowUpdateAPI
  * * Display Name: Allow Update API
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Global flag controlling if updates are allowed for any user, or not. If set to 1, a GraqhQL mutation and stored procedure are created. Permissions are still required to perform the action but if this flag is set to 0, no user will be able to perform the action.
  */
  get AllowUpdateAPI() {
    return this.Get('AllowUpdateAPI');
  }
  set AllowUpdateAPI(value) {
    this.Set('AllowUpdateAPI', value);
  }
  /**
  * * Field Name: AllowCreateAPI
  * * Display Name: Allow Create API
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Global flag controlling if creates are allowed for any user, or not. If set to 1, a GraqhQL mutation and stored procedure are created. Permissions are still required to perform the action but if this flag is set to 0, no user will be able to perform the action.
  */
  get AllowCreateAPI() {
    return this.Get('AllowCreateAPI');
  }
  set AllowCreateAPI(value) {
    this.Set('AllowCreateAPI', value);
  }
  /**
  * * Field Name: AllowDeleteAPI
  * * Display Name: Allow Delete API
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Global flag controlling if deletes are allowed for any user, or not. If set to 1, a GraqhQL mutation and stored procedure are created. Permissions are still required to perform the action but if this flag is set to 0, no user will be able to perform the action.
  */
  get AllowDeleteAPI() {
    return this.Get('AllowDeleteAPI');
  }
  set AllowDeleteAPI(value) {
    this.Set('AllowDeleteAPI', value);
  }
  /**
  * * Field Name: CustomResolverAPI
  * * Display Name: Custom Resolver API
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Set to 1 if a custom resolver has been created for the entity.
  */
  get CustomResolverAPI() {
    return this.Get('CustomResolverAPI');
  }
  set CustomResolverAPI(value) {
    this.Set('CustomResolverAPI', value);
  }
  /**
  * * Field Name: AllowUserSearchAPI
  * * Display Name: Allow User Search API
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Enabling this bit will result in search being possible at the API and UI layers
  */
  get AllowUserSearchAPI() {
    return this.Get('AllowUserSearchAPI');
  }
  set AllowUserSearchAPI(value) {
    this.Set('AllowUserSearchAPI', value);
  }
  /**
  * * Field Name: FullTextSearchEnabled
  * * Display Name: Full Text Search Enabled
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get FullTextSearchEnabled() {
    return this.Get('FullTextSearchEnabled');
  }
  set FullTextSearchEnabled(value) {
    this.Set('FullTextSearchEnabled', value);
  }
  /**
  * * Field Name: FullTextCatalog
  * * Display Name: Full Text Catalog
  * * SQL Data Type: nvarchar(255)
  */
  get FullTextCatalog() {
    return this.Get('FullTextCatalog');
  }
  set FullTextCatalog(value) {
    this.Set('FullTextCatalog', value);
  }
  /**
  * * Field Name: FullTextCatalogGenerated
  * * Display Name: Full Text Catalog Generated
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get FullTextCatalogGenerated() {
    return this.Get('FullTextCatalogGenerated');
  }
  set FullTextCatalogGenerated(value) {
    this.Set('FullTextCatalogGenerated', value);
  }
  /**
  * * Field Name: FullTextIndex
  * * Display Name: Full Text Index
  * * SQL Data Type: nvarchar(255)
  */
  get FullTextIndex() {
    return this.Get('FullTextIndex');
  }
  set FullTextIndex(value) {
    this.Set('FullTextIndex', value);
  }
  /**
  * * Field Name: FullTextIndexGenerated
  * * Display Name: Full Text Index Generated
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get FullTextIndexGenerated() {
    return this.Get('FullTextIndexGenerated');
  }
  set FullTextIndexGenerated(value) {
    this.Set('FullTextIndexGenerated', value);
  }
  /**
  * * Field Name: FullTextSearchFunction
  * * Display Name: Full Text Search Function
  * * SQL Data Type: nvarchar(255)
  */
  get FullTextSearchFunction() {
    return this.Get('FullTextSearchFunction');
  }
  set FullTextSearchFunction(value) {
    this.Set('FullTextSearchFunction', value);
  }
  /**
  * * Field Name: FullTextSearchFunctionGenerated
  * * Display Name: Full Text Search Function Generated
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get FullTextSearchFunctionGenerated() {
    return this.Get('FullTextSearchFunctionGenerated');
  }
  set FullTextSearchFunctionGenerated(value) {
    this.Set('FullTextSearchFunctionGenerated', value);
  }
  /**
  * * Field Name: UserViewMaxRows
  * * Display Name: User View Max Rows
  * * SQL Data Type: int
  * * Default Value: 1000
  */
  get UserViewMaxRows() {
    return this.Get('UserViewMaxRows');
  }
  set UserViewMaxRows(value) {
    this.Set('UserViewMaxRows', value);
  }
  /**
  * * Field Name: spCreate
  * * Display Name: spCreate
  * * SQL Data Type: nvarchar(255)
  */
  get spCreate() {
    return this.Get('spCreate');
  }
  set spCreate(value) {
    this.Set('spCreate', value);
  }
  /**
  * * Field Name: spUpdate
  * * Display Name: spUpdate
  * * SQL Data Type: nvarchar(255)
  */
  get spUpdate() {
    return this.Get('spUpdate');
  }
  set spUpdate(value) {
    this.Set('spUpdate', value);
  }
  /**
  * * Field Name: spDelete
  * * Display Name: spDelete
  * * SQL Data Type: nvarchar(255)
  */
  get spDelete() {
    return this.Get('spDelete');
  }
  set spDelete(value) {
    this.Set('spDelete', value);
  }
  /**
  * * Field Name: spCreateGenerated
  * * Display Name: sp CreateGenerated
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get spCreateGenerated() {
    return this.Get('spCreateGenerated');
  }
  set spCreateGenerated(value) {
    this.Set('spCreateGenerated', value);
  }
  /**
  * * Field Name: spUpdateGenerated
  * * Display Name: sp Update Generated
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get spUpdateGenerated() {
    return this.Get('spUpdateGenerated');
  }
  set spUpdateGenerated(value) {
    this.Set('spUpdateGenerated', value);
  }
  /**
  * * Field Name: spDeleteGenerated
  * * Display Name: sp Delete Generated
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get spDeleteGenerated() {
    return this.Get('spDeleteGenerated');
  }
  set spDeleteGenerated(value) {
    this.Set('spDeleteGenerated', value);
  }
  /**
  * * Field Name: CascadeDeletes
  * * Display Name: Cascade Deletes
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: When set to 1, the deleted spDelete will pre-process deletion to related entities that have 1:M cardinality with this entity. This does not have effect if spDeleteGenerated = 0
  */
  get CascadeDeletes() {
    return this.Get('CascadeDeletes');
  }
  set CascadeDeletes(value) {
    this.Set('CascadeDeletes', value);
  }
  /**
  * * Field Name: DeleteType
  * * Display Name: Delete Type
  * * SQL Data Type: nvarchar(10)
  * * Default Value: Hard
  * * Value List Type: List
  * * Possible Values
  *   * Hard
  *   * Soft
  * * Description: Hard deletes physically remove rows from the underlying BaseTable. Soft deletes do not remove rows but instead mark the row as deleted by using the special field __mj_DeletedAt which will automatically be added to the entity's basetable by the CodeGen tool.
  */
  get DeleteType() {
    return this.Get('DeleteType');
  }
  set DeleteType(value) {
    this.Set('DeleteType', value);
  }
  /**
  * * Field Name: AllowRecordMerge
  * * Display Name: Allow Record Merge
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: This field must be turned on in order to enable merging of records for the entity. For AllowRecordMerge to be turned on, AllowDeleteAPI must be set to 1, and DeleteType must be set to Soft
  */
  get AllowRecordMerge() {
    return this.Get('AllowRecordMerge');
  }
  set AllowRecordMerge(value) {
    this.Set('AllowRecordMerge', value);
  }
  /**
  * * Field Name: spMatch
  * * Display Name: sp Match
  * * SQL Data Type: nvarchar(255)
  * * Description: When specified, this stored procedure is used to find matching records in this particular entity. The convention is to pass in the primary key(s) columns for the given entity to the procedure and the return will be zero to many rows where there is a column for each primary key field(s) and a ProbabilityScore (numeric(1,12)) column that has a 0 to 1 value of the probability of a match.
  */
  get spMatch() {
    return this.Get('spMatch');
  }
  set spMatch(value) {
    this.Set('spMatch', value);
  }
  /**
  * * Field Name: RelationshipDefaultDisplayType
  * * Display Name: Relationship Default Display Type
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Search
  * * Value List Type: List
  * * Possible Values
  *   * Search
  *   * Dropdown
  * * Description: When another entity links to this entity with a foreign key, this is the default component type that will be used in the UI. CodeGen will populate the RelatedEntityDisplayType column in the Entity Fields entity with whatever is provided here whenever a new foreign key is detected by CodeGen. The selection can be overridden on a per-foreign-key basis in each row of the Entity Fields entity.
  */
  get RelationshipDefaultDisplayType() {
    return this.Get('RelationshipDefaultDisplayType');
  }
  set RelationshipDefaultDisplayType(value) {
    this.Set('RelationshipDefaultDisplayType', value);
  }
  /**
  * * Field Name: UserFormGenerated
  * * Display Name: User Form Generated
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get UserFormGenerated() {
    return this.Get('UserFormGenerated');
  }
  set UserFormGenerated(value) {
    this.Set('UserFormGenerated', value);
  }
  /**
  * * Field Name: EntityObjectSubclassName
  * * Display Name: Entity Object Subclass Name
  * * SQL Data Type: nvarchar(255)
  */
  get EntityObjectSubclassName() {
    return this.Get('EntityObjectSubclassName');
  }
  set EntityObjectSubclassName(value) {
    this.Set('EntityObjectSubclassName', value);
  }
  /**
  * * Field Name: EntityObjectSubclassImport
  * * Display Name: Entity Object Subclass Import
  * * SQL Data Type: nvarchar(255)
  */
  get EntityObjectSubclassImport() {
    return this.Get('EntityObjectSubclassImport');
  }
  set EntityObjectSubclassImport(value) {
    this.Set('EntityObjectSubclassImport', value);
  }
  /**
  * * Field Name: PreferredCommunicationField
  * * Display Name: Preferred Communication Field
  * * SQL Data Type: nvarchar(255)
  * * Description: Used to specify a field within the entity that in turn contains the field name that will be used for record-level communication preferences. For example in a hypothetical entity called Contacts, say there is a field called PreferredComm and that field had possible values of Email1, SMS, and Phone, and those value in turn corresponded to field names in the entity. Each record in the Contacts entity could have a specific preference for which field would be used for communication. The MJ Communication Framework will use this information when available, as a priority ahead of the data in the Entity Communication Fields entity which is entity-level and not record-level.
  */
  get PreferredCommunicationField() {
    return this.Get('PreferredCommunicationField');
  }
  set PreferredCommunicationField(value) {
    this.Set('PreferredCommunicationField', value);
  }
  /**
  * * Field Name: Icon
  * * Display Name: Icon
  * * SQL Data Type: nvarchar(500)
  * * Description: Optional, specify an icon (CSS Class) for each entity for display in the UI
  */
  get Icon() {
    return this.Get('Icon');
  }
  set Icon(value) {
    this.Set('Icon', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: CodeName
  * * Display Name: Code Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get CodeName() {
    return this.Get('CodeName');
  }
  /**
  * * Field Name: ClassName
  * * Display Name: Class Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get ClassName() {
    return this.Get('ClassName');
  }
  /**
  * * Field Name: BaseTableCodeName
  * * Display Name: Base Table Code Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get BaseTableCodeName() {
    return this.Get('BaseTableCodeName');
  }
  /**
  * * Field Name: ParentEntity
  * * Display Name: Parent Entity
  * * SQL Data Type: nvarchar(255)
  */
  get ParentEntity() {
    return this.Get('ParentEntity');
  }
  /**
  * * Field Name: ParentBaseTable
  * * Display Name: Parent Base Table
  * * SQL Data Type: nvarchar(255)
  */
  get ParentBaseTable() {
    return this.Get('ParentBaseTable');
  }
  /**
  * * Field Name: ParentBaseView
  * * Display Name: Parent Base View
  * * SQL Data Type: nvarchar(255)
  */
  get ParentBaseView() {
    return this.Get('ParentBaseView');
  }
};
exports.EntityEntity = EntityEntity;
exports.EntityEntity = EntityEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entities')], EntityEntity);
/**
 * Entity Action Filters - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityActionFilter
 * * Base View: vwEntityActionFilters
 * * @description Optional use. Maps Action Filters to specific EntityAction instances, specifying execution order and status. This allows for “pre-processing” before an Action actually is fired off, to check for various state/dirty/value conditions.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityActionFilterEntity = class EntityActionFilterEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Action Filters record from the database
  * @param ID: string - primary key value to load the Entity Action Filters record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityActionFilterEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityActionID
  * * Display Name: Entity Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Actions (vwEntityActions.ID)
  */
  get EntityActionID() {
    return this.Get('EntityActionID');
  }
  set EntityActionID(value) {
    this.Set('EntityActionID', value);
  }
  /**
  * * Field Name: ActionFilterID
  * * Display Name: Action Filter ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Action Filters (vwActionFilters.ID)
  */
  get ActionFilterID() {
    return this.Get('ActionFilterID');
  }
  set ActionFilterID(value) {
    this.Set('ActionFilterID', value);
  }
  /**
  * * Field Name: Sequence
  * * Display Name: Sequence
  * * SQL Data Type: int
  * * Description: Order of filter execution.
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Disabled
  *   * Active
  *   * Pending
  * * Description: Status of the entity action filter (Pending, Active, Disabled).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.EntityActionFilterEntity = EntityActionFilterEntity;
exports.EntityActionFilterEntity = EntityActionFilterEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Action Filters')], EntityActionFilterEntity);
/**
 * Entity Action Invocation Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityActionInvocationType
 * * Base View: vwEntityActionInvocationTypes
 * * @description Stores the possible invocation types of an action within the context of an entity. Examples would be: Record Created/Updated/Deleted/Accessed as well as things like “View” or “List” where you could run an EntityAction against an entire set of records in a view or list – either by user click or programmatically.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityActionInvocationTypeEntity = class EntityActionInvocationTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Action Invocation Types record from the database
  * @param ID: string - primary key value to load the Entity Action Invocation Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityActionInvocationTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the invocation type such as Record Created/Updated/etc.
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the invocation type.
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: DisplaySequence
  * * Display Name: Display Sequence
  * * SQL Data Type: int
  * * Default Value: 0
  */
  get DisplaySequence() {
    return this.Get('DisplaySequence');
  }
  set DisplaySequence(value) {
    this.Set('DisplaySequence', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.EntityActionInvocationTypeEntity = EntityActionInvocationTypeEntity;
exports.EntityActionInvocationTypeEntity = EntityActionInvocationTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Action Invocation Types')], EntityActionInvocationTypeEntity);
/**
 * Entity Action Invocations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityActionInvocation
 * * Base View: vwEntityActionInvocations
 * * @description Links invocation types to entity actions – for example you might link a particular EntityAction to just “Create Record” and you might also have a second item in this table allowing the same Entity Action to be invoked from a User View or List, on demand.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityActionInvocationEntity = class EntityActionInvocationEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Action Invocations record from the database
  * @param ID: string - primary key value to load the Entity Action Invocations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityActionInvocationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityActionID
  * * Display Name: Entity Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Actions (vwEntityActions.ID)
  */
  get EntityActionID() {
    return this.Get('EntityActionID');
  }
  set EntityActionID(value) {
    this.Set('EntityActionID', value);
  }
  /**
  * * Field Name: InvocationTypeID
  * * Display Name: Invocation Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Action Invocation Types (vwEntityActionInvocationTypes.ID)
  */
  get InvocationTypeID() {
    return this.Get('InvocationTypeID');
  }
  set InvocationTypeID(value) {
    this.Set('InvocationTypeID', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Disabled
  *   * Active
  *   * Pending
  * * Description: Status of the entity action invocation (Pending, Active, Disabled).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: InvocationType
  * * Display Name: Invocation Type
  * * SQL Data Type: nvarchar(255)
  */
  get InvocationType() {
    return this.Get('InvocationType');
  }
};
exports.EntityActionInvocationEntity = EntityActionInvocationEntity;
exports.EntityActionInvocationEntity = EntityActionInvocationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Action Invocations')], EntityActionInvocationEntity);
/**
 * Entity Action Params - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityActionParam
 * * Base View: vwEntityActionParams
 * * @description Stores paramater mappings to enable Entity Actions to automatically invoke Actions
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityActionParamEntity = class EntityActionParamEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Action Params record from the database
  * @param ID: string - primary key value to load the Entity Action Params record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityActionParamEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityActionID
  * * Display Name: Entity Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Actions (vwEntityActions.ID)
  */
  get EntityActionID() {
    return this.Get('EntityActionID');
  }
  set EntityActionID(value) {
    this.Set('EntityActionID', value);
  }
  /**
  * * Field Name: ActionParamID
  * * Display Name: Action Param ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Action Params (vwActionParams.ID)
  */
  get ActionParamID() {
    return this.Get('ActionParamID');
  }
  set ActionParamID(value) {
    this.Set('ActionParamID', value);
  }
  /**
  * * Field Name: ValueType
  * * Display Name: Value Type
  * * SQL Data Type: nvarchar(20)
  * * Value List Type: List
  * * Possible Values
  *   * Static
  *   * Entity Object
  *   * Script
  *   * Entity Field
  * * Description: Type of the value, which can be Static, Entity Object, or Script.
  */
  get ValueType() {
    return this.Get('ValueType');
  }
  set ValueType(value) {
    this.Set('ValueType', value);
  }
  /**
  * * Field Name: Value
  * * Display Name: Value
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Value of the parameter, used only when ValueType is Static or Script. When value is Script, any valid JavaScript code can be provided. The script will have access to an object called EntityActionContext. This object will have a property called EntityObject on it that will contain the BaseEntity derived sub-class with the current data for the entity object this action is operating against. The script must provide the parameter value to the EntityActionContext.result property. This scripting capabilty is designed for very small and simple code, for anything of meaningful complexity, create a sub-class instead.
  */
  get Value() {
    return this.Get('Value');
  }
  set Value(value) {
    this.Set('Value', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Additional comments regarding the parameter.
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: ActionParam
  * * Display Name: Action Param
  * * SQL Data Type: nvarchar(255)
  */
  get ActionParam() {
    return this.Get('ActionParam');
  }
};
exports.EntityActionParamEntity = EntityActionParamEntity;
exports.EntityActionParamEntity = EntityActionParamEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Action Params')], EntityActionParamEntity);
/**
 * Entity Actions - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityAction
 * * Base View: vwEntityActions
 * * @description Links entities to actions - this is the main place where you define the actions that part of, or available, for a given entity.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityActionEntity = class EntityActionEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Actions record from the database
  * @param ID: string - primary key value to load the Entity Actions record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityActionEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: ActionID
  * * Display Name: Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Actions (vwActions.ID)
  */
  get ActionID() {
    return this.Get('ActionID');
  }
  set ActionID(value) {
    this.Set('ActionID', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Disabled
  *   * Active
  *   * Pending
  * * Description: Status of the entity action (Pending, Active, Disabled).
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: Action
  * * Display Name: Action
  * * SQL Data Type: nvarchar(425)
  */
  get Action() {
    return this.Get('Action');
  }
};
exports.EntityActionEntity = EntityActionEntity;
exports.EntityActionEntity = EntityActionEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Actions')], EntityActionEntity);
/**
 * Entity AI Actions - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityAIAction
 * * Base View: vwEntityAIActions
 * * @description Tracks the AI actions that should be invoked based on changes to records within a given entity.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityAIActionEntity = class EntityAIActionEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity AI Actions record from the database
  * @param ID: string - primary key value to load the Entity AI Actions record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityAIActionEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: AIModelID
  * * Display Name: AI Model ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: AI Models (vwAIModels.ID)
  */
  get AIModelID() {
    return this.Get('AIModelID');
  }
  set AIModelID(value) {
    this.Set('AIModelID', value);
  }
  /**
  * * Field Name: AIActionID
  * * Display Name: AI Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: AI Actions (vwAIActions.ID)
  */
  get AIActionID() {
    return this.Get('AIActionID');
  }
  set AIActionID(value) {
    this.Set('AIActionID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Prompt
  * * Display Name: Prompt
  * * SQL Data Type: nvarchar(MAX)
  */
  get Prompt() {
    return this.Get('Prompt');
  }
  set Prompt(value) {
    this.Set('Prompt', value);
  }
  /**
  * * Field Name: TriggerEvent
  * * Display Name: Trigger Event
  * * SQL Data Type: nchar(15)
  * * Default Value: After Save
  * * Value List Type: List
  * * Possible Values
  *   * after save
  *   * before save
  */
  get TriggerEvent() {
    return this.Get('TriggerEvent');
  }
  set TriggerEvent(value) {
    this.Set('TriggerEvent', value);
  }
  /**
  * * Field Name: UserMessage
  * * Display Name: User Message
  * * SQL Data Type: nvarchar(MAX)
  */
  get UserMessage() {
    return this.Get('UserMessage');
  }
  set UserMessage(value) {
    this.Set('UserMessage', value);
  }
  /**
  * * Field Name: OutputType
  * * Display Name: Output Type
  * * SQL Data Type: nchar(10)
  * * Default Value: FIeld
  * * Value List Type: List
  * * Possible Values
  *   * entity
  *   * field
  */
  get OutputType() {
    return this.Get('OutputType');
  }
  set OutputType(value) {
    this.Set('OutputType', value);
  }
  /**
  * * Field Name: OutputField
  * * Display Name: Output Field
  * * SQL Data Type: nvarchar(50)
  */
  get OutputField() {
    return this.Get('OutputField');
  }
  set OutputField(value) {
    this.Set('OutputField', value);
  }
  /**
  * * Field Name: SkipIfOutputFieldNotEmpty
  * * Display Name: Skip If Output Field Not Empty
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get SkipIfOutputFieldNotEmpty() {
    return this.Get('SkipIfOutputFieldNotEmpty');
  }
  set SkipIfOutputFieldNotEmpty(value) {
    this.Set('SkipIfOutputFieldNotEmpty', value);
  }
  /**
  * * Field Name: OutputEntityID
  * * Display Name: Output Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get OutputEntityID() {
    return this.Get('OutputEntityID');
  }
  set OutputEntityID(value) {
    this.Set('OutputEntityID', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: AIModel
  * * Display Name: AIModel
  * * SQL Data Type: nvarchar(50)
  */
  get AIModel() {
    return this.Get('AIModel');
  }
  /**
  * * Field Name: AIAction
  * * Display Name: AIAction
  * * SQL Data Type: nvarchar(50)
  */
  get AIAction() {
    return this.Get('AIAction');
  }
  /**
  * * Field Name: OutputEntity
  * * Display Name: Output Entity
  * * SQL Data Type: nvarchar(255)
  */
  get OutputEntity() {
    return this.Get('OutputEntity');
  }
};
exports.EntityAIActionEntity = EntityAIActionEntity;
exports.EntityAIActionEntity = EntityAIActionEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity AI Actions')], EntityAIActionEntity);
/**
 * Entity Communication Fields - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityCommunicationField
 * * Base View: vwEntityCommunicationFields
 * * @description Mapping between entity fields and communication base message types with priority
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityCommunicationFieldEntity = class EntityCommunicationFieldEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Communication Fields record from the database
  * @param ID: string - primary key value to load the Entity Communication Fields record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityCommunicationFieldEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Communication Fields - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityCommunicationFieldEntity
  * @throws {Error} - Delete is not allowed for Entity Communication Fields, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Communication Fields, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityCommunicationMessageTypeID
  * * Display Name: Entity Communication Message Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Communication Message Types (vwEntityCommunicationMessageTypes.ID)
  */
  get EntityCommunicationMessageTypeID() {
    return this.Get('EntityCommunicationMessageTypeID');
  }
  set EntityCommunicationMessageTypeID(value) {
    this.Set('EntityCommunicationMessageTypeID', value);
  }
  /**
  * * Field Name: FieldName
  * * Display Name: Field Name
  * * SQL Data Type: nvarchar(500)
  * * Description: Name of the field in the entity that maps to the communication base message type
  */
  get FieldName() {
    return this.Get('FieldName');
  }
  set FieldName(value) {
    this.Set('FieldName', value);
  }
  /**
  * * Field Name: Priority
  * * Display Name: Priority
  * * SQL Data Type: int
  * * Description: Priority of the field for the communication base message type
  */
  get Priority() {
    return this.Get('Priority');
  }
  set Priority(value) {
    this.Set('Priority', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.EntityCommunicationFieldEntity = EntityCommunicationFieldEntity;
exports.EntityCommunicationFieldEntity = EntityCommunicationFieldEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Communication Fields')], EntityCommunicationFieldEntity);
/**
 * Entity Communication Message Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityCommunicationMessageType
 * * Base View: vwEntityCommunicationMessageTypes
 * * @description Mapping between entities and communication base message types
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityCommunicationMessageTypeEntity = class EntityCommunicationMessageTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Communication Message Types record from the database
  * @param ID: string - primary key value to load the Entity Communication Message Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityCommunicationMessageTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Communication Message Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityCommunicationMessageTypeEntity
  * @throws {Error} - Delete is not allowed for Entity Communication Message Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Communication Message Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: BaseMessageTypeID
  * * Display Name: Base Message Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Communication Base Message Types (vwCommunicationBaseMessageTypes.ID)
  */
  get BaseMessageTypeID() {
    return this.Get('BaseMessageTypeID');
  }
  set BaseMessageTypeID(value) {
    this.Set('BaseMessageTypeID', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: Indicates whether the message type is active
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: BaseMessageType
  * * Display Name: Base Message Type
  * * SQL Data Type: nvarchar(100)
  */
  get BaseMessageType() {
    return this.Get('BaseMessageType');
  }
};
exports.EntityCommunicationMessageTypeEntity = EntityCommunicationMessageTypeEntity;
exports.EntityCommunicationMessageTypeEntity = EntityCommunicationMessageTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Communication Message Types')], EntityCommunicationMessageTypeEntity);
/**
 * Entity Document Runs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityDocumentRun
 * * Base View: vwEntityDocumentRuns
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityDocumentRunEntity = class EntityDocumentRunEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Document Runs record from the database
  * @param ID: string - primary key value to load the Entity Document Runs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityDocumentRunEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Document Runs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityDocumentRunEntity
  * @throws {Error} - Delete is not allowed for Entity Document Runs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Document Runs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityDocumentID
  * * Display Name: Entity Document ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Documents (vwEntityDocuments.ID)
  */
  get EntityDocumentID() {
    return this.Get('EntityDocumentID');
  }
  set EntityDocumentID(value) {
    this.Set('EntityDocumentID', value);
  }
  /**
  * * Field Name: StartedAt
  * * Display Name: Started At
  * * SQL Data Type: datetime
  */
  get StartedAt() {
    return this.Get('StartedAt');
  }
  set StartedAt(value) {
    this.Set('StartedAt', value);
  }
  /**
  * * Field Name: EndedAt
  * * Display Name: Ended At
  * * SQL Data Type: datetime
  */
  get EndedAt() {
    return this.Get('EndedAt');
  }
  set EndedAt(value) {
    this.Set('EndedAt', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(15)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * Complete
  *   * Failed
  * * Description: Can be Pending, In Progress, Completed, or Failed
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: EntityDocument
  * * Display Name: Entity Document
  * * SQL Data Type: nvarchar(250)
  */
  get EntityDocument() {
    return this.Get('EntityDocument');
  }
};
exports.EntityDocumentRunEntity = EntityDocumentRunEntity;
exports.EntityDocumentRunEntity = EntityDocumentRunEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Document Runs')], EntityDocumentRunEntity);
/**
 * Entity Document Settings - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityDocumentSetting
 * * Base View: vwEntityDocumentSettings
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityDocumentSettingEntity = class EntityDocumentSettingEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Document Settings record from the database
  * @param ID: string - primary key value to load the Entity Document Settings record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityDocumentSettingEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Document Settings - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityDocumentSettingEntity
  * @throws {Error} - Delete is not allowed for Entity Document Settings, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Document Settings, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityDocumentID
  * * Display Name: Entity Document ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Documents (vwEntityDocuments.ID)
  */
  get EntityDocumentID() {
    return this.Get('EntityDocumentID');
  }
  set EntityDocumentID(value) {
    this.Set('EntityDocumentID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Value
  * * Display Name: Value
  * * SQL Data Type: nvarchar(MAX)
  */
  get Value() {
    return this.Get('Value');
  }
  set Value(value) {
    this.Set('Value', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: EntityDocument
  * * Display Name: Entity Document
  * * SQL Data Type: nvarchar(250)
  */
  get EntityDocument() {
    return this.Get('EntityDocument');
  }
};
exports.EntityDocumentSettingEntity = EntityDocumentSettingEntity;
exports.EntityDocumentSettingEntity = EntityDocumentSettingEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Document Settings')], EntityDocumentSettingEntity);
/**
 * Entity Document Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityDocumentType
 * * Base View: vwEntityDocumentTypes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityDocumentTypeEntity = class EntityDocumentTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Document Types record from the database
  * @param ID: string - primary key value to load the Entity Document Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityDocumentTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Document Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityDocumentTypeEntity
  * @throws {Error} - Delete is not allowed for Entity Document Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Document Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.EntityDocumentTypeEntity = EntityDocumentTypeEntity;
exports.EntityDocumentTypeEntity = EntityDocumentTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Document Types')], EntityDocumentTypeEntity);
/**
 * Entity Documents - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityDocument
 * * Base View: vwEntityDocuments
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityDocumentEntity = class EntityDocumentEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Documents record from the database
  * @param ID: string - primary key value to load the Entity Documents record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityDocumentEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Documents - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityDocumentEntity
  * @throws {Error} - Delete is not allowed for Entity Documents, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Documents, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(250)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: TypeID
  * * Display Name: Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Document Types (vwEntityDocumentTypes.ID)
  */
  get TypeID() {
    return this.Get('TypeID');
  }
  set TypeID(value) {
    this.Set('TypeID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: VectorDatabaseID
  * * Display Name: Vector Database ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Vector Databases (vwVectorDatabases.ID)
  */
  get VectorDatabaseID() {
    return this.Get('VectorDatabaseID');
  }
  set VectorDatabaseID(value) {
    this.Set('VectorDatabaseID', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(15)
  * * Default Value: Active
  * * Value List Type: List
  * * Possible Values
  *   * Active
  *   * Inactive
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: TemplateID
  * * Display Name: Template ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Templates (vwTemplates.ID)
  */
  get TemplateID() {
    return this.Get('TemplateID');
  }
  set TemplateID(value) {
    this.Set('TemplateID', value);
  }
  /**
  * * Field Name: AIModelID
  * * Display Name: AIModel ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: AI Models (vwAIModels.ID)
  */
  get AIModelID() {
    return this.Get('AIModelID');
  }
  set AIModelID(value) {
    this.Set('AIModelID', value);
  }
  /**
  * * Field Name: PotentialMatchThreshold
  * * Display Name: Potential Match Threshold
  * * SQL Data Type: numeric(12, 11)
  * * Default Value: 1
  * * Description: Value between 0 and 1 that determines what is considered a potential matching record. Value must be <= AbsoluteMatchThreshold. This is primarily used for duplicate detection but can be used for other applications as well where matching is relevant.
  */
  get PotentialMatchThreshold() {
    return this.Get('PotentialMatchThreshold');
  }
  set PotentialMatchThreshold(value) {
    this.Set('PotentialMatchThreshold', value);
  }
  /**
  * * Field Name: AbsoluteMatchThreshold
  * * Display Name: Absolute Match Threshold
  * * SQL Data Type: numeric(12, 11)
  * * Default Value: 1
  * * Description: Value between 0 and 1 that determines what is considered an absolute matching record. Value must be >= PotentialMatchThreshold. This is primarily used for duplicate detection but can be used for other applications as well where matching is relevant.
  */
  get AbsoluteMatchThreshold() {
    return this.Get('AbsoluteMatchThreshold');
  }
  set AbsoluteMatchThreshold(value) {
    this.Set('AbsoluteMatchThreshold', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(100)
  */
  get Type() {
    return this.Get('Type');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.EntityDocumentEntity = EntityDocumentEntity;
exports.EntityDocumentEntity = EntityDocumentEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Documents')], EntityDocumentEntity);
/**
 * Entity Field Values - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityFieldValue
 * * Base View: vwEntityFieldValues
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityFieldValueEntity = class EntityFieldValueEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Field Values record from the database
  * @param ID: string - primary key value to load the Entity Field Values record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityFieldValueEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Field Values - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityFieldValueEntity
  * @throws {Error} - Delete is not allowed for Entity Field Values, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Field Values, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityFieldID
  * * Display Name: Entity Field ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Fields (vwEntityFields.ID)
  */
  get EntityFieldID() {
    return this.Get('EntityFieldID');
  }
  set EntityFieldID(value) {
    this.Set('EntityFieldID', value);
  }
  /**
  * * Field Name: Sequence
  * * Display Name: Sequence
  * * SQL Data Type: int
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: Value
  * * Display Name: Value
  * * SQL Data Type: nvarchar(255)
  */
  get Value() {
    return this.Get('Value');
  }
  set Value(value) {
    this.Set('Value', value);
  }
  /**
  * * Field Name: Code
  * * Display Name: Code
  * * SQL Data Type: nvarchar(50)
  */
  get Code() {
    return this.Get('Code');
  }
  set Code(value) {
    this.Set('Code', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: EntityField
  * * Display Name: Entity Field
  * * SQL Data Type: nvarchar(255)
  */
  get EntityField() {
    return this.Get('EntityField');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  */
  get EntityID() {
    return this.Get('EntityID');
  }
};
exports.EntityFieldValueEntity = EntityFieldValueEntity;
exports.EntityFieldValueEntity = EntityFieldValueEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Field Values')], EntityFieldValueEntity);
/**
 * Entity Fields - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityField
 * * Base View: vwEntityFields
 * * @description List of all fields within each entity with metadata about each field
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityFieldEntity = class EntityFieldEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Fields record from the database
  * @param ID: string - primary key value to load the Entity Fields record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityFieldEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  /**
  * * Field Name: Sequence
  * * SQL Data Type: int
  * * Default Value: 0
  * * Description: Display order of the field within the entity
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the field within the database table
  */
  get Name() {
    return this.Get('Name');
  }
  /**
  * * Field Name: DisplayName
  * * Display Name: Display Name
  * * SQL Data Type: nvarchar(255)
  * * Description: A user friendly alternative to the field name
  */
  get DisplayName() {
    return this.Get('DisplayName');
  }
  set DisplayName(value) {
    this.Set('DisplayName', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Descriptive text explaining the purpose of the field
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: AutoUpdateDescription
  * * Display Name: Auto Update Description
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When set to 1 (default), whenever a description is modified in the column within the underlying view (first choice) or table (second choice), the Description column in the entity field definition will be automatically updated. If you never set metadata in the database directly, you can leave this alone. However, if you have metadata set in the database level for description, and you want to provide a DIFFERENT description in this entity field definition, turn this bit off and then set the Description field and future CodeGen runs will NOT override the Description field here.
  */
  get AutoUpdateDescription() {
    return this.Get('AutoUpdateDescription');
  }
  set AutoUpdateDescription(value) {
    this.Set('AutoUpdateDescription', value);
  }
  /**
  * * Field Name: IsPrimaryKey
  * * Display Name: Is Primary Key
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Indicates if the field is part of the primary key for the entity (auto maintained by CodeGen)
  */
  get IsPrimaryKey() {
    return this.Get('IsPrimaryKey');
  }
  set IsPrimaryKey(value) {
    this.Set('IsPrimaryKey', value);
  }
  /**
  * * Field Name: IsUnique
  * * Display Name: Is Unique
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Indicates if the field must have unique values within the entity.
  */
  get IsUnique() {
    return this.Get('IsUnique');
  }
  set IsUnique(value) {
    this.Set('IsUnique', value);
  }
  /**
  * * Field Name: Category
  * * Display Name: Category
  * * SQL Data Type: nvarchar(255)
  * * Description: Used for generating custom tabs in the generated forms, only utilized if GeneratedFormSection=Category
  */
  get Category() {
    return this.Get('Category');
  }
  set Category(value) {
    this.Set('Category', value);
  }
  /**
  * * Field Name: Type
  * * SQL Data Type: nvarchar(100)
  * * Description: SQL Data type (auto maintained by CodeGen)
  */
  get Type() {
    return this.Get('Type');
  }
  /**
  * * Field Name: Length
  * * SQL Data Type: int
  * * Description: SQL data length (auto maintained by CodeGen)
  */
  get Length() {
    return this.Get('Length');
  }
  /**
  * * Field Name: Precision
  * * SQL Data Type: int
  * * Description: SQL precision (auto maintained by CodeGen)
  */
  get Precision() {
    return this.Get('Precision');
  }
  /**
  * * Field Name: Scale
  * * SQL Data Type: int
  * * Description: SQL scale (auto maintained by CodeGen)
  */
  get Scale() {
    return this.Get('Scale');
  }
  /**
  * * Field Name: AllowsNull
  * * Display Name: Allows Null
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: Does the column allow null or not (auto maintained by CodeGen)
  */
  get AllowsNull() {
    return this.Get('AllowsNull');
  }
  /**
  * * Field Name: DefaultValue
  * * Display Name: Default Value
  * * SQL Data Type: nvarchar(255)
  * * Description: If a default value is defined for the field it is stored here (auto maintained by CodeGen)
  */
  get DefaultValue() {
    return this.Get('DefaultValue');
  }
  /**
  * * Field Name: AutoIncrement
  * * Display Name: Auto Increment
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If this field automatically increments within the table, this field is set to 1 (auto maintained by CodeGen)
  */
  get AutoIncrement() {
    return this.Get('AutoIncrement');
  }
  /**
  * * Field Name: ValueListType
  * * Display Name: Value List Type
  * * SQL Data Type: nvarchar(20)
  * * Default Value: None
  * * Value List Type: List
  * * Possible Values
  *   * None
  *   * List
  *   * ListOrUserEntry
  * * Description: Possible Values of None, List, ListOrUserEntry - the last option meaning that the list of possible values are options, but a user can enter anything else desired too.
  */
  get ValueListType() {
    return this.Get('ValueListType');
  }
  set ValueListType(value) {
    this.Set('ValueListType', value);
  }
  /**
  * * Field Name: ExtendedType
  * * Display Name: Extended Type
  * * SQL Data Type: nvarchar(50)
  * * Value List Type: List
  * * Possible Values
  *   * Email
  *   * URL
  *   * Tel
  *   * SMS
  *   * Geo
  *   * WhatsApp
  *   * FaceTime
  *   * Skype
  *   * SIP
  *   * MSTeams
  *   * ZoomMtg
  *   * Other
  *   * Code
  * * Description: Defines extended behaviors for a field such as for Email, Web URLs, Code, etc.
  */
  get ExtendedType() {
    return this.Get('ExtendedType');
  }
  set ExtendedType(value) {
    this.Set('ExtendedType', value);
  }
  /**
  * * Field Name: CodeType
  * * Display Name: Code Type
  * * SQL Data Type: nvarchar(50)
  * * Value List Type: List
  * * Possible Values
  *   * TypeScript
  *   * SQL
  *   * HTML
  *   * CSS
  *   * JavaScript
  *   * Other
  * * Description: The type of code associated with this field. Only used when the ExtendedType field is set to "Code"
  */
  get CodeType() {
    return this.Get('CodeType');
  }
  set CodeType(value) {
    this.Set('CodeType', value);
  }
  /**
  * * Field Name: DefaultInView
  * * Display Name: Default In View
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 1, this field will be included by default in any new view created by a user.
  */
  get DefaultInView() {
    return this.Get('DefaultInView');
  }
  set DefaultInView(value) {
    this.Set('DefaultInView', value);
  }
  /**
  * * Field Name: ViewCellTemplate
  * * Display Name: View Cell Template
  * * SQL Data Type: nvarchar(MAX)
  * * Description: NULL
  */
  get ViewCellTemplate() {
    return this.Get('ViewCellTemplate');
  }
  set ViewCellTemplate(value) {
    this.Set('ViewCellTemplate', value);
  }
  /**
  * * Field Name: DefaultColumnWidth
  * * Display Name: Default Column Width
  * * SQL Data Type: int
  * * Description: Determines the default width for this field when included in a view
  */
  get DefaultColumnWidth() {
    return this.Get('DefaultColumnWidth');
  }
  set DefaultColumnWidth(value) {
    this.Set('DefaultColumnWidth', value);
  }
  /**
  * * Field Name: AllowUpdateAPI
  * * Display Name: Allow Update API
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: If set to 1, this field will be considered updateable by the API and object model. For this field to have effect, the column type must be updateable (e.g. not part of the primary key and not auto-increment)
  */
  get AllowUpdateAPI() {
    return this.Get('AllowUpdateAPI');
  }
  set AllowUpdateAPI(value) {
    this.Set('AllowUpdateAPI', value);
  }
  /**
  * * Field Name: AllowUpdateInView
  * * Display Name: Allow Update In View
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: If set to 1, and if AllowUpdateAPI=1, the field can be edited within a view when the view is in edit mode.
  */
  get AllowUpdateInView() {
    return this.Get('AllowUpdateInView');
  }
  set AllowUpdateInView(value) {
    this.Set('AllowUpdateInView', value);
  }
  /**
  * * Field Name: IncludeInUserSearchAPI
  * * Display Name: Include In User Search API
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 1, this column will be included in user search queries for both traditional and full text search
  */
  get IncludeInUserSearchAPI() {
    return this.Get('IncludeInUserSearchAPI');
  }
  set IncludeInUserSearchAPI(value) {
    this.Set('IncludeInUserSearchAPI', value);
  }
  /**
  * * Field Name: FullTextSearchEnabled
  * * Display Name: Full Text Search Enabled
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 1, CodeGen will automatically generate a Full Text Catalog/Index in the database and include this field in the search index.
  */
  get FullTextSearchEnabled() {
    return this.Get('FullTextSearchEnabled');
  }
  set FullTextSearchEnabled(value) {
    this.Set('FullTextSearchEnabled', value);
  }
  /**
  * * Field Name: UserSearchParamFormatAPI
  * * Display Name: User Search Param Format API
  * * SQL Data Type: nvarchar(500)
  * * Description: NULL
  */
  get UserSearchParamFormatAPI() {
    return this.Get('UserSearchParamFormatAPI');
  }
  set UserSearchParamFormatAPI(value) {
    this.Set('UserSearchParamFormatAPI', value);
  }
  /**
  * * Field Name: IncludeInGeneratedForm
  * * Display Name: Include In Generated Form
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: If set to 1, this field will be included in the generated form by CodeGen. If set to 0, this field will be excluded from the generated form. For custom forms, this field has no effect as the layout is controlled independently.
  */
  get IncludeInGeneratedForm() {
    return this.Get('IncludeInGeneratedForm');
  }
  set IncludeInGeneratedForm(value) {
    this.Set('IncludeInGeneratedForm', value);
  }
  /**
  * * Field Name: GeneratedFormSection
  * * Display Name: Generated Form Section
  * * SQL Data Type: nvarchar(10)
  * * Default Value: Details
  * * Value List Type: List
  * * Possible Values
  *   * Top
  *   * Category
  *   * Details
  * * Description: When set to Top, the field will be placed in a "top area" on the top of a generated form and visible regardless of which tab is displayed. When set to "category" Options: Top, Category, Details
  */
  get GeneratedFormSection() {
    return this.Get('GeneratedFormSection');
  }
  set GeneratedFormSection(value) {
    this.Set('GeneratedFormSection', value);
  }
  /**
  * * Field Name: IsVirtual
  * * Display Name: Is Virtual
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: NULL
  */
  get IsVirtual() {
    return this.Get('IsVirtual');
  }
  /**
  * * Field Name: IsNameField
  * * Display Name: Is Name Field
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 1, this column will be used as the "Name" field for the entity and will be used to display the name of the record in various places in the UI.
  */
  get IsNameField() {
    return this.Get('IsNameField');
  }
  set IsNameField(value) {
    this.Set('IsNameField', value);
  }
  /**
  * * Field Name: RelatedEntityID
  * * Display Name: RelatedEntity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get RelatedEntityID() {
    return this.Get('RelatedEntityID');
  }
  set RelatedEntityID(value) {
    this.Set('RelatedEntityID', value);
  }
  /**
  * * Field Name: RelatedEntityFieldName
  * * Display Name: Related Entity Field Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the field in the Related Entity that this field links to (auto maintained by CodeGen)
  */
  get RelatedEntityFieldName() {
    return this.Get('RelatedEntityFieldName');
  }
  set RelatedEntityFieldName(value) {
    this.Set('RelatedEntityFieldName', value);
  }
  /**
  * * Field Name: IncludeRelatedEntityNameFieldInBaseView
  * * Display Name: Include Related Entity Name Field In Base View
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: If set to 1, the "Name" field of the Related Entity will be included in this entity as a virtual field
  */
  get IncludeRelatedEntityNameFieldInBaseView() {
    return this.Get('IncludeRelatedEntityNameFieldInBaseView');
  }
  set IncludeRelatedEntityNameFieldInBaseView(value) {
    this.Set('IncludeRelatedEntityNameFieldInBaseView', value);
  }
  /**
  * * Field Name: RelatedEntityNameFieldMap
  * * Display Name: Related Entity Name Field Map
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntityNameFieldMap() {
    return this.Get('RelatedEntityNameFieldMap');
  }
  set RelatedEntityNameFieldMap(value) {
    this.Set('RelatedEntityNameFieldMap', value);
  }
  /**
  * * Field Name: RelatedEntityDisplayType
  * * Display Name: Related Entity Display Type
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Search
  * * Description: Controls the generated form in the MJ Explorer UI - defaults to a search box, other option is a drop down. Possible values are Search and Dropdown
  */
  get RelatedEntityDisplayType() {
    return this.Get('RelatedEntityDisplayType');
  }
  set RelatedEntityDisplayType(value) {
    this.Set('RelatedEntityDisplayType', value);
  }
  /**
  * * Field Name: EntityIDFieldName
  * * Display Name: Entity IDField Name
  * * SQL Data Type: nvarchar(100)
  * * Description: Optional, used for "Soft Keys" to link records to different entity/record combinations on a per-record basis (for example the FileEntityRecordLink table has an EntityID/RecordID field pair. For that entity, the RecordID specifies "EntityID" for this field. This information allows MJ to detect soft keys/links for dependency detection, merging and for preventing orphaned soft-linked records during delete operations.
  */
  get EntityIDFieldName() {
    return this.Get('EntityIDFieldName');
  }
  set EntityIDFieldName(value) {
    this.Set('EntityIDFieldName', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: SchemaName
  * * Display Name: Schema Name
  * * SQL Data Type: nvarchar(255)
  */
  get SchemaName() {
    return this.Get('SchemaName');
  }
  /**
  * * Field Name: BaseTable
  * * Display Name: Base Table
  * * SQL Data Type: nvarchar(255)
  */
  get BaseTable() {
    return this.Get('BaseTable');
  }
  /**
  * * Field Name: BaseView
  * * Display Name: Base View
  * * SQL Data Type: nvarchar(255)
  */
  get BaseView() {
    return this.Get('BaseView');
  }
  /**
  * * Field Name: EntityCodeName
  * * Display Name: Entity Code Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get EntityCodeName() {
    return this.Get('EntityCodeName');
  }
  /**
  * * Field Name: EntityClassName
  * * Display Name: Entity Class Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get EntityClassName() {
    return this.Get('EntityClassName');
  }
  /**
  * * Field Name: RelatedEntity
  * * Display Name: Related Entity
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntity() {
    return this.Get('RelatedEntity');
  }
  /**
  * * Field Name: RelatedEntitySchemaName
  * * Display Name: Related Entity Schema Name
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntitySchemaName() {
    return this.Get('RelatedEntitySchemaName');
  }
  /**
  * * Field Name: RelatedEntityBaseTable
  * * Display Name: Related Entity Base Table
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntityBaseTable() {
    return this.Get('RelatedEntityBaseTable');
  }
  /**
  * * Field Name: RelatedEntityBaseView
  * * Display Name: Related Entity Base View
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntityBaseView() {
    return this.Get('RelatedEntityBaseView');
  }
  /**
  * * Field Name: RelatedEntityCodeName
  * * Display Name: Related Entity Code Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get RelatedEntityCodeName() {
    return this.Get('RelatedEntityCodeName');
  }
  /**
  * * Field Name: RelatedEntityClassName
  * * Display Name: Related Entity Class Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get RelatedEntityClassName() {
    return this.Get('RelatedEntityClassName');
  }
};
exports.EntityFieldEntity = EntityFieldEntity;
exports.EntityFieldEntity = EntityFieldEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Fields')], EntityFieldEntity);
/**
 * Entity Permissions - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityPermission
 * * Base View: vwEntityPermissions
 * * @description Security settings for each entity
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityPermissionEntity = class EntityPermissionEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Permissions record from the database
  * @param ID: string - primary key value to load the Entity Permissions record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityPermissionEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RoleID
  * * Display Name: Role ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Roles (vwRoles.ID)
  */
  get RoleID() {
    return this.Get('RoleID');
  }
  set RoleID(value) {
    this.Set('RoleID', value);
  }
  /**
  * * Field Name: CanCreate
  * * Display Name: Can Create
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get CanCreate() {
    return this.Get('CanCreate');
  }
  set CanCreate(value) {
    this.Set('CanCreate', value);
  }
  /**
  * * Field Name: CanRead
  * * Display Name: Can Read
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get CanRead() {
    return this.Get('CanRead');
  }
  set CanRead(value) {
    this.Set('CanRead', value);
  }
  /**
  * * Field Name: CanUpdate
  * * Display Name: Can Update
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get CanUpdate() {
    return this.Get('CanUpdate');
  }
  set CanUpdate(value) {
    this.Set('CanUpdate', value);
  }
  /**
  * * Field Name: CanDelete
  * * Display Name: Can Delete
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get CanDelete() {
    return this.Get('CanDelete');
  }
  set CanDelete(value) {
    this.Set('CanDelete', value);
  }
  /**
  * * Field Name: ReadRLSFilterID
  * * Display Name: Read RLSFilter ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Row Level Security Filters (vwRowLevelSecurityFilters.ID)
  */
  get ReadRLSFilterID() {
    return this.Get('ReadRLSFilterID');
  }
  set ReadRLSFilterID(value) {
    this.Set('ReadRLSFilterID', value);
  }
  /**
  * * Field Name: CreateRLSFilterID
  * * Display Name: Create RLSFilter ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Row Level Security Filters (vwRowLevelSecurityFilters.ID)
  */
  get CreateRLSFilterID() {
    return this.Get('CreateRLSFilterID');
  }
  set CreateRLSFilterID(value) {
    this.Set('CreateRLSFilterID', value);
  }
  /**
  * * Field Name: UpdateRLSFilterID
  * * Display Name: Update RLSFilter ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Row Level Security Filters (vwRowLevelSecurityFilters.ID)
  */
  get UpdateRLSFilterID() {
    return this.Get('UpdateRLSFilterID');
  }
  set UpdateRLSFilterID(value) {
    this.Set('UpdateRLSFilterID', value);
  }
  /**
  * * Field Name: DeleteRLSFilterID
  * * Display Name: Delete RLSFilter ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Row Level Security Filters (vwRowLevelSecurityFilters.ID)
  */
  get DeleteRLSFilterID() {
    return this.Get('DeleteRLSFilterID');
  }
  set DeleteRLSFilterID(value) {
    this.Set('DeleteRLSFilterID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: RoleName
  * * Display Name: Role Name
  * * SQL Data Type: nvarchar(50)
  */
  get RoleName() {
    return this.Get('RoleName');
  }
  /**
  * * Field Name: RoleSQLName
  * * Display Name: Role SQLName
  * * SQL Data Type: nvarchar(250)
  */
  get RoleSQLName() {
    return this.Get('RoleSQLName');
  }
  /**
  * * Field Name: CreateRLSFilter
  * * Display Name: Create RLSFilter
  * * SQL Data Type: nvarchar(100)
  */
  get CreateRLSFilter() {
    return this.Get('CreateRLSFilter');
  }
  /**
  * * Field Name: ReadRLSFilter
  * * Display Name: Read RLSFilter
  * * SQL Data Type: nvarchar(100)
  */
  get ReadRLSFilter() {
    return this.Get('ReadRLSFilter');
  }
  /**
  * * Field Name: UpdateRLSFilter
  * * Display Name: Update RLSFilter
  * * SQL Data Type: nvarchar(100)
  */
  get UpdateRLSFilter() {
    return this.Get('UpdateRLSFilter');
  }
  /**
  * * Field Name: DeleteRLSFilter
  * * Display Name: Delete RLSFilter
  * * SQL Data Type: nvarchar(100)
  */
  get DeleteRLSFilter() {
    return this.Get('DeleteRLSFilter');
  }
};
exports.EntityPermissionEntity = EntityPermissionEntity;
exports.EntityPermissionEntity = EntityPermissionEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Permissions')], EntityPermissionEntity);
/**
 * Entity Record Documents - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityRecordDocument
 * * Base View: vwEntityRecordDocuments
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityRecordDocumentEntity = class EntityRecordDocumentEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Record Documents record from the database
  * @param ID: string - primary key value to load the Entity Record Documents record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityRecordDocumentEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Record Documents - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityRecordDocumentEntity
  * @throws {Error} - Delete is not allowed for Entity Record Documents, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Record Documents, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record ID
  * * SQL Data Type: nvarchar(450)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: EntityDocumentID
  * * Display Name: Entity Document ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Documents (vwEntityDocuments.ID)
  */
  get EntityDocumentID() {
    return this.Get('EntityDocumentID');
  }
  set EntityDocumentID(value) {
    this.Set('EntityDocumentID', value);
  }
  /**
  * * Field Name: DocumentText
  * * Display Name: Document Text
  * * SQL Data Type: nvarchar(MAX)
  */
  get DocumentText() {
    return this.Get('DocumentText');
  }
  set DocumentText(value) {
    this.Set('DocumentText', value);
  }
  /**
  * * Field Name: VectorIndexID
  * * Display Name: Vector Index ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Vector Indexes (vwVectorIndexes.ID)
  */
  get VectorIndexID() {
    return this.Get('VectorIndexID');
  }
  set VectorIndexID(value) {
    this.Set('VectorIndexID', value);
  }
  /**
  * * Field Name: VectorID
  * * Display Name: Vector ID
  * * SQL Data Type: nvarchar(50)
  */
  get VectorID() {
    return this.Get('VectorID');
  }
  set VectorID(value) {
    this.Set('VectorID', value);
  }
  /**
  * * Field Name: VectorJSON
  * * Display Name: Vector JSON
  * * SQL Data Type: nvarchar(MAX)
  */
  get VectorJSON() {
    return this.Get('VectorJSON');
  }
  set VectorJSON(value) {
    this.Set('VectorJSON', value);
  }
  /**
  * * Field Name: EntityRecordUpdatedAt
  * * Display Name: Entity Record Updated At
  * * SQL Data Type: datetime
  */
  get EntityRecordUpdatedAt() {
    return this.Get('EntityRecordUpdatedAt');
  }
  set EntityRecordUpdatedAt(value) {
    this.Set('EntityRecordUpdatedAt', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.EntityRecordDocumentEntity = EntityRecordDocumentEntity;
exports.EntityRecordDocumentEntity = EntityRecordDocumentEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Record Documents')], EntityRecordDocumentEntity);
/**
 * Entity Relationship Display Components - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityRelationshipDisplayComponent
 * * Base View: vwEntityRelationshipDisplayComponents
 * * @description This table stores a list of components that are available for displaying relationships in the MJ Explorer UI
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityRelationshipDisplayComponentEntity = class EntityRelationshipDisplayComponentEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Relationship Display Components record from the database
  * @param ID: string - primary key value to load the Entity Relationship Display Components record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityRelationshipDisplayComponentEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Relationship Display Components - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntityRelationshipDisplayComponentEntity
  * @throws {Error} - Delete is not allowed for Entity Relationship Display Components, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Relationship Display Components, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: RelationshipType
  * * Display Name: Relationship Type
  * * SQL Data Type: nvarchar(20)
  * * Value List Type: List
  * * Possible Values
  *   * One to Many
  *   * Many to Many
  *   * Both
  * * Description: The type of relationship the component displays. Valid values are "One to Many", "Many to Many", or "Both".
  */
  get RelationshipType() {
    return this.Get('RelationshipType');
  }
  set RelationshipType(value) {
    this.Set('RelationshipType', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.EntityRelationshipDisplayComponentEntity = EntityRelationshipDisplayComponentEntity;
exports.EntityRelationshipDisplayComponentEntity = EntityRelationshipDisplayComponentEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Relationship Display Components')], EntityRelationshipDisplayComponentEntity);
/**
 * Entity Relationships - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntityRelationship
 * * Base View: vwEntityRelationships
 * * @description Metadata about relationships between entities including display preferences for the UI
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntityRelationshipEntity = class EntityRelationshipEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Relationships record from the database
  * @param ID: string - primary key value to load the Entity Relationships record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntityRelationshipEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: Sequence
  * * Display Name: Sequence
  * * SQL Data Type: int
  * * Default Value: 0
  * * Description: Used for display order in generated forms and in other places in the UI where relationships for an entity are shown
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: RelatedEntityID
  * * Display Name: Related Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get RelatedEntityID() {
    return this.Get('RelatedEntityID');
  }
  set RelatedEntityID(value) {
    this.Set('RelatedEntityID', value);
  }
  /**
  * * Field Name: BundleInAPI
  * * Display Name: Bundle In API
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get BundleInAPI() {
    return this.Get('BundleInAPI');
  }
  set BundleInAPI(value) {
    this.Set('BundleInAPI', value);
  }
  /**
  * * Field Name: IncludeInParentAllQuery
  * * Display Name: Include In Parent All Query
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IncludeInParentAllQuery() {
    return this.Get('IncludeInParentAllQuery');
  }
  set IncludeInParentAllQuery(value) {
    this.Set('IncludeInParentAllQuery', value);
  }
  /**
  * * Field Name: Type
  * * SQL Data Type: nchar(20)
  * * Default Value: One To Many
  * * Value List Type: List
  * * Possible Values
  *   * One To Many
  *   * Many To Many
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: EntityKeyField
  * * Display Name: Entity Key Field
  * * SQL Data Type: nvarchar(255)
  */
  get EntityKeyField() {
    return this.Get('EntityKeyField');
  }
  set EntityKeyField(value) {
    this.Set('EntityKeyField', value);
  }
  /**
  * * Field Name: RelatedEntityJoinField
  * * Display Name: Related Entity Join Field
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntityJoinField() {
    return this.Get('RelatedEntityJoinField');
  }
  set RelatedEntityJoinField(value) {
    this.Set('RelatedEntityJoinField', value);
  }
  /**
  * * Field Name: JoinView
  * * Display Name: Join View
  * * SQL Data Type: nvarchar(255)
  */
  get JoinView() {
    return this.Get('JoinView');
  }
  set JoinView(value) {
    this.Set('JoinView', value);
  }
  /**
  * * Field Name: JoinEntityJoinField
  * * Display Name: Join Entity Join Field
  * * SQL Data Type: nvarchar(255)
  */
  get JoinEntityJoinField() {
    return this.Get('JoinEntityJoinField');
  }
  set JoinEntityJoinField(value) {
    this.Set('JoinEntityJoinField', value);
  }
  /**
  * * Field Name: JoinEntityInverseJoinField
  * * Display Name: Join Entity Inverse Join Field
  * * SQL Data Type: nvarchar(255)
  */
  get JoinEntityInverseJoinField() {
    return this.Get('JoinEntityInverseJoinField');
  }
  set JoinEntityInverseJoinField(value) {
    this.Set('JoinEntityInverseJoinField', value);
  }
  /**
  * * Field Name: DisplayInForm
  * * Display Name: Display In Form
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: When unchecked the relationship will NOT be displayed on the generated form
  */
  get DisplayInForm() {
    return this.Get('DisplayInForm');
  }
  set DisplayInForm(value) {
    this.Set('DisplayInForm', value);
  }
  /**
  * * Field Name: DisplayLocation
  * * Display Name: Display Location
  * * SQL Data Type: nvarchar(50)
  * * Default Value: After Field Tabs
  * * Value List Type: List
  * * Possible Values
  *   * After Field Tabs
  *   * Before Field Tabs
  */
  get DisplayLocation() {
    return this.Get('DisplayLocation');
  }
  set DisplayLocation(value) {
    this.Set('DisplayLocation', value);
  }
  /**
  * * Field Name: DisplayName
  * * Display Name: Display Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Optional, when specified this value overrides the related entity name for the label on the tab
  */
  get DisplayName() {
    return this.Get('DisplayName');
  }
  set DisplayName(value) {
    this.Set('DisplayName', value);
  }
  /**
  * * Field Name: DisplayIconType
  * * Display Name: Display Icon Type
  * * SQL Data Type: nvarchar(50)
  * * Default Value: Related Entity Icon
  * * Value List Type: List
  * * Possible Values
  *   * Related Entity Icon
  *   * Custom
  *   * None
  * * Description: When Related Entity Icon - uses the icon from the related entity, if one exists. When Custom, uses the value in the DisplayIcon field in this record, and when None, no icon is displayed
  */
  get DisplayIconType() {
    return this.Get('DisplayIconType');
  }
  set DisplayIconType(value) {
    this.Set('DisplayIconType', value);
  }
  /**
  * * Field Name: DisplayIcon
  * * Display Name: Display Icon
  * * SQL Data Type: nvarchar(255)
  * * Description: If specified, the icon
  */
  get DisplayIcon() {
    return this.Get('DisplayIcon');
  }
  set DisplayIcon(value) {
    this.Set('DisplayIcon', value);
  }
  /**
  * * Field Name: DisplayUserViewID
  * * Display Name: Display User View ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: User Views (vwUserViews.ID)
  */
  get DisplayUserViewID() {
    return this.Get('DisplayUserViewID');
  }
  /**
  * * Field Name: DisplayComponentID
  * * Display Name: Display Component ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entity Relationship Display Components (vwEntityRelationshipDisplayComponents.ID)
  */
  get DisplayComponentID() {
    return this.Get('DisplayComponentID');
  }
  set DisplayComponentID(value) {
    this.Set('DisplayComponentID', value);
  }
  /**
  * * Field Name: DisplayComponentConfiguration
  * * Display Name: Display Component Configuration
  * * SQL Data Type: nvarchar(MAX)
  * * Description: If DisplayComponentID is specified, this field can optionally be used to track component-specific and relationship-specific configuration details that will be used by CodeGen to provide to the display component selected.
  */
  get DisplayComponentConfiguration() {
    return this.Get('DisplayComponentConfiguration');
  }
  set DisplayComponentConfiguration(value) {
    this.Set('DisplayComponentConfiguration', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: EntityBaseTable
  * * Display Name: Entity Base Table
  * * SQL Data Type: nvarchar(255)
  */
  get EntityBaseTable() {
    return this.Get('EntityBaseTable');
  }
  /**
  * * Field Name: EntityBaseView
  * * Display Name: Entity Base View
  * * SQL Data Type: nvarchar(255)
  */
  get EntityBaseView() {
    return this.Get('EntityBaseView');
  }
  /**
  * * Field Name: RelatedEntity
  * * Display Name: Related Entity
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntity() {
    return this.Get('RelatedEntity');
  }
  /**
  * * Field Name: RelatedEntityBaseTable
  * * Display Name: Related Entity Base Table
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntityBaseTable() {
    return this.Get('RelatedEntityBaseTable');
  }
  /**
  * * Field Name: RelatedEntityBaseView
  * * Display Name: Related Entity Base View
  * * SQL Data Type: nvarchar(255)
  */
  get RelatedEntityBaseView() {
    return this.Get('RelatedEntityBaseView');
  }
  /**
  * * Field Name: RelatedEntityClassName
  * * Display Name: Related Entity Class Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get RelatedEntityClassName() {
    return this.Get('RelatedEntityClassName');
  }
  /**
  * * Field Name: RelatedEntityCodeName
  * * Display Name: Related Entity Code Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get RelatedEntityCodeName() {
    return this.Get('RelatedEntityCodeName');
  }
  /**
  * * Field Name: RelatedEntityBaseTableCodeName
  * * Display Name: Related Entity Base Table Code Name
  * * SQL Data Type: nvarchar(MAX)
  */
  get RelatedEntityBaseTableCodeName() {
    return this.Get('RelatedEntityBaseTableCodeName');
  }
  /**
  * * Field Name: DisplayUserViewName
  * * Display Name: Display User View Name
  * * SQL Data Type: nvarchar(100)
  */
  get DisplayUserViewName() {
    return this.Get('DisplayUserViewName');
  }
};
exports.EntityRelationshipEntity = EntityRelationshipEntity;
exports.EntityRelationshipEntity = EntityRelationshipEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Relationships')], EntityRelationshipEntity);
/**
 * Entity Settings - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: EntitySetting
 * * Base View: vwEntitySettings
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let EntitySettingEntity = class EntitySettingEntity extends core_1.BaseEntity {
  /**
  * Loads the Entity Settings record from the database
  * @param ID: string - primary key value to load the Entity Settings record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof EntitySettingEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Entity Settings - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof EntitySettingEntity
  * @throws {Error} - Delete is not allowed for Entity Settings, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Entity Settings, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Value
  * * Display Name: Value
  * * SQL Data Type: nvarchar(MAX)
  */
  get Value() {
    return this.Get('Value');
  }
  set Value(value) {
    this.Set('Value', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.EntitySettingEntity = EntitySettingEntity;
exports.EntitySettingEntity = EntitySettingEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Entity Settings')], EntitySettingEntity);
/**
 * Error Logs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ErrorLog
 * * Base View: vwErrorLogs
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ErrorLogEntity = class ErrorLogEntity extends core_1.BaseEntity {
  /**
  * Loads the Error Logs record from the database
  * @param ID: string - primary key value to load the Error Logs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ErrorLogEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Error Logs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof ErrorLogEntity
  * @throws {Error} - Delete is not allowed for Error Logs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Error Logs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: CompanyIntegrationRunID
  * * Display Name: CompanyIntegrationRun ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Company Integration Runs (vwCompanyIntegrationRuns.ID)
  */
  get CompanyIntegrationRunID() {
    return this.Get('CompanyIntegrationRunID');
  }
  set CompanyIntegrationRunID(value) {
    this.Set('CompanyIntegrationRunID', value);
  }
  /**
  * * Field Name: CompanyIntegrationRunDetailID
  * * Display Name: CompanyIntegrationRunDetail ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Company Integration Run Details (vwCompanyIntegrationRunDetails.ID)
  */
  get CompanyIntegrationRunDetailID() {
    return this.Get('CompanyIntegrationRunDetailID');
  }
  set CompanyIntegrationRunDetailID(value) {
    this.Set('CompanyIntegrationRunDetailID', value);
  }
  /**
  * * Field Name: Code
  * * SQL Data Type: nchar(20)
  */
  get Code() {
    return this.Get('Code');
  }
  set Code(value) {
    this.Set('Code', value);
  }
  /**
  * * Field Name: Message
  * * SQL Data Type: nvarchar(MAX)
  */
  get Message() {
    return this.Get('Message');
  }
  set Message(value) {
    this.Set('Message', value);
  }
  /**
  * * Field Name: CreatedBy
  * * Display Name: Created By
  * * SQL Data Type: nvarchar(50)
  * * Default Value: suser_name()
  */
  get CreatedBy() {
    return this.Get('CreatedBy');
  }
  set CreatedBy(value) {
    this.Set('CreatedBy', value);
  }
  /**
  * * Field Name: Status
  * * SQL Data Type: nvarchar(10)
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: Category
  * * SQL Data Type: nvarchar(20)
  */
  get Category() {
    return this.Get('Category');
  }
  set Category(value) {
    this.Set('Category', value);
  }
  /**
  * * Field Name: Details
  * * SQL Data Type: nvarchar(MAX)
  */
  get Details() {
    return this.Get('Details');
  }
  set Details(value) {
    this.Set('Details', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ErrorLogEntity = ErrorLogEntity;
exports.ErrorLogEntity = ErrorLogEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Error Logs')], ErrorLogEntity);
/**
 * Explorer Navigation Items - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ExplorerNavigationItem
 * * Base View: vwExplorerNavigationItems
 * * @description Table to store navigation items for MemberJunction Explorer
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ExplorerNavigationItemEntity = class ExplorerNavigationItemEntity extends core_1.BaseEntity {
  /**
  * Loads the Explorer Navigation Items record from the database
  * @param ID: string - primary key value to load the Explorer Navigation Items record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ExplorerNavigationItemEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  * * Description: Unique identifier for each navigation item
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Sequence
  * * Display Name: Sequence
  * * SQL Data Type: int
  * * Description: Sequence number for the navigation item, must be unique and greater than 0
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  * * Description: Unique name of the navigation item displayed to the user
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Route
  * * Display Name: Route
  * * SQL Data Type: nvarchar(255)
  * * Description: The route for the navigation item relative to the app main URL, using Angular syntax like "entity/:entityName"
  */
  get Route() {
    return this.Get('Route');
  }
  set Route(value) {
    this.Set('Route', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: Indicates if the navigation item is active; allows turning off items in the UI without deleting them from the metadata
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: ShowInHomeScreen
  * * Display Name: Show In Home Screen
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Controls if the navigation item is shown on the Home screen for MJ Explorer
  */
  get ShowInHomeScreen() {
    return this.Get('ShowInHomeScreen');
  }
  set ShowInHomeScreen(value) {
    this.Set('ShowInHomeScreen', value);
  }
  /**
  * * Field Name: ShowInNavigationDrawer
  * * Display Name: Show In Navigation Drawer
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: Controls if the item is shown in the left navigation drawer in the MJ Explorer app or not.
  */
  get ShowInNavigationDrawer() {
    return this.Get('ShowInNavigationDrawer');
  }
  set ShowInNavigationDrawer(value) {
    this.Set('ShowInNavigationDrawer', value);
  }
  /**
  * * Field Name: IconCSSClass
  * * Display Name: Icon CSSClass
  * * SQL Data Type: nvarchar(100)
  * * Description: Optional, CSS class for an icon to be displayed with the navigation item
  */
  get IconCSSClass() {
    return this.Get('IconCSSClass');
  }
  set IconCSSClass(value) {
    this.Set('IconCSSClass', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the navigation item, shown to the user on hover or in larger displays
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Administrator comments, not shown to the end user in MJ Explorer app
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ExplorerNavigationItemEntity = ExplorerNavigationItemEntity;
exports.ExplorerNavigationItemEntity = ExplorerNavigationItemEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Explorer Navigation Items')], ExplorerNavigationItemEntity);
/**
 * File Categories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: FileCategory
 * * Base View: vwFileCategories
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let FileCategoryEntity = class FileCategoryEntity extends core_1.BaseEntity {
  /**
  * Loads the File Categories record from the database
  * @param ID: string - primary key value to load the File Categories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof FileCategoryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: File Categories (vwFileCategories.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(255)
  */
  get Parent() {
    return this.Get('Parent');
  }
};
exports.FileCategoryEntity = FileCategoryEntity;
exports.FileCategoryEntity = FileCategoryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'File Categories')], FileCategoryEntity);
/**
 * File Entity Record Links - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: FileEntityRecordLink
 * * Base View: vwFileEntityRecordLinks
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let FileEntityRecordLinkEntity = class FileEntityRecordLinkEntity extends core_1.BaseEntity {
  /**
  * Loads the File Entity Record Links record from the database
  * @param ID: string - primary key value to load the File Entity Record Links record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof FileEntityRecordLinkEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * File Entity Record Links - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof FileEntityRecordLinkEntity
  * @throws {Error} - Delete is not allowed for File Entity Record Links, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for File Entity Record Links, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: FileID
  * * Display Name: File ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Files (vwFiles.ID)
  */
  get FileID() {
    return this.Get('FileID');
  }
  set FileID(value) {
    this.Set('FileID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record ID
  * * SQL Data Type: nvarchar(750)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: File
  * * Display Name: File
  * * SQL Data Type: nvarchar(500)
  */
  get File() {
    return this.Get('File');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.FileEntityRecordLinkEntity = FileEntityRecordLinkEntity;
exports.FileEntityRecordLinkEntity = FileEntityRecordLinkEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'File Entity Record Links')], FileEntityRecordLinkEntity);
/**
 * File Storage Providers - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: FileStorageProvider
 * * Base View: vwFileStorageProviders
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let FileStorageProviderEntity = class FileStorageProviderEntity extends core_1.BaseEntity {
  /**
  * Loads the File Storage Providers record from the database
  * @param ID: string - primary key value to load the File Storage Providers record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof FileStorageProviderEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * File Storage Providers - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof FileStorageProviderEntity
  * @throws {Error} - Delete is not allowed for File Storage Providers, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for File Storage Providers, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: ServerDriverKey
  * * Display Name: Server Driver Key
  * * SQL Data Type: nvarchar(100)
  */
  get ServerDriverKey() {
    return this.Get('ServerDriverKey');
  }
  set ServerDriverKey(value) {
    this.Set('ServerDriverKey', value);
  }
  /**
  * * Field Name: ClientDriverKey
  * * Display Name: Client Driver Key
  * * SQL Data Type: nvarchar(100)
  */
  get ClientDriverKey() {
    return this.Get('ClientDriverKey');
  }
  set ClientDriverKey(value) {
    this.Set('ClientDriverKey', value);
  }
  /**
  * * Field Name: Priority
  * * Display Name: Priority
  * * SQL Data Type: int
  * * Default Value: 0
  */
  get Priority() {
    return this.Get('Priority');
  }
  set Priority(value) {
    this.Set('Priority', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.FileStorageProviderEntity = FileStorageProviderEntity;
exports.FileStorageProviderEntity = FileStorageProviderEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'File Storage Providers')], FileStorageProviderEntity);
/**
 * Files - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: File
 * * Base View: vwFiles
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let FileEntity = class FileEntity extends core_1.BaseEntity {
  /**
  * Loads the Files record from the database
  * @param ID: string - primary key value to load the Files record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof FileEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(500)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: CategoryID
  * * Display Name: Category ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: File Categories (vwFileCategories.ID)
  */
  get CategoryID() {
    return this.Get('CategoryID');
  }
  set CategoryID(value) {
    this.Set('CategoryID', value);
  }
  /**
  * * Field Name: ProviderID
  * * Display Name: Provider ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: File Storage Providers (vwFileStorageProviders.ID)
  */
  get ProviderID() {
    return this.Get('ProviderID');
  }
  set ProviderID(value) {
    this.Set('ProviderID', value);
  }
  /**
  * * Field Name: ContentType
  * * Display Name: Content Type
  * * SQL Data Type: nvarchar(50)
  */
  get ContentType() {
    return this.Get('ContentType');
  }
  set ContentType(value) {
    this.Set('ContentType', value);
  }
  /**
  * * Field Name: ProviderKey
  * * Display Name: Provider Key
  * * SQL Data Type: nvarchar(500)
  */
  get ProviderKey() {
    return this.Get('ProviderKey');
  }
  set ProviderKey(value) {
    this.Set('ProviderKey', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Description: Pending, Uploading, Uploaded, Deleting, Deleted
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Category
  * * Display Name: Category
  * * SQL Data Type: nvarchar(255)
  */
  get Category() {
    return this.Get('Category');
  }
  /**
  * * Field Name: Provider
  * * Display Name: Provider
  * * SQL Data Type: nvarchar(50)
  */
  get Provider() {
    return this.Get('Provider');
  }
};
exports.FileEntity = FileEntity;
exports.FileEntity = FileEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Files')], FileEntity);
/**
 * flyway _schema _histories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: flyway_schema_history
 * * Base View: vwflyway_schema_histories
 * * Primary Key: installed_rank
 * @extends {BaseEntity}
 * @class
 * @public
 */
let flyway_schema_historyEntity = class flyway_schema_historyEntity extends core_1.BaseEntity {
  /**
  * Loads the flyway _schema _histories record from the database
  * @param installed_rank: number - primary key value to load the flyway _schema _histories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof flyway_schema_historyEntity
  * @method
  * @override
  */
  async Load(installed_rank, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'installed_rank',
      Value: installed_rank
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: installed_rank
  * * Display Name: installed _rank
  * * SQL Data Type: int
  */
  get installed_rank() {
    return this.Get('installed_rank');
  }
  /**
  * * Field Name: version
  * * Display Name: version
  * * SQL Data Type: nvarchar(50)
  */
  get version() {
    return this.Get('version');
  }
  set version(value) {
    this.Set('version', value);
  }
  /**
  * * Field Name: description
  * * Display Name: description
  * * SQL Data Type: nvarchar(200)
  */
  get description() {
    return this.Get('description');
  }
  set description(value) {
    this.Set('description', value);
  }
  /**
  * * Field Name: type
  * * Display Name: type
  * * SQL Data Type: nvarchar(20)
  */
  get type() {
    return this.Get('type');
  }
  set type(value) {
    this.Set('type', value);
  }
  /**
  * * Field Name: script
  * * Display Name: script
  * * SQL Data Type: nvarchar(1000)
  */
  get script() {
    return this.Get('script');
  }
  set script(value) {
    this.Set('script', value);
  }
  /**
  * * Field Name: checksum
  * * Display Name: checksum
  * * SQL Data Type: int
  */
  get checksum() {
    return this.Get('checksum');
  }
  set checksum(value) {
    this.Set('checksum', value);
  }
  /**
  * * Field Name: installed_by
  * * Display Name: installed _by
  * * SQL Data Type: nvarchar(100)
  */
  get installed_by() {
    return this.Get('installed_by');
  }
  set installed_by(value) {
    this.Set('installed_by', value);
  }
  /**
  * * Field Name: installed_on
  * * Display Name: installed _on
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get installed_on() {
    return this.Get('installed_on');
  }
  set installed_on(value) {
    this.Set('installed_on', value);
  }
  /**
  * * Field Name: execution_time
  * * Display Name: execution _time
  * * SQL Data Type: int
  */
  get execution_time() {
    return this.Get('execution_time');
  }
  set execution_time(value) {
    this.Set('execution_time', value);
  }
  /**
  * * Field Name: success
  * * Display Name: success
  * * SQL Data Type: bit
  */
  get success() {
    return this.Get('success');
  }
  set success(value) {
    this.Set('success', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.flyway_schema_historyEntity = flyway_schema_historyEntity;
exports.flyway_schema_historyEntity = flyway_schema_historyEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'flyway _schema _histories')], flyway_schema_historyEntity);
/**
 * Integration URL Formats - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: IntegrationURLFormat
 * * Base View: vwIntegrationURLFormats
 * * @description Used to generate web links for end users to easily access resources in a source system. URL Formats support templating to inject various field values at run-time to take a user directly to a resource in a source system.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let IntegrationURLFormatEntity = class IntegrationURLFormatEntity extends core_1.BaseEntity {
  /**
  * Loads the Integration URL Formats record from the database
  * @param ID: string - primary key value to load the Integration URL Formats record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof IntegrationURLFormatEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Integration URL Formats - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof IntegrationURLFormatEntity
  * @throws {Error} - Delete is not allowed for Integration URL Formats, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Integration URL Formats, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: IntegrationID
  * * Display Name: Integration ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Integrations (vwIntegrations.ID)
  */
  get IntegrationID() {
    return this.Get('IntegrationID');
  }
  set IntegrationID(value) {
    this.Set('IntegrationID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: URLFormat
  * * SQL Data Type: nvarchar(500)
  * * Description: The URL Format for the given integration including the ability to include markup with fields from the integration
  */
  get URLFormat() {
    return this.Get('URLFormat');
  }
  set URLFormat(value) {
    this.Set('URLFormat', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Integration
  * * Display Name: Integration
  * * SQL Data Type: nvarchar(100)
  */
  get Integration() {
    return this.Get('Integration');
  }
  /**
  * * Field Name: NavigationBaseURL
  * * Display Name: Navigation Base URL
  * * SQL Data Type: nvarchar(500)
  */
  get NavigationBaseURL() {
    return this.Get('NavigationBaseURL');
  }
  /**
  * * Field Name: FullURLFormat
  * * Display Name: Full URLFormat
  * * SQL Data Type: nvarchar(1000)
  */
  get FullURLFormat() {
    return this.Get('FullURLFormat');
  }
};
exports.IntegrationURLFormatEntity = IntegrationURLFormatEntity;
exports.IntegrationURLFormatEntity = IntegrationURLFormatEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Integration URL Formats')], IntegrationURLFormatEntity);
/**
 * Integrations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Integration
 * * Base View: vwIntegrations
 * * @description Catalog of all integrations that have been configured in the system.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let IntegrationEntity = class IntegrationEntity extends core_1.BaseEntity {
  /**
  * Loads the Integrations record from the database
  * @param ID: string - primary key value to load the Integrations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof IntegrationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Integrations - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof IntegrationEntity
  * @throws {Error} - Delete is not allowed for Integrations, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Integrations, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(255)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: NavigationBaseURL
  * * Display Name: Navigation Base URL
  * * SQL Data Type: nvarchar(500)
  */
  get NavigationBaseURL() {
    return this.Get('NavigationBaseURL');
  }
  set NavigationBaseURL(value) {
    this.Set('NavigationBaseURL', value);
  }
  /**
  * * Field Name: ClassName
  * * Display Name: Class Name
  * * SQL Data Type: nvarchar(100)
  */
  get ClassName() {
    return this.Get('ClassName');
  }
  set ClassName(value) {
    this.Set('ClassName', value);
  }
  /**
  * * Field Name: ImportPath
  * * Display Name: Import Path
  * * SQL Data Type: nvarchar(100)
  */
  get ImportPath() {
    return this.Get('ImportPath');
  }
  set ImportPath(value) {
    this.Set('ImportPath', value);
  }
  /**
  * * Field Name: BatchMaxRequestCount
  * * Display Name: Batch Max Request Count
  * * SQL Data Type: int
  * * Default Value: -1
  */
  get BatchMaxRequestCount() {
    return this.Get('BatchMaxRequestCount');
  }
  set BatchMaxRequestCount(value) {
    this.Set('BatchMaxRequestCount', value);
  }
  /**
  * * Field Name: BatchRequestWaitTime
  * * Display Name: Batch Request Wait Time
  * * SQL Data Type: int
  * * Default Value: -1
  */
  get BatchRequestWaitTime() {
    return this.Get('BatchRequestWaitTime');
  }
  set BatchRequestWaitTime(value) {
    this.Set('BatchRequestWaitTime', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
};
exports.IntegrationEntity = IntegrationEntity;
exports.IntegrationEntity = IntegrationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Integrations')], IntegrationEntity);
/**
 * Libraries - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Library
 * * Base View: vwLibraries
 * * @description Stores information about the available libraries, including a list of classes/functions, type definitions, and sample code. You can add additional custom libraries here to make them avaialable to code generation features within the system.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let LibraryEntity = class LibraryEntity extends core_1.BaseEntity {
  /**
  * Loads the Libraries record from the database
  * @param ID: string - primary key value to load the Libraries record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof LibraryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Libraries - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof LibraryEntity
  * @throws {Error} - Delete is not allowed for Libraries, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Libraries, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * Active
  *   * Disabled
  * * Description: Status of the library, only libraries marked as Active will be available for use by generated code. If a library was once active but no longer is, existing code that used the library will not be affected.
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: TypeDefinitions
  * * Display Name: Type Definitions
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Code showing the types and functions defined in the library to be used for reference by humans and AI
  */
  get TypeDefinitions() {
    return this.Get('TypeDefinitions');
  }
  set TypeDefinitions(value) {
    this.Set('TypeDefinitions', value);
  }
  /**
  * * Field Name: SampleCode
  * * Display Name: Sample Code
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Examples of code use of the classes and/or functions from within the library
  */
  get SampleCode() {
    return this.Get('SampleCode');
  }
  set SampleCode(value) {
    this.Set('SampleCode', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.LibraryEntity = LibraryEntity;
exports.LibraryEntity = LibraryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Libraries')], LibraryEntity);
/**
 * Library Items - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: LibraryItem
 * * Base View: vwLibraryItems
 * * @description Table to store individual library items
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let LibraryItemEntity = class LibraryItemEntity extends core_1.BaseEntity {
  /**
  * Loads the Library Items record from the database
  * @param ID: string - primary key value to load the Library Items record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof LibraryItemEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Library Items - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof LibraryItemEntity
  * @throws {Error} - Delete is not allowed for Library Items, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Library Items, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: LibraryID
  * * Display Name: Library ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Libraries (vwLibraries.ID)
  */
  get LibraryID() {
    return this.Get('LibraryID');
  }
  set LibraryID(value) {
    this.Set('LibraryID', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(50)
  * * Value List Type: List
  * * Possible Values
  *   * Class
  *   * Interface
  *   * Variable
  *   * Type
  *   * Module
  *   * Function
  * * Description: Type of the library item for example Class, Interface, etc.
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Library
  * * Display Name: Library
  * * SQL Data Type: nvarchar(255)
  */
  get Library() {
    return this.Get('Library');
  }
};
exports.LibraryItemEntity = LibraryItemEntity;
exports.LibraryItemEntity = LibraryItemEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Library Items')], LibraryItemEntity);
/**
 * List Categories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ListCategory
 * * Base View: vwListCategories
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ListCategoryEntity = class ListCategoryEntity extends core_1.BaseEntity {
  /**
  * Loads the List Categories record from the database
  * @param ID: string - primary key value to load the List Categories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ListCategoryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * List Categories - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof ListCategoryEntity
  * @throws {Error} - Delete is not allowed for List Categories, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for List Categories, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: List Categories (vwListCategories.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ListCategoryEntity = ListCategoryEntity;
exports.ListCategoryEntity = ListCategoryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'List Categories')], ListCategoryEntity);
/**
 * List Details - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ListDetail
 * * Base View: vwListDetails
 * * @description Tracks the records within each list.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ListDetailEntity = class ListDetailEntity extends core_1.BaseEntity {
  /**
  * Loads the List Details record from the database
  * @param ID: string - primary key value to load the List Details record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ListDetailEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ListID
  * * Display Name: List ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Lists (vwLists.ID)
  */
  get ListID() {
    return this.Get('ListID');
  }
  set ListID(value) {
    this.Set('ListID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record
  * * SQL Data Type: nvarchar(445)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: Sequence
  * * SQL Data Type: int
  * * Default Value: 0
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: List
  * * Display Name: List
  * * SQL Data Type: nvarchar(100)
  */
  get List() {
    return this.Get('List');
  }
};
exports.ListDetailEntity = ListDetailEntity;
exports.ListDetailEntity = ListDetailEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'List Details')], ListDetailEntity);
/**
 * Lists - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: List
 * * Base View: vwLists
 * * @description Static lists are useful for controlling a set of data for a given entity. These can be used programatically for applications like logging and tracking long-running tasks and also by end users for tracking any particular list of records they want to directly control the set.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ListEntity = class ListEntity extends core_1.BaseEntity {
  /**
  * Loads the Lists record from the database
  * @param ID: string - primary key value to load the Lists record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ListEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: CategoryID
  * * Display Name: Category ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: List Categories (vwListCategories.ID)
  */
  get CategoryID() {
    return this.Get('CategoryID');
  }
  set CategoryID(value) {
    this.Set('CategoryID', value);
  }
  /**
  * * Field Name: ExternalSystemRecordID
  * * Display Name: External System Record ID
  * * SQL Data Type: nvarchar(100)
  */
  get ExternalSystemRecordID() {
    return this.Get('ExternalSystemRecordID');
  }
  set ExternalSystemRecordID(value) {
    this.Set('ExternalSystemRecordID', value);
  }
  /**
  * * Field Name: CompanyIntegrationID
  * * Display Name: Company Integration ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Company Integrations (vwCompanyIntegrations.ID)
  */
  get CompanyIntegrationID() {
    return this.Get('CompanyIntegrationID');
  }
  set CompanyIntegrationID(value) {
    this.Set('CompanyIntegrationID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.ListEntity = ListEntity;
exports.ListEntity = ListEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Lists')], ListEntity);
/**
 * Output Delivery Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: OutputDeliveryType
 * * Base View: vwOutputDeliveryTypes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let OutputDeliveryTypeEntity = class OutputDeliveryTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Output Delivery Types record from the database
  * @param ID: string - primary key value to load the Output Delivery Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof OutputDeliveryTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Output Delivery Types - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof OutputDeliveryTypeEntity
  * @throws {Error} - Save is not allowed for Output Delivery Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Output Delivery Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Output Delivery Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof OutputDeliveryTypeEntity
  * @throws {Error} - Delete is not allowed for Output Delivery Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Output Delivery Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.OutputDeliveryTypeEntity = OutputDeliveryTypeEntity;
exports.OutputDeliveryTypeEntity = OutputDeliveryTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Output Delivery Types')], OutputDeliveryTypeEntity);
/**
 * Output Format Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: OutputFormatType
 * * Base View: vwOutputFormatTypes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let OutputFormatTypeEntity = class OutputFormatTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Output Format Types record from the database
  * @param ID: string - primary key value to load the Output Format Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof OutputFormatTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Output Format Types - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof OutputFormatTypeEntity
  * @throws {Error} - Save is not allowed for Output Format Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Output Format Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Output Format Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof OutputFormatTypeEntity
  * @throws {Error} - Delete is not allowed for Output Format Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Output Format Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: DisplayFormat
  * * Display Name: Display Format
  * * SQL Data Type: nvarchar(MAX)
  */
  get DisplayFormat() {
    return this.Get('DisplayFormat');
  }
  set DisplayFormat(value) {
    this.Set('DisplayFormat', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.OutputFormatTypeEntity = OutputFormatTypeEntity;
exports.OutputFormatTypeEntity = OutputFormatTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Output Format Types')], OutputFormatTypeEntity);
/**
 * Output Trigger Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: OutputTriggerType
 * * Base View: vwOutputTriggerTypes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let OutputTriggerTypeEntity = class OutputTriggerTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Output Trigger Types record from the database
  * @param ID: string - primary key value to load the Output Trigger Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof OutputTriggerTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Output Trigger Types - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof OutputTriggerTypeEntity
  * @throws {Error} - Save is not allowed for Output Trigger Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Output Trigger Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Output Trigger Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof OutputTriggerTypeEntity
  * @throws {Error} - Delete is not allowed for Output Trigger Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Output Trigger Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.OutputTriggerTypeEntity = OutputTriggerTypeEntity;
exports.OutputTriggerTypeEntity = OutputTriggerTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Output Trigger Types')], OutputTriggerTypeEntity);
/**
 * Queries - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Query
 * * Base View: vwQueries
 * * @description Catalog of stored queries. This is useful for any arbitrary query that is known to be performant and correct and can be reused. Queries can be viewed/run by a user, used programatically via RunQuery, and also used by AI systems for improved reliability instead of dynamically generated SQL. Queries can also improve security since they store the SQL instead of using dynamic SQL.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let QueryEntity = class QueryEntity extends core_1.BaseEntity {
  /**
  * Loads the Queries record from the database
  * @param ID: string - primary key value to load the Queries record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof QueryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Queries - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof QueryEntity
  * @throws {Error} - Delete is not allowed for Queries, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Queries, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: CategoryID
  * * Display Name: Category ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Query Categories (vwQueryCategories.ID)
  */
  get CategoryID() {
    return this.Get('CategoryID');
  }
  set CategoryID(value) {
    this.Set('CategoryID', value);
  }
  /**
  * * Field Name: UserQuestion
  * * Display Name: User Question
  * * SQL Data Type: nvarchar(MAX)
  */
  get UserQuestion() {
    return this.Get('UserQuestion');
  }
  set UserQuestion(value) {
    this.Set('UserQuestion', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: SQL
  * * Display Name: SQL
  * * SQL Data Type: nvarchar(MAX)
  */
  get SQL() {
    return this.Get('SQL');
  }
  set SQL(value) {
    this.Set('SQL', value);
  }
  /**
  * * Field Name: TechnicalDescription
  * * Display Name: Technical Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get TechnicalDescription() {
    return this.Get('TechnicalDescription');
  }
  set TechnicalDescription(value) {
    this.Set('TechnicalDescription', value);
  }
  /**
  * * Field Name: OriginalSQL
  * * Display Name: Original SQL
  * * SQL Data Type: nvarchar(MAX)
  */
  get OriginalSQL() {
    return this.Get('OriginalSQL');
  }
  set OriginalSQL(value) {
    this.Set('OriginalSQL', value);
  }
  /**
  * * Field Name: Feedback
  * * Display Name: Feedback
  * * SQL Data Type: nvarchar(MAX)
  */
  get Feedback() {
    return this.Get('Feedback');
  }
  set Feedback(value) {
    this.Set('Feedback', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(15)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * Approved
  *   * Rejected
  *   * Expired
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: QualityRank
  * * Display Name: Quality Rank
  * * SQL Data Type: int
  * * Default Value: 0
  * * Description: Value indicating the quality of the query, higher values mean a better quality
  */
  get QualityRank() {
    return this.Get('QualityRank');
  }
  set QualityRank(value) {
    this.Set('QualityRank', value);
  }
  /**
  * * Field Name: ExecutionCostRank
  * * Display Name: Execution Cost Rank
  * * SQL Data Type: int
  * * Description: Higher numbers indicate more execution overhead/time required. Useful for planning which queries to use in various scenarios.
  */
  get ExecutionCostRank() {
    return this.Get('ExecutionCostRank');
  }
  set ExecutionCostRank(value) {
    this.Set('ExecutionCostRank', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Category
  * * Display Name: Category
  * * SQL Data Type: nvarchar(50)
  */
  get Category() {
    return this.Get('Category');
  }
};
exports.QueryEntity = QueryEntity;
exports.QueryEntity = QueryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Queries')], QueryEntity);
/**
 * Query Categories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: QueryCategory
 * * Base View: vwQueryCategories
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let QueryCategoryEntity = class QueryCategoryEntity extends core_1.BaseEntity {
  /**
  * Loads the Query Categories record from the database
  * @param ID: string - primary key value to load the Query Categories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof QueryCategoryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Query Categories (vwQueryCategories.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(50)
  */
  get Parent() {
    return this.Get('Parent');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.QueryCategoryEntity = QueryCategoryEntity;
exports.QueryCategoryEntity = QueryCategoryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Query Categories')], QueryCategoryEntity);
/**
 * Query Fields - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: QueryField
 * * Base View: vwQueryFields
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let QueryFieldEntity = class QueryFieldEntity extends core_1.BaseEntity {
  /**
  * Loads the Query Fields record from the database
  * @param ID: string - primary key value to load the Query Fields record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof QueryFieldEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Query Fields - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof QueryFieldEntity
  * @throws {Error} - Delete is not allowed for Query Fields, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Query Fields, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: QueryID
  * * Display Name: Query ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Queries (vwQueries.ID)
  */
  get QueryID() {
    return this.Get('QueryID');
  }
  set QueryID(value) {
    this.Set('QueryID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Sequence
  * * Display Name: Sequence
  * * SQL Data Type: int
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: SQLBaseType
  * * Display Name: SQLBase Type
  * * SQL Data Type: nvarchar(50)
  * * Description: The base type, not including parameters, in SQL. For example this field would be nvarchar or decimal, and wouldn't include type parameters. The SQLFullType field provides that information.
  */
  get SQLBaseType() {
    return this.Get('SQLBaseType');
  }
  set SQLBaseType(value) {
    this.Set('SQLBaseType', value);
  }
  /**
  * * Field Name: SQLFullType
  * * Display Name: SQLFull Type
  * * SQL Data Type: nvarchar(100)
  * * Description: The full SQL type for the field, for example datetime or nvarchar(10) etc.
  */
  get SQLFullType() {
    return this.Get('SQLFullType');
  }
  set SQLFullType(value) {
    this.Set('SQLFullType', value);
  }
  /**
  * * Field Name: SourceEntityID
  * * Display Name: Source Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get SourceEntityID() {
    return this.Get('SourceEntityID');
  }
  set SourceEntityID(value) {
    this.Set('SourceEntityID', value);
  }
  /**
  * * Field Name: SourceFieldName
  * * Display Name: Source Field Name
  * * SQL Data Type: nvarchar(255)
  */
  get SourceFieldName() {
    return this.Get('SourceFieldName');
  }
  set SourceFieldName(value) {
    this.Set('SourceFieldName', value);
  }
  /**
  * * Field Name: IsComputed
  * * Display Name: Is Computed
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsComputed() {
    return this.Get('IsComputed');
  }
  set IsComputed(value) {
    this.Set('IsComputed', value);
  }
  /**
  * * Field Name: ComputationDescription
  * * Display Name: Computation Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get ComputationDescription() {
    return this.Get('ComputationDescription');
  }
  set ComputationDescription(value) {
    this.Set('ComputationDescription', value);
  }
  /**
  * * Field Name: IsSummary
  * * Display Name: Is Summary
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsSummary() {
    return this.Get('IsSummary');
  }
  set IsSummary(value) {
    this.Set('IsSummary', value);
  }
  /**
  * * Field Name: SummaryDescription
  * * Display Name: Summary Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get SummaryDescription() {
    return this.Get('SummaryDescription');
  }
  set SummaryDescription(value) {
    this.Set('SummaryDescription', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Query
  * * Display Name: Query
  * * SQL Data Type: nvarchar(255)
  */
  get Query() {
    return this.Get('Query');
  }
  /**
  * * Field Name: SourceEntity
  * * Display Name: Source Entity
  * * SQL Data Type: nvarchar(255)
  */
  get SourceEntity() {
    return this.Get('SourceEntity');
  }
};
exports.QueryFieldEntity = QueryFieldEntity;
exports.QueryFieldEntity = QueryFieldEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Query Fields')], QueryFieldEntity);
/**
 * Query Permissions - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: QueryPermission
 * * Base View: vwQueryPermissions
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let QueryPermissionEntity = class QueryPermissionEntity extends core_1.BaseEntity {
  /**
  * Loads the Query Permissions record from the database
  * @param ID: string - primary key value to load the Query Permissions record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof QueryPermissionEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Query Permissions - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof QueryPermissionEntity
  * @throws {Error} - Delete is not allowed for Query Permissions, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Query Permissions, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: QueryID
  * * Display Name: Query ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Queries (vwQueries.ID)
  */
  get QueryID() {
    return this.Get('QueryID');
  }
  set QueryID(value) {
    this.Set('QueryID', value);
  }
  /**
  * * Field Name: RoleID
  * * Display Name: Role ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Roles (vwRoles.ID)
  */
  get RoleID() {
    return this.Get('RoleID');
  }
  set RoleID(value) {
    this.Set('RoleID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.QueryPermissionEntity = QueryPermissionEntity;
exports.QueryPermissionEntity = QueryPermissionEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Query Permissions')], QueryPermissionEntity);
/**
 * Queue Tasks - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: QueueTask
 * * Base View: vwQueueTasks
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let QueueTaskEntity = class QueueTaskEntity extends core_1.BaseEntity {
  /**
  * Loads the Queue Tasks record from the database
  * @param ID: string - primary key value to load the Queue Tasks record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof QueueTaskEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Queue Tasks - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof QueueTaskEntity
  * @throws {Error} - Delete is not allowed for Queue Tasks, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Queue Tasks, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: QueueID
  * * Display Name: Queue ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Queues (vwQueues.ID)
  */
  get QueueID() {
    return this.Get('QueueID');
  }
  set QueueID(value) {
    this.Set('QueueID', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nchar(10)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * In Progress
  *   * Completed
  *   * Failed
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: StartedAt
  * * Display Name: Started At
  * * SQL Data Type: datetime
  */
  get StartedAt() {
    return this.Get('StartedAt');
  }
  set StartedAt(value) {
    this.Set('StartedAt', value);
  }
  /**
  * * Field Name: EndedAt
  * * Display Name: Ended At
  * * SQL Data Type: datetime
  */
  get EndedAt() {
    return this.Get('EndedAt');
  }
  set EndedAt(value) {
    this.Set('EndedAt', value);
  }
  /**
  * * Field Name: Data
  * * Display Name: Data
  * * SQL Data Type: nvarchar(MAX)
  */
  get Data() {
    return this.Get('Data');
  }
  set Data(value) {
    this.Set('Data', value);
  }
  /**
  * * Field Name: Options
  * * Display Name: Options
  * * SQL Data Type: nvarchar(MAX)
  */
  get Options() {
    return this.Get('Options');
  }
  set Options(value) {
    this.Set('Options', value);
  }
  /**
  * * Field Name: Output
  * * Display Name: Output
  * * SQL Data Type: nvarchar(MAX)
  */
  get Output() {
    return this.Get('Output');
  }
  set Output(value) {
    this.Set('Output', value);
  }
  /**
  * * Field Name: ErrorMessage
  * * Display Name: Error Message
  * * SQL Data Type: nvarchar(MAX)
  */
  get ErrorMessage() {
    return this.Get('ErrorMessage');
  }
  set ErrorMessage(value) {
    this.Set('ErrorMessage', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Queue
  * * Display Name: Queue
  * * SQL Data Type: nvarchar(50)
  */
  get Queue() {
    return this.Get('Queue');
  }
};
exports.QueueTaskEntity = QueueTaskEntity;
exports.QueueTaskEntity = QueueTaskEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Queue Tasks')], QueueTaskEntity);
/**
 * Queue Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: QueueType
 * * Base View: vwQueueTypes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let QueueTypeEntity = class QueueTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Queue Types record from the database
  * @param ID: string - primary key value to load the Queue Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof QueueTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Queue Types - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof QueueTypeEntity
  * @throws {Error} - Save is not allowed for Queue Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Queue Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Queue Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof QueueTypeEntity
  * @throws {Error} - Delete is not allowed for Queue Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Queue Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: DriverClass
  * * Display Name: Driver Class
  * * SQL Data Type: nvarchar(100)
  */
  get DriverClass() {
    return this.Get('DriverClass');
  }
  set DriverClass(value) {
    this.Set('DriverClass', value);
  }
  /**
  * * Field Name: DriverImportPath
  * * Display Name: Driver Import Path
  * * SQL Data Type: nvarchar(200)
  */
  get DriverImportPath() {
    return this.Get('DriverImportPath');
  }
  set DriverImportPath(value) {
    this.Set('DriverImportPath', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.QueueTypeEntity = QueueTypeEntity;
exports.QueueTypeEntity = QueueTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Queue Types')], QueueTypeEntity);
/**
 * Queues - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Queue
 * * Base View: vwQueues
 * * @description Queues can be used to async execute long running tasks
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let QueueEntity = class QueueEntity extends core_1.BaseEntity {
  /**
  * Loads the Queues record from the database
  * @param ID: string - primary key value to load the Queues record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof QueueEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Queues - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof QueueEntity
  * @throws {Error} - Delete is not allowed for Queues, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Queues, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: QueueTypeID
  * * Display Name: Queue Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Queue Types (vwQueueTypes.ID)
  */
  get QueueTypeID() {
    return this.Get('QueueTypeID');
  }
  set QueueTypeID(value) {
    this.Set('QueueTypeID', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: ProcessPID
  * * Display Name: Process PID
  * * SQL Data Type: int
  */
  get ProcessPID() {
    return this.Get('ProcessPID');
  }
  set ProcessPID(value) {
    this.Set('ProcessPID', value);
  }
  /**
  * * Field Name: ProcessPlatform
  * * Display Name: Process Platform
  * * SQL Data Type: nvarchar(30)
  */
  get ProcessPlatform() {
    return this.Get('ProcessPlatform');
  }
  set ProcessPlatform(value) {
    this.Set('ProcessPlatform', value);
  }
  /**
  * * Field Name: ProcessVersion
  * * Display Name: Process Version
  * * SQL Data Type: nvarchar(15)
  */
  get ProcessVersion() {
    return this.Get('ProcessVersion');
  }
  set ProcessVersion(value) {
    this.Set('ProcessVersion', value);
  }
  /**
  * * Field Name: ProcessCwd
  * * Display Name: Process Cwd
  * * SQL Data Type: nvarchar(100)
  */
  get ProcessCwd() {
    return this.Get('ProcessCwd');
  }
  set ProcessCwd(value) {
    this.Set('ProcessCwd', value);
  }
  /**
  * * Field Name: ProcessIPAddress
  * * Display Name: Process IPAddress
  * * SQL Data Type: nvarchar(50)
  */
  get ProcessIPAddress() {
    return this.Get('ProcessIPAddress');
  }
  set ProcessIPAddress(value) {
    this.Set('ProcessIPAddress', value);
  }
  /**
  * * Field Name: ProcessMacAddress
  * * Display Name: Process Mac Address
  * * SQL Data Type: nvarchar(50)
  */
  get ProcessMacAddress() {
    return this.Get('ProcessMacAddress');
  }
  set ProcessMacAddress(value) {
    this.Set('ProcessMacAddress', value);
  }
  /**
  * * Field Name: ProcessOSName
  * * Display Name: Process OSName
  * * SQL Data Type: nvarchar(25)
  */
  get ProcessOSName() {
    return this.Get('ProcessOSName');
  }
  set ProcessOSName(value) {
    this.Set('ProcessOSName', value);
  }
  /**
  * * Field Name: ProcessOSVersion
  * * Display Name: Process OSVersion
  * * SQL Data Type: nvarchar(10)
  */
  get ProcessOSVersion() {
    return this.Get('ProcessOSVersion');
  }
  set ProcessOSVersion(value) {
    this.Set('ProcessOSVersion', value);
  }
  /**
  * * Field Name: ProcessHostName
  * * Display Name: Process Host Name
  * * SQL Data Type: nvarchar(50)
  */
  get ProcessHostName() {
    return this.Get('ProcessHostName');
  }
  set ProcessHostName(value) {
    this.Set('ProcessHostName', value);
  }
  /**
  * * Field Name: ProcessUserID
  * * Display Name: Process User ID
  * * SQL Data Type: nvarchar(25)
  */
  get ProcessUserID() {
    return this.Get('ProcessUserID');
  }
  set ProcessUserID(value) {
    this.Set('ProcessUserID', value);
  }
  /**
  * * Field Name: ProcessUserName
  * * Display Name: Process User Name
  * * SQL Data Type: nvarchar(50)
  */
  get ProcessUserName() {
    return this.Get('ProcessUserName');
  }
  set ProcessUserName(value) {
    this.Set('ProcessUserName', value);
  }
  /**
  * * Field Name: LastHeartbeat
  * * Display Name: Last Heartbeat
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get LastHeartbeat() {
    return this.Get('LastHeartbeat');
  }
  set LastHeartbeat(value) {
    this.Set('LastHeartbeat', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: QueueType
  * * Display Name: Queue Type
  * * SQL Data Type: nvarchar(50)
  */
  get QueueType() {
    return this.Get('QueueType');
  }
};
exports.QueueEntity = QueueEntity;
exports.QueueEntity = QueueEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Queues')], QueueEntity);
/**
 * Recommendation Items - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: RecommendationItem
 * * Base View: vwRecommendationItems
 * * @description Table to store individual recommendation items that are the right side of the recommendation which we track in the DestinationEntityID/DestinationEntityRecordID
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RecommendationItemEntity = class RecommendationItemEntity extends core_1.BaseEntity {
  /**
  * Loads the Recommendation Items record from the database
  * @param ID: string - primary key value to load the Recommendation Items record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RecommendationItemEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Recommendation Items - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RecommendationItemEntity
  * @throws {Error} - Delete is not allowed for Recommendation Items, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Recommendation Items, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: RecommendationID
  * * Display Name: Recommendation ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Recommendations (vwRecommendations.ID)
  */
  get RecommendationID() {
    return this.Get('RecommendationID');
  }
  set RecommendationID(value) {
    this.Set('RecommendationID', value);
  }
  /**
  * * Field Name: DestinationEntityID
  * * Display Name: Destination Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get DestinationEntityID() {
    return this.Get('DestinationEntityID');
  }
  set DestinationEntityID(value) {
    this.Set('DestinationEntityID', value);
  }
  /**
  * * Field Name: DestinationEntityRecordID
  * * Display Name: Destination Entity Record ID
  * * SQL Data Type: nvarchar(450)
  * * Description: The record ID of the destination entity
  */
  get DestinationEntityRecordID() {
    return this.Get('DestinationEntityRecordID');
  }
  set DestinationEntityRecordID(value) {
    this.Set('DestinationEntityRecordID', value);
  }
  /**
  * * Field Name: MatchProbability
  * * Display Name: Match Probability
  * * SQL Data Type: decimal(18, 15)
  * * Description: A value between 0 and 1 indicating the probability of the match, higher numbers indicating a more certain match/recommendation.
  */
  get MatchProbability() {
    return this.Get('MatchProbability');
  }
  set MatchProbability(value) {
    this.Set('MatchProbability', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: DestinationEntity
  * * Display Name: Destination Entity
  * * SQL Data Type: nvarchar(255)
  */
  get DestinationEntity() {
    return this.Get('DestinationEntity');
  }
};
exports.RecommendationItemEntity = RecommendationItemEntity;
exports.RecommendationItemEntity = RecommendationItemEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Recommendation Items')], RecommendationItemEntity);
/**
 * Recommendation Providers - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: RecommendationProvider
 * * Base View: vwRecommendationProviders
 * * @description Recommendation providers details
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RecommendationProviderEntity = class RecommendationProviderEntity extends core_1.BaseEntity {
  /**
  * Loads the Recommendation Providers record from the database
  * @param ID: string - primary key value to load the Recommendation Providers record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RecommendationProviderEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Recommendation Providers - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RecommendationProviderEntity
  * @throws {Error} - Delete is not allowed for Recommendation Providers, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Recommendation Providers, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.RecommendationProviderEntity = RecommendationProviderEntity;
exports.RecommendationProviderEntity = RecommendationProviderEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Recommendation Providers')], RecommendationProviderEntity);
/**
 * Recommendation Runs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: RecommendationRun
 * * Base View: vwRecommendationRuns
 * * @description Recommendation runs log each time a provider is requested to provide recommendations
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RecommendationRunEntity = class RecommendationRunEntity extends core_1.BaseEntity {
  /**
  * Loads the Recommendation Runs record from the database
  * @param ID: string - primary key value to load the Recommendation Runs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RecommendationRunEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Recommendation Runs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RecommendationRunEntity
  * @throws {Error} - Delete is not allowed for Recommendation Runs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Recommendation Runs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: RecommendationProviderID
  * * Display Name: Recommendation Provider ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Recommendation Providers (vwRecommendationProviders.ID)
  */
  get RecommendationProviderID() {
    return this.Get('RecommendationProviderID');
  }
  set RecommendationProviderID(value) {
    this.Set('RecommendationProviderID', value);
  }
  /**
  * * Field Name: StartDate
  * * Display Name: Start Date
  * * SQL Data Type: datetime
  * * Description: The start date of the recommendation run
  */
  get StartDate() {
    return this.Get('StartDate');
  }
  set StartDate(value) {
    this.Set('StartDate', value);
  }
  /**
  * * Field Name: EndDate
  * * Display Name: End Date
  * * SQL Data Type: datetime
  * * Description: The end date of the recommendation run
  */
  get EndDate() {
    return this.Get('EndDate');
  }
  set EndDate(value) {
    this.Set('EndDate', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(50)
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * In Progress
  *   * Completed
  *   * Canceled
  *   * Error
  * * Description: The status of the recommendation run
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: RunByUserID
  * * Display Name: Run By User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get RunByUserID() {
    return this.Get('RunByUserID');
  }
  set RunByUserID(value) {
    this.Set('RunByUserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: RecommendationProvider
  * * Display Name: Recommendation Provider
  * * SQL Data Type: nvarchar(255)
  */
  get RecommendationProvider() {
    return this.Get('RecommendationProvider');
  }
  /**
  * * Field Name: RunByUser
  * * Display Name: Run By User
  * * SQL Data Type: nvarchar(100)
  */
  get RunByUser() {
    return this.Get('RunByUser');
  }
};
exports.RecommendationRunEntity = RecommendationRunEntity;
exports.RecommendationRunEntity = RecommendationRunEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Recommendation Runs')], RecommendationRunEntity);
/**
 * Recommendations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Recommendation
 * * Base View: vwRecommendations
 * * @description Recommendation headers that store the left side of the recommendation which we track in the SourceEntityID/SourceEntityRecordID
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RecommendationEntity = class RecommendationEntity extends core_1.BaseEntity {
  /**
  * Loads the Recommendations record from the database
  * @param ID: string - primary key value to load the Recommendations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RecommendationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Recommendations - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RecommendationEntity
  * @throws {Error} - Delete is not allowed for Recommendations, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Recommendations, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: RecommendationRunID
  * * Display Name: Recommendation Run ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Recommendation Runs (vwRecommendationRuns.ID)
  */
  get RecommendationRunID() {
    return this.Get('RecommendationRunID');
  }
  set RecommendationRunID(value) {
    this.Set('RecommendationRunID', value);
  }
  /**
  * * Field Name: SourceEntityID
  * * Display Name: Source Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get SourceEntityID() {
    return this.Get('SourceEntityID');
  }
  set SourceEntityID(value) {
    this.Set('SourceEntityID', value);
  }
  /**
  * * Field Name: SourceEntityRecordID
  * * Display Name: Source Entity Record ID
  * * SQL Data Type: nvarchar(MAX)
  * * Description: The record ID of the source entity
  */
  get SourceEntityRecordID() {
    return this.Get('SourceEntityRecordID');
  }
  set SourceEntityRecordID(value) {
    this.Set('SourceEntityRecordID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: SourceEntity
  * * Display Name: Source Entity
  * * SQL Data Type: nvarchar(255)
  */
  get SourceEntity() {
    return this.Get('SourceEntity');
  }
};
exports.RecommendationEntity = RecommendationEntity;
exports.RecommendationEntity = RecommendationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Recommendations')], RecommendationEntity);
/**
 * Record Change Replay Runs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: RecordChangeReplayRun
 * * Base View: vwRecordChangeReplayRuns
 * * @description Table to track the runs of replaying external record changes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RecordChangeReplayRunEntity = class RecordChangeReplayRunEntity extends core_1.BaseEntity {
  /**
  * Loads the Record Change Replay Runs record from the database
  * @param ID: string - primary key value to load the Record Change Replay Runs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RecordChangeReplayRunEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Record Change Replay Runs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RecordChangeReplayRunEntity
  * @throws {Error} - Delete is not allowed for Record Change Replay Runs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Record Change Replay Runs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: StartedAt
  * * Display Name: Started At
  * * SQL Data Type: datetime
  * * Description: Timestamp when the replay run started
  */
  get StartedAt() {
    return this.Get('StartedAt');
  }
  set StartedAt(value) {
    this.Set('StartedAt', value);
  }
  /**
  * * Field Name: EndedAt
  * * Display Name: Ended At
  * * SQL Data Type: datetime
  * * Description: Timestamp when the replay run ended
  */
  get EndedAt() {
    return this.Get('EndedAt');
  }
  set EndedAt(value) {
    this.Set('EndedAt', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(50)
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * In Progress
  *   * Complete
  *   * Error
  * * Description: Status of the replay run (Pending, In Progress, Complete, Error)
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.RecordChangeReplayRunEntity = RecordChangeReplayRunEntity;
exports.RecordChangeReplayRunEntity = RecordChangeReplayRunEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Record Change Replay Runs')], RecordChangeReplayRunEntity);
/**
 * Record Changes - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: RecordChange
 * * Base View: vwRecordChanges
 * * @description For entities that have TrackRecordChanges=1, Record Changes will store the history of all changes made within the system. For integrations you can directly add values here if you have inbound signals indicating records were changed in a source system. This entity only automatically captures Record Changes if they were made within the system.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RecordChangeEntity = class RecordChangeEntity extends core_1.BaseEntity {
  /**
  * Loads the Record Changes record from the database
  * @param ID: string - primary key value to load the Record Changes record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RecordChangeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Record Changes - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RecordChangeEntity
  * @throws {Error} - Delete is not allowed for Record Changes, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Record Changes, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record
  * * SQL Data Type: nvarchar(750)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Create
  * * Value List Type: List
  * * Possible Values
  *   * Create
  *   * Update
  *   * Delete
  * * Description: Create, Update, or Delete
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: Source
  * * Display Name: Source
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Internal
  * * Value List Type: List
  * * Possible Values
  *   * Internal
  *   * External
  * * Description: Internal or External
  */
  get Source() {
    return this.Get('Source');
  }
  set Source(value) {
    this.Set('Source', value);
  }
  /**
  * * Field Name: ChangedAt
  * * Display Name: Changed At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  * * Description: The date/time that the change occured.
  */
  get ChangedAt() {
    return this.Get('ChangedAt');
  }
  set ChangedAt(value) {
    this.Set('ChangedAt', value);
  }
  /**
  * * Field Name: ChangesJSON
  * * Display Name: Changes JSON
  * * SQL Data Type: nvarchar(MAX)
  * * Description: JSON structure that describes what was changed in a structured format.
  */
  get ChangesJSON() {
    return this.Get('ChangesJSON');
  }
  set ChangesJSON(value) {
    this.Set('ChangesJSON', value);
  }
  /**
  * * Field Name: ChangesDescription
  * * Display Name: Changes Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: A generated, human-readable description of what was changed.
  */
  get ChangesDescription() {
    return this.Get('ChangesDescription');
  }
  set ChangesDescription(value) {
    this.Set('ChangesDescription', value);
  }
  /**
  * * Field Name: FullRecordJSON
  * * Display Name: Full Record JSON
  * * SQL Data Type: nvarchar(MAX)
  * * Description: A complete snapshot of the record AFTER the change was applied in a JSON format that can be parsed.
  */
  get FullRecordJSON() {
    return this.Get('FullRecordJSON');
  }
  set FullRecordJSON(value) {
    this.Set('FullRecordJSON', value);
  }
  /**
  * * Field Name: Status
  * * SQL Data Type: nvarchar(50)
  * * Default Value: Complete
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * Complete
  *   * Error
  * * Description: For internal record changes generated within MJ, the status is immediately Complete. For external changes that are detected, the workflow starts off as Pending, then In Progress and finally either Complete or Error
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: ErrorLog
  * * Display Name: Error Log
  * * SQL Data Type: nvarchar(MAX)
  */
  get ErrorLog() {
    return this.Get('ErrorLog');
  }
  set ErrorLog(value) {
    this.Set('ErrorLog', value);
  }
  /**
  * * Field Name: ReplayRunID
  * * Display Name: Replay Run ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Record Change Replay Runs (vwRecordChangeReplayRuns.ID)
  */
  get ReplayRunID() {
    return this.Get('ReplayRunID');
  }
  set ReplayRunID(value) {
    this.Set('ReplayRunID', value);
  }
  /**
  * * Field Name: IntegrationID
  * * Display Name: Integration ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Integrations (vwIntegrations.ID)
  */
  get IntegrationID() {
    return this.Get('IntegrationID');
  }
  set IntegrationID(value) {
    this.Set('IntegrationID', value);
  }
  /**
  * * Field Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get CreatedAt() {
    return this.Get('CreatedAt');
  }
  /**
  * * Field Name: UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get UpdatedAt() {
    return this.Get('UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: Integration
  * * Display Name: Integration
  * * SQL Data Type: nvarchar(100)
  */
  get Integration() {
    return this.Get('Integration');
  }
};
exports.RecordChangeEntity = RecordChangeEntity;
exports.RecordChangeEntity = RecordChangeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Record Changes')], RecordChangeEntity);
/**
 * Record Merge Deletion Logs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: RecordMergeDeletionLog
 * * Base View: vwRecordMergeDeletionLogs
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RecordMergeDeletionLogEntity = class RecordMergeDeletionLogEntity extends core_1.BaseEntity {
  /**
  * Loads the Record Merge Deletion Logs record from the database
  * @param ID: string - primary key value to load the Record Merge Deletion Logs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RecordMergeDeletionLogEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Record Merge Deletion Logs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RecordMergeDeletionLogEntity
  * @throws {Error} - Delete is not allowed for Record Merge Deletion Logs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Record Merge Deletion Logs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: RecordMergeLogID
  * * Display Name: Record Merge Log ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Record Merge Logs (vwRecordMergeLogs.ID)
  */
  get RecordMergeLogID() {
    return this.Get('RecordMergeLogID');
  }
  set RecordMergeLogID(value) {
    this.Set('RecordMergeLogID', value);
  }
  /**
  * * Field Name: DeletedRecordID
  * * Display Name: Deleted Record ID
  * * SQL Data Type: nvarchar(750)
  */
  get DeletedRecordID() {
    return this.Get('DeletedRecordID');
  }
  set DeletedRecordID(value) {
    this.Set('DeletedRecordID', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(10)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * Complete
  *   * Error
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: ProcessingLog
  * * Display Name: Processing Log
  * * SQL Data Type: nvarchar(MAX)
  */
  get ProcessingLog() {
    return this.Get('ProcessingLog');
  }
  set ProcessingLog(value) {
    this.Set('ProcessingLog', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.RecordMergeDeletionLogEntity = RecordMergeDeletionLogEntity;
exports.RecordMergeDeletionLogEntity = RecordMergeDeletionLogEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Record Merge Deletion Logs')], RecordMergeDeletionLogEntity);
/**
 * Record Merge Logs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: RecordMergeLog
 * * Base View: vwRecordMergeLogs
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RecordMergeLogEntity = class RecordMergeLogEntity extends core_1.BaseEntity {
  /**
  * Loads the Record Merge Logs record from the database
  * @param ID: string - primary key value to load the Record Merge Logs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RecordMergeLogEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Record Merge Logs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RecordMergeLogEntity
  * @throws {Error} - Delete is not allowed for Record Merge Logs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Record Merge Logs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: SurvivingRecordID
  * * Display Name: Surviving Record ID
  * * SQL Data Type: nvarchar(450)
  */
  get SurvivingRecordID() {
    return this.Get('SurvivingRecordID');
  }
  set SurvivingRecordID(value) {
    this.Set('SurvivingRecordID', value);
  }
  /**
  * * Field Name: InitiatedByUserID
  * * Display Name: Initiated By User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get InitiatedByUserID() {
    return this.Get('InitiatedByUserID');
  }
  set InitiatedByUserID(value) {
    this.Set('InitiatedByUserID', value);
  }
  /**
  * * Field Name: ApprovalStatus
  * * Display Name: Approval Status
  * * SQL Data Type: nvarchar(10)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * Approved
  *   * Rejected
  */
  get ApprovalStatus() {
    return this.Get('ApprovalStatus');
  }
  set ApprovalStatus(value) {
    this.Set('ApprovalStatus', value);
  }
  /**
  * * Field Name: ApprovedByUserID
  * * Display Name: Approved By User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get ApprovedByUserID() {
    return this.Get('ApprovedByUserID');
  }
  set ApprovedByUserID(value) {
    this.Set('ApprovedByUserID', value);
  }
  /**
  * * Field Name: ProcessingStatus
  * * Display Name: Processing Status
  * * SQL Data Type: nvarchar(10)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Started
  *   * Complete
  *   * Error
  */
  get ProcessingStatus() {
    return this.Get('ProcessingStatus');
  }
  set ProcessingStatus(value) {
    this.Set('ProcessingStatus', value);
  }
  /**
  * * Field Name: ProcessingStartedAt
  * * Display Name: Processing Started At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get ProcessingStartedAt() {
    return this.Get('ProcessingStartedAt');
  }
  set ProcessingStartedAt(value) {
    this.Set('ProcessingStartedAt', value);
  }
  /**
  * * Field Name: ProcessingEndedAt
  * * Display Name: Processing Ended At
  * * SQL Data Type: datetime
  */
  get ProcessingEndedAt() {
    return this.Get('ProcessingEndedAt');
  }
  set ProcessingEndedAt(value) {
    this.Set('ProcessingEndedAt', value);
  }
  /**
  * * Field Name: ProcessingLog
  * * Display Name: Processing Log
  * * SQL Data Type: nvarchar(MAX)
  */
  get ProcessingLog() {
    return this.Get('ProcessingLog');
  }
  set ProcessingLog(value) {
    this.Set('ProcessingLog', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: InitiatedByUser
  * * Display Name: Initiated By User
  * * SQL Data Type: nvarchar(100)
  */
  get InitiatedByUser() {
    return this.Get('InitiatedByUser');
  }
};
exports.RecordMergeLogEntity = RecordMergeLogEntity;
exports.RecordMergeLogEntity = RecordMergeLogEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Record Merge Logs')], RecordMergeLogEntity);
/**
 * Report Categories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ReportCategory
 * * Base View: vwReportCategories
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ReportCategoryEntity = class ReportCategoryEntity extends core_1.BaseEntity {
  /**
  * Loads the Report Categories record from the database
  * @param ID: string - primary key value to load the Report Categories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ReportCategoryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Report Categories (vwReportCategories.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(100)
  */
  get Parent() {
    return this.Get('Parent');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.ReportCategoryEntity = ReportCategoryEntity;
exports.ReportCategoryEntity = ReportCategoryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Report Categories')], ReportCategoryEntity);
/**
 * Report Snapshots - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ReportSnapshot
 * * Base View: vwReportSnapshots
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ReportSnapshotEntity = class ReportSnapshotEntity extends core_1.BaseEntity {
  /**
  * Loads the Report Snapshots record from the database
  * @param ID: string - primary key value to load the Report Snapshots record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ReportSnapshotEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ReportID
  * * Display Name: Report ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Reports (vwReports.ID)
  */
  get ReportID() {
    return this.Get('ReportID');
  }
  set ReportID(value) {
    this.Set('ReportID', value);
  }
  /**
  * * Field Name: ResultSet
  * * Display Name: Result Set
  * * SQL Data Type: nvarchar(MAX)
  */
  get ResultSet() {
    return this.Get('ResultSet');
  }
  set ResultSet(value) {
    this.Set('ResultSet', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Report
  * * Display Name: Report
  * * SQL Data Type: nvarchar(255)
  */
  get Report() {
    return this.Get('Report');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.ReportSnapshotEntity = ReportSnapshotEntity;
exports.ReportSnapshotEntity = ReportSnapshotEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Report Snapshots')], ReportSnapshotEntity);
/**
 * Reports - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Report
 * * Base View: vwReports
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ReportEntity = class ReportEntity extends core_1.BaseEntity {
  /**
  * Loads the Reports record from the database
  * @param ID: string - primary key value to load the Reports record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ReportEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: CategoryID
  * * Display Name: Category ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Report Categories (vwReportCategories.ID)
  */
  get CategoryID() {
    return this.Get('CategoryID');
  }
  set CategoryID(value) {
    this.Set('CategoryID', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: SharingScope
  * * Display Name: Sharing Scope
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Personal
  * * Value List Type: List
  * * Possible Values
  *   * None
  *   * Specific
  *   * Everyone
  */
  get SharingScope() {
    return this.Get('SharingScope');
  }
  set SharingScope(value) {
    this.Set('SharingScope', value);
  }
  /**
  * * Field Name: ConversationID
  * * Display Name: Conversation ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Conversations (vwConversations.ID)
  */
  get ConversationID() {
    return this.Get('ConversationID');
  }
  set ConversationID(value) {
    this.Set('ConversationID', value);
  }
  /**
  * * Field Name: ConversationDetailID
  * * Display Name: Conversation Detail ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Conversation Details (vwConversationDetails.ID)
  */
  get ConversationDetailID() {
    return this.Get('ConversationDetailID');
  }
  set ConversationDetailID(value) {
    this.Set('ConversationDetailID', value);
  }
  /**
  * * Field Name: DataContextID
  * * Display Name: Data Context ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Data Contexts (vwDataContexts.ID)
  */
  get DataContextID() {
    return this.Get('DataContextID');
  }
  set DataContextID(value) {
    this.Set('DataContextID', value);
  }
  /**
  * * Field Name: Configuration
  * * Display Name: Configuration
  * * SQL Data Type: nvarchar(MAX)
  */
  get Configuration() {
    return this.Get('Configuration');
  }
  set Configuration(value) {
    this.Set('Configuration', value);
  }
  /**
  * * Field Name: OutputTriggerTypeID
  * * Display Name: Output Trigger Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Output Trigger Types (vwOutputTriggerTypes.ID)
  */
  get OutputTriggerTypeID() {
    return this.Get('OutputTriggerTypeID');
  }
  set OutputTriggerTypeID(value) {
    this.Set('OutputTriggerTypeID', value);
  }
  /**
  * * Field Name: OutputFormatTypeID
  * * Display Name: Output Format Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Output Format Types (vwOutputFormatTypes.ID)
  */
  get OutputFormatTypeID() {
    return this.Get('OutputFormatTypeID');
  }
  set OutputFormatTypeID(value) {
    this.Set('OutputFormatTypeID', value);
  }
  /**
  * * Field Name: OutputDeliveryTypeID
  * * Display Name: Output Delivery Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Output Delivery Types (vwOutputDeliveryTypes.ID)
  */
  get OutputDeliveryTypeID() {
    return this.Get('OutputDeliveryTypeID');
  }
  set OutputDeliveryTypeID(value) {
    this.Set('OutputDeliveryTypeID', value);
  }
  /**
  * * Field Name: OutputFrequency
  * * Display Name: Output Frequency
  * * SQL Data Type: nvarchar(50)
  */
  get OutputFrequency() {
    return this.Get('OutputFrequency');
  }
  set OutputFrequency(value) {
    this.Set('OutputFrequency', value);
  }
  /**
  * * Field Name: OutputTargetEmail
  * * Display Name: Output Target Email
  * * SQL Data Type: nvarchar(255)
  */
  get OutputTargetEmail() {
    return this.Get('OutputTargetEmail');
  }
  set OutputTargetEmail(value) {
    this.Set('OutputTargetEmail', value);
  }
  /**
  * * Field Name: OutputWorkflowID
  * * Display Name: Output Workflow ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Workflows (vwWorkflows.ID)
  */
  get OutputWorkflowID() {
    return this.Get('OutputWorkflowID');
  }
  set OutputWorkflowID(value) {
    this.Set('OutputWorkflowID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Category
  * * Display Name: Category
  * * SQL Data Type: nvarchar(100)
  */
  get Category() {
    return this.Get('Category');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: Conversation
  * * Display Name: Conversation
  * * SQL Data Type: nvarchar(255)
  */
  get Conversation() {
    return this.Get('Conversation');
  }
  /**
  * * Field Name: DataContext
  * * Display Name: Data Context
  * * SQL Data Type: nvarchar(255)
  */
  get DataContext() {
    return this.Get('DataContext');
  }
  /**
  * * Field Name: OutputTriggerType
  * * Display Name: Output Trigger Type
  * * SQL Data Type: nvarchar(255)
  */
  get OutputTriggerType() {
    return this.Get('OutputTriggerType');
  }
  /**
  * * Field Name: OutputFormatType
  * * Display Name: Output Format Type
  * * SQL Data Type: nvarchar(255)
  */
  get OutputFormatType() {
    return this.Get('OutputFormatType');
  }
  /**
  * * Field Name: OutputDeliveryType
  * * Display Name: Output Delivery Type
  * * SQL Data Type: nvarchar(255)
  */
  get OutputDeliveryType() {
    return this.Get('OutputDeliveryType');
  }
  /**
  * * Field Name: OutputWorkflow
  * * Display Name: Output Workflow
  * * SQL Data Type: nvarchar(100)
  */
  get OutputWorkflow() {
    return this.Get('OutputWorkflow');
  }
};
exports.ReportEntity = ReportEntity;
exports.ReportEntity = ReportEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Reports')], ReportEntity);
/**
 * Resource Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ResourceType
 * * Base View: vwResourceTypes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ResourceTypeEntity = class ResourceTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Resource Types record from the database
  * @param ID: string - primary key value to load the Resource Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ResourceTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Resource Types - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof ResourceTypeEntity
  * @throws {Error} - Save is not allowed for Resource Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Resource Types, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Resource Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof ResourceTypeEntity
  * @throws {Error} - Delete is not allowed for Resource Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Resource Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: DisplayName
  * * Display Name: Display Name
  * * SQL Data Type: nvarchar(255)
  */
  get DisplayName() {
    return this.Get('DisplayName');
  }
  set DisplayName(value) {
    this.Set('DisplayName', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Icon
  * * Display Name: Icon
  * * SQL Data Type: nvarchar(100)
  */
  get Icon() {
    return this.Get('Icon');
  }
  set Icon(value) {
    this.Set('Icon', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.ResourceTypeEntity = ResourceTypeEntity;
exports.ResourceTypeEntity = ResourceTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Resource Types')], ResourceTypeEntity);
/**
 * Roles - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Role
 * * Base View: vwRoles
 * * @description Roles are used for security administration and can have zero to many Users as members
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RoleEntity = class RoleEntity extends core_1.BaseEntity {
  /**
  * Loads the Roles record from the database
  * @param ID: string - primary key value to load the Roles record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RoleEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the role
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: DirectoryID
  * * Display Name: Directory ID
  * * SQL Data Type: nvarchar(250)
  * * Description: The unique ID of the role in the directory being used for authentication, for example an ID in Azure.
  */
  get DirectoryID() {
    return this.Get('DirectoryID');
  }
  set DirectoryID(value) {
    this.Set('DirectoryID', value);
  }
  /**
  * * Field Name: SQLName
  * * SQL Data Type: nvarchar(250)
  * * Description: The name of the role in the database, this is used for auto-generating permission statements by CodeGen
  */
  get SQLName() {
    return this.Get('SQLName');
  }
  set SQLName(value) {
    this.Set('SQLName', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.RoleEntity = RoleEntity;
exports.RoleEntity = RoleEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Roles')], RoleEntity);
/**
 * Row Level Security Filters - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: RowLevelSecurityFilter
 * * Base View: vwRowLevelSecurityFilters
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let RowLevelSecurityFilterEntity = class RowLevelSecurityFilterEntity extends core_1.BaseEntity {
  /**
  * Loads the Row Level Security Filters record from the database
  * @param ID: string - primary key value to load the Row Level Security Filters record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof RowLevelSecurityFilterEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Row Level Security Filters - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RowLevelSecurityFilterEntity
  * @throws {Error} - Save is not allowed for Row Level Security Filters, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Row Level Security Filters, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Row Level Security Filters - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof RowLevelSecurityFilterEntity
  * @throws {Error} - Delete is not allowed for Row Level Security Filters, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Row Level Security Filters, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: FilterText
  * * Display Name: Filter Text
  * * SQL Data Type: nvarchar(MAX)
  */
  get FilterText() {
    return this.Get('FilterText');
  }
  set FilterText(value) {
    this.Set('FilterText', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.RowLevelSecurityFilterEntity = RowLevelSecurityFilterEntity;
exports.RowLevelSecurityFilterEntity = RowLevelSecurityFilterEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Row Level Security Filters')], RowLevelSecurityFilterEntity);
/**
 * Scheduled Action Params - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ScheduledActionParam
 * * Base View: vwScheduledActionParams
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ScheduledActionParamEntity = class ScheduledActionParamEntity extends core_1.BaseEntity {
  /**
  * Loads the Scheduled Action Params record from the database
  * @param ID: string - primary key value to load the Scheduled Action Params record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ScheduledActionParamEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: ScheduledActionID
  * * Display Name: Scheduled Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Scheduled Actions (vwScheduledActions.ID)
  */
  get ScheduledActionID() {
    return this.Get('ScheduledActionID');
  }
  set ScheduledActionID(value) {
    this.Set('ScheduledActionID', value);
  }
  /**
  * * Field Name: ActionParamID
  * * Display Name: Action Param ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Action Params (vwActionParams.ID)
  */
  get ActionParamID() {
    return this.Get('ActionParamID');
  }
  set ActionParamID(value) {
    this.Set('ActionParamID', value);
  }
  /**
  * * Field Name: ValueType
  * * Display Name: Value Type
  * * SQL Data Type: nvarchar(20)
  * * Value List Type: List
  * * Possible Values
  *   * Static
  *   * SQL Statement
  */
  get ValueType() {
    return this.Get('ValueType');
  }
  set ValueType(value) {
    this.Set('ValueType', value);
  }
  /**
  * * Field Name: Value
  * * Display Name: Value
  * * SQL Data Type: nvarchar(MAX)
  */
  get Value() {
    return this.Get('Value');
  }
  set Value(value) {
    this.Set('Value', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ScheduledActionParamEntity = ScheduledActionParamEntity;
exports.ScheduledActionParamEntity = ScheduledActionParamEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Scheduled Action Params')], ScheduledActionParamEntity);
/**
 * Scheduled Actions - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: ScheduledAction
 * * Base View: vwScheduledActions
 * * @description Track scheduled actions and their details
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let ScheduledActionEntity = class ScheduledActionEntity extends core_1.BaseEntity {
  /**
  * Loads the Scheduled Actions record from the database
  * @param ID: string - primary key value to load the Scheduled Actions record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof ScheduledActionEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: CreatedByUserID
  * * Display Name: Created By User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get CreatedByUserID() {
    return this.Get('CreatedByUserID');
  }
  set CreatedByUserID(value) {
    this.Set('CreatedByUserID', value);
  }
  /**
  * * Field Name: ActionID
  * * Display Name: Action ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Actions (vwActions.ID)
  */
  get ActionID() {
    return this.Get('ActionID');
  }
  set ActionID(value) {
    this.Set('ActionID', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(20)
  * * Value List Type: List
  * * Possible Values
  *   * Daily
  *   * Weekly
  *   * Monthly
  *   * Yearly
  *   * Custom
  * * Description: Type of the scheduled action (Daily, Weekly, Monthly, Yearly, Custom)
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: CronExpression
  * * Display Name: Cron Expression
  * * SQL Data Type: nvarchar(100)
  * * Description: Cron expression defining the schedule, automatically maintained by the system unless Type is Custom, in which case the user directly sets this
  */
  get CronExpression() {
    return this.Get('CronExpression');
  }
  set CronExpression(value) {
    this.Set('CronExpression', value);
  }
  /**
  * * Field Name: Timezone
  * * Display Name: Timezone
  * * SQL Data Type: nvarchar(100)
  * * Description: Timezone for the scheduled action, if not specified defaults to UTC/Z
  */
  get Timezone() {
    return this.Get('Timezone');
  }
  set Timezone(value) {
    this.Set('Timezone', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * Active
  *   * Disabled
  *   * Expired
  * * Description: Status of the scheduled action (Pending, Active, Disabled, Expired)
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: IntervalDays
  * * Display Name: Interval Days
  * * SQL Data Type: int
  * * Description: Interval in days for the scheduled action
  */
  get IntervalDays() {
    return this.Get('IntervalDays');
  }
  set IntervalDays(value) {
    this.Set('IntervalDays', value);
  }
  /**
  * * Field Name: DayOfWeek
  * * Display Name: Day Of Week
  * * SQL Data Type: nvarchar(20)
  * * Description: Day of the week for the scheduled action
  */
  get DayOfWeek() {
    return this.Get('DayOfWeek');
  }
  set DayOfWeek(value) {
    this.Set('DayOfWeek', value);
  }
  /**
  * * Field Name: DayOfMonth
  * * Display Name: Day Of Month
  * * SQL Data Type: int
  * * Description: Day of the month for the scheduled action
  */
  get DayOfMonth() {
    return this.Get('DayOfMonth');
  }
  set DayOfMonth(value) {
    this.Set('DayOfMonth', value);
  }
  /**
  * * Field Name: Month
  * * Display Name: Month
  * * SQL Data Type: nvarchar(20)
  * * Description: Month for the scheduled action
  */
  get Month() {
    return this.Get('Month');
  }
  set Month(value) {
    this.Set('Month', value);
  }
  /**
  * * Field Name: CustomCronExpression
  * * Display Name: Custom Cron Expression
  * * SQL Data Type: nvarchar(255)
  */
  get CustomCronExpression() {
    return this.Get('CustomCronExpression');
  }
  set CustomCronExpression(value) {
    this.Set('CustomCronExpression', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.ScheduledActionEntity = ScheduledActionEntity;
exports.ScheduledActionEntity = ScheduledActionEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Scheduled Actions')], ScheduledActionEntity);
/**
 * Schema Info - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: SchemaInfo
 * * Base View: vwSchemaInfos
 * * @description Tracks the schemas in the system and the ID ranges that are valid for entities within each schema.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let SchemaInfoEntity = class SchemaInfoEntity extends core_1.BaseEntity {
  /**
  * Loads the Schema Info record from the database
  * @param ID: string - primary key value to load the Schema Info record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof SchemaInfoEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Schema Info - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof SchemaInfoEntity
  * @throws {Error} - Delete is not allowed for Schema Info, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Schema Info, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: SchemaName
  * * Display Name: Schema Name
  * * SQL Data Type: nvarchar(50)
  */
  get SchemaName() {
    return this.Get('SchemaName');
  }
  set SchemaName(value) {
    this.Set('SchemaName', value);
  }
  /**
  * * Field Name: EntityIDMin
  * * Display Name: Entity IDMin
  * * SQL Data Type: int
  */
  get EntityIDMin() {
    return this.Get('EntityIDMin');
  }
  set EntityIDMin(value) {
    this.Set('EntityIDMin', value);
  }
  /**
  * * Field Name: EntityIDMax
  * * Display Name: Entity IDMax
  * * SQL Data Type: int
  */
  get EntityIDMax() {
    return this.Get('EntityIDMax');
  }
  set EntityIDMax(value) {
    this.Set('EntityIDMax', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.SchemaInfoEntity = SchemaInfoEntity;
exports.SchemaInfoEntity = SchemaInfoEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Schema Info')], SchemaInfoEntity);
/**
 * Skills - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Skill
 * * Base View: vwSkills
 * * @description A hierarchical list of possible skills that are linked to Employees and can also be linked to any other entity
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let SkillEntity = class SkillEntity extends core_1.BaseEntity {
  /**
  * Loads the Skills record from the database
  * @param ID: string - primary key value to load the Skills record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof SkillEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Skills - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof SkillEntity
  * @throws {Error} - Save is not allowed for Skills, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Skills, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Skills - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof SkillEntity
  * @throws {Error} - Delete is not allowed for Skills, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Skills, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(50)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Skills (vwSkills.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(50)
  */
  get Parent() {
    return this.Get('Parent');
  }
};
exports.SkillEntity = SkillEntity;
exports.SkillEntity = SkillEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Skills')], SkillEntity);
/**
 * Tagged Items - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: TaggedItem
 * * Base View: vwTaggedItems
 * * @description Tracks the links between any record in any entity with Tags
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let TaggedItemEntity = class TaggedItemEntity extends core_1.BaseEntity {
  /**
  * Loads the Tagged Items record from the database
  * @param ID: string - primary key value to load the Tagged Items record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof TaggedItemEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Tagged Items - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof TaggedItemEntity
  * @throws {Error} - Save is not allowed for Tagged Items, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Tagged Items, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: TagID
  * * Display Name: Tag ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Tags (vwTags.ID)
  */
  get TagID() {
    return this.Get('TagID');
  }
  set TagID(value) {
    this.Set('TagID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record ID
  * * SQL Data Type: nvarchar(450)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Tag
  * * Display Name: Tag
  * * SQL Data Type: nvarchar(255)
  */
  get Tag() {
    return this.Get('Tag');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.TaggedItemEntity = TaggedItemEntity;
exports.TaggedItemEntity = TaggedItemEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Tagged Items')], TaggedItemEntity);
/**
 * Tags - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Tag
 * * Base View: vwTags
 * * @description Tags are used to arbitrarily associate any record in any entity with addtional information.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let TagEntity = class TagEntity extends core_1.BaseEntity {
  /**
  * Loads the Tags record from the database
  * @param ID: string - primary key value to load the Tags record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof TagEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Tags - AllowCreateAPI and AllowUpdateAPI are both set to 0 in the database.  Save is not allowed, so this method is generated to override the base class method and throw an error. To enable save for this entity, set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof TagEntity
  * @throws {Error} - Save is not allowed for Tags, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.
  */
  async Save(options) {
    throw new Error('Save is not allowed for Tags, to enable it set AllowCreateAPI and/or AllowUpdateAPI to 1 in the database.');
  }
  /**
  * Tags - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof TagEntity
  * @throws {Error} - Delete is not allowed for Tags, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Tags, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Tags (vwTags.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: DisplayName
  * * Display Name: Display Name
  * * SQL Data Type: nvarchar(255)
  */
  get DisplayName() {
    return this.Get('DisplayName');
  }
  set DisplayName(value) {
    this.Set('DisplayName', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(255)
  */
  get Parent() {
    return this.Get('Parent');
  }
};
exports.TagEntity = TagEntity;
exports.TagEntity = TagEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Tags')], TagEntity);
/**
 * Template Categories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: TemplateCategory
 * * Base View: vwTemplateCategories
 * * @description Template categories for organizing templates
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let TemplateCategoryEntity = class TemplateCategoryEntity extends core_1.BaseEntity {
  /**
  * Loads the Template Categories record from the database
  * @param ID: string - primary key value to load the Template Categories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof TemplateCategoryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Template Categories - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof TemplateCategoryEntity
  * @throws {Error} - Delete is not allowed for Template Categories, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Template Categories, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the template category
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the template category
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Template Categories (vwTemplateCategories.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(255)
  */
  get Parent() {
    return this.Get('Parent');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.TemplateCategoryEntity = TemplateCategoryEntity;
exports.TemplateCategoryEntity = TemplateCategoryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Template Categories')], TemplateCategoryEntity);
/**
 * Template Content Types - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: TemplateContentType
 * * Base View: vwTemplateContentTypes
 * * @description Template content types for categorizing content within templates
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let TemplateContentTypeEntity = class TemplateContentTypeEntity extends core_1.BaseEntity {
  /**
  * Loads the Template Content Types record from the database
  * @param ID: string - primary key value to load the Template Content Types record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof TemplateContentTypeEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Template Content Types - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof TemplateContentTypeEntity
  * @throws {Error} - Delete is not allowed for Template Content Types, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Template Content Types, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the template content type
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the template content type
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: CodeType
  * * Display Name: Code Type
  * * SQL Data Type: nvarchar(25)
  * * Default Value: Other
  * * Value List Type: List
  * * Possible Values
  *   * TypeScript
  *   * SQL
  *   * HTML
  *   * CSS
  *   * JavaScript
  *   * JSON
  *   * Other
  * * Description: Refers to the primary language or codetype of the templates of this type, HTML, JSON, JavaScript, etc
  */
  get CodeType() {
    return this.Get('CodeType');
  }
  set CodeType(value) {
    this.Set('CodeType', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.TemplateContentTypeEntity = TemplateContentTypeEntity;
exports.TemplateContentTypeEntity = TemplateContentTypeEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Template Content Types')], TemplateContentTypeEntity);
/**
 * Template Contents - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: TemplateContent
 * * Base View: vwTemplateContents
 * * @description Template content for different versions of a template for purposes like HTML/Text/etc
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let TemplateContentEntity = class TemplateContentEntity extends core_1.BaseEntity {
  /**
  * Loads the Template Contents record from the database
  * @param ID: string - primary key value to load the Template Contents record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof TemplateContentEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Template Contents - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof TemplateContentEntity
  * @throws {Error} - Delete is not allowed for Template Contents, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Template Contents, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: TemplateID
  * * Display Name: Template ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Templates (vwTemplates.ID)
  */
  get TemplateID() {
    return this.Get('TemplateID');
  }
  set TemplateID(value) {
    this.Set('TemplateID', value);
  }
  /**
  * * Field Name: TypeID
  * * Display Name: Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Template Content Types (vwTemplateContentTypes.ID)
  */
  get TypeID() {
    return this.Get('TypeID');
  }
  set TypeID(value) {
    this.Set('TypeID', value);
  }
  /**
  * * Field Name: TemplateText
  * * Display Name: Template Text
  * * SQL Data Type: nvarchar(MAX)
  * * Description: The actual text content for the template
  */
  get TemplateText() {
    return this.Get('TemplateText');
  }
  set TemplateText(value) {
    this.Set('TemplateText', value);
  }
  /**
  * * Field Name: Priority
  * * Display Name: Priority
  * * SQL Data Type: int
  * * Description: Priority of the content version, higher priority versions will be used ahead of lower priority versions for a given Type
  */
  get Priority() {
    return this.Get('Priority');
  }
  set Priority(value) {
    this.Set('Priority', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: Indicates whether the content is active or not. Use this to disable a particular Template Content item without having to remove it
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Template
  * * Display Name: Template
  * * SQL Data Type: nvarchar(255)
  */
  get Template() {
    return this.Get('Template');
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(255)
  */
  get Type() {
    return this.Get('Type');
  }
};
exports.TemplateContentEntity = TemplateContentEntity;
exports.TemplateContentEntity = TemplateContentEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Template Contents')], TemplateContentEntity);
/**
 * Template Params - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: TemplateParam
 * * Base View: vwTemplateParams
 * * @description Parameters allowed for use inside the template
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let TemplateParamEntity = class TemplateParamEntity extends core_1.BaseEntity {
  /**
  * Loads the Template Params record from the database
  * @param ID: string - primary key value to load the Template Params record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof TemplateParamEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Template Params - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof TemplateParamEntity
  * @throws {Error} - Delete is not allowed for Template Params, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Template Params, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: TemplateID
  * * Display Name: Template ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Templates (vwTemplates.ID)
  */
  get TemplateID() {
    return this.Get('TemplateID');
  }
  set TemplateID(value) {
    this.Set('TemplateID', value);
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the parameter
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the parameter
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Scalar
  * * Value List Type: List
  * * Possible Values
  *   * Scalar
  *   * Array
  *   * Object
  *   * Record
  *   * Entity
  * * Description: Type of the parameter - Record is an individual record within the entity specified by EntityID. Entity means an entire Entity or an entity filtered by the LinkedParameterName/Field attributes and/or ExtraFilter. Object is any valid JSON object. Array and Scalar have their common meanings.
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: DefaultValue
  * * Display Name: Default Value
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Default value of the parameter
  */
  get DefaultValue() {
    return this.Get('DefaultValue');
  }
  set DefaultValue(value) {
    this.Set('DefaultValue', value);
  }
  /**
  * * Field Name: IsRequired
  * * Display Name: Is Required
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsRequired() {
    return this.Get('IsRequired');
  }
  set IsRequired(value) {
    this.Set('IsRequired', value);
  }
  /**
  * * Field Name: LinkedParameterName
  * * Display Name: Linked Parameter Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Only used when Type=Entity, this is used to link an Entity parameter with another parameter so that the rows in the Entity parameter can be filtered automatically based on the FKEY relationship between the Record and this Entity parameter. For example, if the Entity-based parameter is for an entity like Activities and there is another parameter of type Record for an entity like Contacts, in that situation the Activities Parameter would point to the Contacts parameter as the LinkedParameterName because we would filter down the Activities in each template render to only those linked to the Contact.
  */
  get LinkedParameterName() {
    return this.Get('LinkedParameterName');
  }
  set LinkedParameterName(value) {
    this.Set('LinkedParameterName', value);
  }
  /**
  * * Field Name: LinkedParameterField
  * * Display Name: Linked Parameter Field
  * * SQL Data Type: nvarchar(500)
  * * Description: If the LinkedParameterName is specified, this is an optional setting to specify the field within the LinkedParameter that will be used for filtering. This is only needed if there is more than one foreign key relationship between the Entity parameter and the Linked parameter, or if there is no defined foreign key in the database between the two entities.
  */
  get LinkedParameterField() {
    return this.Get('LinkedParameterField');
  }
  set LinkedParameterField(value) {
    this.Set('LinkedParameterField', value);
  }
  /**
  * * Field Name: ExtraFilter
  * * Display Name: Extra Filter
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Only used when Type = Entity, used to specify an optional filter to reduce the set of rows that are returned for each of the templates being rendered.
  */
  get ExtraFilter() {
    return this.Get('ExtraFilter');
  }
  set ExtraFilter(value) {
    this.Set('ExtraFilter', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record ID
  * * SQL Data Type: nvarchar(2000)
  * * Description: Record ID, used only when Type is Record and a specific hardcoded record ID is desired, this is an uncommon use case, helpful for pulling in static types and metadata in some cases.
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Template
  * * Display Name: Template
  * * SQL Data Type: nvarchar(255)
  */
  get Template() {
    return this.Get('Template');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.TemplateParamEntity = TemplateParamEntity;
exports.TemplateParamEntity = TemplateParamEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Template Params')], TemplateParamEntity);
/**
 * Templates - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Template
 * * Base View: vwTemplates
 * * @description Templates are used for dynamic expansion of a static template with data from a given context. Templates can be used to create documents, messages and anything else that requires dynamic document creation merging together static text, data and lightweight logic
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let TemplateEntity = class TemplateEntity extends core_1.BaseEntity {
  /**
  * Loads the Templates record from the database
  * @param ID: string - primary key value to load the Templates record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof TemplateEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Templates - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof TemplateEntity
  * @throws {Error} - Delete is not allowed for Templates, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Templates, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  * * Description: Name of the template
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Description of the template
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: CategoryID
  * * Display Name: Category ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Template Categories (vwTemplateCategories.ID)
  */
  get CategoryID() {
    return this.Get('CategoryID');
  }
  set CategoryID(value) {
    this.Set('CategoryID', value);
  }
  /**
  * * Field Name: UserPrompt
  * * Display Name: User Prompt
  * * SQL Data Type: nvarchar(MAX)
  * * Description: This prompt will be used by the AI to generate template content as requested by the user.
  */
  get UserPrompt() {
    return this.Get('UserPrompt');
  }
  set UserPrompt(value) {
    this.Set('UserPrompt', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: ActiveAt
  * * Display Name: Active At
  * * SQL Data Type: datetime
  * * Description: Optional, if provided, this template will not be available for use until the specified date. Requires IsActive to be set to 1
  */
  get ActiveAt() {
    return this.Get('ActiveAt');
  }
  set ActiveAt(value) {
    this.Set('ActiveAt', value);
  }
  /**
  * * Field Name: DisabledAt
  * * Display Name: Disabled At
  * * SQL Data Type: datetime
  * * Description: Optional, if provided, this template will not be available for use after the specified date. If IsActive=0, this has no effect.
  */
  get DisabledAt() {
    return this.Get('DisabledAt');
  }
  set DisabledAt(value) {
    this.Set('DisabledAt', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  * * Description: If set to 0, the template will be disabled regardless of the values in ActiveAt/DisabledAt.
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Category
  * * Display Name: Category
  * * SQL Data Type: nvarchar(255)
  */
  get Category() {
    return this.Get('Category');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.TemplateEntity = TemplateEntity;
exports.TemplateEntity = TemplateEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Templates')], TemplateEntity);
/**
 * User Application Entities - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserApplicationEntity
 * * Base View: vwUserApplicationEntities
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserApplicationEntityEntity = class UserApplicationEntityEntity extends core_1.BaseEntity {
  /**
  * Loads the User Application Entities record from the database
  * @param ID: string - primary key value to load the User Application Entities record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserApplicationEntityEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserApplicationID
  * * Display Name: UserApplication ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: User Applications (vwUserApplications.ID)
  */
  get UserApplicationID() {
    return this.Get('UserApplicationID');
  }
  set UserApplicationID(value) {
    this.Set('UserApplicationID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: Sequence
  * * SQL Data Type: int
  * * Default Value: 0
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Application
  * * Display Name: Application
  * * SQL Data Type: nvarchar(100)
  */
  get Application() {
    return this.Get('Application');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
};
exports.UserApplicationEntityEntity = UserApplicationEntityEntity;
exports.UserApplicationEntityEntity = UserApplicationEntityEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User Application Entities')], UserApplicationEntityEntity);
/**
 * User Applications - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserApplication
 * * Base View: vwUserApplications
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserApplicationEntity = class UserApplicationEntity extends core_1.BaseEntity {
  /**
  * Loads the User Applications record from the database
  * @param ID: string - primary key value to load the User Applications record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserApplicationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: ApplicationID
  * * Display Name: Application ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Applications (vwApplications.ID)
  */
  get ApplicationID() {
    return this.Get('ApplicationID');
  }
  set ApplicationID(value) {
    this.Set('ApplicationID', value);
  }
  /**
  * * Field Name: Sequence
  * * SQL Data Type: int
  * * Default Value: 0
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: Application
  * * Display Name: Application
  * * SQL Data Type: nvarchar(100)
  */
  get Application() {
    return this.Get('Application');
  }
};
exports.UserApplicationEntity = UserApplicationEntity;
exports.UserApplicationEntity = UserApplicationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User Applications')], UserApplicationEntity);
/**
 * User Favorites - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserFavorite
 * * Base View: vwUserFavorites
 * * @description Records that each user can mark as a favorite for easy access
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserFavoriteEntity = class UserFavoriteEntity extends core_1.BaseEntity {
  /**
  * Loads the User Favorites record from the database
  * @param ID: string - primary key value to load the User Favorites record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserFavoriteEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record
  * * SQL Data Type: nvarchar(450)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: EntityBaseTable
  * * Display Name: Entity Base Table
  * * SQL Data Type: nvarchar(255)
  */
  get EntityBaseTable() {
    return this.Get('EntityBaseTable');
  }
  /**
  * * Field Name: EntityBaseView
  * * Display Name: Entity Base View
  * * SQL Data Type: nvarchar(255)
  */
  get EntityBaseView() {
    return this.Get('EntityBaseView');
  }
};
exports.UserFavoriteEntity = UserFavoriteEntity;
exports.UserFavoriteEntity = UserFavoriteEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User Favorites')], UserFavoriteEntity);
/**
 * User Notifications - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserNotification
 * * Base View: vwUserNotifications
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserNotificationEntity = class UserNotificationEntity extends core_1.BaseEntity {
  /**
  * Loads the User Notifications record from the database
  * @param ID: string - primary key value to load the User Notifications record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserNotificationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: Title
  * * Display Name: Title
  * * SQL Data Type: nvarchar(255)
  */
  get Title() {
    return this.Get('Title');
  }
  set Title(value) {
    this.Set('Title', value);
  }
  /**
  * * Field Name: Message
  * * Display Name: Message
  * * SQL Data Type: nvarchar(MAX)
  */
  get Message() {
    return this.Get('Message');
  }
  set Message(value) {
    this.Set('Message', value);
  }
  /**
  * * Field Name: ResourceTypeID
  * * Display Name: Resource Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Resource Types (vwResourceTypes.ID)
  */
  get ResourceTypeID() {
    return this.Get('ResourceTypeID');
  }
  set ResourceTypeID(value) {
    this.Set('ResourceTypeID', value);
  }
  /**
  * * Field Name: ResourceRecordID
  * * Display Name: Resource Record ID
  * * SQL Data Type: int
  */
  get ResourceRecordID() {
    return this.Get('ResourceRecordID');
  }
  set ResourceRecordID(value) {
    this.Set('ResourceRecordID', value);
  }
  /**
  * * Field Name: ResourceConfiguration
  * * Display Name: Resource Configuration
  * * SQL Data Type: nvarchar(MAX)
  */
  get ResourceConfiguration() {
    return this.Get('ResourceConfiguration');
  }
  set ResourceConfiguration(value) {
    this.Set('ResourceConfiguration', value);
  }
  /**
  * * Field Name: Unread
  * * Display Name: Unread
  * * SQL Data Type: bit
  * * Default Value: 1
  */
  get Unread() {
    return this.Get('Unread');
  }
  set Unread(value) {
    this.Set('Unread', value);
  }
  /**
  * * Field Name: ReadAt
  * * Display Name: Read At
  * * SQL Data Type: datetime
  */
  get ReadAt() {
    return this.Get('ReadAt');
  }
  set ReadAt(value) {
    this.Set('ReadAt', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: ResourceType
  * * Display Name: Resource Type
  * * SQL Data Type: nvarchar(255)
  */
  get ResourceType() {
    return this.Get('ResourceType');
  }
};
exports.UserNotificationEntity = UserNotificationEntity;
exports.UserNotificationEntity = UserNotificationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User Notifications')], UserNotificationEntity);
/**
 * User Record Logs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserRecordLog
 * * Base View: vwUserRecordLogs
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserRecordLogEntity = class UserRecordLogEntity extends core_1.BaseEntity {
  /**
  * Loads the User Record Logs record from the database
  * @param ID: string - primary key value to load the User Record Logs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserRecordLogEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * User Record Logs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof UserRecordLogEntity
  * @throws {Error} - Delete is not allowed for User Record Logs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for User Record Logs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record
  * * SQL Data Type: nvarchar(450)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: EarliestAt
  * * Display Name: Earliest At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get EarliestAt() {
    return this.Get('EarliestAt');
  }
  set EarliestAt(value) {
    this.Set('EarliestAt', value);
  }
  /**
  * * Field Name: LatestAt
  * * Display Name: Latest At
  * * SQL Data Type: datetime
  * * Default Value: getdate()
  */
  get LatestAt() {
    return this.Get('LatestAt');
  }
  set LatestAt(value) {
    this.Set('LatestAt', value);
  }
  /**
  * * Field Name: TotalCount
  * * Display Name: Total Count
  * * SQL Data Type: int
  * * Default Value: 0
  */
  get TotalCount() {
    return this.Get('TotalCount');
  }
  set TotalCount(value) {
    this.Set('TotalCount', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: UserName
  * * Display Name: User Name
  * * SQL Data Type: nvarchar(100)
  */
  get UserName() {
    return this.Get('UserName');
  }
  /**
  * * Field Name: UserFirstLast
  * * Display Name: User First Last
  * * SQL Data Type: nvarchar(101)
  */
  get UserFirstLast() {
    return this.Get('UserFirstLast');
  }
  /**
  * * Field Name: UserEmail
  * * Display Name: User Email
  * * SQL Data Type: nvarchar(100)
  */
  get UserEmail() {
    return this.Get('UserEmail');
  }
  /**
  * * Field Name: UserSupervisor
  * * Display Name: User Supervisor
  * * SQL Data Type: nvarchar(81)
  */
  get UserSupervisor() {
    return this.Get('UserSupervisor');
  }
  /**
  * * Field Name: UserSupervisorEmail
  * * Display Name: User Supervisor Email
  * * SQL Data Type: nvarchar(100)
  */
  get UserSupervisorEmail() {
    return this.Get('UserSupervisorEmail');
  }
};
exports.UserRecordLogEntity = UserRecordLogEntity;
exports.UserRecordLogEntity = UserRecordLogEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User Record Logs')], UserRecordLogEntity);
/**
 * User Roles - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserRole
 * * Base View: vwUserRoles
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserRoleEntity = class UserRoleEntity extends core_1.BaseEntity {
  /**
  * Loads the User Roles record from the database
  * @param ID: string - primary key value to load the User Roles record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserRoleEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: RoleID
  * * Display Name: Role ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Roles (vwRoles.ID)
  */
  get RoleID() {
    return this.Get('RoleID');
  }
  set RoleID(value) {
    this.Set('RoleID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
  /**
  * * Field Name: Role
  * * Display Name: Role
  * * SQL Data Type: nvarchar(50)
  */
  get Role() {
    return this.Get('Role');
  }
};
exports.UserRoleEntity = UserRoleEntity;
exports.UserRoleEntity = UserRoleEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User Roles')], UserRoleEntity);
/**
 * User View Categories - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserViewCategory
 * * Base View: vwUserViewCategories
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserViewCategoryEntity = class UserViewCategoryEntity extends core_1.BaseEntity {
  /**
  * Loads the User View Categories record from the database
  * @param ID: string - primary key value to load the User View Categories record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserViewCategoryEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: ParentID
  * * Display Name: Parent ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: User View Categories (vwUserViewCategories.ID)
  */
  get ParentID() {
    return this.Get('ParentID');
  }
  set ParentID(value) {
    this.Set('ParentID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Parent
  * * Display Name: Parent
  * * SQL Data Type: nvarchar(100)
  */
  get Parent() {
    return this.Get('Parent');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.UserViewCategoryEntity = UserViewCategoryEntity;
exports.UserViewCategoryEntity = UserViewCategoryEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User View Categories')], UserViewCategoryEntity);
/**
 * User View Run Details - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserViewRunDetail
 * * Base View: vwUserViewRunDetails
 * * @description Tracks the set of records that were included in each run of a given user view.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserViewRunDetailEntity = class UserViewRunDetailEntity extends core_1.BaseEntity {
  /**
  * Loads the User View Run Details record from the database
  * @param ID: string - primary key value to load the User View Run Details record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserViewRunDetailEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * User View Run Details - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof UserViewRunDetailEntity
  * @throws {Error} - Delete is not allowed for User View Run Details, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for User View Run Details, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserViewRunID
  * * Display Name: User View Run ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: User View Runs (vwUserViewRuns.ID)
  */
  get UserViewRunID() {
    return this.Get('UserViewRunID');
  }
  set UserViewRunID(value) {
    this.Set('UserViewRunID', value);
  }
  /**
  * * Field Name: RecordID
  * * Display Name: Record
  * * SQL Data Type: nvarchar(450)
  */
  get RecordID() {
    return this.Get('RecordID');
  }
  set RecordID(value) {
    this.Set('RecordID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: UserViewID
  * * Display Name: User View
  * * SQL Data Type: uniqueidentifier
  */
  get UserViewID() {
    return this.Get('UserViewID');
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity
  * * SQL Data Type: uniqueidentifier
  */
  get EntityID() {
    return this.Get('EntityID');
  }
};
exports.UserViewRunDetailEntity = UserViewRunDetailEntity;
exports.UserViewRunDetailEntity = UserViewRunDetailEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User View Run Details')], UserViewRunDetailEntity);
/**
 * User View Runs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserViewRun
 * * Base View: vwUserViewRuns
 * * @description User Views can be logged when run to capture the date and user that ran the view as well as the output results.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserViewRunEntity = class UserViewRunEntity extends core_1.BaseEntity {
  /**
  * Loads the User View Runs record from the database
  * @param ID: string - primary key value to load the User View Runs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserViewRunEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * User View Runs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof UserViewRunEntity
  * @throws {Error} - Delete is not allowed for User View Runs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for User View Runs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserViewID
  * * Display Name: User View ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: User Views (vwUserViews.ID)
  */
  get UserViewID() {
    return this.Get('UserViewID');
  }
  set UserViewID(value) {
    this.Set('UserViewID', value);
  }
  /**
  * * Field Name: RunAt
  * * Display Name: Run At
  * * SQL Data Type: datetime
  */
  get RunAt() {
    return this.Get('RunAt');
  }
  set RunAt(value) {
    this.Set('RunAt', value);
  }
  /**
  * * Field Name: RunByUserID
  * * Display Name: Run By User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get RunByUserID() {
    return this.Get('RunByUserID');
  }
  set RunByUserID(value) {
    this.Set('RunByUserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: UserView
  * * Display Name: User View
  * * SQL Data Type: nvarchar(100)
  */
  get UserView() {
    return this.Get('UserView');
  }
  /**
  * * Field Name: RunByUser
  * * Display Name: Run By User
  * * SQL Data Type: nvarchar(100)
  */
  get RunByUser() {
    return this.Get('RunByUser');
  }
};
exports.UserViewRunEntity = UserViewRunEntity;
exports.UserViewRunEntity = UserViewRunEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User View Runs')], UserViewRunEntity);
/**
 * User Views - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: UserView
 * * Base View: vwUserViews
 * * @description Views are sets of records within a given entity defined by filtering rules. Views can be used programatically to retrieve dynamic sets of data and in user interfaces like MJ Explorer for end-user consumption.
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserViewEntity = class UserViewEntity extends core_1.BaseEntity {
  /**
  * Loads the User Views record from the database
  * @param ID: string - primary key value to load the User Views record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserViewEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: EntityID
  * * Display Name: Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get EntityID() {
    return this.Get('EntityID');
  }
  set EntityID(value) {
    this.Set('EntityID', value);
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: CategoryID
  * * Display Name: Category ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: User View Categories (vwUserViewCategories.ID)
  */
  get CategoryID() {
    return this.Get('CategoryID');
  }
  set CategoryID(value) {
    this.Set('CategoryID', value);
  }
  /**
  * * Field Name: IsShared
  * * Display Name: Is Shared
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsShared() {
    return this.Get('IsShared');
  }
  set IsShared(value) {
    this.Set('IsShared', value);
  }
  /**
  * * Field Name: IsDefault
  * * Display Name: Is Default
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsDefault() {
    return this.Get('IsDefault');
  }
  set IsDefault(value) {
    this.Set('IsDefault', value);
  }
  /**
  * * Field Name: GridState
  * * Display Name: Grid State
  * * SQL Data Type: nvarchar(MAX)
  */
  get GridState() {
    return this.Get('GridState');
  }
  set GridState(value) {
    this.Set('GridState', value);
  }
  /**
  * * Field Name: FilterState
  * * Display Name: Filter State
  * * SQL Data Type: nvarchar(MAX)
  */
  get FilterState() {
    return this.Get('FilterState');
  }
  set FilterState(value) {
    this.Set('FilterState', value);
  }
  /**
  * * Field Name: CustomFilterState
  * * Display Name: Custom Filter State
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get CustomFilterState() {
    return this.Get('CustomFilterState');
  }
  set CustomFilterState(value) {
    this.Set('CustomFilterState', value);
  }
  /**
  * * Field Name: SmartFilterEnabled
  * * Display Name: Smart Filter Enabled
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get SmartFilterEnabled() {
    return this.Get('SmartFilterEnabled');
  }
  set SmartFilterEnabled(value) {
    this.Set('SmartFilterEnabled', value);
  }
  /**
  * * Field Name: SmartFilterPrompt
  * * Display Name: Smart Filter Prompt
  * * SQL Data Type: nvarchar(MAX)
  */
  get SmartFilterPrompt() {
    return this.Get('SmartFilterPrompt');
  }
  set SmartFilterPrompt(value) {
    this.Set('SmartFilterPrompt', value);
  }
  /**
  * * Field Name: SmartFilterWhereClause
  * * Display Name: Smart Filter Where Clause
  * * SQL Data Type: nvarchar(MAX)
  */
  get SmartFilterWhereClause() {
    return this.Get('SmartFilterWhereClause');
  }
  set SmartFilterWhereClause(value) {
    this.Set('SmartFilterWhereClause', value);
  }
  /**
  * * Field Name: SmartFilterExplanation
  * * Display Name: Smart Filter Explanation
  * * SQL Data Type: nvarchar(MAX)
  */
  get SmartFilterExplanation() {
    return this.Get('SmartFilterExplanation');
  }
  set SmartFilterExplanation(value) {
    this.Set('SmartFilterExplanation', value);
  }
  /**
  * * Field Name: WhereClause
  * * Display Name: Where Clause
  * * SQL Data Type: nvarchar(MAX)
  */
  get WhereClause() {
    return this.Get('WhereClause');
  }
  set WhereClause(value) {
    this.Set('WhereClause', value);
  }
  /**
  * * Field Name: CustomWhereClause
  * * Display Name: Custom Where Clause
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get CustomWhereClause() {
    return this.Get('CustomWhereClause');
  }
  set CustomWhereClause(value) {
    this.Set('CustomWhereClause', value);
  }
  /**
  * * Field Name: SortState
  * * Display Name: Sort State
  * * SQL Data Type: nvarchar(MAX)
  */
  get SortState() {
    return this.Get('SortState');
  }
  set SortState(value) {
    this.Set('SortState', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: UserName
  * * Display Name: User Name
  * * SQL Data Type: nvarchar(100)
  */
  get UserName() {
    return this.Get('UserName');
  }
  /**
  * * Field Name: UserFirstLast
  * * Display Name: User First Last
  * * SQL Data Type: nvarchar(101)
  */
  get UserFirstLast() {
    return this.Get('UserFirstLast');
  }
  /**
  * * Field Name: UserEmail
  * * Display Name: User Email
  * * SQL Data Type: nvarchar(100)
  */
  get UserEmail() {
    return this.Get('UserEmail');
  }
  /**
  * * Field Name: UserType
  * * Display Name: User Type
  * * SQL Data Type: nchar(15)
  */
  get UserType() {
    return this.Get('UserType');
  }
  /**
  * * Field Name: Entity
  * * Display Name: Entity
  * * SQL Data Type: nvarchar(255)
  */
  get Entity() {
    return this.Get('Entity');
  }
  /**
  * * Field Name: EntityBaseView
  * * Display Name: Entity Base View
  * * SQL Data Type: nvarchar(255)
  */
  get EntityBaseView() {
    return this.Get('EntityBaseView');
  }
};
exports.UserViewEntity = UserViewEntity;
exports.UserViewEntity = UserViewEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'User Views')], UserViewEntity);
/**
 * Users - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: User
 * * Base View: vwUsers
 * * @description A list of all users who have or had access to the system
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let UserEntity = class UserEntity extends core_1.BaseEntity {
  /**
  * Loads the Users record from the database
  * @param ID: string - primary key value to load the Users record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof UserEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: FirstName
  * * Display Name: First Name
  * * SQL Data Type: nvarchar(50)
  */
  get FirstName() {
    return this.Get('FirstName');
  }
  set FirstName(value) {
    this.Set('FirstName', value);
  }
  /**
  * * Field Name: LastName
  * * Display Name: Last Name
  * * SQL Data Type: nvarchar(50)
  */
  get LastName() {
    return this.Get('LastName');
  }
  set LastName(value) {
    this.Set('LastName', value);
  }
  /**
  * * Field Name: Title
  * * Display Name: Title
  * * SQL Data Type: nvarchar(50)
  */
  get Title() {
    return this.Get('Title');
  }
  set Title(value) {
    this.Set('Title', value);
  }
  /**
  * * Field Name: Email
  * * SQL Data Type: nvarchar(100)
  */
  get Email() {
    return this.Get('Email');
  }
  set Email(value) {
    this.Set('Email', value);
  }
  /**
  * * Field Name: Type
  * * SQL Data Type: nchar(15)
  * * Value List Type: List
  * * Possible Values
  *   * User
  *   * Owner
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: IsActive
  * * Display Name: Is Active
  * * SQL Data Type: bit
  * * Default Value: 0
  */
  get IsActive() {
    return this.Get('IsActive');
  }
  set IsActive(value) {
    this.Set('IsActive', value);
  }
  /**
  * * Field Name: LinkedRecordType
  * * Display Name: Linked Record Type
  * * SQL Data Type: nchar(10)
  * * Default Value: None
  */
  get LinkedRecordType() {
    return this.Get('LinkedRecordType');
  }
  set LinkedRecordType(value) {
    this.Set('LinkedRecordType', value);
  }
  /**
  * * Field Name: LinkedEntityID
  * * Display Name: Linked Entity ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Entities (vwEntities.ID)
  */
  get LinkedEntityID() {
    return this.Get('LinkedEntityID');
  }
  set LinkedEntityID(value) {
    this.Set('LinkedEntityID', value);
  }
  /**
  * * Field Name: LinkedEntityRecordID
  * * Display Name: Linked Entity Record ID
  * * SQL Data Type: nvarchar(450)
  */
  get LinkedEntityRecordID() {
    return this.Get('LinkedEntityRecordID');
  }
  set LinkedEntityRecordID(value) {
    this.Set('LinkedEntityRecordID', value);
  }
  /**
  * * Field Name: EmployeeID
  * * Display Name: Employee
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Employees (vwEmployees.ID)
  */
  get EmployeeID() {
    return this.Get('EmployeeID');
  }
  set EmployeeID(value) {
    this.Set('EmployeeID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: __mj _Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: __mj _Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: FirstLast
  * * Display Name: First Last
  * * SQL Data Type: nvarchar(101)
  */
  get FirstLast() {
    return this.Get('FirstLast');
  }
  /**
  * * Field Name: EmployeeFirstLast
  * * Display Name: Employee First Last
  * * SQL Data Type: nvarchar(81)
  */
  get EmployeeFirstLast() {
    return this.Get('EmployeeFirstLast');
  }
  /**
  * * Field Name: EmployeeEmail
  * * Display Name: Employee Email
  * * SQL Data Type: nvarchar(100)
  */
  get EmployeeEmail() {
    return this.Get('EmployeeEmail');
  }
  /**
  * * Field Name: EmployeeTitle
  * * Display Name: Employee Title
  * * SQL Data Type: nvarchar(50)
  */
  get EmployeeTitle() {
    return this.Get('EmployeeTitle');
  }
  /**
  * * Field Name: EmployeeSupervisor
  * * Display Name: Employee Supervisor
  * * SQL Data Type: nvarchar(81)
  */
  get EmployeeSupervisor() {
    return this.Get('EmployeeSupervisor');
  }
  /**
  * * Field Name: EmployeeSupervisorEmail
  * * Display Name: Employee Supervisor Email
  * * SQL Data Type: nvarchar(100)
  */
  get EmployeeSupervisorEmail() {
    return this.Get('EmployeeSupervisorEmail');
  }
};
exports.UserEntity = UserEntity;
exports.UserEntity = UserEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Users')], UserEntity);
/**
 * Vector Databases - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: VectorDatabase
 * * Base View: vwVectorDatabases
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let VectorDatabaseEntity = class VectorDatabaseEntity extends core_1.BaseEntity {
  /**
  * Loads the Vector Databases record from the database
  * @param ID: string - primary key value to load the Vector Databases record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof VectorDatabaseEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Vector Databases - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof VectorDatabaseEntity
  * @throws {Error} - Delete is not allowed for Vector Databases, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Vector Databases, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: DefaultURL
  * * Display Name: Default URL
  * * SQL Data Type: nvarchar(255)
  */
  get DefaultURL() {
    return this.Get('DefaultURL');
  }
  set DefaultURL(value) {
    this.Set('DefaultURL', value);
  }
  /**
  * * Field Name: ClassKey
  * * Display Name: Class Key
  * * SQL Data Type: nvarchar(100)
  */
  get ClassKey() {
    return this.Get('ClassKey');
  }
  set ClassKey(value) {
    this.Set('ClassKey', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.VectorDatabaseEntity = VectorDatabaseEntity;
exports.VectorDatabaseEntity = VectorDatabaseEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Vector Databases')], VectorDatabaseEntity);
/**
 * Vector Indexes - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: VectorIndex
 * * Base View: vwVectorIndexes
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let VectorIndexEntity = class VectorIndexEntity extends core_1.BaseEntity {
  /**
  * Loads the Vector Indexes record from the database
  * @param ID: string - primary key value to load the Vector Indexes record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof VectorIndexEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Vector Indexes - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof VectorIndexEntity
  * @throws {Error} - Delete is not allowed for Vector Indexes, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Vector Indexes, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: VectorDatabaseID
  * * Display Name: Vector Database ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Vector Databases (vwVectorDatabases.ID)
  */
  get VectorDatabaseID() {
    return this.Get('VectorDatabaseID');
  }
  set VectorDatabaseID(value) {
    this.Set('VectorDatabaseID', value);
  }
  /**
  * * Field Name: EmbeddingModelID
  * * Display Name: Embedding Model ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: AI Models (vwAIModels.ID)
  */
  get EmbeddingModelID() {
    return this.Get('EmbeddingModelID');
  }
  set EmbeddingModelID(value) {
    this.Set('EmbeddingModelID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: VectorDatabase
  * * Display Name: Vector Database
  * * SQL Data Type: nvarchar(100)
  */
  get VectorDatabase() {
    return this.Get('VectorDatabase');
  }
  /**
  * * Field Name: EmbeddingModel
  * * Display Name: Embedding Model
  * * SQL Data Type: nvarchar(50)
  */
  get EmbeddingModel() {
    return this.Get('EmbeddingModel');
  }
};
exports.VectorIndexEntity = VectorIndexEntity;
exports.VectorIndexEntity = VectorIndexEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Vector Indexes')], VectorIndexEntity);
/**
 * Version Installations - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: VersionInstallation
 * * Base View: vwVersionInstallations
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let VersionInstallationEntity = class VersionInstallationEntity extends core_1.BaseEntity {
  /**
  * Loads the Version Installations record from the database
  * @param ID: string - primary key value to load the Version Installations record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof VersionInstallationEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Version Installations - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof VersionInstallationEntity
  * @throws {Error} - Delete is not allowed for Version Installations, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Version Installations, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: MajorVersion
  * * Display Name: Major Version
  * * SQL Data Type: int
  */
  get MajorVersion() {
    return this.Get('MajorVersion');
  }
  set MajorVersion(value) {
    this.Set('MajorVersion', value);
  }
  /**
  * * Field Name: MinorVersion
  * * Display Name: Minor Version
  * * SQL Data Type: int
  */
  get MinorVersion() {
    return this.Get('MinorVersion');
  }
  set MinorVersion(value) {
    this.Set('MinorVersion', value);
  }
  /**
  * * Field Name: PatchVersion
  * * Display Name: Patch Version
  * * SQL Data Type: int
  */
  get PatchVersion() {
    return this.Get('PatchVersion');
  }
  set PatchVersion(value) {
    this.Set('PatchVersion', value);
  }
  /**
  * * Field Name: Type
  * * Display Name: Type
  * * SQL Data Type: nvarchar(20)
  * * Default Value: System
  * * Value List Type: List
  * * Possible Values
  *   * New
  *   * Upgrade
  * * Description: What type of installation was applied
  */
  get Type() {
    return this.Get('Type');
  }
  set Type(value) {
    this.Set('Type', value);
  }
  /**
  * * Field Name: InstalledAt
  * * Display Name: Installed At
  * * SQL Data Type: datetime
  */
  get InstalledAt() {
    return this.Get('InstalledAt');
  }
  set InstalledAt(value) {
    this.Set('InstalledAt', value);
  }
  /**
  * * Field Name: Status
  * * Display Name: Status
  * * SQL Data Type: nvarchar(20)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * In Progress
  *   * Complete
  *   * Failed
  * * Description: Pending, Complete, Failed
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: InstallLog
  * * Display Name: Install Log
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Any logging that was saved from the installation process
  */
  get InstallLog() {
    return this.Get('InstallLog');
  }
  set InstallLog(value) {
    this.Set('InstallLog', value);
  }
  /**
  * * Field Name: Comments
  * * Display Name: Comments
  * * SQL Data Type: nvarchar(MAX)
  * * Description: Optional, comments the administrator wants to save for each installed version
  */
  get Comments() {
    return this.Get('Comments');
  }
  set Comments(value) {
    this.Set('Comments', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: CompleteVersion
  * * Display Name: Complete Version
  * * SQL Data Type: nvarchar(302)
  */
  get CompleteVersion() {
    return this.Get('CompleteVersion');
  }
};
exports.VersionInstallationEntity = VersionInstallationEntity;
exports.VersionInstallationEntity = VersionInstallationEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Version Installations')], VersionInstallationEntity);
/**
 * Workflow Engines - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: WorkflowEngine
 * * Base View: vwWorkflowEngines
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let WorkflowEngineEntity = class WorkflowEngineEntity extends core_1.BaseEntity {
  /**
  * Loads the Workflow Engines record from the database
  * @param ID: string - primary key value to load the Workflow Engines record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof WorkflowEngineEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Workflow Engines - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof WorkflowEngineEntity
  * @throws {Error} - Delete is not allowed for Workflow Engines, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Workflow Engines, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: DriverPath
  * * Display Name: Driver Path
  * * SQL Data Type: nvarchar(500)
  */
  get DriverPath() {
    return this.Get('DriverPath');
  }
  set DriverPath(value) {
    this.Set('DriverPath', value);
  }
  /**
  * * Field Name: DriverClass
  * * Display Name: Driver Class
  * * SQL Data Type: nvarchar(100)
  */
  get DriverClass() {
    return this.Get('DriverClass');
  }
  set DriverClass(value) {
    this.Set('DriverClass', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
};
exports.WorkflowEngineEntity = WorkflowEngineEntity;
exports.WorkflowEngineEntity = WorkflowEngineEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Workflow Engines')], WorkflowEngineEntity);
/**
 * Workflow Runs - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: WorkflowRun
 * * Base View: vwWorkflowRuns
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let WorkflowRunEntity = class WorkflowRunEntity extends core_1.BaseEntity {
  /**
  * Loads the Workflow Runs record from the database
  * @param ID: string - primary key value to load the Workflow Runs record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof WorkflowRunEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Workflow Runs - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof WorkflowRunEntity
  * @throws {Error} - Delete is not allowed for Workflow Runs, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Workflow Runs, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: WorkflowID
  * * Display Name: Workflow ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Workflows (vwWorkflows.ID)
  */
  get WorkflowID() {
    return this.Get('WorkflowID');
  }
  set WorkflowID(value) {
    this.Set('WorkflowID', value);
  }
  /**
  * * Field Name: ExternalSystemRecordID
  * * Display Name: External System Record
  * * SQL Data Type: nvarchar(500)
  */
  get ExternalSystemRecordID() {
    return this.Get('ExternalSystemRecordID');
  }
  set ExternalSystemRecordID(value) {
    this.Set('ExternalSystemRecordID', value);
  }
  /**
  * * Field Name: StartedAt
  * * Display Name: Started At
  * * SQL Data Type: datetime
  */
  get StartedAt() {
    return this.Get('StartedAt');
  }
  set StartedAt(value) {
    this.Set('StartedAt', value);
  }
  /**
  * * Field Name: EndedAt
  * * Display Name: Ended At
  * * SQL Data Type: datetime
  */
  get EndedAt() {
    return this.Get('EndedAt');
  }
  set EndedAt(value) {
    this.Set('EndedAt', value);
  }
  /**
  * * Field Name: Status
  * * SQL Data Type: nchar(10)
  * * Default Value: Pending
  * * Value List Type: List
  * * Possible Values
  *   * Pending
  *   * In Progress
  *   * Complete
  *   * Failed
  */
  get Status() {
    return this.Get('Status');
  }
  set Status(value) {
    this.Set('Status', value);
  }
  /**
  * * Field Name: Results
  * * SQL Data Type: nvarchar(MAX)
  */
  get Results() {
    return this.Get('Results');
  }
  set Results(value) {
    this.Set('Results', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Workflow
  * * Display Name: Workflow
  * * SQL Data Type: nvarchar(100)
  */
  get Workflow() {
    return this.Get('Workflow');
  }
  /**
  * * Field Name: WorkflowEngineName
  * * Display Name: Workflow Engine Name
  * * SQL Data Type: nvarchar(100)
  */
  get WorkflowEngineName() {
    return this.Get('WorkflowEngineName');
  }
};
exports.WorkflowRunEntity = WorkflowRunEntity;
exports.WorkflowRunEntity = WorkflowRunEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Workflow Runs')], WorkflowRunEntity);
/**
 * Workflows - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Workflow
 * * Base View: vwWorkflows
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let WorkflowEntity = class WorkflowEntity extends core_1.BaseEntity {
  /**
  * Loads the Workflows record from the database
  * @param ID: string - primary key value to load the Workflows record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof WorkflowEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * Workflows - AllowDeleteAPI is set to 0 in the database.  Delete is not allowed, so this method is generated to override the base class method and throw an error. To enable delete for this entity, set AllowDeleteAPI to 1 in the database.
  * @public
  * @method
  * @override
  * @memberof WorkflowEntity
  * @throws {Error} - Delete is not allowed for Workflows, to enable it set AllowDeleteAPI to 1 in the database.
  */
  async Delete() {
    throw new Error('Delete is not allowed for Workflows, to enable it set AllowDeleteAPI to 1 in the database.');
  }
  /**
  * * Field Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * SQL Data Type: nvarchar(100)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: WorkflowEngineID
  * * Display Name: Workflow Engine ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Workflow Engines (vwWorkflowEngines.ID)
  */
  get WorkflowEngineID() {
    return this.Get('WorkflowEngineID');
  }
  set WorkflowEngineID(value) {
    this.Set('WorkflowEngineID', value);
  }
  /**
  * * Field Name: ExternalSystemRecordID
  * * Display Name: External System Record
  * * SQL Data Type: nvarchar(100)
  */
  get ExternalSystemRecordID() {
    return this.Get('ExternalSystemRecordID');
  }
  set ExternalSystemRecordID(value) {
    this.Set('ExternalSystemRecordID', value);
  }
  /**
  * * Field Name: AutoRunEnabled
  * * Display Name: Auto Run Enabled
  * * SQL Data Type: bit
  * * Default Value: 0
  * * Description: If set to 1, the workflow will be run automatically on the interval specified by the AutoRunIntervalType and AutoRunInterval fields
  */
  get AutoRunEnabled() {
    return this.Get('AutoRunEnabled');
  }
  set AutoRunEnabled(value) {
    this.Set('AutoRunEnabled', value);
  }
  /**
  * * Field Name: AutoRunIntervalUnits
  * * Display Name: Auto Run Interval Units
  * * SQL Data Type: nvarchar(20)
  * * Value List Type: List
  * * Possible Values
  *   * Years
  *   * Months
  *   * Weeks
  *   * Days
  *   * Hours
  *   * Minutes
  * * Description: Minutes, Hours, Days, Weeks, Months, Years
  */
  get AutoRunIntervalUnits() {
    return this.Get('AutoRunIntervalUnits');
  }
  set AutoRunIntervalUnits(value) {
    this.Set('AutoRunIntervalUnits', value);
  }
  /**
  * * Field Name: AutoRunInterval
  * * Display Name: Auto Run Interval
  * * SQL Data Type: int
  * * Description: The interval, denominated in the units specified in the AutoRunIntervalUnits column, between auto runs of this workflow.
  */
  get AutoRunInterval() {
    return this.Get('AutoRunInterval');
  }
  set AutoRunInterval(value) {
    this.Set('AutoRunInterval', value);
  }
  /**
  * * Field Name: SubclassName
  * * Display Name: Subclass Name
  * * SQL Data Type: nvarchar(200)
  * * Description: If specified, this subclass key, via the ClassFactory, will be instantiated, to execute this workflow. If not specified the WorkflowBase class will be used by default.
  */
  get SubclassName() {
    return this.Get('SubclassName');
  }
  set SubclassName(value) {
    this.Set('SubclassName', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: AutoRunIntervalMinutes
  * * Display Name: Auto Run Interval Minutes
  * * SQL Data Type: int
  */
  get AutoRunIntervalMinutes() {
    return this.Get('AutoRunIntervalMinutes');
  }
};
exports.WorkflowEntity = WorkflowEntity;
exports.WorkflowEntity = WorkflowEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Workflows')], WorkflowEntity);
/**
 * Workspace Items - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: WorkspaceItem
 * * Base View: vwWorkspaceItems
 * * @description Tracks the resources that are active within a given worksapce
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let WorkspaceItemEntity = class WorkspaceItemEntity extends core_1.BaseEntity {
  /**
  * Loads the Workspace Items record from the database
  * @param ID: string - primary key value to load the Workspace Items record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof WorkspaceItemEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: WorkspaceID
  * * Display Name: Workspace ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Workspaces (vwWorkspaces.ID)
  */
  get WorkspaceID() {
    return this.Get('WorkspaceID');
  }
  set WorkspaceID(value) {
    this.Set('WorkspaceID', value);
  }
  /**
  * * Field Name: ResourceTypeID
  * * Display Name: Resource Type ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Resource Types (vwResourceTypes.ID)
  */
  get ResourceTypeID() {
    return this.Get('ResourceTypeID');
  }
  set ResourceTypeID(value) {
    this.Set('ResourceTypeID', value);
  }
  /**
  * * Field Name: ResourceRecordID
  * * Display Name: Resource Record ID
  * * SQL Data Type: nvarchar(2000)
  */
  get ResourceRecordID() {
    return this.Get('ResourceRecordID');
  }
  set ResourceRecordID(value) {
    this.Set('ResourceRecordID', value);
  }
  /**
  * * Field Name: Sequence
  * * Display Name: Sequence
  * * SQL Data Type: int
  */
  get Sequence() {
    return this.Get('Sequence');
  }
  set Sequence(value) {
    this.Set('Sequence', value);
  }
  /**
  * * Field Name: Configuration
  * * Display Name: Configuration
  * * SQL Data Type: nvarchar(MAX)
  */
  get Configuration() {
    return this.Get('Configuration');
  }
  set Configuration(value) {
    this.Set('Configuration', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: Workspace
  * * Display Name: Workspace
  * * SQL Data Type: nvarchar(255)
  */
  get Workspace() {
    return this.Get('Workspace');
  }
  /**
  * * Field Name: ResourceType
  * * Display Name: Resource Type
  * * SQL Data Type: nvarchar(255)
  */
  get ResourceType() {
    return this.Get('ResourceType');
  }
};
exports.WorkspaceItemEntity = WorkspaceItemEntity;
exports.WorkspaceItemEntity = WorkspaceItemEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Workspace Items')], WorkspaceItemEntity);
/**
 * Workspaces - strongly typed entity sub-class
 * * Schema: __mj
 * * Base Table: Workspace
 * * Base View: vwWorkspaces
 * * @description A user can have one or more workspaces
 * * Primary Key: ID
 * @extends {BaseEntity}
 * @class
 * @public
 */
let WorkspaceEntity = class WorkspaceEntity extends core_1.BaseEntity {
  /**
  * Loads the Workspaces record from the database
  * @param ID: string - primary key value to load the Workspaces record.
  * @param EntityRelationshipsToLoad - (optional) the relationships to load
  * @returns {Promise<boolean>} - true if successful, false otherwise
  * @public
  * @async
  * @memberof WorkspaceEntity
  * @method
  * @override
  */
  async Load(ID, EntityRelationshipsToLoad = null) {
    const compositeKey = new core_1.CompositeKey();
    compositeKey.KeyValuePairs.push({
      FieldName: 'ID',
      Value: ID
    });
    return await super.InnerLoad(compositeKey, EntityRelationshipsToLoad);
  }
  /**
  * * Field Name: ID
  * * Display Name: ID
  * * SQL Data Type: uniqueidentifier
  * * Default Value: newsequentialid()
  */
  get ID() {
    return this.Get('ID');
  }
  /**
  * * Field Name: Name
  * * Display Name: Name
  * * SQL Data Type: nvarchar(255)
  */
  get Name() {
    return this.Get('Name');
  }
  set Name(value) {
    this.Set('Name', value);
  }
  /**
  * * Field Name: Description
  * * Display Name: Description
  * * SQL Data Type: nvarchar(MAX)
  */
  get Description() {
    return this.Get('Description');
  }
  set Description(value) {
    this.Set('Description', value);
  }
  /**
  * * Field Name: UserID
  * * Display Name: User ID
  * * SQL Data Type: uniqueidentifier
  * * Related Entity/Foreign Key: Users (vwUsers.ID)
  */
  get UserID() {
    return this.Get('UserID');
  }
  set UserID(value) {
    this.Set('UserID', value);
  }
  /**
  * * Field Name: __mj_CreatedAt
  * * Display Name: Created At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_CreatedAt() {
    return this.Get('__mj_CreatedAt');
  }
  /**
  * * Field Name: __mj_UpdatedAt
  * * Display Name: Updated At
  * * SQL Data Type: datetimeoffset
  * * Default Value: getutcdate()
  */
  get __mj_UpdatedAt() {
    return this.Get('__mj_UpdatedAt');
  }
  /**
  * * Field Name: User
  * * Display Name: User
  * * SQL Data Type: nvarchar(100)
  */
  get User() {
    return this.Get('User');
  }
};
exports.WorkspaceEntity = WorkspaceEntity;
exports.WorkspaceEntity = WorkspaceEntity = __decorate([(0, global_1.RegisterClass)(core_1.BaseEntity, 'Workspaces')], WorkspaceEntity);
