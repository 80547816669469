<!-- <div class="breadcrumb"><a routerLink="/"><kendo-icon name="home"></kendo-icon></a> : Search Organizations</div> -->
<div class="search-main-wrapper">
    <div class="breadcrumbWrapper">
        <div class="container-large">
          <div class="breadcrumb">
            <a routerLink="/">
              <kendo-icon name="home"></kendo-icon>
            </a>
            <kendo-icon name="arrow-chevron-right"></kendo-icon> <a class="active">Search by Organization</a>
      
          </div>
          <div class="back-sec">
            <a routerLink="/">
            <kendo-icon name="arrow-chevron-left"></kendo-icon>
            Back
            </a>
          </div>
        </div>
      </div>
    <div class="header-banner">
        <h2>Search Any Organization You Want</h2>
        <div class="search-header">
            <input kendoTextBox type="text" [(ngModel)]="searchText" class="search-textbox"
                placeholder="Search by Name, Web Domain, or Tax ID" (keydown.enter)="doSearch()" />
        <button kendoButton (click)="doSearch()" class="search-button">
            <img src="../../assets/zoom.png" alt="" /> Search
        </button>
        </div>
        </div>
        <!-- <kendo-loader *ngIf="runningSearch"></kendo-loader> -->
        <div class="gg-loader" *ngIf="runningSearch">
            <img src="../../assets/loader.gif"/>
        </div>
        <div class="no-data" *ngIf="!runningSearch && !searchResults">
            <div class="img-wrap">
                <img src="../../assets/noDataImg.png" alt="" />
                <p>
                    Uncover Earning Insights: Begin Your Search for Comprehensive Salary
                    Data and Trends
                </p>
            </div>
        </div>
        <div *ngIf="runningSearch === false && !!searchResults" class="result-data-wrapper">
            <div class="container-large">
                <div class="result-data">
                    <span>{{searchResults.length}} Results</span>
                </div>
                <div class="search-results">
                    <kendo-grid [kendoGridBinding]="searchResults" [sortable]="true" [pageable]="true"
                        [pageSize]="10" (cellClick)="navToOrg($event)">
                        <kendo-grid-column *ngFor="let col of visibleColumns" [title]="getColumnDisplayName(col)"
                            [sticky]="col.Name === 'Name' ? true : false" [field]="col.Name" [format]="getColumnFormat(col)"
                            [width]="getColumnWidth(col)">
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
    </div>