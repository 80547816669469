import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EntityFieldInfo, EntityInfo, LogError, Metadata, RunView } from '@memberjunction/core';
import { TaxReturnEntity } from 'mj_generatedentities';
import { SharedService } from '../shared-service';

@Component({
  selector: 'app-organization-search',
  templateUrl: './organization-search.component.html',
  styleUrls: ['./organization-search.component.css']
})
export class OrganizationSearchComponent implements OnInit {
  public searchText: string = '';
  public searchResults: any[] | null = null;
  public visibleColumns: any[] = [];
  public runningSearch: boolean = false;

  constructor (private router: Router, private route: ActivatedRoute, private sharedService: SharedService) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const search = params['search'];
      if (search) {
        // Run your search function here
        this.searchText = search;
        this.doSearch();
      }    
    });
  }

  public async doSearch() {
    try {
      // check to see if our last search was the same as the current one
      const lastSearchText = SharedService.GetCacheItem('org-search-string');
      if (lastSearchText === this.searchText) {
        // we already have the results, so just use them
        this.searchResults = SharedService.GetCacheItem('org-search-results');
      }
      else {
        this.runningSearch = true; // not the same search, turn on the indicator

        this.sharedService.setupComplete$.subscribe(async (isComplete) => {
          if (isComplete) {
            const rv = new RunView();
            const md = new Metadata();
            const trEntity = md.Entities.find(e => e.Name === 'Tax Returns');
            if (!trEntity) 
              throw new Error('Could not find entity Tax Returns');
      
            const result = await rv.RunView({
              ViewName: '_Salary_Pro_Tax_Returns',
              OrderBy: 'TotalGrossReceipts DESC, Name ASC',
              UserSearchString: this.searchText
            })
            if (result && result.Success) {
              // we need to reduce the array first and only take the Latest Tax REturn for each Tax ID
              // this is because the view returns all tax returns for an org, but we only want the latest one
              const latestTaxReturns = result.Results.reduce((acc: TaxReturnEntity[], curr: TaxReturnEntity) => {
                const existing = acc.find(item => item.TaxID === curr.TaxID);
              
                if (!existing) {
                  acc.push(curr);
                } else if (existing.TaxYear < curr.TaxYear) {
                  Object.assign(existing, curr);
                }
              
                return acc;
              }, []);        
      
              this.searchResults = latestTaxReturns;
              this.setupVisibleColumns(result.Results, trEntity);
            }
            this.router.navigate([], {
              queryParams: { search: this.searchText },
              queryParamsHandling: 'merge', // merge with the current query params
              replaceUrl: true // if you want to replace the current entry in the history
            });
      
            // save the search results and search string into the SharedService.DataCache
            // so we don't re-run it each time the user goes back to the page wihtout changing the search
            SharedService.SetCacheItem('org-search-results', this.searchResults);
            SharedService.SetCacheItem('org-search-string', this.searchText);

            this.runningSearch = false;
          }
        });
      }
    }
    catch (e) {
      this.runningSearch = false;
      this.sharedService.DisplayNotification('Error running search', 'error');
      LogError(e);
    }
  }

  protected setupVisibleColumns(data: any[], taxReturnEntity: EntityInfo) {
    this.visibleColumns = [];
    if (data.length > 0) {
      const row = data[0];
      const keys = Object.keys(row);
      for (const key of keys) {
        switch (key.trim().toLowerCase()) {
          case 'id':
          case 'totalgrossreceipts':
          case 'missiondescription':
          case 'summarytext':
          case 'activitydescription':
            // skip these columns if the view returns them, we dont wnt to show them here
            break;
          default:
            // get the entity field from the entity definition and add to visible columns
            const field = taxReturnEntity.Fields.find(f => f.Name === key);
            if (!field)
              throw new Error(`Could not find field ${key} in entity ${taxReturnEntity.Name}`);
            this.visibleColumns.push(field);
            break;
        }
      }
    }
  }

  getColumnFormat(col: EntityFieldInfo) {
    // col.Type contains the MS SQL Server data type of the column (not including the length)
    // we need to format the dates, the numbers, and the money columns
    switch (col.Type) {
      case 'datetime':
        return '{0:d}';
      case 'decimal':
        return '{0:n0}'
      case 'money':
        return '{0:c0}';
      default: 
        return '';
    }

  }

  getColumnWidth(col: EntityFieldInfo) {
    switch (col.Type) {
      case 'datetime':
        return 150;
      case 'decimal':
      case 'money':
        return 110;
      case 'int':
        return 85;
      default:
        return 175;
    }
  }

  getColumnDisplayName(col: EntityFieldInfo){
    if (col.Name.trim().toLowerCase() === "taxyear") {
      return "Latest Tax Return"
    }
    else
      return col.DisplayName ? col.DisplayName : col.Name
  }

  navToOrg(row: any) {
    this.router.navigate(['/orgs', row.dataItem.TaxID]);
  }

}
