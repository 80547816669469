import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LogError, Metadata, RunView, RunViewParams, EntityInfo } from '@memberjunction/core';
import { TaxReturnContractorEntity, IndustryEntity, NAICSEntity, ReportRequestEntity, Report__ggEntity } from 'mj_generatedentities';
import { MJEventType, MJGlobal } from '@memberjunction/global';
import { SharedData } from '../shared-data';
import { SharedService } from '../shared-service';
import { NumberSuffixPipe } from '../NumberSuffixPipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


interface FormValues {
  Name: string;
  SourceAccount: any;
  MaxNumberOfPeers: any;
  TargetRoles: any[];
  TargetIndustry: any;
  TargetMetroSize: any;
  TargetRevenueSize: any;
  TargetEmployeeSize: any;
  LLMQuestion: string;
}

@Component({
  selector: 'app-benchmark',
  templateUrl: './benchmark.component.html',
  styleUrls: ['./benchmark.component.css']
})
export class BenchmarkComponent implements OnInit {
  reportHTML: SafeHtml;
  loaded: boolean = false;
  existingRecords: any[] = [];
  data: any = {}

  industries: any[] = [];
  metroSizes: any[] = [];
  revenueSizes: any[] = [];
  employeeSizes: any[] = [];
  roles: any[] = [];
  peers: any[] = [];
  accounts: any[] = [];
  entity: FormValues;
  allAccounts: any[];

  constructor(private route: ActivatedRoute, 
    private router: Router, 
    private sharedService: SharedService, 
    public sharedData: SharedData, 
    public numberSuffixPipe: NumberSuffixPipe, 
    private sanitizer: DomSanitizer
  ) { }

  formatRange = (dataItem: any, formatAsCurrency: boolean) => {
    let formatter;

    if (formatAsCurrency) {
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    }
    else {
      formatter = new Intl.NumberFormat('en-US');
    }

    const minVal = formatter.format(dataItem.MinVal);
    const maxVal = formatter.format(dataItem.MaxVal);
    // Format the dataItem here and return a string
    return `${dataItem.Name} (${minVal} - ${maxVal})`; // example
  };


  public async onRowClick(event: any) {
    this.loaded = false
    const rv = new RunView();
    const report = await rv.RunView({
      EntityName: 'Reports__gg',
      ExtraFilter: `ReportRequestID=${event.dataItem.ID}`,
    })
    this.router.navigate(['/benchmark', report.Results[0].ID]);
    this.stopPolling()
    this.loaded = true
  }

  async getExistingResults() {
        const rv = new RunView();
        const existingResult = await rv.RunView({
          EntityName: 'Report Requests',
          OrderBy: "ID DESC",
        })
        this.existingRecords = existingResult.Results
  }


  async ngOnInit(): Promise<void> {
    this.loaded = false;
    MJGlobal.Instance.GetEventListener(true).subscribe(async (e) => {
      if (e.event === MJEventType.LoggedIn) {
        this.getExistingResults()

        // get data for dropdowns
        this.sharedService.setupComplete$.subscribe(async (isComplete) => {
          if (isComplete) {
            this.industries = this.sharedData.Industries;
            this.metroSizes = this.sharedData.MetroAreaSizes.map(item => ({
              ...item,
              formattedName: this.formatRange(item, false)
            }));
            this.revenueSizes = this.sharedData.OrgRevenueSizes.map(item => ({
              ...item,
              formattedName: this.formatRange(item, true)
            }));
            this.employeeSizes = this.sharedData.OrgEmployeeSizes.map(item => ({
              ...item,
              formattedName: this.formatRange(item, false)
            }));
            this.roles = this.sharedData.ContactRoles;
            this.peers = [{Value: 10}, {Value: 20}, {Value:50}, {Value:100}]

            this.allAccounts = this.sharedData.Accounts
            this.accounts = this.allAccounts.slice(0, 20)

            this.entity = {
              Name: 'Default Report Name',
              SourceAccount: this.allAccounts[0],
              MaxNumberOfPeers: this.peers[2],
              TargetRoles: [this.roles[3]],
              TargetIndustry: this.industries[9],
              TargetMetroSize: this.metroSizes[1],
              TargetRevenueSize: this.revenueSizes[1],
              TargetEmployeeSize: this.employeeSizes[2],
              LLMQuestion: '',
            }

            this.startPolling()

            this.loaded = true
            }
          });
      }
    });
  } 

  private pollingInterval: any;
  async startPolling() {
    this.pollingInterval = setInterval(() => {
      console.log('Polling for new results');
      this.getExistingResults();
      console.log('Polling complete');  
    }, 30000); // 30 seconds
  }
  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }


  async getContacts(accountId) {
    const rv = new RunView();
    const result = await rv.RunView({
      EntityName: 'Contacts',
      Fields: ["RoleID", "Role"],
      IgnoreMaxRows: true,
      OrderBy: "ID",
      ExtraFilter: `AccountID=${accountId}`
    })

    return new Set(result.Results.map((contact: any) => contact.RoleID));
  }

  public onFilterChange(filter: any): void {
    if (filter) {
      this.accounts = this.allAccounts.filter(account => account.Name.toLowerCase().includes(filter.toLowerCase())).slice(0, 20);
    } else {
      this.accounts = this.allAccounts.slice(0, 20);
    }
  }

  public async onSourceAccountChange(event) {
    // TODO: autofill more fields
    if (event) {
      console.log(event)
      const rv = new RunView();
      if (event.IndustryID) {
        this.entity.TargetIndustry = {ID : event.IndustryID, Name: event.Industry}
      }

      // restrict roles to those that exist in the account
      const contactRoles = await this.getContacts(event.ID)
      this.roles = this.sharedData.ContactRoles.filter(role => contactRoles.has(role.ID));
      // default to CEO, if exists
      if (this.roles.some(role => role.Name.trim().toLowerCase() === 'executive director/ceo')) {
        this.entity.TargetRoles = [this.roles.find(role => role.Name.trim().toLowerCase() === 'executive director/ceo')]
      } else {
        this.entity.TargetRoles = [this.roles[0]] 
      }

    }
  }

  public async onSubmit() {
    this.loaded = false
    const reportId = await this.createReport(this.entity)
    this.router.navigate(['/benchmark', reportId]);
    this.stopPolling()
    this.loaded = true
  }

  public async createReport(formValues: FormValues) {
    try {

      const md = new Metadata();
      const newReportRequest = await md.GetEntityObject('Report Requests') as unknown as ReportRequestEntity;
      newReportRequest.NewRecord()
      newReportRequest.Name = formValues.Name
      newReportRequest.SourceAccountID = Math.floor(formValues.SourceAccount.ID)
      newReportRequest.MaxNumberOfPeers = formValues.MaxNumberOfPeers.Value
      newReportRequest.TargetIndustryID = formValues.TargetIndustry.ID
      newReportRequest.TargetMinMetroSize = formValues.TargetMetroSize.MinVal
      newReportRequest.TargetMaxMetroSize = formValues.TargetMetroSize.MaxVal
      newReportRequest.TargetMinRevenue = formValues.TargetRevenueSize.MinVal
      newReportRequest.TargetMaxRevenue = formValues.TargetRevenueSize.MaxVal
      newReportRequest.TargetMinNumberOfEmployees = formValues.TargetEmployeeSize.MinVal
      newReportRequest.TargetMaxNumberOfEmployees = formValues.TargetEmployeeSize.MaxVal
      newReportRequest.TargetPositions = JSON.stringify(formValues.TargetRoles) // TODO: remname DB column
      newReportRequest.Status = 'Pending'
      newReportRequest.RequestingUserID = 9
      newReportRequest.LLMQuestion = formValues.LLMQuestion
      await newReportRequest.Save()

      while (newReportRequest.Status === 'Pending') {
        this.loaded = false
        await newReportRequest.Load(newReportRequest.ID)
        console.log('Waiting for report to be generated - status: ' + newReportRequest.Status)
        await new Promise(resolve => setTimeout(resolve, 5000));
      }

      const rv = new RunView();
      const reportView = await rv.RunView({
        EntityName: 'Reports__gg',
        ExtraFilter: `ReportRequestID=${newReportRequest.ID}`,
      })
      return reportView.Results[0].ID

    }
    catch (e) {
      this.sharedService.DisplayNotification("Error", 'error')
      LogError(e);
    }
  }


} 