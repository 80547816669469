import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';
import { Metadata, RunView, RunViewParams } from '@memberjunction/core';
import { ContactLevelEntity, ContactRoleEntity, IndustryEntity, RangeClassEntity, TaxReturnCompensationAggregationEntity } from 'mj_generatedentities';
import { MJEventType, MJGlobal } from '@memberjunction/global';
import { SharedData } from '../shared-data';
import { SharedService } from '../shared-service';
import { MultiColumnComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { NumberSuffixPipe } from '../NumberSuffixPipe';

interface TaxReturnCompensationAggregationEntityInterface extends InstanceType<typeof TaxReturnCompensationAggregationEntity> {
  [key: string]: any;
}

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  public loaded: boolean = false;
  public taxData: TaxReturnCompensationAggregationEntityInterface[] = [];
  public averageSalaries: any[] = [];
  public groupByFieldTitle: string = "";
  public groupByField: string = "";
  public sortField: string | undefined = undefined;
  public chartCategories: string[] = [];
  public allLevels: ContactLevelEntity[] = [];
  public labelFont = "bold 12px Arial, sans-serif";

  public minTaxYear: number = 0;
  public maxTaxYear: number = 0;
  public selectedTaxYears: [number, number] = [0 , 0]
  public taxYearsLabel: string = "";

  public selectedMetroAreaSize: RangeClassEntity | undefined = undefined;
  public selectedIndustry: IndustryEntity | undefined = undefined;
  public selectedOrgSizeEmployees: RangeClassEntity | undefined = undefined;
  public selectedOrgSizeRevenue: RangeClassEntity | undefined = undefined;
  public selectedContactLevel: ContactLevelEntity | undefined = undefined;
  public hideFilters: boolean = false;
  public activeState: string = 'chart';
  public filterDialogOpened: boolean = false;
  public filterDialogData: any = {};
  public activeFiltersCount: number = 0;
 

  @Input() public roleId: number | null = null
  @Input() public roleRecord: ContactRoleEntity | undefined = undefined;


  @ViewChild('filterMetroAreaSize', { static: false }) filterMetroAreaSize!: MultiColumnComboBoxComponent;
  @ViewChild('filterIndustry', { static: false }) filterIndustry!: MultiColumnComboBoxComponent;
  @ViewChild('filterOrgSizeEmployees', { static: false }) filterOrgSizeEmployees!: MultiColumnComboBoxComponent;
  @ViewChild('filterOrgSizeRevenue', { static: false }) filterOrgSizeRevenue!: MultiColumnComboBoxComponent;
  @ViewChild('filterContactLevel', { static: false }) filterContactLevel!: MultiColumnComboBoxComponent;

  public yearTitles = (value: number): string => {
    return this.minTaxYear === value || this.maxTaxYear === value ? value.toString() : '';
  };

  constructor(private route: ActivatedRoute, private router: Router, private sharedService: SharedService, public sharedData: SharedData, public titleService: Title,  public numberSuffixPipe: NumberSuffixPipe) { 
    this.route.params.subscribe(params => {
      let roleId = params['roleId'];
      if (roleId !== undefined && roleId !== null && !this.roleId) 
        this.roleId = parseInt(roleId);
    });
  }

  async ngOnInit(): Promise<void> {
    MJGlobal.Instance.GetEventListener(true).subscribe(async (e) => {
      if (e.event === MJEventType.LoggedIn) {
        this.loadData();
      }
    });
  } 

  protected evaluateQueryParams() {
    this.route.queryParams.subscribe(params => {
      // make sure we've loaded the data before we try to apply the filters
      if (params['groupByField']) {
        this.groupByField = params['groupByField'];
      }
      if (params['groupByFieldTitle']) {
        this.groupByFieldTitle = params['groupByFieldTitle'];
      }
      if (params['sortField']) {
        this.sortField = params['sortField'];
      }
      if (params['taxYearStart']) {
        this.selectedTaxYears[0] = params['taxYearStart'];
      }
      if (params['taxYearEnd']) {
        this.selectedTaxYears[1] = params['taxYearEnd'];
      }
      if (params['metroAreaSize']) {
        const metroAreaSize = this.sharedData.MetroAreaSizes.find(m => m.Name === params['metroAreaSize']);
        this.selectedMetroAreaSize = metroAreaSize;
      }
      if (params['industry']) {
        const industry = this.sharedData.Industries.find(i => i.Name === params['industry']);
        this.selectedIndustry = industry;
      }
      if (params['employees']) {
        const employees = this.sharedData.OrgEmployeeSizes.find(e => e.Name === params['employees']);
        this.selectedOrgSizeEmployees = employees;
      }
      if (params['revenue']) {
        const revenue = this.sharedData.OrgRevenueSizes.find(r => r.Name === params['revenue']);
        this.selectedOrgSizeRevenue = revenue;
      }
      if (params['contactLevel']) {
        const contactLevel = this.sharedData.ContactLevels.find(c => c.Name === params['contactLevel']);
        this.selectedContactLevel = contactLevel;
      }

      // we do NOT call groupAndFilter here because that is done by the caller of this method
    });    
  }
 


  public getGroupedAndFilteredAverageSalaries() {
    const recentData = this.handleFiltering();
    return this.doGrouping(recentData);
  }

  protected handleFiltering(): TaxReturnCompensationAggregationEntity[] {
    let recentData = this.taxData;
    this.activeFiltersCount = 0;
    // Read the current query parameters
    
    const currentQueryParams = { ...this.route.snapshot.queryParams };
    if (this.selectedTaxYears) {
      recentData = this.taxData.filter(record => 
                                                  (record.TaxYear >= this.selectedTaxYears[0] || this.selectedTaxYears[0] === null) && 
                                                  (record.TaxYear <= this.selectedTaxYears[1] || this.selectedTaxYears[1] === null));
      if (this.selectedTaxYears[0])                                              
        currentQueryParams['taxYearStart'] = this.selectedTaxYears[0];
      else
        delete currentQueryParams['taxYearStart'];
      if (this.selectedTaxYears[1])
        currentQueryParams['taxYearEnd'] = this.selectedTaxYears[1];
      else
        delete currentQueryParams['taxYearEnd'];

      if(+this.selectedTaxYears[0] !== this.minTaxYear || +this.selectedTaxYears[1] !== this.maxTaxYear)
        this.activeFiltersCount++;
    }
    else {
      delete currentQueryParams['taxYearStart']
      delete currentQueryParams['taxYearEnd'];
    }
    
    // now apply other filters if we have any, and make sure the references to the Angular components exist as we don't have those when the form first loads
    if (this.selectedMetroAreaSize ||
        this.selectedIndustry || 
        this.selectedOrgSizeEmployees ||
        this.selectedOrgSizeRevenue ||
        this.selectedContactLevel ) {
          const metroAreaSize = this.selectedMetroAreaSize;
          const industry = this.selectedIndustry;
          const employees = this.selectedOrgSizeEmployees;
          const revenue = this.selectedOrgSizeRevenue;
          const contactLevel = this.selectedContactLevel;
          if (metroAreaSize) {
            this.activeFiltersCount++;
            recentData = recentData.filter(record => record.MetroAreaSize === metroAreaSize.Name);
            currentQueryParams['metroAreaSize'] = metroAreaSize.Name;
          }
          else
            delete currentQueryParams['metroAreaSize'];

          if (industry) {
            this.activeFiltersCount++;
            recentData = recentData.filter(record => record.Industry === industry.Name);
            currentQueryParams['industry'] = industry.Name;
          }
          else
            delete currentQueryParams['industry'];

          if (employees) {
            this.activeFiltersCount++;
            recentData = recentData.filter(record => record.OrgSizeEmployees === employees.Name);
            currentQueryParams['employees'] = employees.Name;
          }
          else
            delete currentQueryParams['employees'];

          if (revenue) {
            this.activeFiltersCount++;
            recentData = recentData.filter(record => record.OrgSizeRevenue === revenue.Name);
            currentQueryParams['revenue'] = revenue.Name;
          }
          else
            delete currentQueryParams['revenue'];

          if (contactLevel) {
            this.activeFiltersCount++;
            recentData = recentData.filter(record => record.ContactLevel === contactLevel.Name);
            currentQueryParams['contactLevel'] = contactLevel.Name;
          }
          else
            delete currentQueryParams['contactLevel'];
    }
    else {
      delete currentQueryParams['metroAreaSize'];
      delete currentQueryParams['industry'];
      delete currentQueryParams['employees'];
      delete currentQueryParams['revenue'];
      delete currentQueryParams['contactLevel'];
    }

    // finally add the gruoping stuff to the query params
    currentQueryParams['groupByFieldTitle'] = this.groupByFieldTitle
    currentQueryParams['groupByField'] = this.groupByField;

    if (this.sortField)
      currentQueryParams['sortField'] = this.sortField;
    else
      delete currentQueryParams['sortField'];

    // Navigate with the updated query parameters
    this.router.navigate(
      [], // don't change anything, just update query params
      {
        relativeTo: this.route,
        queryParams: currentQueryParams // replace the query params with the updated ones since we started with a copy of the current ones and may have deleted stuff for prior filters
      }
    );    

    return recentData;
  }

  protected doGrouping = (recentData: TaxReturnCompensationAggregationEntityInterface[]) => {
    // Group data by the groupingField and calculate the average salary
    const groupedData: any = {};
    for (let data of recentData) {
      if (groupedData[data[this.groupByField]]) {
        groupedData[data[this.groupByField]].totalComp += data.AverageComp * data.RecordCount;
        groupedData[data[this.groupByField]].totalCount += data.RecordCount;
      } else {
        groupedData[data[this.groupByField]] = {
          totalComp: data.AverageComp * data.RecordCount,
          totalCount: data.RecordCount
        };
      }
    }
 
    // Compute average compensation and record count for each group
    const averages = Object.keys(groupedData).map(group => ({
      Group: group,
      AverageComp: groupedData[group].totalComp / groupedData[group].totalCount,
      RecordCount: groupedData[group].totalCount
    }));
  
    const sortField: string = this.sortField || 'AverageComp';
    if (sortField) {
      const s = sortField;
      // sorting specified by the caller
      if (averages[0] && s in averages[0]) {
        // If sortingField exists in the averages array, sort directly
        averages.sort((a: any, b: any) => {
          if (a[s] < b[s])
            return -1;
          else if (a[s] > b[s])
            return 1;
          else
            return 0;
        });
      } 
      else {
        // the field we want to sort on IS NOT in the averages array, so we need to look it up
        // for each of the groups and sort based on that value
        averages.sort((a, b) => {
          const aGroup = this.taxData.find(record => record[this.groupByField] === a.Group);
          const bGroup = this.taxData.find(record => record[this.groupByField] === b.Group);
          if (aGroup && bGroup) {
            if (aGroup[s] < bGroup[s])
              return -1;
            else if (aGroup[s] > bGroup[s])
              return 1;
            else
              return 0;
          }
          else
            return 0;
        });
      }
    }
  
    return averages;
  }


  async groupAndFilterData() {
    setTimeout(() => {
      if (this.selectedTaxYears[0] === this.selectedTaxYears[1])
        this.taxYearsLabel = `${this.selectedTaxYears[0]}`;
      else
        this.taxYearsLabel = `${this.selectedTaxYears[0]} - ${this.selectedTaxYears[1]}`;

      this.averageSalaries = this.getGroupedAndFilteredAverageSalaries()  
      this.chartCategories = this.averageSalaries.map(record => record.Group); // get all the group names

      // update the browser title
      this.titleService.setTitle(`${this.roleRecord?.Name} - ${this.taxYearsLabel} - ${this.groupByFieldTitle} - ${environment.APP_TITLE}`)
    }, 50); // create a small delay because we want to make sure that the Angular process has caught up with data binding
  }

  // event handler from button click
  handleGroupBy(groupByField: string, groupByFieldTitle: string) {
    this.groupByField = groupByField;
    this.groupByFieldTitle = groupByFieldTitle;
    this.groupAndFilterData();
  }

  

  async loadData() {
    this.sharedService.setupComplete$.subscribe(async (isComplete) => {
      if (isComplete) {
        if (this.roleId !== null && this.roleId !== undefined) {
          if (this.roleRecord === null || this.roleRecord === undefined) {
            this.roleRecord = this.sharedData.ContactRoles.find(r => r.ID === this.roleId);
          }
          if (this.roleRecord !== null && this.roleRecord !== undefined) {
            // get the tax data for this role from the shared data cache
            const role = this.roleRecord.Name;
            this.taxData = this.sharedData.TaxReturnComp.filter(t => t.ContactRole === role)

            this.minTaxYear = Math.min(...this.taxData.map(t => t.TaxYear));
            this.maxTaxYear = Math.max(...this.taxData.map(t => t.TaxYear));
            this.selectedTaxYears = [this.minTaxYear, this.maxTaxYear]; // default is to have all years

            this.evaluateQueryParams(); // do this after data is loaded, sets the properties of this class for filtering and grouping from query params when provided

            if (this.groupByField === "") {
              this.groupByField = "OrgSizeRevenue";
              this.groupByFieldTitle = "Org Size/Revenue";
            }
            this.groupAndFilterData(); // filter and group the data

            this.loaded = true;
          }
        }
      }
    });
  }


  getBarColor(index: any, item: any): string {
    // A list of colors that should be easily visible on most devices.
    // You can replace these with any colors you like.
    const colors = [
      "#AEC6CF", // pastel blue
      "#FDD2B1", // peach
      "#77DD77", // pastel green
      "#FFB347", // pastel orange
      "#B39EB5", // pastel purple
      "#FF6961", // pastel red
      "#CB99C9", // pastel violet
      "#FDFD96", // pastel yellow
      "#B19CD9", // pastel lavender
      "#FFB6C1", // pastel pink
    ];
  

    const idx = isNaN(index) ? index.index : index;
    // Use the modulo operator to cycle through the colors if there are more items than colors.
    return colors[idx % colors.length];
  }

  public labelContent = (e: any): string => {
    // const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //     minimumFractionDigits: 0,
    //     maximumFractionDigits: 0
    // });
    return `$${this.numberSuffixPipe.transform(e.dataItem.AverageComp, 1)} (${e.dataItem.RecordCount} records)`;
  }

  xAxisLabels = (event: any): string => {
    if(innerWidth <= 768 && ![0,event.count -1,Math.floor((event.count /2))].includes(event.index)){
      return '';
    }
    return `$${this.numberSuffixPipe.transform(event.value,1)}`;
  }

  public categoryLabelContent(e: any): string {
    return e.dataItem.Group;// `${formatter.format(e.dataItem.averageComp)} (${e.dataItem.recordCount} records)`;
  }

  onSeriesClick(e: SeriesClickEvent): void {
    this.handleDrillDown(e.category)
  }

  onGridRowClick(e: any) {
    this.handleDrillDown(e.dataItem.Group)
  }

  handleDrillDown(drillDownValue: string) {
    if (this.roleRecord) {
      if (this.roleRecord) {
        const qp = {
          roleId: this.roleRecord.ID,
          groupByField: this.groupByField,
          groupByFieldTitle: this.groupByFieldTitle,
          sortField: this.sortField,
          taxYearStart: this.selectedTaxYears[0],
          taxYearEnd: this.selectedTaxYears[1],
          metroAreaSize: this.selectedMetroAreaSize?.Name,
          industry: this.selectedIndustry?.Name,
          employees: this.selectedOrgSizeEmployees?.Name,
          revenue: this.selectedOrgSizeRevenue?.Name,
          contactLevel: this.selectedContactLevel?.Name,
          drillDownValue: drillDownValue
        };
        this.router.navigate(['salary-drill-down'],{ queryParams: qp});  
      }
    }
  }

  navTo(url: string) {
    this.router.navigate([url]);
  }

  toggleFilter() {
    this.hideFilters = !this.hideFilters;
  }

  setActiveState(state: string) {
    this.activeState = state;
  }

  openFilterDialog() {
    this.filterDialogOpened = true;
    this.filterDialogData = {
      ...this.filterDialogData,
      selectedMetroAreaSize: this.selectedMetroAreaSize,
      selectedIndustry: this.selectedIndustry,
      selectedOrgSizeEmployees: this.selectedOrgSizeEmployees,
      selectedOrgSizeRevenue: this.selectedOrgSizeRevenue,
      selectedContactLevel: this.selectedContactLevel,
      selectedTaxYears: this.selectedTaxYears,
      groupByField: this.groupByField,
      groupByFieldTitle: this.groupByFieldTitle,
      years: [this.minTaxYear, this.maxTaxYear]
    };
  }

  closeFilterDialog(data: any | undefined = undefined) {
    this.filterDialogOpened = false;
    if(data) {
      this.filterDialogData = data;
      this.selectedMetroAreaSize = data.selectedMetroAreaSize;
      this.selectedIndustry = data.selectedIndustry;
      this.selectedOrgSizeEmployees = data.selectedOrgSizeEmployees;
      this.selectedOrgSizeRevenue = data.selectedOrgSizeRevenue;
      this.selectedContactLevel = data.selectedContactLevel;
      this.selectedTaxYears = data.selectedTaxYears;
      this.groupByField = data.groupByField;
      this.groupByFieldTitle = data.groupByFieldTitle;
      this.groupAndFilterData();
    }
  }
  
} 