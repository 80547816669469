"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExplorerNavigationItem = void 0;
const baseInfo_1 = require("./baseInfo");
/**
 * Metadata about a single item in the explorer navigation options
 */
class ExplorerNavigationItem extends baseInfo_1.BaseInfo {
  constructor(initData = null) {
    super();
    this.ID = null;
    this.Sequence = null;
    this.Name = null;
    this.Route = null;
    this.IsActive = null;
    this.ShowInHomeScreen = null;
    this.ShowInNavigationDrawer = null;
    this.IconCSSClass = null;
    this.Description = null;
    this.Comments = null;
    this.__mj_CreatedAt = null;
    this.__mj_UpdatedAt = null;
    if (initData) {
      this.copyInitData(initData);
    }
  }
}
exports.ExplorerNavigationItem = ExplorerNavigationItem;
