<div class="custom-toolbar">
    <button
        kendoButton
        [svgIcon]="menuSvg"
        fillMode="flat"
        (click)="drawer.toggle()"
    ></button>
</div>
<kendo-drawer-container>
    <kendo-drawer #drawer
        [items]="items"
        mode="push"
        [mini]="true"
        [expanded]="true"
        [autoCollapse]="false"
        (select)="onSelect($event)">
        <ng-template kendoDrawerItemTemplate let-item>
            <div class="k-drawer-link" [routerLink]="item.path">
              <kendo-svgicon [icon]="item.svgIcon"></kendo-svgicon>
              <span>{{ item.text }}</span>
            </div>
          </ng-template>
    </kendo-drawer>

    <kendo-drawer-content>
        <my-content [selectedItem]="selected"></my-content>
    </kendo-drawer-content>
</kendo-drawer-container>